import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

import LoadingSpinner from "components/feedback/LoadingSpinner";
import {updateUserDrawerState} from "atoms/updateUserDrawerState";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import Card from "components/card/Card";
import {toastState} from "atoms/toastState";
import logger from "infra/logger";
import {loggedInUserState} from "atoms/loggedInUserState";
import {userDrawerSelectOptions} from "../variables/userDrawerSelectOptions";
import RoleBasedRender from "components/wrappers/roleBasedRender";

const UpdateUserDrawer = (props) => {
  const [selectedRole, setSelectedRole] = useState("");
  const setToast = useSetRecoilState(toastState);
  const [drawerState, setDrawerState] = useRecoilState(updateUserDrawerState);
  const [user, setUser] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    MobileNumber: "",
  });
  const loggedInUser = useRecoilValue(loggedInUserState);
  const validationSchema = yup.object().shape({
    FirstName: yup.string(),
    LastName: yup.string(),
    MobileNumber: yup
      .string()
      .max(10)
      .matches(/^[0-9]*$/, "Mobile Number must contain only digits"),
    Email: yup.string().email("Invalid email address"),
    Role: yup.string(),
    Type: yup.string(),
  });

  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [{loading: userLoading}, getUser,cancelRequest] = useAxios(
    {method: "GET", url: `/api/user/${drawerState?.activeId}`},
    {manual: true}
  );

  const [{data: updatedUser, loading: updateUserLoading}, updateUser] =
    useAxios(
      {method: "PATCH", url: `/api/user/update/${drawerState?.activeId}`},
      {manual: true}
    );

  const getUserDetails = async () => {
    try {
      const response = await getUser();
      const data = response.data.data;
      setValue("FirstName", data.FirstName);
      setValue("LastName", data.LastName);
      setValue("Email", data.Email);
      setValue("MobileNumber", data.MobileNumber);
      setValue("Role", data.Role);
      setValue("Type", data.Type);
      setUser((prev) => {
        return {
          ...prev,
          FirstName: data.FirstName,
          LastName: data.LastName,
          Email: data.Email,
          MobileNumber: data.MobileNumber,
          Role: data.Role,
          Type: data.Type,
        };
      });
      setSelectedRole(data.Role);
      logger.log("user", data);
    } catch (error) {
      logger.log(error);
      if (error?.code != "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "Error occured while getting user details",
          status: "error",
        });
        closeHandler();
      }
    }
  };

  const closeHandler = () => {
    setDrawerState(null);
  };

  const saveHandler = async () => {
    const formValues = getValues();
    let userToUpdate = {};
    if (
      formValues.Email.trim() !== "" &&
      formValues.Email.trim() !== user.Email
    ) {
      userToUpdate["Email"] = formValues.Email.trim();
    }
    if (
      formValues.FirstName.trim() !== "" &&
      formValues.FirstName.trim() !== user.FirstName
    ) {
      userToUpdate["FirstName"] = formValues.FirstName.trim();
    }
    if (
      formValues.LastName.trim() !== "" &&
      formValues.LastName.trim() !== user.LastName
    ) {
      userToUpdate["LastName"] = formValues.LastName.trim();
    }
    if (
      formValues.MobileNumber.trim() !== "" &&
      formValues.MobileNumber.trim() !== user.MobileNumber
    ) {
      userToUpdate["MobileNumber"] = formValues.MobileNumber.trim();
    }
    if (formValues.Role !== "" && formValues.Role !== user.Role) {
      userToUpdate["Role"] = formValues.Role;
      if (formValues.Role === "manager" || formValues.Role === "property_owner") {
        userToUpdate["Type"] = "";
      }
    }
    if (formValues.Type !== user.Type) {
      userToUpdate["Type"] = formValues.Type;
    }

    logger.log(userToUpdate);
    if (Object.keys(userToUpdate).length > 0) {
      try {
        const response = await updateUser({data: userToUpdate});
        setToast({
          isOpen: true,
          title: "User details Updated",
          status: "success",
        });
        props.update();
      } catch (error) {
        const err = error?.response?.data?.error;
        logger.log(error);
        setToast({
          isOpen: true,
          title: "Error occured when updating User",
          description: err ? err : null,
          status: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        title: "nothing new to update",
        status: "warning",
      });
    }
  };

  const handleRoleChange = (event) => {
    const newRole = event.target.value;
    setSelectedRole(newRole);
    setValue("Role", newRole);
  };

  useEffect(() => {
    if (drawerState?.activeId) {
      getUserDetails();
      return () => cancelRequest();
    }
  }, []);

  return (
    <Drawer
      isOpen={drawerState?.isOpen}
      placement="right"
      onClose={closeHandler}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          Update Your Details {drawerState?.activeId && user["Email"]}
        </DrawerHeader>

        <DrawerBody>
          {!userLoading && !updateUserLoading ? (
            <>
              <Card>
                {/* {drawerState?.activeId && <UserCard user={user} />} */}
                <form>
                  {userDrawerSelectOptions.map((item, index) => (
                    <Flex key={index} mb="20px" alignItems="center">
                      <Controller
                        name={item.name}
                        control={control}
                        defaultValue=""
                        render={({field}) => (
                          <FormControl
                            flexDirection="row"
                            isInvalid={errors[item.name]}
                          >
                            <FormLabel fontSize={20}>{item.label}</FormLabel>
                            {item.type === "select" ? (
                              <Select
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                width="400px"
                                height="50px"
                                onChange={
                                  item.name === "Role"
                                    ? handleRoleChange
                                    : field.onChange
                                }
                              >
                                <option value="">Select</option>
                                {item.options.map((option, ind) => {
                                  return (
                                    <option key={ind} value={option.value}>
                                      {option.name}
                                    </option>
                                  );
                                })}
                              </Select>
                            ) : (
                              <Input
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                placeholder={item.placeholder}
                                width="400px"
                                height="50px"
                                type={item.type}
                                isRequired
                              />
                            )}
                            <FormHelperText color={"#f57d36"}>
                              {item.helper}
                            </FormHelperText>
                            <FormErrorMessage mb={"20px"}>
                              {errors[item.name] && errors[item.name].message}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    </Flex>
                  ))}
                   {selectedRole === "admin" && loggedInUser?.tenantType === "bco" && (
                  <Flex mb="20px" alignItems="center">
                    <Controller
                      name="Type"
                      control={control}
                      defaultValue=""
                      render={({field}) => (
                        <FormControl
                          flexDirection="row"
                          isInvalid={errors.Type}
                        >
                          <FormLabel fontSize={20}>Type</FormLabel>
                          <Select
                            {...field}
                            focusBorderColor="#f57d36"
                            fontSize="sm"
                            width="400px"
                            height="50px"
                          >
                            <option value="">Select</option>
                            <option value="investor">Investor</option>
                          </Select>
                          {/* <FormHelperText color={"#f57d36"}>
                            Please select a type
                          </FormHelperText> */}
                          <FormErrorMessage mb={"20px"}>
                            {errors.Type && errors.Type.message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  </Flex>
                )}
                </form>
              </Card>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            bg="#f57d36"
            color="white"
            colorScheme="orange"
            onClick={handleSubmit(saveHandler)}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default UpdateUserDrawer;
