// atoms/payRunDrawerState.js
import { atom } from 'recoil';

export const PayRunDrawerState = atom({
  key: 'PayRunDrawerState',
  default: {
    isOpen: false,
    data: null,
  },
});
