import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import {yupResolver} from "@hookform/resolvers/yup";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {MdMessage} from "react-icons/md";
import * as yup from "yup";
import useAxios from "axios-hooks";
import {useRecoilValue, useSetRecoilState} from "recoil";

import {activeConnectorState} from "atoms/activeConnectorState";
import {toastState} from "atoms/toastState";
import Card from "components/card/Card";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import logger from "infra/logger";

//import { TbMessage } from "react-icons/tb";

const ChangeAvailability = ({refresh}) => {
  const activeConnector = useRecoilValue(activeConnectorState);
  const setToast = useSetRecoilState(toastState);
  const [{data, loading, error}, execute] = useAxios(
    {method: "POST", url: "/api/ocpp16/changeAvailability"},
    {manual: true}
  ); 
  const validationSchema = yup.object().shape({
    Type: yup.string().required("Please Select IdTag"),
  });

  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const controllers = [
    {
      name: "Type",
      label: "Type",
      type: "select",
      helperText: "Select Operative/InOperative",
    },
  ].map((el, ind) => {
    const options = ["Inoperative", "Operative"].map((ele, index) => {
      return (
        <option value={ele} key={index}>
          {ele}
        </option>
      );
    });
    return (
      <Controller
        key={ind}
        name={el.name}
        control={control}
        render={({field}) => (
          <FormControl as="fieldset" maxW={200} isInvalid={errors[el.name]}>
            <FormLabel as="legend" fontSize={18}>
              {el.label}
            </FormLabel>
            {el.type === "select" && (
              <Select required placeholder="Select Availability" {...field}>
                {options}
              </Select>
            )}
            <FormHelperText>{el?.helperText}</FormHelperText>
            <FormErrorMessage mb={"20px"}>
              {errors[el.name] && errors[el.name].message}
            </FormErrorMessage>
          </FormControl>
        )}
      />
    );
  });

  const operation = async () => {
    const formValues = getValues();
    let body = {};
    body["ConnectorId"] = activeConnector?.Code;
    body["Availability"] = formValues.Type;
    body["ChargerId"] = activeConnector?.ChargePointCode
    try {
      const response = await execute({data: body});
      setToast({
        isOpen: true,
        title: "Availability Changed",
        description: response.data.data.Message,
        status: "success",
      });
    } catch (error) {
      const err = error?.response?.data?.error;
      logger.log(error);
      setToast({
        isOpen: true,
        title: "Error occured when changing Availability",
        description: err ? err : null,
        status: "error",
      });
    }
    refresh();
  };
  return loading ? (
    <LoadingSpinner />
  ) : (
    <Card alignItems="center" pt={0}>
      <Text fontWeight={700} fontSize={20} borderBottom="1px solid black">
        Change Availability
      </Text>
      <SimpleGrid columns={{sm: 2, md: 2, lg: 3}} spacing={5} mb={5}>
        {controllers}
      </SimpleGrid>
      <Button
        borderRadius={5}
        textColor={"white"}
        backgroundColor="#f57d36"
        _hover={{backgroundColor: "whatsapp.600"}}
        rightIcon={<MdMessage />}
        onClick={handleSubmit(operation)}
      >
        Send Message
      </Button>
    </Card>
  );
};

export default ChangeAvailability;
