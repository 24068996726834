import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import {yupResolver} from "@hookform/resolvers/yup";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {MdMessage} from "react-icons/md";
import * as yup from "yup";
import useAxios from "axios-hooks";
import {useRecoilValue, useSetRecoilState} from "recoil";

import {activeConnectorState} from "atoms/activeConnectorState";
import {toastState} from "atoms/toastState";
import Card from "components/card/Card";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import logger from "infra/logger";

const TriggerMessage = ({refresh}) => {
  const activeConnector = useRecoilValue(activeConnectorState);

  const setToast = useSetRecoilState(toastState);
  const [{data, loading, error}, execute] = useAxios(
    {method: "POST", url: "/api/ocpp16/triggerMessage"},
    {manual: true}
  );
  const validationSchema = yup.object().shape({
    Message: yup.string().required("Please select Message"),
  });

  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const controllers = [
    {
      name: "Message",
      label: "Message Triggers",
      type: "radio",
      helperText: "Select one Message Trigger",
    },
  ].map((ele) => {
    const options = [
      "BootNotification",
      "DiagnosticsStatusNotification",
      "FirmwareStatusNotification",
      "Heartbeat",
      "MeterValues",
      "StatusNotification",
    ].map((el, index) => {
      return (
        <Radio key={index} value={el}>
          {el}
        </Radio>
      );
    });
    return (
      <Controller
        name={ele.name}
        control={control}
        render={({field}) => (
          <FormControl as="fieldset" maxW={200} isInvalid={errors[ele.name]}>
            <FormLabel as="legend" fontSize={18}>
              {ele.label}
            </FormLabel>
            {ele.type === "radio" && (
              <RadioGroup
                required
                placeholder="Select one Message Trigger"
                {...field}
              >
                <Stack gap={2} align={"column"} my={2}>
                  {options}
                </Stack>
              </RadioGroup>
            )}
            <FormHelperText>{ele?.helperText}</FormHelperText>
            <FormErrorMessage mb={"20px"}>
              {errors[ele.name] && errors[ele.name].message}
            </FormErrorMessage>
          </FormControl>
        )}
      />
    );
  });

  const operation = async () => {
    const formValues = getValues();
    let body = {};
    body["ConnectorId"] = activeConnector.Code;
    body["ChargerId"] = activeConnector.ChargePointCode;
    body["Trigger"] = formValues.Message;
    try {
      const response = await execute({data: body});
      setToast({
        isOpen: true,
        title: "Message Triggered SSuccessfully",
        description: response.data.data.Message,
        status: "success",
      });
    } catch (error) {
      const err = error?.response?.data?.error;
      logger.log(error);
      setToast({
        isOpen: true,
        title: "Error occured when Triggering Message",
        description: err ? err : null,
        status: "error",
      });
    }
    refresh();
  };
  return loading ? (
    <LoadingSpinner />
  ) : (
    <Card alignItems="center" pt={0}>
      <Text
        fontWeight={700}
        fontSize={20}
        borderBottom="1px solid black"
        mb={5}
      >
        Trigger Message
      </Text>
      <SimpleGrid columns={{sm: 2, md: 2, lg: 3}} spacing={5} mb={5}>
        {controllers}
      </SimpleGrid>
      <Button
        borderRadius={5}
        textColor={"white"}
        backgroundColor="#f57d36"
        _hover={{backgroundColor: "whatsapp.600"}}
        rightIcon={<MdMessage />}
        onClick={handleSubmit(operation)}
      >
        Send Message
      </Button>
    </Card>
  );
};

export default TriggerMessage;
