const connectorTypeOptions = [
    {name: "AC Type 1", value: "AC Type 1"},
    {name: "AC Type 2", value: "AC Type 2"},
    {name: "CCS Type 2", value: "CCS Type 2"},
    {name: "CHAdeMO", value: "CHAdeMO"},
  ];
  
export const updateChargePointConnectorSelectOptions = [
    {
      label: "Code",
      placeholder: "Code",
      name: "Code",
      type: "number",
    },
    {
      label: "Type of Connector",
      placeholder: "Type of Connector",
      name: "TypeOfConnector",
      type: "select",
      options: connectorTypeOptions,
    },
    {
      label: "Qr Code",
      placeholder: "Qr Code",
      name: "QrCode",
      type: "input",
    },
  ]

export const createChargePointConnectorSelectOptions = [
  {
    label: "Code",
    placeholder: "Code",
    name: "Code",
    type: "input",
  },
  {
    label: "Type of Connector",
    placeholder: "Type of Connector",
    name: "TypeOfConnector",
    type: "select",
    options: connectorTypeOptions,
  },
  {
    label: "Qr Code",
    placeholder: "Qr Code",
    name: "QrCode",
    type: "input",
  },
]