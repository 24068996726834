export const tableHeaders = [
  {
    Header: "S.No",
    // accessor: "data.name", // Accessing name from the JSON data
  },

  {
    Header: "First Name",
    // accessor: "data.name", // Accessing name from the JSON data
  },
  {
    Header: "Last Name",
    // accessor: "data.phoneNumber", // Accessing phoneNumber from the JSON data
  },
  {
    Header: "Phone Number",
    // accessor: "data.city", // Accessing city from the JSON data
  },
  {
    Header: "Email",
    // accessor: "data.chargingPoints[0].isActive", // Accessing isActive from the chargingPoints array in JSON data
  },
  // {
  //   Header: "Status",
  //   // accessor: "data.chargingPoints[0].isActive", // Accessing isActive from the chargingPoints array in JSON data
  // },
  {
    Header: "Role",
  },
  {
    Header: "Type",
  },
  {
    Header: "Actions",
    // accessor: "data.chargingPoints[0].isActive", // Accessing isActive from the chargingPoints array in JSON data
  },
];
