// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom Components
import {ItemContent} from "components/menu/ItemContent";
import {SearchBar} from "components/navbar/searchBar/SearchBar";
import {SidebarResponsive} from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React from "react";
// Assets
import navImage from "assets/img/layout/Navbar.png";
import {
  MdNotificationsNone,
  MdInfoOutline,
  MdAccountCircle,
  MdOutlineSettings,
  MdPerson,
  MdSettings,
  MdAccountBalance,
} from "react-icons/md";
import {FaEthereum} from "react-icons/fa";
import routes from "routes.js";
import {ThemeEditor} from "./ThemeEditor";
import useAuthentication from "hooks/UseAuthentication";

import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {loggedInUserState} from "atoms/loggedInUserState";
import {URZZA_CMS_ADMIN, TENANT_CMS_ADMIN} from "utils/constants";
import {IoLogOutOutline} from "react-icons/io5";
import logger from "infra/logger";
import {ADMIN} from "utils/constants";
import {accessLevels} from "utils/accessLevels";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import {onlyOtherAdminsAndPropertyOwners} from "utils/accessLevels";

const HeaderLinks = (props) => {
  const {logout} = useAuthentication();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const {secondary} = props;
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");

  const navigate = useNavigate();

  const signOutHandler = async () => {
    try {
      await logout();
    } catch (error) {
      logger.error("Error occurred while signing out:", error);
    }
  };

  const changeToSettings = async () => {
    navigate("/admin/account");
  };

  return (
    <Flex
      w={{sm: "100%", md: "auto"}}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? {base: "wrap", md: "nowrap"} : "unset"}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      {/* <SearchBar
        mb={secondary ? { base: "10px", md: "unset" } : "unset"}
        me="10px"
        borderRadius="30px"
      /> */}
      <Flex
        bg={ethBg}
        display={secondary ? "flex" : "none"}
        borderRadius="30px"
        ms="auto"
        p="6px"
        align="center"
        me="6px"
      ></Flex>
      <SidebarResponsive routes={routes} />
      {/* <Menu>
        <MenuButton p="0px">
          <Icon
            mt="6px"
            as={MdNotificationsNone}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: "30px", md: "unset" }}
          minW={{ base: "unset", md: "400px", xl: "450px" }}
          maxW={{ base: "360px", md: "unset" }}
        >
          <Flex jusitfy="space-between" w="100%" mb="20px">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Notifications
            </Text>
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              ms="auto"
              cursor="pointer"
            >
              Mark all read
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <ItemContent info="Horizon UI Dashboard PRO" aName="Alicia" />
            </MenuItem>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <ItemContent
                info="Horizon Design System Free"
                aName="Josh Henry"
              />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu> */}

      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{cursor: "pointer"}}
            color="white"
            name={loggedInUser?.name}
            bg="#f57d36"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          w={"100%"}
        >
          <Flex
            w="100%"
            mb="0px"
            direction={"row"}
            align="center"
            justifyContent={"flex-start"}
            pl={5}
            borderBottom="1px solid"
            borderColor={borderColor}
            color="black"
          >
            <MdAccountCircle size={30} color="#f57d36" />
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              fontSize="md"
              fontWeight="700"
              color={textColor}
            >
              {loggedInUser?.name.toUpperCase()}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px" gap={"3px"}>
            <RoleBasedRender roles={onlyOtherAdminsAndPropertyOwners}>
              <MenuItem
                _focus={{bg: "none"}}
                color="green"
                borderRadius="8px"
                bg={"#e4e8ff"}
                p={0}
                overflow={"hidden"}
              >
                <Button
                  justifyContent={"start"}
                  py={6}
                  px={4}
                  borderRadius={"0px"}
                  width={"100%"}
                  _hover={{
                    bg: "blue",
                    color: "white",
                    py: "7",
                  }}
                  leftIcon={<MdAccountBalance size={25} />}
                  color={"blue"}
                  colorScheme="none"
                  fontSize="sm"
                  onClick={changeToSettings}
                >
                  Account
                </Button>
              </MenuItem>
            </RoleBasedRender>

            <MenuItem
              _focus={{bg: "none"}}
              color="red"
              borderRadius="8px"
              bg={"#fef2f1"}
              p={0}
              overflow={"hidden"}
            >
              <Button
                justifyContent={"start"}
                py={6}
                px={4}
                borderRadius={"0px"}
                width={"100%"}
                _hover={{
                  bg: "red",
                  color: "white",
                  py: "7",
                  transition: ".25s all ease",
                }}
                leftIcon={<IoLogOutOutline size={25} />}
                color={"red"}
                colorScheme="none"
                fontSize="sm"
                onClick={signOutHandler}
              >
                Log out
              </Button>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
};

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};

export default HeaderLinks;
