import PageNotFound from "404";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ChargingStation from "views/admin/chargingStation";
import AddChargingStation from "views/admin/chargingStation/AddChargingStation";
import ChargersList from "views/admin/chargingStation/ChargersList";
import ViewChargingStation from "views/admin/chargingStation/ViewChargingStation";
import Dashboard from "views/admin/dashboard";
import Users from "views/admin/users";
import SignIn from "views/auth/signIn";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import UsersList from "views/admin/users/UsersList";
import RfidCards from "views/admin/rfidCards";
import RfidCardsList from "views/admin/rfidCards/RfidCardsList";
import Payouts from "views/admin/payouts";
import ForgotPasswordPage from "views/auth/signIn/ForgotPasswordPage";
import OtpVerificationPage from "views/auth/signIn/OtpVerificationPage";
import ResetPasswordPage from "views/auth/signIn/ResetPasswordPage";
import Billing from "views/admin/account/Billing";
import DeleteAcc from "views/admin/account/DeleteAcc";
import TenantScreen from "views/admin/tenants";
import Sources from "views/admin/sources";
import SourcesList from "views/admin/sources/SourcesList";
import Partners from "views/admin/partners";
import PartnersList from "views/admin/partners/PartnersList";
import Tenants from "views/admin/tenants/components/Tenants";
import AccountSettings from "views/admin/account/AccountSettings";
import Tenant from "views/admin/account";
import PrivateRoute from "./PrivateRoute";
import { onlyAdmins } from "utils/accessLevels";
import { onlyUAdmins } from "utils/accessLevels";
import { onlyAdminsAndPropertyOwners } from "utils/accessLevels";
import { adminsAndManagers } from "utils/accessLevels";
import { onlyOtherAdminsAndPropertyOwners } from "utils/accessLevels";
import DashboardTabs from "views/admin/dashboard/DashboardTabs";
import DashboardOverviewTab from "views/admin/dashboard/components/DashboardOverviewTab";
import DashboardAnalyticsTab from "views/admin/dashboard/components/DashboardAnalyticsTab";
import PerformanceReportTab from "views/admin/dashboard/components/PerformanceReportTab";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Navigate to={"/auth"} />} />

      <Route path="/auth" element={<AuthLayout />}>
        <Route path="signIn" element={<SignIn />} />
        <Route path="forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="verifyotp" element={<OtpVerificationPage />} />
        <Route path="resetpassword" element={<ResetPasswordPage />} />
      </Route>

      <Route path="/resetpassword" element={<ResetPasswordPage />} />

      <Route path="/admin/*" element={<AdminLayout />}>
        <Route index element={<Navigate to={"dashboard"} />} />
        <Route path="dashboard/*" element={<Dashboard />} >
          <Route index element={<Navigate to={"overview"} />} />
          <Route path="overview" element={<DashboardTabs />} />
          <Route path="analytics" element={<DashboardTabs />} />
          <Route path="performancereport" element={<PrivateRoute element={DashboardTabs} roles={onlyUAdmins} /> } />
        </Route>

        <Route path="chargingstations/*" element={<ChargingStation />}>
          <Route index element={<Navigate to={"list"} />} />
          <Route path="new" element={<PrivateRoute element={AddChargingStation} roles={onlyAdmins} />} />
          <Route path="list" element={<ChargersList />} /> 
          <Route path="view/:id/*" element={<ViewChargingStation />}>
          </Route>
        </Route>

        <Route path="chargepoints/*" element={<ChargingStation />}>
          <Route index element={<Navigate to={"list"} />} />
          <Route path="list" element={<ChargersList />} />
        </Route>

        <Route path="payouts" element={<PrivateRoute element={Payouts} roles={onlyAdminsAndPropertyOwners} />} />

        <Route path="rfid_cards/*" element={<PrivateRoute element={RfidCards} roles={adminsAndManagers} />}>
          <Route index element={<Navigate to={"list"} />} />
          <Route path="list" element={<RfidCardsList />} />
        </Route>

        <Route path="users/*" element={<PrivateRoute element={Users} roles={onlyAdmins} />}>
          <Route index element={<Navigate to={"list"} />} />
          <Route path="list" element={<UsersList />} />
        </Route>

        <Route path="account/*" element={<PrivateRoute element={Tenant} roles={onlyOtherAdminsAndPropertyOwners} />}>
          <Route index element={<Navigate to={"settings"} />} />
          <Route path="settings" element={<AccountSettings />} />
          <Route path="billing" element={<Billing />} />
          <Route path="delete" element={<DeleteAcc />} />
        </Route>

        <Route path="tenants/*" element={<PrivateRoute element={TenantScreen} roles={onlyUAdmins} />}>
          <Route index element={<Navigate to={"list"} />} />
          <Route path="list" element={<Tenants />} />
        </Route>
         <Route path="sources/*" element={<PrivateRoute element={Sources} roles={onlyUAdmins} />}>
          <Route index element={<Navigate to={"list"} />} />
          <Route path="list" element={<SourcesList />} />
        </Route>
        <Route path="partners/*" element={<PrivateRoute element={Partners} roles={onlyUAdmins} />}>
          <Route index element={<Navigate to={"list"} />} />
          <Route path="list" element={<PartnersList />} />
        </Route>
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRoutes;
