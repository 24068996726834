import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import {yupResolver} from "@hookform/resolvers/yup";
import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import * as yup from "yup";
import useAxios from "axios-hooks";

import {toastState} from "atoms/toastState";
import {updateConnectorDrawerState} from "atoms/updateConnectorDrawerState";
import Card from "components/card/Card";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import logger from "infra/logger";
import { updateChargePointConnectorSelectOptions } from "../variables/chargePointConnectorSelectOptions";

const UpdateChargePointConnectorDrawer = () => {
  const [connector, SetConnector] = useState(null);
  const drawer = useRecoilValue(updateConnectorDrawerState);
  const setToast = useSetRecoilState(toastState);
  const [drawerState, setDrawerState] = useRecoilState(
    updateConnectorDrawerState
  );
  const [
    {data: updateData, loading: updateLoading, error: updateError},
    UpdateConnector,
  ] = useAxios(
    {method: "PATCH", url: `/api/chargePointConnector/update/${drawer.activeId}`},
    {manual: true}
  );

  const [{data: getData, loading: getLoadiing, error: getError}, getConnector] =
    useAxios(
      {method: "Get", url: `/api/chargePointConnector/${drawer.activeId}`},
      {manual: true}
    );
  const validationSchema = yup.object().shape({
    Code: yup.number().min(1).required("Code is required"),
    TypeOfConnector: yup
      .string()
      .oneOf(
        ["AC Type 1", "AC Type 2", "CCS Type 2", "CHAdeMO"],
        "Select a valid option"
      )
      .required("Type of Connector is required"),
    QrCode: yup.string().min(1).required("Qr Code is required"),
  });

  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const connectorTypeOptions = [
    {name: "AC Type 1", value: "AC Type 1"},
    {name: "AC Type 2", value: "AC Type 2"},
    {name: "CCS Type 2", value: "CCS Type 2"},
    {name: "CHAdeMO", value: "CHAdeMO"},
  ];

  const updateHandler = async () => {
    const formValues = getValues();
    let data = {};

    if (formValues.Code > 0 && formValues.Code  != connector.Code ) {
      data["Code"] = parseInt(formValues.Code);
    }
    if (formValues.TypeOfConnector.trim() !== "" && formValues.TypeOfConnector.trim() !== connector.TypeOfConnector) {
      data["TypeOfConnector"] = formValues.TypeOfConnector.trim();
    }
     if (formValues.QrCode.trim() !== "" && formValues.QrCode.trim() !== connector.QrCode ) {
      data["QrCode"] = formValues.QrCode.trim();
    }
    if (Object.keys(data).length > 0) {
      try {
        const response = await UpdateConnector({data: data});
        setToast({
          isOpen: true,
          title: "Connector Updated Successfully",
          status: "success",
        });
      } catch (error) {
        const err = error?.response?.data?.error;
        logger.log(error);
        setToast({
          isOpen: true,
          title: "Error occured when updating Connector",
          description: err ? err : null,
          status: "error",
        });
      }
      closeHandler()
    } else {
      setToast({
        isOpen: true,
        title: "Nothing new to Update Connector",
        status: "warning",
        position: "top",
      });
    }
  };
  const fetchConnector = async () => {
    try {
      const response = await getConnector();
      SetConnector(response.data.data);
    } catch (error) {
      logger.log(error);
    }
  };

  const closeHandler = () => {
    setDrawerState(null);
  };

  useEffect(() => {
    fetchConnector();
  }, []);
  useEffect(() => {
    if (connector) {
      setValue("Code", connector.Code);
      setValue("QrCode", connector.QrCode);
      setValue("TypeOfConnector", connector.TypeOfConnector);
    }
  }, [connector]);
  return (
    <Drawer
      isOpen={drawerState?.isOpen}
      placement="right"
      onClose={closeHandler}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Update a Connector</DrawerHeader>
        <DrawerBody>
          {getLoadiing || updateLoading ? (
            <LoadingSpinner />
          ) : (
            <Card>
              <form>
                {updateChargePointConnectorSelectOptions.map((item, index) => (
                  <Flex key={index} mb="20px" alignItems="center">
                    <Controller
                      name={item.name}
                      control={control}
                      defaultValue=""
                      render={({field}) => (
                        <FormControl
                          isRequired
                          flexDirection="row"
                          isInvalid={errors[item.name]}
                        >
                          <FormLabel fontSize={16}>{item.label}</FormLabel>
                          {item.type === "select" ? (
                            <Select
                              {...field}
                              focusBorderColor="#f57d36"
                              fontSize="sm"
                              width="400px"
                              height="50px"
                            >
                              <option value="">Select</option>
                              {item.options.map((option, ind) => {
                                return (
                                  <option key={ind} value={option.value}>
                                    {option.name}
                                  </option>
                                );
                              })}
                            </Select>
                          ) : (
                            <Input
                              {...field}
                              focusBorderColor="#f57d36"
                              fontSize="sm"
                              placeholder={item.placeholder}
                              width="400px"
                              height="50px"
                              type={item.type}
                              isRequired
                            />
                          )}
                          <FormHelperText color={"#f57d36"}>
                            {item.helper}
                          </FormHelperText>
                          <FormErrorMessage mb={"20px"}>
                            {errors[item.name] && errors[item.name].message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  </Flex>
                ))}
              </form>
            </Card>
          )}
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            bg="#f57d36"
            color="white"
            colorScheme="orange"
            onClick={handleSubmit(updateHandler)}
          >
            Update
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default UpdateChargePointConnectorDrawer;
