import {
  Box,
  FormControl,
  FormLabel,
  Input,
  List,
  ListItem,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { accessLevels } from "utils/accessLevels";
import { MAP_BOX_ACCESS_TOKEN } from "utils/constants";
import { loggedInUserState } from "atoms/loggedInUserState";
import logger from "infra/logger";

const SearchLocation = ({ setLocation }) => {
  const loggedInUser = useRecoilValue(loggedInUserState);
  const role = loggedInUser?.role;
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [{ data, loading, error }, execute] = useAxios({}, { manual: true });
  const handleLocationSearch = async () => {
    try {
      const endPoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json`;
      const response = await execute({
        url: endPoint,
        method: "GET",
        params: {
          access_token: MAP_BOX_ACCESS_TOKEN,
          autocomplete: true,
          country: "IN",
          fuzzyMatch: true,
          limit: 10,
        },
      });
      const results = response.data.features;
      setSearchResults(results);
    } catch (error) {
      logger.log("Error fetching data", error);
    }
  };

  const handleSuggestionSelect = (item) => {
    const [Longitude, Latitude] = item.geometry.coordinates;
    setLocation({ Latitude, Longitude });
    setSearchText("");
  };

  const searchOptions = searchResults.map((ele, index) => {
    return (
      <ListItem
        key={index}
        p={2}
        cursor="pointer"
        _hover={{ bgColor: "gray.100" }}
        onClick={() => handleSuggestionSelect(ele)}
      >
        {ele.place_name}
      </ListItem>
    );
  });

  const changeHandler = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText.length > 2) {
      handleLocationSearch();
    }
  }, [searchText]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <FormControl isRequired mb="40px">
        <FormLabel fontSize="sm">Search</FormLabel>
        <Input
          fontSize="sm"
          placeholder="Search for a location"
          value={searchText}
          onChange={(e) => changeHandler(e)}
        />
      </FormControl>
      {searchResults.length > 0 && (
        <Box mt="10px">
          <List
            spacing={2}
            borderColor="gray.200"
            borderWidth="1px"
            borderRadius="md"
          >
            {searchText.length > 0 && searchOptions}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default SearchLocation;
