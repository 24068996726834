import React, {useEffect, useState} from "react";
import useAxios from "axios-hooks";
import {RiDownloadCloud2Line} from "react-icons/ri";
import {useSetRecoilState} from "recoil";
import {
  Flex,
  FormLabel,
  Input,
  Select,
  Button,
  FormControl,
} from "@chakra-ui/react";
import {Controller, useForm} from "react-hook-form";

import TotalAmount from "../components/TotalAmount";
import TotalUnits from "../components/TotalUnits";
import TotalSessions from "../components/TotalSession";
import {toastState} from "atoms/toastState";
import logger from "infra/logger";
import ChargingSessionsColumnsTable from "../components/ChargingSessionsColumsTable";
import Card from "components/card/Card";
import {chargingSessionTableHeaders} from "../variables/tableHeaders";

const ChargingSessions = ({id}) => {
  const [sessions, setSessions] = useState([]);
  const [sources, setSources] = useState([]);
  const [stats, setStats] = useState({});
  const setToast = useSetRecoilState(toastState);
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  const [{data, loading, error}, execute] = useAxios(
    {method: "GET", url: `/api/chargingSession/forStation?stationId=${id}`},
    {manual: true}
  );

  const [
    {data: statsData, loading: statsLoading, error: statsError},
    getSessionStatsData,
  ] = useAxios(
    {method: "GET", url: `/api/chargingSession/stats/forStation`},
    {manual: true}
  );

  const [
    {data: exportData, loading: exportLoading, error: exportError},
    exportSessionsData,
  ] = useAxios(
    {method: "GET", url: `/api/chargingSession/download/forStation`},
    {manual: true}
  );

  const [{sourceData, sourceLoading, sourceError}, getAllSources] = useAxios(
    {
      method: "GET",
      url: `/api/source/all`,
    },
    {manual: true}
  );

  const {
    control,
    formState: {errors, isValid},
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const options = sources.map((ele, index) => {
    return <option key={index}>{ele.Name}</option>;
  }) || <div></div>;

  const getSources = async () => {
    try {
      const response = await getAllSources();
      setSources(response.data.data);
      setToast({
        status: "success",
        message: "Sources fetched successfully",
        title: "Success",
        position: "top-right",
        duration: 5000,
      })
    } catch (error) {
      logger.log("error occured");
      setToast({
        title: "Error fetching sources",
        status: "error",
        duration: 5000,
        iseOpen: true,
      });
    }
  };

  const getStats = async () => {
    try {
      const formValues = getValues();
      let params = {};
      params["stationId"] = id;
      params["from"] =
        formValues.From !== "" ? formValues.From : formatDate(threeMonthsAgo);
      params["to"] = formValues.To !== "" ? formValues.To : getCurrentDate();
      params["source"] = formValues.Source;
      logger.log(params);
      const response = await getSessionStatsData({params});
      logger.log(response);
      setStats(response.data.data);
      setToast({
        status: "success",
        message: "Stats fetched successfully",
        title: "Success",
        position: "top-right",
        duration: 5000,
      })
    } catch (error) {
      logger.log("error occured");
      setToast({
        title: "Error fetching stats",
        status: "error",
        duration: 5000,
        iseOpen: true,
      });
    }
  };

  const exportHandler = async () => {
    const formValues = getValues();
    let params = {};
    params["stationId"] = id;
    params["from"] =
      formValues.From !== "" ? formValues.From : formatDate(threeMonthsAgo);
    params["to"] =
      formValues.From !== "" ? formValues.From : formatDate(threeMonthsAgo);
    params["source"] = formValues.Source;

    try {
      const response = await exportSessionsData({params});
      logger.log("response", response);
      // Create a blob object from the response data
      const blob = new Blob([response.data], {type: "application/json"});

      // Create a temporary anchor element to trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = response.headers["content-disposition"].split("=")[1]; // Extract the filename from the Content-Disposition header
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setToast({
        isOpen: true,
        title: "Logs downloaded successfully",
        status: "success",
      });
    } catch (error) {
      logger.error("Error downloading logs:", error);
      setToast({
        isOpen: true,
        title: "Error occured while getting logs",
        status: "error",
      });
    }
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Zero-padding month
    const day = String(today.getDate()).padStart(2, "0"); // Zero-padding day
    const hours = String(today.getHours()).padStart(2, "0"); // Zero-padding hour
    const minutes = String(today.getMinutes()).padStart(2, "0"); // Zero-padding minute
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  function formatDate(date, hours = 0, minutes = 0) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Zero-padding month
    const day = String(date.getDate()).padStart(2, "0"); // Zero-padding day
    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
    return `${formattedDate}T${formattedTime}`;
  }

  const fetchSessions = async () => {
    const currentDate = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

    const formValues = getValues();
    let params = {};
    params["from"] = formValues.From || formatDate(threeMonthsAgo); // Use three months ago if From is not provided
    params["to"] = formValues.To || getCurrentDate(); // Use getCurrentDate if To is not provided
    params["source"] = formValues.Source;

    try {
      const response = await execute({params});
      const result = response.data.data;
      setSessions(result);
      await getStats();
    } catch (error) {
      logger.log("error occurred");
    }
  };

  useEffect(() => {
    getSources();
    fetchSessions();
  }, []);
  return (
    <>
      <Card
        w="100%"
        px="0px"
        overflowX={{sm: "scroll", lg: "hidden"}}
        marginBottom="20px"
        paddingBottom="5px"
      >
        <Flex
          direction="row"
          alignItems="end"
          justifyContent="space-around"
          gap={5}
          mb={10}
          flexWrap="wrap"
        >
          {[
            {
              label: "From",
              type: "datetime-local",
              name: "From",
              defaultValue: formatDate(threeMonthsAgo), // Set defaultValue to three months ago
            },
            {
              label: "To",
              type: "datetime-local",
              name: "To",
              defaultValue: getCurrentDate(), // Set defaultValue to current date
            },
            {
              label: "Source",
              type: "select",
              name: "Source",
              defaultValue: "",
            },
          ].map((item) => {
            return (
              <Controller
                key={item.name}
                name={item.name}
                control={control}
                defaultValue={item.defaultValue}
                render={({field}) => (
                  <FormControl flexDirection="row" width="unset">
                    <FormLabel fontSize={20}>{item.label}</FormLabel>
                    {item.type === "datetime-local" && (
                      <Input
                        {...field}
                        borderColor={"blackAlpha.500"}
                        background="white"
                        focusBorderColor="#f57d36"
                        fontSize="sm"
                        width="280px"
                        height="45px"
                        type={item.type}
                      />
                    )}
                    {item.type === "select" && (
                      <Select
                        {...field}
                        borderColor={"blackAlpha.500"}
                        background="white"
                        focusBorderColor="#f57d36"
                        fontSize="sm"
                        width="300px"
                        height="45px"
                        type={item.type}
                      >
                        <option value="">All</option>
                        {options}
                      </Select>
                    )}
                  </FormControl>
                )}
              />
            );
          })}

          <Button
            colorScheme="green"
            borderRadius={5}
            mb={2.5}
            onClick={fetchSessions}
            isLoading={loading}
          >
            Search Data
          </Button>
          <Button
            leftIcon={<RiDownloadCloud2Line size={20} />}
            colorScheme="blue"
            borderRadius={5}
            mb={2.5}
            onClick={exportHandler}
            isLoading={exportLoading}
          >
            Export
          </Button>
        </Flex>
      </Card>
      <Card width={"100%"} height={"100%"} style={{marginBottom: "20px"}}>
        <Flex>
          <TotalAmount stats={stats} />
          <TotalUnits stats={stats} />
          <TotalSessions stats={stats} />
        </Flex>
      </Card>

      {sessions.length > 0 ? (
        <Card>
          <ChargingSessionsColumnsTable
            columnsData={chargingSessionTableHeaders}
            tableData={sessions}
          />
        </Card>
      ) : (
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          bg={"white"}
          boxShadow={"base"}
          padding={60}
        >
          No Sessions
        </Flex>
      )}
    </>
  );
};

export default ChargingSessions;
