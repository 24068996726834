// Chakra imports
import { Box, Flex, Select, Text, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { format, subMonths } from "date-fns";
import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";

// Assets

import ReactECharts from "echarts-for-react";
import { dailyUnits } from "variables/charts";
import useAxios from "axios-hooks";
import logger from "infra/logger";

const DailyTrendUnits = (props) => {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const { ...rest } = props;

  const [monthLists, setMonthLists] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [xAxisData, setXAxisData] = useState([]);
  const [updatedOption, setUpdatedOption] = useState(dailyUnits);

  const [{ data, loading, error }, execute, cancelRequest] = useAxios(
    {
      method: "GET",
      url: `/api/dashboard/analytics?key=month_units_daily_trend&cp=${
        props?.chargePointCode == "all" ? "" : props?.chargePointCode
      }&year=${selectedDate?.year}&month=${selectedDate?.monthNum + 1}`,
    },
    { manual: true }
  );

  const getDailyUnits = async () => {
    try {
      const response = await execute({
        data: { Year: selectedDate?.year, Month: selectedDate?.monthNum + 1 },
      });

      const dailyUnitsData = response.data.data;

      const xAxisData = dailyUnitsData.map((item) => item.day);
      const seriesData = dailyUnitsData.map((item) => item.total_units);

      const updatedOption = {
        ...dailyUnits,
        xAxis: {
          ...dailyUnits.xAxis,
          data: xAxisData,
        },
        series: [
          {
            ...dailyUnits.series[0],
            data: seriesData,
          },
        ],
      };

      setUpdatedOption(updatedOption);
    } catch (error) {
      logger.log("Error in getDailyRevenue:", error);
    }
  };

  const generateMonthList = () => {
    const currentDate = new Date();
    const currentMonthYear = {
      month: format(currentDate, "MMM"),
      year: format(currentDate, "yyyy"),
      monthNum: currentDate.getMonth(),
    };
    const monthList = [currentMonthYear];
    for (let i = 1; i < 12; i++) {
      const previousMonth = subMonths(currentDate, i);

      monthList.push({
        month: format(previousMonth, "MMM"),
        year: format(previousMonth, "yyyy"),
        monthNum: previousMonth.getMonth(),
      });
    }

    setMonthLists(monthList.reverse());
  };

  const monthChangeHandler = (event) => {
    const data = JSON.parse(event.target.value);
    const index = monthLists.findIndex(
      (ele) => ele.month === data.month && ele.year === data.year
    );
    setSelectedDate(monthLists[index]);
  };

  useEffect(() => {
    generateMonthList();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      getDailyUnits();
      return () => cancelRequest();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (props?.chargePointCode) {
      const timeout = setTimeout(() => {
        if (selectedDate) {
          getDailyUnits();
        }
      }, 300);
      return () => {
        clearTimeout(timeout);
        cancelRequest();
      };
    }
  }, [props?.chargePointCode, props?.refresh]);

  useEffect(() => {
    if (monthLists.length > 0) {
      const currentDate = new Date();
      const currentMonth = format(currentDate, "MMM");
      const currentYear = format(currentDate, "yyyy");
      const index = monthLists.findIndex(
        (ele) => ele.month === currentMonth && ele.year === currentYear
      );
      setSelectedDate(monthLists[index]);
      return () => cancelRequest();
    }
  }, [monthLists]);

  return (
    <Card
      justifyContent="space-around"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      {...rest}
    >
      <Flex
        align="center"
        justifyContent="space-between"
        w="100% "
        ps="0px"
        pe="20px"
        pt="5px"
      >
        <Text
          color={textColor}
          fontSize="xl"
          textAlign="start"
          fontWeight="700"
          lineHeight="100%"
        >
          Daily Units
        </Text>
        <Select
          value={JSON.stringify(selectedDate)}
          onChange={(e) => monthChangeHandler(e)}
          size="md"
          width="150px"
        >
          {monthLists.map((dailyUnits, index) => {
            return (
              <option key={index} value={JSON.stringify(dailyUnits)}>
                {dailyUnits.month}, {dailyUnits.year}
              </option>
            );
          })}
        </Select>
      </Flex>
      <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
        <Box minH="260px" minW="95%" mt="auto" ml="auto" mr="auto">
          <ReactECharts option={updatedOption} />
        </Box>
      </Flex>
    </Card>
  );
};

export default DailyTrendUnits;
