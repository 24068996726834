import { Box, Spinner } from '@chakra-ui/react'
import Card from 'components/card/Card'
import React from 'react'

const LoadingSpinner = () => {
  return (
   <Card w={"100%"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={'center'} >
    <Spinner size='lg' color='blue.500'/>
   </Card>
  )
}

export default LoadingSpinner