import useAxios from "axios-hooks";
import React, {useEffect} from "react";
import {useRecoilState, useRecoilValue} from "recoil";

import {activeConnectorIdState} from "atoms/activeConnectorIdState";
import {activeConnectorState} from "atoms/activeConnectorState";
import ConnectorStatusCard from "components/card/ConnectorStatusCard";
import logger from "infra/logger";

const ConnectorStatus = () => {
  const [activeConnector, setActiveConnector] =
    useRecoilState(activeConnectorState);
  const activeConnectorId = useRecoilValue(activeConnectorIdState);
   const status = activeConnector?.Status
   const colorScheme =
    status === "Charging" || status === "Preparing"
      ? "orange"
      : status === "Available"
      ? "green"
      : "black";

  const [{data, loading, error}, execute] = useAxios(
    {method: "Get", url: `/api/chargePointConnector/${activeConnectorId}`},
    {manual: true}
  );

  const getConnector = async () => {
    if (activeConnectorId) {
      try {
        const response = await execute();
        setActiveConnector(response.data.data);
      } catch (err) {
        logger.log(err);
        setActiveConnector(null);
      }
    }
  };

  useEffect(() => {
    setActiveConnector(null)
    getConnector();

    const intervalId = setInterval(() => {
      getConnector();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [activeConnectorId]);
  return (
    <ConnectorStatusCard />
  );
};

export default ConnectorStatus;
