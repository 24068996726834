import {loggedInUserState} from "atoms/loggedInUserState";
import {useRecoilValue} from "recoil";

const RoleBasedRender = ({roles, children}) => {
  const loggedInUser = useRecoilValue(loggedInUserState);
  if (roles && roles.includes(loggedInUser?.role)) {
    return children;
  }
  return null;
};

export default RoleBasedRender;
