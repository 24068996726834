import useAxios from "axios-hooks";
import { useEffect, useState } from "react";
import { Box, Button, Flex } from "@chakra-ui/react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { billingPlanTableHeaders } from "../variables/tableHeaders";
import Card from "components/card/Card";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import { chargePointState } from "atoms/chargePointState";
import { toastState } from "atoms/toastState";
import Configurations from "../components/Configurations";
import logger from "infra/logger";
import Billing from "../components/Billing";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import { onlyUAdmins } from "utils/accessLevels";

const BillingPlan = ({ id }) => {
  const [chargePoints, setChargePoints] = useRecoilState(chargePointState);
  const [updateData, setUpdateData] = useState([]);
  const setToast = useSetRecoilState(toastState);
  const [updating, setUpdating] = useState(false);

  const [{ data, loading, error }, execute] = useAxios(
    { method: "GET", url: `/api/chargePoint/forStation/${id}` },
    { manual: true }
  );

  const [
    { data: upData, loading: upLoading, error: upError },
    updateChargePoint,
  ] = useAxios({}, { manual: true });

  const fetchChargePoints = async () => {
    try {
      const response = await execute();
      const result = response.data.data;
      setChargePoints(result);
      setToast({
        status: "success",
        message: "Charge Points fetched successfully",
        title: "Success",
        position: "top-right",
        duration: 5000,
      });
      logger.log(result);
    } catch (err) {
      logger.log(err);
      setToast({
        title: "Error fetching charge points",
        status: "error",
        duration: 5000,
        iseOpen: true,
      });
    }
  };

  const addToUpdateList = async (dat) => {
    const index = updateData.findIndex((item) => item.Id === dat.Id);
    if (index !== -1) {
      setUpdateData((prev) => {
        const newArray = [...prev];
        newArray[index] = dat; // Replace the item at the found index with the new data
        return newArray;
      });
    } else {
      setUpdateData((prev) => {
        return [...prev, dat];
      });
    }
  };

  const updateChargePointsList = async () => {
    setUpdating(true);
    if (updateData.length > 0) {
      for (let i = 0; i < updateData.length; i++) {
        const pl = updateData[i];

        if (isNaN(pl.payload.Tariff) && pl.payload.TaxEnabled === undefined) {
          setToast({
            title: "Please enter valid Tariff",
            status: "warning",
            duration: 5000,
            isOpen: true,
          });
          setUpdating(false);
          continue;
        }

        if (chargePoints[i].Tariff !== pl.payload.Tariff) {
          try {
            const response = await updateChargePoint({
              method: "PATCH",
              url: `/api/chargePoint/update/${pl.Id}`,
              data: pl.payload,
            });
            setToast({
              title: "Billing plan updated successfully",
              status: "success",
              duration: 5000,
              isOpen: true,
            });
          } catch (error) {
            logger.log(error);
            setToast({
              title: "Error updating Billing plan",
              status: "error",
              duration: 5000,
              isOpen: true,
            });
          }
        } else {
          setToast({
            title: "Nothing New to Update",
            status: "warning",
            duration: 5000,
            isOpen: true,
          });
          setUpdating(false);
          continue;
        }
      }
      logger.log("updating", updateData);
      fetchChargePoints();
      setUpdating(false);
      setUpdateData([]);
    } else {
      setToast({
        title: "Nothing to update",
        status: "warning",
        duration: 5000,
        isOpen: true,
      });
      setUpdating(false);
    }
  };

  useEffect(() => {
    fetchChargePoints();
  }, []);

  return loading || updating ? (
    <LoadingSpinner />
  ) : (
    <>
      {chargePoints.length > 0 ? (
        <Card>
          <RoleBasedRender roles={onlyUAdmins}>
            <Box
              w={"100%"}
              display={"flex"}
              pr={5}
              pb={5}
              flexDirection={"row-reverse"}
            >
              <Button
                onClick={updateChargePointsList}
                bg={"#f57d36"}
                color={"white"}
                borderRadius={8}
                _hover={{ backgroundColor: "orange.600" }}
                p={5}
              >
                save
              </Button>
            </Box>
          </RoleBasedRender>
          <Billing
            columnsData={billingPlanTableHeaders}
            tableData={chargePoints}
            addToUpdate={(d) => addToUpdateList(d)}
          />
        </Card>
      ) : (
        <Flex w={"100%"} alignItems={"center"} justifyContent={"center"} p={30}>
          No ChargePoints
        </Flex>
      )}
      <Card
        mt={5}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Configurations id={id} />
      </Card>
    </>
  );
};

export default BillingPlan;
