import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";

import { sourcesTableHeaders } from "./variables/sourcesTableHeaders";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import { useSetRecoilState } from "recoil";
import { deleteModalState } from "atoms/deleteModalState";
import logger from "infra/logger";
import SourceColumnsTable from "./components/SourceColumnsTable";


const SourcesList = () => {
  const [users, setUsers] = useState([]);
  const setDeleteModal = useSetRecoilState(deleteModalState)
  const [{ data, loading, error }, execute] = useAxios({ method: "GET", url: "/api/source/all" }, { manual: true });
  const [{data:status, loading:statusLoading, error:statusError}, executeStatus] = useAxios({ method: "GET", url: "" }, { manual: true })

  const getSources = async () => {
    try {
      const response = await execute();
      const data = response.data.data
      setUsers(data)
    } catch (error) {
      logger.log(error);
    }
  };
  const changeStatus = async (id, status) => {
    try {
      const response = await executeStatus({ method: "PUT", url: `/api/user/${id}`, data: { status: !status } });
      getSources()
    } catch (error) {
      logger.log(error);
    }
  }
  useEffect(() => {
    setDeleteModal(false)
    getSources();
  }, []);

  return !loading ? (
    <SourceColumnsTable columnsData={sourcesTableHeaders} tableData={users} getSources={getSources} responseData={data} />
  ) : (
    <LoadingSpinner />
  );
};

export default SourcesList;
