import React, {useEffect, useState} from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {RiEyeCloseLine} from "react-icons/ri";
import {onAuthStateChanged, signInWithEmailAndPassword} from "firebase/auth";
import {MdOutlineRemoveRedEye} from "react-icons/md";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";

import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import {auth} from "../../../firebase";
import useAuthentication from "hooks/UseAuthentication";
import Footer from "components/footer/FooterAuth";
import LOGO from "../../../assets/img/cmsLogo.svg";
import Appcss from "../../../assets/css/App.css";
import useAxios from "axios-hooks";
import {useSetRecoilState} from "recoil";
import {toastState} from "atoms/toastState";
import { configureAxios } from "utils/api";
const ForgotPasswordPage = () => {
  // Chakra color mode
  //const textColor = useColorModeValue("navy.700", "white");
  const textColor = "white";
  //const textColorSecondary = "gray.400";
  const textColorSecondary = "white";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const [isLoading, setIsLoading] = useState(false);
  const googleHover = useColorModeValue(
    {bg: "gray.200"},
    {bg: "whiteAlpha.300"}
  );
  const googleActive = useColorModeValue(
    {bg: "secondaryGray.300"},
    {bg: "whiteAlpha.200"}
  );

  const setToast = useSetRecoilState(toastState);
  const navigate = useNavigate();

  const schema = yup.object().shape({
    Email: yup
      .string()
      .email("Invalid Email Format")
      .required("Email is Required"),
  });

  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [{data, loading, error}, execute] = useAxios(
    {method: "POST", url: `/api/auth/forgotpassword`},
    {manual: true}
  );

  const submitHandler = async () => {
    setIsLoading(true);
    const formValues = getValues();
    try {
      let reqData = {};
      reqData["email"] = formValues?.Email;
      const response = await execute({data: reqData});
      navigate("/auth/verifyotp", {state: {email: formValues?.Email}});
      setToast({
        isOpen: true,
        title: `Reset Password link sent to ${formValues?.Email}`,
        status: "success",
      });
    } catch (error) {
      setToast({
        isOpen: true,
        title: "Error sending reset password link",
        status: "error",
      });
    }

    setIsLoading(false);
  };
  useEffect(() => {
    configureAxios(null);
  }, []);
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        w={{base: "100%", md: "100%", lg: "50%"}}
        h="100%"
        zIndex={100}
        direction="column"
        alignItems={{base: "center", lg: "flex-start"}}
        justifyContent={"flex-start"}
        gap={40}
        position={"relative"}
        pl={{base: "unset", lg: "100px", md: "unset"}}
        pt={{base: 10, md: 20, lg: 25}}
      >
        <Image src={LOGO} alt="URZZA CMS" w="auto" h="80px" />
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex direction="column" alignItems={"flex-start"} w={"100%"}>
            <Heading color={textColor} fontSize="36px" mb="10px">
              Forgot Password
            </Heading>

            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Enter your email to send a link to reset your password
            </Text>
          </Flex>
          <Flex
            zIndex="2"
            direction="column"
            w={{base: "100%", md: "420px"}}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{base: "auto", lg: "unset"}}
            me="auto"
            mb={{base: "20px", md: "auto"}}
          >
            <form onSubmit={handleSubmit(submitHandler)}>
              <Controller
                name="Email"
                control={control}
                defaultValue=""
                render={({field}) => (
                  <FormControl isInvalid={errors.Email} isRequired>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Email
                    </FormLabel>
                    <Input
                      {...field}
                      isRequired
                      variant="auth"
                      fontSize="sm"
                      type="email"
                      placeholder="Enter your registered email"
                      mb="5px"
                      fontWeight="500"
                      size="lg"
                      borderRadius={0}
                      bg={"white"}
                    />
                    <FormErrorMessage mb={"20px"}>
                      {errors.Email && errors.Email.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Flex
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row"
              >
                <Button
                  type="submit"
                  fontSize="md"
                  variant="brand"
                  _hover={{backgroundColor: "#ff5c00"}}
                  _focus={{bg: "#ff5c00 !important"}}
                  fontWeight="700"
                  h="50"
                  mb="24px"
                  px={20}
                  bg="#f57d36"
                  color="white"
                  mt="20px"
                  borderRadius={0}
                  colorScheme="blue"
                  isLoading={isLoading}
                  spinner={<Spinner color="white" />}
                >
                  Submit
                </Button>
                <Text
                  style={{
                    cursor: "pointer",
                    color: "#f57d36",
                    fontWeight: "500",
                    textDecoration: "underline",
                    textDecorationColor: "#f57d36",
                  }}
                  onClick={() => navigate("/auth/signIn")}
                >
                  Back to SignIn?
                </Text>
              </Flex>
            </form>
          </Flex>
        </Flex>

        <Footer />
      </Flex>
    </DefaultAuth>
  );
};

export default ForgotPasswordPage;
