import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Spinner,
  Text,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import { useSetRecoilState } from "recoil";
import { toastState } from "atoms/toastState";
import logger from "infra/logger";

const DeleteModal = ({ isOpen, onClose, onConfirm, data, title }) => {
  const setToast = useSetRecoilState(toastState);

  const [{ da, loading, error }, execute] = useAxios(
    {
      method: "DELETE",
      url: `/api/${title}/delete/${data?.Id}`,
    },
    { manual: true }
  );

  const deleteHandler = async () => {
    try {
      const response = await execute();
      onConfirm();
      setToast({
        isOpen: true,
        title: `${title} details Deleted`,
        status: "success",
      });
    } catch (error) {
      logger.log(error);
      setToast({
        isOpen: true,
        title: error?.response?.data?.metadata?.error_code || "Error occured",
        description: error?.response?.data?.metadata?.error_message,
        status: "error",
      });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm Delete</ModalHeader>
        <ModalCloseButton />
        {loading ? (
          <ModalBody display="flex" alignItems="center" justifyContent="center">
            <LoadingSpinner />
          </ModalBody>
        ) : (
          <ModalBody>
            Are you sure you want to delete this {title}
            {data?.Email && " related to "}
            {data?.Email && (
              <Text fontWeight="bold" display="inline">
                {data?.Email}
              </Text>
            )}
            {data?.ChargingStationId && " related to "}
            {data?.ChargingStationId && (
              <Text fontWeight="bold" display="inline">
                {data?.ChargingStationId}
              </Text>
            )}
          </ModalBody>
        )}
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" ml={3} onClick={deleteHandler}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
