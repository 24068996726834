import React from "react";
import { useRecoilState } from "recoil";
import { payOutsDrawerState } from "atoms/payOutsDrawerState";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";

import { PayRunDrawerState } from "atoms/payRunDrawerState";

const PayRunDrawer = () => {
  const [drawerState, setDrawerState] = useRecoilState(PayRunDrawerState);

  //TODO link to api

  const onClose = () => {
    setDrawerState({ isOpen: false, data: null });
  };

  return (
    <Drawer isOpen={drawerState.isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>View Payout Details</DrawerHeader>

        <DrawerBody>
          <FormControl>
            <FormLabel>Payee Name</FormLabel>
            <Input value={drawerState.data?.PayoutRefNo || ''} readOnly />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Total Units Consumed</FormLabel>
            <Input value={drawerState.data?.Date || ''} readOnly />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Gross Amount</FormLabel>
            <Input value={drawerState.data?.TotalAmount || ''} readOnly />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Tax Amount</FormLabel>
            <Input value={drawerState.data?.AccountNo || ''} readOnly />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Net Amount</FormLabel>
            <Input value={drawerState.data?.UTRNo || ''} readOnly />
          </FormControl>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default PayRunDrawer;
