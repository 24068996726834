import React, {useEffect, useMemo, useRef, useState} from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Text,
  Tooltip,
  assignRef,
} from "@chakra-ui/react";
import {useSetRecoilState} from "recoil";
import useAxios from "axios-hooks";
import {RiDownloadCloud2Line, RiFileInfoLine} from "react-icons/ri";
import {keyframes} from "@emotion/react";

import Card from "components/card/Card";
import Log from "components/log/Log";
import Pulsing from "components/feedback/Pulsing";
import {toastState} from "atoms/toastState";
import logger from "infra/logger";

const Logs = ({point}) => {
  const setToast = useSetRecoilState(toastState);
  const [ocppLogs, setOcppLogs] = useState([]);
  const [autoFetchLogs, setAutoFetchLogs] = useState(true);
  const [page, setPage] = useState(1);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const scrollContainerRef = useRef();
  const [endReached, setEndReached] = useState(false);
  const [fromDate, setFromDate] = useState(false);
  const [toDate, setToDate] = useState(false);
  const progressAnimation = keyframes`
  100% {
    background-size: 100% 100%;
  }
`;
  const [{data, loading, error}, getLog] = useAxios(
    {
      method: "GET",
      url: `/api/ocppLogs/`,
    },
    {manual: true}
  );

  const [
    {data: downloadedData, loading: downloadLoading, error: downloadError},
    downloadLogs,
  ] = useAxios(
    {
      method: "GET",
      url: `/api/ocppLogs/download`,
      responseType: "blob",
    },
    {manual: true}
  );

  const getLogs = async () => {
    let params = {chargePointCode: point.Code, page: page};
    if (startTime) {
      params["startTime"] = startTime;
    }
    if (endTime) {
      params["endTime"] = endTime;
    }
    try {
      const response = await getLog({params});

      setOcppLogs((prev) => {
        if (page === 1) {
          return response.data.data.data;
        } else if (page > 1) {
          if (response.data.data.data.length > 0) {
            return [...prev, ...response.data.data.data];
          } else {
            setEndReached(true);
            return [...prev];
          }
        }
      });
    } catch (error) {
      logger.log(error);
      // setToast({
      //   isOpen: true,
      //   title: "Error occured while getting logs",
      //   status: "error",
      // });
    }
  };

  function calculateDaysDifference(date1, date2) {
    // Convert both dates to milliseconds
    const date1InMs = new Date(date1).getTime();
    const date2InMs = new Date(date2).getTime();

    // Calculate the difference in milliseconds
    const differenceInMs = Math.abs(date2InMs - date1InMs);

    // Convert the difference to days
    const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

    return differenceInDays;
}

  const logsDownloadHandler = async () => {
    if (!startTime || !endTime) {
      setToast({
        isOpen: true,
        title: "Please select both From and To dates",
        status: "error",
      });
      return;
    }
    if(calculateDaysDifference(startTime, endTime)-1 > 31){
      setToast({
        isOpen: true,
        title: "Please select date range less than 31 days",
        status: "error",
      });
      return;
    }
    let params = {chargePointCode: point.Code};
    if (startTime) {
      params["startTime"] = startTime;
    }
    if (endTime) {
      params["endTime"] = endTime;
    }
    try {
      const response = await downloadLogs({params});
      // Create a blob object from the response data
      const blob = new Blob([response.data], {type: "application/json"});

      // Create a temporary anchor element to trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = response.headers["content-disposition"].split("=")[1]; // Extract the filename from the Content-Disposition header
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      logger.error("Error downloading logs:", error);
      setToast({
        isOpen: true,
        title: "Error occured while getting logs",
        status: "error",
      });
    }
  };

  const logs = useMemo(() => {
    if (ocppLogs?.length > 0) {
      return ocppLogs.map((ele, index) => {
        return <Log key={index} data={ele} />;
      });
    } else {
      return (
        <Text color={"white"} textAlign={"center"}>
          No Logs Present
        </Text>
      );
    }
  }, [ocppLogs]);

  const dateChangeHandler = (event, type) => {
    if (type === "startTime") {
      setStartTime(event.target.value);
      setFromDate(true);
    }
    if (type === "endTime") {
      setEndTime(event.target.value);
      setToDate(true);
    }
    setEndReached(false);
    setAutoFetchLogs(false);
    setPage(1);
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      const isAtTop = scrollContainer.scrollTop === 0;
      if (isAtTop && !startTime && !endTime) {
        setPage(1);
        setAutoFetchLogs(true);
      } else {
        if (autoFetchLogs) {
          setAutoFetchLogs(false);
        }
      }
      if (
        scrollContainer.scrollTop ===
        scrollContainer.scrollHeight - scrollContainer.clientHeight
      ) {
        if (!endReached) {
          setPage((prev) => prev + 1);
        }
      }
    };

    scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [ocppLogs]);

  useEffect(() => {
    if (!endReached && page === 1) {
      getLogs();
    }
  }, [startTime, endTime]);

  useEffect(() => {
    if (page > 1 && !endReached) {
      getLogs();
    }
  }, [page]);

  useEffect(() => {
    // Set up an interval to run the function every 10 seconds
    if (page === 1) {
      getLogs();
    }
    const intervalId = setInterval(() => {
      if (autoFetchLogs && page === 1) {
        getLogs();
      }
    }, 5000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [autoFetchLogs]);

  return (
    <Card>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="flex-start"
        mb={5}
        gap={5}
        position="relative"
      >
        <FormControl marginRight={5} w={200}>
          <FormLabel>From</FormLabel>
          <Input
            type="datetime-local"
            width={200}
            colorScheme="orange"
            focusBorderColor="#f57d36"
            value={startTime}
            onChange={(e) => dateChangeHandler(e, "startTime")}
          />
        </FormControl>
        <FormControl w={200}>
          <FormLabel>To</FormLabel>
          <Input
            type="datetime-local"
            width={200}
            colorScheme="orange"
            focusBorderColor="#f57d36"
            value={endTime}
            onChange={(e) => dateChangeHandler(e, "endTime")}
          />
        </FormControl>
        <Button
          leftIcon={<RiDownloadCloud2Line size={20} />}
          borderRadius={5}
          colorScheme="green"
          isLoading={downloadLoading}
          loadingText="Downloading"
          onClick={logsDownloadHandler}
          disabled={ocppLogs?.length == 0 || (!startTime && !endTime) }
        >
          Download
        </Button>
        <Tooltip
          label='Maximum allowed time frame is 31 Days' placement='right' hasArrow 
        >
          <Box fontSize="2xl" color={"gray.500"} gap={0} width={5}> 
            &#128712;
          </Box>
        </Tooltip>

        {autoFetchLogs && (
          <Pulsing width="12px" right="40px" position="absolute" />
        )}
      </Box>
      

      <Divider mb={"3"} />

      <Box
        bg="black"
        height={900}
        overflowY={"auto"}
        pt={5}
        ref={scrollContainerRef}
        position="relative"
      >
        {logs}
        {loading && (
          <Box
            position="sticky"
            bottom={0}
            width={"100%"}
            height={"16px"}
            bg={`linear-gradient(#474bff 0 0) left/0% 100% no-repeat #dbdcef`}
            css={{
              animation: `${progressAnimation} 2s infinite linear`,
            }}
          />
        )}
      </Box>
    </Card>
  );
};

export default Logs;
