import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import DynamicStats from "./DynamicStats";
import OngoingSessionsList from "./OngoingSessionsList";
import SeparatorWithTitle from "components/card/SeparatorWithTitle";

export default function DashboardOverviewTab() {
  return (
    <Box pt={{ base: "32.5px", md: "20px", xl: "20px" }}>
      <DynamicStats />
      <SeparatorWithTitle title="Ongoing Sessions" />
      <OngoingSessionsList />
    </Box>
  );
}
