import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

import React, {useEffect, useMemo, useState} from "react";

import {useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import {useNavigate} from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";
import useAxios from "axios-hooks";

// Custom components
import Card from "components/card/Card";
import {deleteModalState} from "atoms/deleteModalState";
import DeleteModal from "components/modals/DeleteModal";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import logger from "infra/logger";
import {loggedInUserState} from "atoms/loggedInUserState";
import {accessLevels} from "utils/accessLevels";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import { onlyAdmins } from "utils/accessLevels";

export default function ChargingStationsTable(props) {
  const {columnsData} = props;
  const [showDeleteModal, setShowDeleteModal] =
    useRecoilState(deleteModalState);
  const [selectedStation, setSelectedStation] = useState(null);
  const [chargingStations, setChargingStations] = useState([]);
  const loggerInUser = useRecoilValue(loggedInUserState);
  const navigate = useNavigate();
  const [{stationData, loading, error}, getStations] = useAxios(
    {method: "GET", url: `/api/chargingStation`},
    {manual: true}
  );

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => chargingStations, [chargingStations]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0, // Start at page 0
        pageSize: 10, // Set the initial page size
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: {pageIndex},
  } = tableInstance;

  const {canPreviousPage, canNextPage, pageCount, nextPage, previousPage} =
    tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const getChargingStations = async () => {
    try {
      const response = await getStations();
      setChargingStations(response.data.data);
    } catch (error) {
      logger.log(error);
    }
  };
  const handleDeleteClick = (station) => {
    setSelectedStation(station);
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setSelectedStation(null);
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(false);
    getChargingStations();
  };

  useEffect(() => {
    getChargingStations();
  }, []);

  return loading ? (
    <LoadingSpinner />
  ) : chargingStations.length > 0 ? (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{sm: "scroll", lg: "hidden"}}
      overflowY={"hidden"}
    >
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="center"
                    align="center"
                    fontSize={{sm: "10px", lg: "12px"}}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "S.No") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {Number(cell.row.id) + 1}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Name") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.row.original.Name}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "PinCode") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.row.original.PinCode}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Status") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text
                          color={"white"}
                          bg="whatsapp.500"
                          fontSize="sm"
                          fontWeight="700"
                          border="1px solid whatsapp.500"
                          borderRadius="md"
                          p="1"
                          textAlign={"center"}
                        >
                          Active
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "No.of CP") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          1
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Actions") {
                    const role = loggerInUser?.role;
                    const accessLevel = accessLevels[role]?.accessLevel;
                    const isDisabled = accessLevel > 2;
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Button
                          colorScheme="blue"
                          variant="ghost"
                          onClick={() =>
                            navigate(
                              `/admin/chargingstations/view/${cell.row.original.Id}/overview`
                            )
                          }
                        >
                          View
                        </Button>
                        <RoleBasedRender roles={onlyAdmins}>
                          <Button
                            colorScheme="red"
                            variant="ghost"
                            isDisabled={isDisabled}
                            onClick={() => handleDeleteClick(cell.row.original)}
                          >
                            Delete
                          </Button>
                        </RoleBasedRender>
                      </Flex>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{sm: "14px"}}
                      minW={{sm: "150px", md: "200px", lg: "auto"}}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        data={selectedStation}
        title="chargingStation"
      />
      <Flex justify="space-between" alignItems="center">
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </Button>
        <Text>
          Page {pageIndex + 1} of {pageCount}
        </Text>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Button>
      </Flex>
    </Card>
  ) : (
    <Text w={"100%"} textAlign={"center"} mt={5}>
      No Charging Station Found
    </Text>
  );
}
