import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useSetRecoilState } from "recoil";
import React, { useEffect, useState } from "react";
import useAxios from "axios-hooks";

import Logs from "../components/Logs";
import Card from "components/card/Card";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import logger from "infra/logger";
import { toastState } from "atoms/toastState";

const OcppLogs = ({ id }) => {
  const [chargePoints, setChargePoints] = useState([]);
  
  const setToast = useSetRecoilState(toastState);

  const [{ data, loading, error }, getChargePoint] = useAxios(
    { method: "GET", url: `/api/chargePoint/forStation/${id}` },
    { manual: true }
  );

  const getChargePoints = async () => {
    try {
      const response = await getChargePoint();
      setChargePoints(response.data.data);
      setToast({
        status: "success",
        message: "Charge Points fetched successfully",
        title: "Success",
        position: "top-right",
        duration: 5000,
      })
    } catch (error) {
      logger.log(error);
      setToast({
        title: "Error fetching charge points",
        status: "error",
        duration: 5000,
        iseOpen: true,
      });
    }
  };

  const ocppLogTabs = chargePoints.map((ele, index) => {
    return <Tab _focus={{boxShadow: "none"}} key={index} >{ele.Code}</Tab>;
  });

  const ocppLogPanels = chargePoints.map((ele, index) => {
    return (
      <TabPanel key={index}>
        <Logs point={ele} />
      </TabPanel>
    );
  });

  useEffect(() => {
    getChargePoints();
  }, []);

  return !loading ? (
    <Card minH="60vh" height="100%">
      {chargePoints.length > 0 && (
        <Tabs isLazy variant="soft-rounded"  colorScheme="orange">
          <Flex
            flexDirection="row"
            alignItems="start"
            justifyContent="space-between"
          >
            <TabList>{ocppLogTabs}</TabList>
          </Flex>
          <TabPanels>{ocppLogPanels}</TabPanels>
        </Tabs>
      )}
    </Card>
  ) : (
    <LoadingSpinner />
  );
};

export default OcppLogs;
