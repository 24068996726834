import React from "react";
import "../../assets/css/Pulsing.css";
import {Box} from "@chakra-ui/react";
const Pulsing = ({width, position, right, left, top, bottom}) => {
  return (
    <Box
      position={position || "relative"}
      w={width || "16px"}
      right={right || "unset"}
      left={left || "unset"}
      top={top || "unset"}
      bottom={bottom || "unset"}
    >
      <div className="pulse"></div>
    </Box>
  );
};

export default Pulsing;
