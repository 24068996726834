import React, {useEffect, useState} from "react";
import {
  Flex,
} from "@chakra-ui/react";
import {useRecoilState, useSetRecoilState} from "recoil";
import {useLocation, useNavigate} from "react-router-dom";

import "../../../../assets/css/chargePoints.css";
import {updateConnectorDrawerState} from "atoms/updateConnectorDrawerState";
import UpdateChargePointConnectorDrawer from "../components/UpdateChargePointConnectorDrawer";
import ConnectorCard from "../../../../components/card/ConnectorCard";
import SelectEditConnector from "../components/SelectEditConnector";
import RemoteOperations from "../components/RemoteOperations";
import StartCharging from "../components/StartCharging";
import OngoingSession from "../components/OngoingSession";
import {activeConnectorState} from "atoms/activeConnectorState";
import {activeConnectorIdState} from "atoms/activeConnectorIdState";

const Connectors = ({data, refreshHandler}) => {
  const [activeConnector, setActiveConnector] =
    useRecoilState(activeConnectorState);
  const setActiveConnectorId = useSetRecoilState(activeConnectorIdState);
  const [count, setCount] = useState(0);
  const location = useLocation();

  // const [activeConnectorId, setActiveConnectorId] = useRecoilState(
  //   activeConnectorIdState
  // );
  const navigate = useNavigate();
  const [updateDrawerState, setUpdateDrawerState] = useRecoilState(
    updateConnectorDrawerState
  );
  const options = data.map((ele, index) => {
    return <option key={index}>{ele.Code}</option>;
  }) || <div></div>;

  const connectorHandler = (e) => {
    const index = data.findIndex(
      (obj) => obj.Code === parseInt(e.target.value)
    );
    setActiveConnectorId(data[index].Id);
  };
  const activeConnectorHandler = (code) => {
    const index = data.findIndex((obj) => obj.Code === parseInt(code));
    if (index !== -1) {
      setActiveConnector(data[index]);
      setActiveConnectorId(data[index]?.Id)
    } else {
      navigate(-1);
    }
  };

  const editHandler = () => {
    setUpdateDrawerState({isOpen: true, activeId: activeConnector?.Id});
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const connectorId = searchParams.get("connector");
    if (count === 0 && data.length > 0 && connectorId) {
      activeConnectorHandler(connectorId);
      setCount((prev) => prev + 1);
    } else if (data.length > 0) {
      setActiveConnector(data[0]);
      setActiveConnectorId(data[0]?.Id)
    }
  }, [data]);

  return (
    <Flex flexDirection="column">
      <SelectEditConnector
        connectorHandler={connectorHandler}
        options={options}
        editHandler={editHandler}
      />
      <Flex flexDirection="row" alignItems="center">
        <ConnectorCard />
        {activeConnector?.Status === "Charging" ? (
          <OngoingSession refresh={refreshHandler} />
        ) : (
          <StartCharging refresh={refreshHandler} />
        )}
      </Flex>
      <RemoteOperations
        activeConnector={activeConnector}
        refresh={refreshHandler}
      />
      {updateDrawerState?.isOpen && (
        <UpdateChargePointConnectorDrawer id={activeConnector?.id} />
      )}
    </Flex>
  );
};

export default Connectors;
