import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Spinner,
  Input,
  Select,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useRecoilState, useSetRecoilState} from "recoil";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

import {toastState} from "atoms/toastState";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import Card from "components/card/Card";
import logger from "infra/logger";
import {createPartnerDrawerState} from "atoms/createPartnerDrawerState";
import { partnerDrawerSelectOptions } from "../variables/partnerDrawerSelectOptions";

const CreatePartnerDrawer = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const setToast = useSetRecoilState(toastState);
  const [drawerState, setDrawerState] = useRecoilState(
    createPartnerDrawerState
  );
  const validationSchema = yup.object().shape({
    Name: yup.string().min(1).required("Name is required"),
    UserName: yup.string().min(1).required("User Name is required"),
    MobileNumber: yup
      .string()
      .matches(/^[0-9]*$/, "Mobile Number must contain only digits")
      .max(10)
      .min(10)
      .required("Phone Number is required "),
    Email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address format"
      )
      .required("Email is required"),
    Status: yup.boolean().required("Status is required"),
  });

  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [{data, loading: createLoading, error}, createPartner] = useAxios(
    {method: "POST", url: `/api/partner/create`},
    {manual: true}
  );

  const createHandler = async () => {
    setIsLoading(true);
    const formValues = getValues();
    let data = {};
    if (formValues.Email.trim() !== "") {
      data["email"] = formValues.Email.trim();
    }
    if (formValues.Name.trim() !== "") {
      data["name"] = formValues.Name.trim();
    }
     if (formValues.UserName.trim() !== "") {
      data["username"] = formValues.UserName.trim();
    }
    if (formValues.MobileNumber.trim() !== "") {
      data["mobilenumber"] = formValues.MobileNumber.trim();
    }
    if (formValues.Status !== "") {
      data["is_active"] = Boolean(formValues.Status);
    }
    logger.log(formValues);
    logger.log(data);
    if (Object.keys(data).length === 5) {
      try {
        const response = await createPartner({data: data});

        setToast({
          isOpen: true,
          title: "User Created successfully",
          status: "success",
        });
        props.update();
      } catch (error) {
        const err = error?.response?.data?.error;
        logger.log(error);
        setToast({
          isOpen: true,
          title: "Error occured when creating User",
          description: err ? err : null,
          status: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        title: "Enter all the fields to create User",
        status: "warning",
        position: "top",
      });
    }
    setIsLoading(false);
  };

  const closeHandler = () => {
    setDrawerState(null);
  };

  return (
    <Drawer
      isOpen={drawerState?.isOpen}
      placement="right"
      onClose={closeHandler}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Create a new Partner</DrawerHeader>

        <DrawerBody>
          {!createLoading ? (
            <>
              <Card>
                <form>
                  {partnerDrawerSelectOptions.map((item, index) => (
                    <Flex key={index} mb="20px" alignItems="center">
                      <Controller
                        name={item.name}
                        control={control}
                        defaultValue=""
                        render={({field}) => (
                          <FormControl
                            isRequired
                            flexDirection="row"
                            isInvalid={errors[item.name]}
                          >
                            <FormLabel fontSize={20}>{item.label}</FormLabel>
                            {item.type === "select" ? (
                              <Select
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                width="400px"
                                height="50px"
                                isRequired
                              >
                                <option value="">Select</option>
                                {item.options.map((option, ind) => {
                                  return (
                                    <option key={ind} value={option.value}>
                                      {option.name}
                                    </option>
                                  );
                                })}
                              </Select>
                            ) : (
                              <Input
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                placeholder={item.placeholder}
                                width="400px"
                                height="50px"
                                type={item.type}
                                isRequired
                              />
                            )}
                            <FormHelperText color={"#f57d36"}>
                              {item.helper}
                            </FormHelperText>
                            <FormErrorMessage mb={"20px"}>
                              {errors[item.name] && errors[item.name].message}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    </Flex>
                  ))}
                </form>
              </Card>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            bg="#f57d36"
            color="white"
            colorScheme="orange"
            onClick={handleSubmit(createHandler)}
            isLoading={isLoading}
            loadingText="Creating"
            spinner={<Spinner size="md" />}
            disabled={isLoading}
          >
            Create
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CreatePartnerDrawer;
