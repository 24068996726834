// Daily Traffic Dashboards Default

import { Flex, background } from "@chakra-ui/react";

export const option = {
  grid: { left: 100 },
  xAxis: {
    type: "category",
    data: [], // Will be populated dynamically
  },
  yAxis: {
    type: "value",
    name: "Amount ₹",
    nameLocation: "middle",
    nameGap: 45,
    nameTextStyle: {
      color: "black",
      fontweight: "bolder",
      fontFamily: "Arial",
      fontSize: 18,
      align: "center",
      vertialAlign: "bottom",
      lineHeight: 66,
    },
  },
  series: [
    {
      data: [],
      type: "bar",
      smooth: true,
      barWidth: 10,
      itemStyle: {
        color: {
          type: "linear", // Use a linear gradient
          x: 0, // Start point (left)
          y: 0, // Start point (top)
          x2: 0, // End point (right)
          y2: 1, // End point (bottom)
          colorStops: [
            // Define the gradient colors
            {
              offset: 0, // Color start at 0% of the bar
              color: "blue", // Start color
            },
            {
              offset: 1, // Color end at 100% of the bar
              color: "lightblue", // End color
            },
          ],
        },
      },
    },
  ],
  tooltip: {
    trigger: "axis",
  },
};

export const dailySessions = {
  grid: { left: 100 },
  xAxis: {
    type: "category",
    data: [], // Will be populated dynamically
  },
  yAxis: {
    type: "value",
    name: "sessions ⚡",
    nameLocation: "middle",
    nameGap: 45,
    nameTextStyle: {
      color: "black",
      fontweight: "bolder",
      fontFamily: "Arial",
      fontSize: 18,
      align: "center",
      vertialAlign: "bottom",
      lineHeight: 66,
    },
  },
  series: [
    {
      data: [],
      type: "bar",
      smooth: true,
      barWidth: 9,
      itemStyle: {
        color: {
          type: "linear", // Use a linear gradient
          x: 0, // Start point (left)
          y: 0, // Start point (top)
          x2: 0, // End point (right)
          y2: 1, // End point (bottom)
          colorStops: [
            // Define the gradient colors
            {
              offset: 0, // Color start at 0% of the bar
              color: "blue", // Start color
            },
            {
              offset: 1, // Color end at 100% of the bar
              color: "lightblue", // End color
            },
          ],
        },
      },
    },
  ],
  tooltip: {
    trigger: "axis",
  },
};

export const dailyUnits = {
  grid: { left: 100 },
  xAxis: {
    type: "category",
    data: [], // Will be populated dynamically
  },
  yAxis: {
    type: "value",
    name: "Units ⚡",
    nameLocation: "middle",
    nameGap: 45,
    nameTextStyle: {
      color: "black",
      fontweight: "bolder",
      fontFamily: "Arial",
      fontSize: 18,
      align: "center",
      vertialAlign: "bottom",
      lineHeight: 66,
    },
  },
  series: [
    {
      data: [],
      type: "bar",
      smooth: true,
      barWidth: 10,
      itemStyle: {
        color: {
          type: "linear", // Use a linear gradient
          x: 0, // Start point (left)
          y: 0, // Start point (top)
          x2: 0, // End point (right)
          y2: 1, // End point (bottom)
          colorStops: [
            // Define the gradient colors
            {
              offset: 0, // Color start at 0% of the bar
              color: "blue", // Start color
            },
            {
              offset: 1, // Color end at 100% of the bar
              color: "lightblue", // End color
            },
          ],
        },
      },
    },
  ],
  tooltip: {
    trigger: "axis",
  },
};

export const weeklyTrendRevenueBar = {
  grid: { left: 100 },
  xAxis: {
    type: "category",
    data: [],
  },
  yAxis: {
    type: "value",
    name: "Amount ₹",
    nameLocation: "middle",
    nameGap: 45,
    nameTextStyle: {
      color: "black",
      fontweight: "bolder",
      fontFamily: "Arial",
      fontSize: 18,
      align: "center",
      vertialAlign: "bottom",
      lineHeight: 66,
    },
  },
  series: [
    {
      data: [],
      type: "bar",
      smooth: true,
      barWidth: 20,
      itemStyle: {
        color: {
          type: "linear", // Use a linear gradient
          x: 0, // Start point (left)
          y: 0, // Start point (top)
          x2: 0, // End point (right)
          y2: 1, // End point (bottom)
          colorStops: [
            // Define the gradient colors
            {
              offset: 0, // Color start at 0% of the bar
              color: "blue", // Start color
            },
            {
              offset: 1, // Color end at 100% of the bar
              color: "lightblue", // End color
            },
          ],
        },
      },
    },
  ],
  tooltip: {
    trigger: "axis",
  },
};

export const weeklyTrendSessions = {
  grid: { left: 100 },
  xAxis: {
    type: "category",
    data: [],
  },
  yAxis: {
    type: "value",
    name: "sessions ⚡",
    nameLocation: "middle",
    nameGap: 45,
    nameTextStyle: {
      color: "black",
      fontweight: "bolder",
      fontFamily: "Arial",
      fontSize: 18,
      align: "center",
      vertialAlign: "bottom",
      lineHeight: 66,
    },
  },
  series: [
    {
      data: [],
      type: "bar",
      smooth: true,
      barWidth: 20,
      itemStyle: {
        color: {
          type: "linear", // Use a linear gradient
          x: 0, // Start point (left)
          y: 0, // Start point (top)
          x2: 0, // End point (right)
          y2: 1, // End point (bottom)
          colorStops: [
            // Define the gradient colors
            {
              offset: 0, // Color start at 0% of the bar
              color: "blue", // Start color
            },
            {
              offset: 1, // Color end at 100% of the bar
              color: "lightblue", // End color
            },
          ],
        },
      },
    },
  ],
  tooltip: {
    trigger: "axis",
  },
};

export const weeklyTrendUnits = {
  grid: { left: 100 },
  xAxis: {
    type: "category",
    data: [],
  },
  yAxis: {
    type: "value",
    name: "Units ⚡",
    nameLocation: "middle",
    nameGap: 45,
    nameTextStyle: {
      color: "black",
      fontweight: "bolder",
      fontFamily: "Arial",
      fontSize: 18,
      align: "center",
      vertialAlign: "bottom",
      lineHeight: 66,
    },
  },
  series: [
    {
      data: [],
      type: "bar",
      smooth: true,
      barWidth: 20,
      itemStyle: {
        color: {
          type: "linear", // Use a linear gradient
          x: 0, // Start point (left)
          y: 0, // Start point (top)
          x2: 0, // End point (right)
          y2: 1, // End point (bottom)
          colorStops: [
            // Define the gradient colors
            {
              offset: 0, // Color start at 0% of the bar
              color: "blue", // Start color
            },
            {
              offset: 1, // Color end at 100% of the bar
              color: "lightblue", // End color
            },
          ],
        },
      },
    },
  ],
  tooltip: {
    trigger: "axis",
  },
};

export const monthlyTrendRevenueBar = {
  grid: { left: 100, top: 40 },
  xAxis: {
    type: "category",
    data: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yAxis: {
    type: "value",
    name: "Amount ₹",
    nameLocation: "middle",
    nameGap: 45,
    nameTextStyle: {
      color: "black",
      fontWeight: "bolder",
      fontFamily: "Arial",
      fontSize: 16,
      align: "center",
      verticalAlign: "bottom",
      lineHeight: 66,
    },
  },
  series: [
    {
      data: [],
      type: "bar",
      smooth: true,
      barWidth: 20,
      itemStyle: {
        color: {
          type: "linear", // Use a linear gradient
          x: 0, // Start point (left)
          y: 0, // Start point (top)
          x2: 0, // End point (right)
          y2: 1, // End point (bottom)
          colorStops: [
            // Define the gradient colors
            {
              offset: 0, // Color start at 0% of the bar
              color: "blue", // Start color
            },
            {
              offset: 1, // Color end at 100% of the bar
              color: "lightblue", // End color
            },
          ],
        },
      },
    },
  ],
  tooltip: {
    trigger: "axis",
  },
};

export const monthlyTrendUnits = {
  grid: { left: 100, top: 40 },
  xAxis: {
    type: "category",
    data: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yAxis: {
    type: "value",
    name: "Units ⚡",
    nameLocation: "middle",
    nameGap: 45,
    nameTextStyle: {
      color: "black",
      fontWeight: "bolder",
      fontFamily: "Arial",
      fontSize: 16,
      align: "center",
      verticalAlign: "bottom",
      lineHeight: 66,
    },
  },
  series: [
    {
      data: [],
      type: "bar",
      smooth: true,
      barWidth: 20,
      itemStyle: {
        color: {
          type: "linear", // Use a linear gradient
          x: 0, // Start point (left)
          y: 0, // Start point (top)
          x2: 0, // End point (right)
          y2: 1, // End point (bottom)
          colorStops: [
            // Define the gradient colors
            {
              offset: 0, // Color start at 0% of the bar
              color: "blue", // Start color
            },
            {
              offset: 1, // Color end at 100% of the bar
              color: "lightblue", // End color
            },
          ],
        },
      },
    },
  ],
  tooltip: {
    trigger: "axis",
  },
};

export const monthlyTrendchargingSessionsBar = {
  grid: { left: 100, top: 40 },
  xAxis: {
    type: "category",
    data: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yAxis: {
    type: "value",
    name: "sessions ⚡",
    nameLocation: "middle",
    nameGap: 45,
    nameTextStyle: {
      color: "black",
      fontWeight: "bolder",
      fontFamily: "Arial",
      fontSize: 16,
      align: "center",
      verticalAlign: "bottom",
      lineHeight: 66,
    },
  },
  series: [],
  legend: {
    type: "scroll",
    data: [],
    bottom: 10,
    left: "center",
    padding: [10, 20],
    itemGap: 20,
    pageIconColor: "black",
    pageTextStyle: {
      color: "black",
    },
  },
  tooltip: {
    trigger: "axis",
  },
};

export const revenueSplitByChargingStations = {
  tooltip: {
    trigger: "item",
    formatter: "{b}: {c} Rupees ({d}%)", // Format the tooltip to show station name, revenue, and percentage
  },
  legend: {
    orient: "vertical",
    left: "right", // Adjust the legend position as needed
    data: [], // Empty legend data initially, will be populated dynamically
    type: "scroll",
  },
  series: [
    {
      name: "Revenue",
      type: "pie",
      radius: "55%", // Adjust the pie chart radius as needed
      center: ["40%", "50%"], // Adjust the center position as needed
      data: [],
    },
  ],
};

export const chargingSessionsSplitByChargingStations = {
  tooltip: {
    trigger: "item",
    formatter: "{b}: {c} Sessions ({d}%)",
  },
  legend: {
    orient: "vertical",
    left: "right", // Adjust the legend position as needed
    data: [], // Add legend data for charging stations
    type: "scroll",
  },
  series: [
    {
      name: "Charging Sessions",
      type: "pie",
      radius: "55%", // Adjust the pie chart radius as needed
      center: ["40%", "50%"], // Adjust the center position as needed
      data: [],
    },
  ],
};

// Define the initial chart options for the downtime report
export const downtimePieChartOptions = {
  tooltip: {
    trigger: "item",
    formatter: "{b}: {c} (min) ({d}%)", // Show name, value (percentage), and overall percentage
  },
  legend: {
    orient: "vertical",
    left: "right", // Position the legend to the right
    data: [],
    type: "scroll",
  },
  series: [
    {
      name: "Downtime Report",
      type: "pie",
      radius: "55%", // Adjust the pie chart size
      center: ["40%", "50%"], // Center the chart
      data: [],
      label: {
        formatter: "{b}\n{d}%", // Display name and percentage
      },
    },
  ],
};

export const dailyDowntimeBar = {
  grid: { left: 100, top: 40 },
  xAxis: {
    type: "category",
    data: [],
  },
  yAxis: {
    type: "value",
    name: "Downtime (min)",
    nameLocation: "middle",
    nameGap: 45,
    nameTextStyle: {
      color: "black",
      fontWeight: "bolder",
      fontFamily: "Arial",
      fontSize: 16,
      align: "center",
      verticalAlign: "bottom",
      lineHeight: 66,
    },
  },
  series: [],
  legend: {
    type: "scroll",
    data: [],
    bottom: 10,
    left: "center",
    padding: [10, 20],
    itemGap: 20,
    pageIconColor: "black",
    pageTextStyle: {
      color: "black",
    },
  },
  tooltip: {
    trigger: "axis",
  },
};

export const weeklyDowntimeBar = {
  grid: { left: 100, top: 40 },
  xAxis: {
    type: "category",
    data: [],
  },
  yAxis: {
    type: "value",
    name: "Downtime (min)",
    nameLocation: "middle",
    nameGap: 45,
    nameTextStyle: {
      color: "black",
      fontWeight: "bolder",
      fontFamily: "Arial",
      fontSize: 16,
      align: "center",
      verticalAlign: "bottom",
      lineHeight: 66,
    },
  },
  series: [],
  legend: {
    type: "scroll",
    data: [],
    bottom: 10,
    left: "center",
    padding: [10, 20],
    itemGap: 20,
    pageIconColor: "black",
    pageTextStyle: {
      color: "black",
    },
  },
  tooltip: {
    trigger: "axis",
  },
};

export const monthlyDowntimeBar = {
  grid: { left: 100, top: 40 },
  xAxis: {
    type: "category",
    data: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yAxis: {
    type: "value",
    name: "Downtime (mins)",
    nameLocation: "middle",
    nameGap: 45,
    nameTextStyle: {
      color: "black",
      fontWeight: "bolder",
      fontFamily: "Arial",
      fontSize: 16,
      align: "center",
      verticalAlign: "bottom",
      lineHeight: 66,
    },
  },
  series: [],
  legend: {
    type: "scroll",
    data: [],
    bottom: 10,
    left: "center",
    padding: [10, 20],
    itemGap: 20,
    pageIconColor: "black",
    pageTextStyle: {
      color: "black",
    },
  },
  tooltip: {
    trigger: "axis",
  },
};
