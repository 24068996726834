import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Spinner } from "@chakra-ui/react";
import { configureAxios } from "../../utils/api";
import { loggedInUserState } from "atoms/loggedInUserState";
import { useSetRecoilState } from "recoil";
import logger from "infra/logger";
import useAxios from "axios-hooks";
const withAuthentication = (WrappedComponent) => {
  //....................................................
  const WithAuthentication = (props) => {
    const [viewRoute, setViewRoute] = useState(false);
    const setLoggedInUser = useSetRecoilState(loggedInUserState);
    const navigate = useNavigate();

    const [user, setUser] = useState({});

    const [{ userData, loading, error }, fetchUserData] = useAxios(
      {},
      { manual: true }
    );

    const getLoggedInUser = async (id) => {
      try {
        const response = await fetchUserData({
          method: "GET",
          url: `/api/user/${id}`,
        });
        // setUser(response.data.data);
        setLoggedInUser((prev) => {
          return {
            ...prev,
            tenantType: response?.data?.data?.TenantType,
          };
        });
        console.log("inWithAuthenticaiton", user);
      } catch (error) {
        logger.log(error);
      }
    };

    useEffect(() => {
      //TODO check for auth state change from local storage
      const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
        if (!currentUser) {
          setLoggedInUser(null);
          setViewRoute(false);
          configureAxios(null);
          navigate("/");
        } else if (currentUser) {
          const token = await currentUser.getIdToken();
          const idTokenResult = await currentUser.getIdTokenResult();
          logger.log("idTokenResult", idTokenResult);
          configureAxios(token);
          let role = idTokenResult.claims.scope;
          if (role === "admin") {
            const subDomain = window.location.hostname.split(".")[0];
            if (subDomain === process.env.REACT_APP_ADMIN_SUB_DOMAIN) {
              role = "u" + role;
            }
          }

          getLoggedInUser(idTokenResult.claims.userId);

          setLoggedInUser((prev) => {
            return {
              ...prev,
              name: idTokenResult.claims.name,
              role: role,
              email_verfied: idTokenResult.claims.email_verified,
              tenantId: idTokenResult.claims.tenantId,
              userId: idTokenResult.claims.userId,
            };
          });
          logger.log("idTokenResult", idTokenResult);

          setViewRoute(true);
        }
      });

      return () => unsubscribe(); // Cleanup the listener when the component unmounts
    }, []);

    return viewRoute ? (
      <WrappedComponent {...props} />
    ) : (
      <Box
        width={"100vw"}
        height={"100vh"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Box>
    );
  };
  //...........................................................
  return WithAuthentication;
};

export default withAuthentication;
