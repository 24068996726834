import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";

import { useRecoilValue } from "recoil";
import { loggedInUserState } from "atoms/loggedInUserState";
import TenantsList from "./TenantsList";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import logger from "infra/logger";

const Tenants = () => {
  const [tenants, setTenants] = useState([]);
  const [verificationStatus, setVerificationStatus] = useState("all");
  const loggedInUser = useRecoilValue(loggedInUserState);

  const [{ data, loading, error }, execute] = useAxios(
    { method: "GET", url: `/api/tenant?status=${verificationStatus}` },
    { manual: true }
  );

  const [
    { updateStatus, updateStatusLoading, updateStatusError },
    updateTenantVerificationStatus,
  ] = useAxios({}, { manual: true });
  
  const getTenants = async () => {
    try {
      let url = `/api/tenant?status=${verificationStatus}`;
      const response = await execute({ method: "GET", url });
      setTenants(response.data.data);
      logger.log("tenants", response.data.data);
    } catch (error) {
      logger.log(error);
    }
  };

  // const actionButtonsHandler = async (status, id) => {
  //   try {
  //     const response = await updateTenantVerificationStatus({
  //       url: `/api/tenant/verification/${id}?status=${status}`,
  //       method: "PATCH",
  //     });

  //     getTenants();
  //   } catch (error) {}
  // };

  useEffect(() => {
    getTenants();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <TenantsList
          tenants={tenants}
          getTenants={getTenants}
          setVerificationStatus={setVerificationStatus}
          verificationStatus={verificationStatus}
          // actionButtonsHandler={actionButtonsHandler}
          setTenants={setTenants}
        />
      )}
    </>
  );
};

export default Tenants;
