import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import DailyTrendRevenue from "./DailyTrendRevenue";
import DailyTrendSessions from "./DailyTrendSessions";
import DailyTrendUnits from "./DailyTrendUnits";
import WeeklyTrendUnits from "./WeeklyTrendUnits";
import WeeklyTrendRevenue from "./WeeklyTrendRevenue";
import WeeklyTrendSessions from "./WeeklyTrendSessions";
import MonthlyTrendRevenue from "./MonthlyTrendRevenue";
import MonthlyTrendChargingSessions from "./MonthlyTrendChargingSessions";
import MonthlyTrendUnits from "./MonthlyTrendUnits";
import RSCS from "./RSCS";
import NCSSCS from "./NCSSCS";
import SeparatorWithTitle from "components/card/SeparatorWithTitle";
import { dynamicAnalyticsSelectOptions } from "../variables/dynamicStatsSelectOptions";
import useAxios from "axios-hooks";
import logger from "infra/logger";

export default function DashboardAnalyticsTab() {
  const [selectedPoint, setSelectedPoint] = useState("all");
  const [points, setPoints] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const validationSchema = yup.object().shape({
    ChargePoint: yup.string().required("chargePoint is Mandatory"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [
    {
      data: chargePointsData,
      loading: chargePointsLoading,
      error: chargePointsError,
    },
    getPoints,
    cancelPointsRequest,
  ] = useAxios(
    {
      method: "GET",
      url: `/api/chargePoint`,
    },
    { manual: true }
  );

  const getChargePoints = async () => {
    try {
      const response = await getPoints();
      setPoints(response.data.data);
    } catch (error) {
      logger.log(error);
    }
  };
  const pointOptions = points.map((ele, index) => {
    return (
      <option key={index} value={ele.Code}>
        {ele.Code} - {ele.Name}
      </option>
    );
  });

  const updateAnalytics = () => {
    const formValues = getValues();
    if (formValues.ChargePoint != "") {
      console.log("formValues", formValues);
      setSelectedPoint(formValues.ChargePoint);
    } else {
      setSelectedPoint("all");
    }
    setRefresh(!refresh);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getChargePoints();
    }, 1000);
    return () => {
      clearTimeout(timeout);
      cancelPointsRequest();
    };
  }, []);

  return (
    <Box pt={{ base: "32.5px", md: "20px", xl: "20px" }}>
      <Flex
        direction="row"
        alignItems="flex-end"
        justifyContent="center"
        gap={5}
        p={5}
        flexWrap="wrap"
        width="100%"
        borderRadius={"10px"}
        backgroundColor={"#e8edf7"}
      >
        {dynamicAnalyticsSelectOptions.map((item) => {
          return (
            <Controller
              key={item.name}
              name={item.name}
              control={control}
              defaultValue={item.defaultValue}
              render={({ field }) => (
                <FormControl
                  isRequired
                  flexDirection="row"
                  isInvalid={errors[item.name]}
                  width="unset"
                >
                  <FormLabel fontSize={20}>{item.label}</FormLabel>
                  {item.type === "select" && (
                    <Select
                      {...field}
                      borderColor={"blackAlpha.500"}
                      background="white"
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                      type={item.type}
                      isRequired
                    >
                      <option value="all">All Chargers</option>
                      {pointOptions}
                    </Select>
                  )}
                  <FormHelperText color={"#f57d36"}>
                    {item.helper}
                  </FormHelperText>
                  <FormErrorMessage mb={"20px"}>
                    {errors[item.name] && errors[item.name].message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />
          );
        })}

        <Button
          colorScheme="green"
          borderRadius={5}
          mb={3}
          onClick={handleSubmit(updateAnalytics)}
          // isLoading={loading}
        >
          Get Data
        </Button>
      </Flex>

      <SeparatorWithTitle title="Trends" />
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <DailyTrendRevenue chargePointCode={selectedPoint} refresh={refresh} />
        <DailyTrendSessions chargePointCode={selectedPoint} refresh={refresh} />
        <DailyTrendUnits chargePointCode={selectedPoint} refresh={refresh} />
        <WeeklyTrendUnits chargePointCode={selectedPoint} refresh={refresh} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <WeeklyTrendRevenue chargePointCode={selectedPoint} refresh={refresh} />
        <WeeklyTrendSessions chargePointCode={selectedPoint} refresh={refresh} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <MonthlyTrendRevenue chargePointCode={selectedPoint} refresh={refresh} />
        <MonthlyTrendChargingSessions chargePointCode={selectedPoint} refresh={refresh} />
        <MonthlyTrendUnits chargePointCode={selectedPoint} refresh={refresh} />
        <RSCS chargePointCode={selectedPoint} refresh={refresh} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <NCSSCS chargePointCode={selectedPoint} refresh={refresh} />
      </SimpleGrid>
    </Box>
  );
}
