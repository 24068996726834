export const StationInputFields = [
  {
    label: "Charging Station Name",
    placeholder: "Add ChargingStation Name",
    name: "Name",
  },
  {
    label: "Description",
    placeholder: "Add description",
    name: "Description",
  },

  {
    label: "Address",
    placeholder: "Add address",
    name: "Address",
  },
  {
    label: "Phone Number",
    placeholder: "Phone Number",
    type: "number",
    helper: "*Note: type without country code",
    name: "PhoneNumber",
  },
  {
    label: "Pin code",
    placeholder: "Pin code",
    type: "number",
    name: "PinCode",
  },
  {
    label: "City",
    placeholder: "City",
    name: "City",
    type: "city",
  },
  {
    label: "State",
    placeholder: "State",
    name: "State",
  },
  {
    label: "Country",
    placeholder: "Country",
    name: "Country",
  },
];

export const BillingPlanFixedInputFields = [
  {
    name: "FixedElectricityCharges",
    label: "Electricity Cost",
    placeholder: "Enter Electricity Cost",
    caluculatedAgainst: "₹",
  },
  {
    name: "FixedRentalCharges",
    label: "Rental Cost",
    placeholder: "Enter Rental Cost",
    caluculatedAgainst: "₹",
  },
  {
    name: "FixedDataCharges",
    label: "Data Cost",
    placeholder: "Enter Data Cost",
    caluculatedAgainst: "₹",
  },
];

export const BillingPlanUnitInputFields = [
  {
    name: "ElectricityCost",
    label: "Electricity Cost",
    placeholder: "Enter Electricity Cost",
    caluculatedAgainst: "₹",
  },
  {
    name: "RentalCost",
    label: "Rental Cost",
    placeholder: "Enter Rental Cost",
    caluculatedAgainst: "₹",
  },
  {
    name: "ChargerOperationCost",
    label: "Charger Operation Cost",
    placeholder: "Enter Charger Operation Cost",
    caluculatedAgainst: "₹",
  },
];
