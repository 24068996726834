import Axios from "axios";
import {configure} from "axios-hooks";
import {auth} from "../firebase";
import { jwtDecode } from "jwt-decode";

const subDomain = window.location.hostname.split(".")[0];
const BASE_URL = `${process.env.REACT_APP_PROTOCOL}://${subDomain}.${process.env.REACT_APP_DOMAIN}`;

export const configuredAxios = {
  instance: Axios.create({}),
};

export const configureAxios = (token) => {
  const headers = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  const axios = Axios.create({
    baseURL: BASE_URL,
    proxy: false,
    headers,
  });

  // using jwt-decode

  axios.interceptors.request.use(
    async (request) => {
      if (!token) {
        return request;
      }
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);
        const expirationTime = decodedToken?.exp;
        const timeUntilExpiration = expirationTime - currentTime;
        if (timeUntilExpiration < 300) {
          token = await auth.currentUser?.getIdToken();
          axios.defaults.headers["Authorization"] = `Bearer ${token}`;
          request.headers["Authorization"] = `Bearer ${token}`;
          return request;
        } else {
          return request;
        }
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async (err) => {
      return Promise.reject(err);
    }
  );

  configuredAxios.instance = axios;

  configure({axios});
};
