import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { useSetRecoilState } from "recoil";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { toastState } from "atoms/toastState";
import TotalRevenue from "./TotalRevenue";
import TotalDuration from "./TotalDuration";
import TotalSessions from "./TotalSessions";
import TotalUnits from "./TotalUnits";
import AvgSessionsTime from "./AvgSessionsTime";
import AvgUnitsPerSession from "./AvgUnitsPerSession";
import AvgSessionsPerDay from "./AvgSessionsPerDay";
import HeatMap from "./HeatMap";
import logger from "infra/logger";
import { getFirstDateOfMonth } from "utils/utils";
import { getCurrentDate } from "utils/utils";
import { dynamicStatsSelectOptions } from "../variables/dynamicStatsSelectOptions";

const DynamicStats = () => {
  const setToast = useSetRecoilState(toastState);
  const [chartData, setChartData] = useState({});
  const [heatMapdata, setHeatMapData] = useState([[]]);
  const [points, setPoints] = useState([]);
  const [{ data, loading, error }, getCustomStats, cancelCustomRequest] =
    useAxios(
      {
        method: "GET",
        url: `/api/dashboard/analytics?key=custom_stats`,
      },
      { manual: true }
    );

  const [
    { data: heatmapData, loading: heatmapLoading, error: heatmapError },
    getHeatMapData,
    cancelHeatmapRequest,
  ] = useAxios(
    {
      method: "GET",
      url: `/api/dashboard/analytics?key=heatmap_data`,
    },
    { manual: true }
  );

  const [
    {
      data: chargePointsData,
      loading: chargePointsLoading,
      error: chargePointsError,
    },
    getPoints,
    cancelPointsRequest,
  ] = useAxios(
    {
      method: "GET",
      url: `/api/chargePoint`,
    },
    { manual: true }
  );

  const validationSchema = yup.object().shape({
    From: yup.date().required("From is required"),
    To: yup.date().required("To is required"),
    ChargePoint: yup.string().required("chargePoint is Mandatory"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  // Function to get the first date of the current month

  const getStats = async () => {
    const formValues = getValues();
    let params = {};
    if (formValues.From && formValues.To && formValues.ChargePoint != "") {
      params["startTime"] = formValues.From;
      params["endTime"] = formValues.To;
      params["chargePointCode"] = formValues.ChargePoint;
    } else {
      params["startTime"] = getFirstDateOfMonth();
      params["endTime"] = getCurrentDate();
      params["chargePointCode"] = "all";
    }
    try {
      const response = await getCustomStats({ params });
      setChartData(response.data.data);
      const heatmapResponse = await getHeatMapData({ params });
      setHeatMapData(heatmapResponse.data.data);
    } catch (error) {
      logger.error("Error fetching data:", error);
      setToast({
        isOpen: true,
        title: `Error occured while getting stats`,
        status: "error",
      });
    }
  };

  const getChargePoints = async () => {
    try {
      const response = await getPoints();
      setPoints(response.data.data);
    } catch (error) {
      logger.log(error);
    }
  };
  const pointOptions = points.map((ele, index) => {
    return (
      <option key={index} value={ele.Code}>
        {ele.Code} - {ele.Name}
      </option>
    );
  });

  useEffect(() => {
    const formValues = getValues();
    setValue("ChargePoint", "all");
    const timeout = setTimeout(() => {
      if (formValues.From && formValues.To) {
        getStats();
      }
    }, 500);
    return () => {
      clearTimeout(timeout);
      cancelCustomRequest();
      cancelHeatmapRequest();
    };
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      getChargePoints();
    }, 1000);
    return () => {
      clearTimeout(timeout);
      cancelPointsRequest();
    };
  }, []);
  return (
    <Box
      direction="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      backgroundColor="#e8edf7"
      padding={5}
      borderRadius={8}
      mb={5}
      overflow="hidden"
    >
      <Flex
        direction="row"
        alignItems="flex-end"
        justifyContent="center"
        gap={5}
        mb={10}
        flexWrap="wrap"
      >
        {dynamicStatsSelectOptions.map((item) => {
          return (
            <Controller
              key={item.name}
              name={item.name}
              control={control}
              defaultValue={item.defaultValue}
              render={({ field }) => (
                <FormControl
                  isRequired
                  flexDirection="row"
                  isInvalid={errors[item.name]}
                  width="unset"
                >
                  <FormLabel fontSize={20}>{item.label}</FormLabel>
                  {item.type === "datetime-local" && (
                    <Input
                      {...field}
                      borderColor={"blackAlpha.500"}
                      background="white"
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                      type={item.type}
                      isRequired
                    />
                  )}
                  {item.type === "select" && (
                    <Select
                      {...field}
                      borderColor={"blackAlpha.500"}
                      background="white"
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                      type={item.type}
                      isRequired
                    >
                      <option value="all">All Chargers</option>
                      {pointOptions}
                    </Select>
                  )}
                  <FormHelperText color={"#f57d36"}>
                    {item.helper}
                  </FormHelperText>
                  <FormErrorMessage mb={"20px"}>
                    {errors[item.name] && errors[item.name].message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />
          );
        })}
        <Button
          colorScheme="green"
          borderRadius={5}
          mb={2.5}
          onClick={handleSubmit(getStats)}
          isLoading={loading}
        >
          Get Data
        </Button>
      </Flex>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap="20px" mb="20px">
        <TotalRevenue data={chartData?.total_revenue} />
        <TotalSessions data={chartData?.total_sessions} />
        <TotalUnits data={chartData?.total_units} />
        <TotalDuration data={chartData?.total_duration} />
        <AvgSessionsPerDay data={chartData?.average_sessions_per_day} />
        <AvgUnitsPerSession data={chartData?.average_units_per_session} />
        <AvgSessionsTime data={chartData?.average_duration_per_session} />
      </SimpleGrid>
      <HeatMap data={heatMapdata} />
    </Box>
  );
};

export default DynamicStats;
