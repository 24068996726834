import useAxios from "axios-hooks";
import React, {Suspense, useEffect, useState} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import { Button, Flex} from "@chakra-ui/react";

import {toastState} from "atoms/toastState";
import Card from "components/card/Card";
import ChargingSessionCard from "../../../../components/card/ChargingSessionCard";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import { activeConnectorState } from "atoms/activeConnectorState";
import logger from "infra/logger";
import { loggedInUserState } from "atoms/loggedInUserState";
import { accessLevels } from "utils/accessLevels";

const OngoingSession = ({ refresh }) => {
    const activeConnector = useRecoilValue(activeConnectorState)
    const loggedInUser = useRecoilValue(loggedInUserState);
  const role = loggedInUser?.role;
  const accessLevel = accessLevels[role]?.accessLevel;

  const setToast = useSetRecoilState(toastState);
  const [session, setSession] = useState(null);
  const [{data, loading, error}, execute] = useAxios(
    {
      method: "GET",
      url: `/api/chargingSession/ongoing?chargePointCode=${activeConnector?.ChargePointCode}&connectorId=${activeConnector?.Code}`,
    },
    {manual: true}
  );
  const [
    {data: stopData, loading: stopLaoding, error: stopError},
    executeStop,
  ] = useAxios(
    {
      method: "POST",
      url: `/api/ocpp16/charging/stop`,
    },
    {manual: true}
  );
  const getOngoingSesson = async () => {
    try {
      const response = await execute();
      setSession(response.data.data);
    } catch (error) {
      const err = error?.response?.data?.error;
      logger.log(error);

    }
  };
  const stopSession = async () => {
    var payload = {};
    payload["ClientId"] = session.chargePointCode;
    payload["TransactionId"] = session.id;
    try {
      const response = await executeStop({data: payload});
      setToast({
        isOpen: true,
        title: "Session Stopped",
        status: "success",
      });
    } catch (error) {
      logger.log(error);
      const err = error?.response?.data?.error;
      logger.log(error);
      setToast({
        isOpen: true,
        title: "Error occured when stopping Session",
        description: err ? err : null,
        status: "error",
      });
    }
    refresh();
  };
  useEffect(() => {
    if (activeConnector?.Status === "Charging") {
      getOngoingSesson();
      const intervalId = setInterval(() => {
      getOngoingSesson()
      }, 10000)
      return () => clearInterval(intervalId)
    }
  }, []);
  return loading ? (
    <LoadingSpinner />
  ) : (
    <Card borderWidth={1}  w="auto" ml={10} borderRadius="5">
      <Flex direction="column" alignItems="center">
        <ChargingSessionCard session={session} />
        <Button
          isDisabled= {!accessLevel<=2 ? true : session ? false : true}
          variant="solid"
          backgroundColor="red"
          _hover={{backgroundColor: "#b00404"}}
          color="white"
          borderRadius={6}
          px={10}
          onClick={stopSession}
        >
          Stop
        </Button>
      </Flex>
    </Card>
  );
};
export default OngoingSession;
