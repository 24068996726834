import React from "react";

import {Icon} from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdOutlinePayment,
  MdHome,
  MdOutlineShoppingCart,
  MdTripOrigin,
} from "react-icons/md";
import {
  FaChargingStation,
  FaExchangeAlt,
  FaHandshake,
  FaUser,
  FaDatabase,
  FaHouseUser,
  FaMoneyCheck,
} from "react-icons/fa";
import { LuNfc } from "react-icons/lu";
// Admin Imports
import Dashboard from "views/admin/dashboard";
import Transactions from "views/admin/payouts";
import ChargingStation from "views/admin/chargingStation";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignIn from "views/auth/signIn";
import Users from "views/admin/users";
import RfidCards from "views/admin/rfidCards";
import TenantScreen from "views/admin/tenants";
import Sources from "views/admin/sources";
import Partners from "views/admin/partners";
import {RiShakeHandsFill} from "react-icons/ri";
import AccountSettings from "views/admin/account/AccountSettings";
import { BsCash } from "react-icons/bs";
import { allRoles } from "utils/accessLevels";
import { adminsAndManagers } from "utils/accessLevels";
import { onlyAdmins } from "utils/accessLevels";
import { onlyAdminsAndPropertyOwners } from "utils/accessLevels";
import { onlyUAdmins } from "utils/accessLevels";

// Define the routes for navigation
const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    icon: (
      <Icon
        as={MdHome}
        maxWidth="30px"
        maxHeight="40px"
        width="30px"
        height="50px"
        color="inherit"
      />
    ),
    component: Dashboard,
    accessLevel: 3,
    roles: allRoles,
    element: <Dashboard />,
  },
  {
    name: "Charging Stations",
    layout: "/admin",
    icon: (
      <Icon
        as={FaChargingStation}
        maxWidth="30px"
        maxHeight="40px"
        width="30px"
        height="50px"
        color="inherit"
      />
    ),
    path: "/chargingstations",
    accessLevel: 3,
    roles: allRoles,
    element: <ChargingStation />,
  },
  {
    name: "RFID-Cards",
    layout: "/admin",
    path: "/rfid_cards",
    icon: (
      <Icon
        as={LuNfc}
        maxWidth="30px"
        maxHeight="40px"
        width="30px"
        height="50px"
        color="inherit"
      />
    ),
    accessLevel: 2,
    roles: adminsAndManagers,
    element: <RfidCards />,
  },
  {
    name: "Payouts",
    layout: "/admin",
    path: "/payouts",
    icon: (
      <Icon
        as={BsCash}
        maxWidth="30px"
        maxHeight="40px"
        width="30px"
        height="50px"
        color="inherit"
      />
    ),
    accessLevel: 1,
    roles: onlyAdminsAndPropertyOwners,
    secondary: true,
    element: <Transactions />,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "/users",
    icon: (
      <Icon
        as={FaUser}
        maxWidth="30px"
        maxHeight="40px"
        width="30px"
        height="50px"
        color="inherit"
      />
    ),
    accessLevel: 1,
    roles: onlyAdmins,
    element: <Users />,
  },
  {
    name: "Tenants",
    layout: "/admin",
    path: "/tenants",
    icon: <Icon as={FaHouseUser} width="30px" height="50px" color="inherit" />,
    accessLevel: 0,
    roles: onlyUAdmins,
    element: <TenantScreen />,
  },

  {
    name: "Account Settings",
    layout: "/account",
    path: "/settings",
    roles: onlyAdminsAndPropertyOwners,
    element: <AccountSettings />,
    accessLevel: 1,
  },
  {
    name: "Partners",
    layout: "/admin",
    path: "/partners",
    icon: (
      <Icon
        as={RiShakeHandsFill}
        maxWidth="30px"
        maxHeight="40px"
        width="30px"
        height="50px"
        color="inherit"
      />
    ),
    roles: onlyUAdmins,
    accessLevel: 0,
    element: <Partners />,
  },
  {
    name: "Sources",
    layout: "/admin",
    path: "/sources",
    icon: (
      <Icon
        as={MdTripOrigin}
        maxWidth="30px"
        maxHeight="40px"
        width="30px"
        height="50px"
        color="inherit"
      />
    ),
    roles: onlyUAdmins,
    accessLevel: 0,
    element: <Sources />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/signIn",
    element: <SignIn />,
  },
];

export default routes;
