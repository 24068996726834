// Chakra imports
import {
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, {useState} from "react";
import {useEffect} from "react";
import {useSetRecoilState} from "recoil";
import {useLocation, useNavigate} from "react-router-dom";

import {deleteModalState} from "atoms/deleteModalState";
import {stationTableHeaders, tableHeaders} from "./variables/tableHeaders";
import ChargePointsTable from "./components/ChargePointsTable";
import ChargingStationsTable from "./components/ChargingStationsTable";
import LoadingSpinner from "components/feedback/LoadingSpinner";

const loadingData = [
  {
    Name: <Skeleton />,
    PinCode: <LoadingSpinner />,
    PhoneNumber: <LoadingSpinner />,
  },
];
const ChargersList = () => {
  const setDeleteModal = useSetRecoilState(deleteModalState);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const location = useLocation();
  const navigation = useNavigate();
  useEffect(() => {
    setDeleteModal(false);
  }, []);

  const clickHandler = (x) => {
    if (x === 0) {
      navigation(`/admin/chargingstations/list`);
    } else if (x === 1) {
      navigation(`/admin/chargepoints/list`);
    }
  };

  useEffect(() => {
    if (location.pathname === "/admin/chargingstations/list") {
      setActiveTabIndex(0);
    } else if (location.pathname === "/admin/chargepoints/list") {
      setActiveTabIndex(1);
    }
  }, [location]);

  return (
    <Tabs variant="soft-rounded" colorScheme="orange" isLazy={true} index={activeTabIndex}>
      <TabList color="whiter" gap={5}>
        <Tab
          _focus={{boxShadow: "none"}}
          onClick={() => clickHandler(0)}
        >
          Charging Stations
        </Tab>
        <Tab
          _focus={{boxShadow: "none"}}
          onClick={() => clickHandler(1)}
        >
          Charge Points
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <ChargingStationsTable columnsData={stationTableHeaders} />
        </TabPanel>
        <TabPanel>
          <ChargePointsTable columnsData={tableHeaders} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ChargersList;
