import Card from "components/card/Card";
import React, {useEffect, useState} from "react";
import useAxios from "axios-hooks";
import {Flex, Text} from "@chakra-ui/react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";

import "../../../../assets/css/chargePoints.css";
import qr from "../../../../assets/img/chargePointsTab/qr.png";
import restart from "../../../../assets/img/chargePointsTab/restart.png";
import edit from "../../../../assets/img/chargePointsTab/edit.png";
import {updateChargingPointDrawerState} from "atoms/updateChargingPointDrawerState";
import UpdateChargePointDrawer from "../components/UpdateChargePointDrawer";
import ConnectorTabs from "../tabs/ConnectorTabs";
import {toastState} from "atoms/toastState";
import {loggedInUserState} from "atoms/loggedInUserState";
import {accessLevels} from "utils/accessLevels";
import logger from "infra/logger";
import {onlyAdmins} from "utils/accessLevels";
import RoleBasedRender from "components/wrappers/roleBasedRender";

const ChargePoint = ({data, getChargePoints}) => {
  const [chargePointStatus, setChargePointStatus] = useState("");
  const loggedInUser = useRecoilValue(loggedInUserState);
  const role = loggedInUser?.role;
  const canEdit = accessLevels[role]?.accessLevel <= 2;
  const updatedAt = new Date(data.UpdatedAt);
  const setToast = useSetRecoilState(toastState);
  const [updateDrawerState, setUpdateDrawerState] = useRecoilState(
    updateChargingPointDrawerState
  );
  const [{data: resetData, loading, error}, execute] = useAxios(
    {method: "POST", url: "/api/ocpp16/resetCharger"},
    {manual: true}
  );
  const [
    {data: statusData, loading: statusLoading, error: statusError},
    getChargePointStatus,
  ] = useAxios(
    {method: "GET", url: `/api/chargePoint/${data?.Id}`},
    {manual: true}
  );
  const editHandler = (id) => {
    setUpdateDrawerState({isOpen: true, activeId: id});
  };

  const updateHandler = () => {
    setUpdateDrawerState(null);
    getChargePoints();
  };

  const getChargePointDetails = async () => {
    try {
      const response = await getChargePointStatus();
      setChargePointStatus(response?.data?.data?.Status);
    } catch (error) {
      logger.log(error);
    }
  };

  const subDomain = window.location.hostname.split(".")[0];
  const resetHandler = async () => {
    let body = {};
    body["Type"] = "Hard";
    body["ChargerId"] = data?.Code;
    try {
      const response = await execute({data: body});
      setToast({
        isOpen: true,
        title: "ChargePoint Restarted successfully",
        status: "success",
      });
    } catch (error) {
      const err = error?.response?.data?.error;
      setToast({
        isOpen: true,
        title: "Error occured when restarting ChargePoint",
        description: err ? err : null,
        status: "error",
      });
    }
  };
  useEffect(() => {
    if (data?.Status) {
      setChargePointStatus(data?.Status);
    }
    const interVal = setInterval(() => {
      getChargePointDetails();
    }, 5000);
    return () => clearInterval(interVal);
  }, [data?.Status]);
  return (
    <Card>
      <div>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className="acCharger_main"
          direction={{base: "column", lg: "row"}}
          flexWrap={{base: "wrap", md: "wrap", lg: "nowrap"}}
        >
          <div className="acCharger_table">
            <table className="acCharger_table_main">
              <tbody>
                <tr>
                  <td className="acCharger_table_label">Socket URL</td>
                  <td className="acCharger_table_data">
                    {"ws://" + subDomain + ".cms.urzzaev.com/cms/" + data.Code}
                  </td>
                </tr>
                <tr>
                  <td className="acCharger_table_label">Code</td>
                  <td>{data.Code}</td>
                </tr>
                <tr>
                  <td className="acCharger_table_label">Status</td>
                  <td>
                    <Flex textAlign={"center"} color="white">
                      <Text
                        bg={
                          chargePointStatus == "Online"
                            ? "whatsapp.500"
                            : "red.500"
                        }
                        borderRadius={"5px"}
                        p={1}
                      >
                        {chargePointStatus}
                      </Text>
                    </Flex>
                  </td>
                </tr>
                <tr>
                  <td className="acCharger_table_label">Power</td>
                  <td>{data.Power} kW</td>
                </tr>
                <tr>
                  <td className="acCharger_table_label">Compatible</td>
                  <td>Car</td>
                </tr>
                <tr>
                  <td className="acCharger_table_label">Last Updated</td>
                  <td>
                    {updatedAt.toLocaleString("en-US", {
                      timeZone: "Asia/Kolkata",
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Flex
            flexDirection={{base: "column", sm: "row", lg: "column", md: "row"}}
            wrap={{base: "wrap", lg: "nowrap", md: "wrap"}}
            mt={{sm: 5}}
            mx={5}
            className="acCharger_table_buttons"
          >
            <button className="acCharger_button qr" disabled={!canEdit}>
              <img
                src={qr}
                alt="qr"
                style={{display: "inline", marginRight: "0.44rem"}}
              />
              QR code
            </button>
            <button
              className="acCharger_button restart"
              onClick={resetHandler}
              disabled={!canEdit}
            >
              <img
                src={restart}
                alt="restart"
                style={{display: "inline", marginRight: "0.44rem"}}
              />
              Restart
            </button>
            <RoleBasedRender roles={onlyAdmins}>
              <button
                className="acCharger_button edit"
                onClick={() => editHandler(data.ChargingStationId)}
                disabled={!canEdit}
              >
                <img
                  src={edit}
                  alt="edit"
                  style={{display: "inline", marginRight: "0.44rem"}}
                />
                Edit
              </button>
            </RoleBasedRender>
            <button
              className="acCharger_button configuration"
              onClick={() => editHandler(data.ChargingStationId)}
              disabled={!canEdit}
            >
              {/* <img
                src={edit}
                alt="edit"
                style={{display: "inline", marginRight: "0.44rem"}}
              /> */}
              Change Config
            </button>
            <button
              className="acCharger_button diagnostics"
              onClick={() => editHandler(data.ChargingStationId)}
              disabled={!canEdit}
            >
              {/* <img
                src={edit}
                alt="edit"
                style={{display: "inline", marginRight: "0.44rem"}}
              /> */}
              Get Diagnostics
            </button>
          </Flex>
        </Flex>

        <ConnectorTabs chargePointId={data.Id} chargePointCode={data.Code} />
      </div>
      {updateDrawerState?.isOpen && (
        <UpdateChargePointDrawer
          update={updateHandler}
          chargePointId={data.Id}
        />
      )}
    </Card>
  );
};

export default ChargePoint;
