import { Icon } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import React, { useEffect, useState } from "react";
import { MdBarChart } from "react-icons/md";

import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";

const AvgSessionsPerDay = ({data}) => {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [avgSessionsPerDay, setAvgSessionsPerDay] = useState(0);
  

  useEffect(() => {
    setAvgSessionsPerDay(data);
  }, [data]);

  return (
    <MiniStatistics
      startContent={
        <IconBox
          w="56px"
          h="56px"
          bg={boxBg}
          icon={<Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />}
        />
      }
      name="Avg. Sessions/Day"
      value={avgSessionsPerDay}
    />
  );
};

export default AvgSessionsPerDay;
