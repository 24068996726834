import * as yup from "yup";

export const Validation_MobileNumber = yup
  .number()
  .max(9999999999, "Enter Vaild Number")
  .min(1000000000, "Enter Vaild Number");

export const Validation_MobileNumber_Req = yup
  .number()
  .max(9999999999, "Enter Vaild Number")
  .min(1000000000, "Enter Vaild Number")
  .required("Phone Number is required ");

export const Validation_Name = yup.string();
export const Validation_Name_Req = yup.string().required("Name is rquired");

export const Validation_PinCode = yup
  .string()
  .matches(/^[0-9]*$/, "PinCode must contain only digits");
export const Validation_PinCode_Req = yup
  .string()
  .matches(/^[0-9]*$/, "PinCode must contain only digits")
  .max(6)
  .min(6)
  .required("PinCode is required ");

export const Validation_Address = yup.string();
export const Validation_Address_Req = yup.string().required("Address is required");

export const Validation_Descrption = yup.string();
export const Validation_Descrption_Req = yup.string().required("Description is required");

export const Validation_State = yup.string();
export const Validation_State_Req = yup.string().required("State is required");

export const Validation_City = yup.string();
export const Validation_City_Req = yup.string().required("City is required");

export const Validation_Country = yup.string();
export const Validation_Country_Req = yup.string().required("Country is required");
