export const chargingSessionTableHeaders = [
  {
    Header: "S.No",
  },
  {
    Header: "ChargePoint",
  },
  {
    Header: "ChargingSessionId",
  },
  {
    Header: "Connector",
  },
  {
    Header: "Start-Time",
    // accessor: "data.city", // Accessing city from the JSON data
  },
  {
    Header: "Dispatched-Units",
  },
  {
    Header: "Actions",
  },
];
