export const sourceDrawerSelectOptions = [
    {
        label: "Name",
        placeholder: "Name",
        name: "Name",
        helper: "",
    },
    {
        label: "PartnerId",
        placeholder: "PartnerId",
        name: "PartnerId",
        helper: "", 
    }
]