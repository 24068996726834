import React, {useEffect, useState} from "react";
import {
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import {FaPlus} from "react-icons/fa";
import useAxios from "axios-hooks";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";

import {createConnectorDrawerState} from "atoms/createConnectorDrawerState";
import CreateConnectorDrawer from "../components/CreateConnectorDrawer";
import Connectors from "../tab_panels/Connectors";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import {toastState} from "atoms/toastState";
import logger from "infra/logger";
import {loggedInUserState} from "atoms/loggedInUserState";
import {accessLevels} from "utils/accessLevels";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import {onlyAdmins} from "utils/accessLevels";

const ConnectorTabs = ({chargePointId, chargePointCode}) => {
  const [drawerState, setDrawerState] = useRecoilState(
    createConnectorDrawerState
  );
  const setToast = useSetRecoilState(toastState);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const role = loggedInUser?.role;
  const canEdit = accessLevels[role]?.accessLevel <= 2;
  const [connectors, setConnectors] = useState([]);
  const [{data, loading, error}, execute] = useAxios(
    {
      method: "GET",
      url: `/api/chargePointConnector/forChargePoint/${chargePointId}`,
    },
    {manual: true}
  );

  const getConnectors = async () => {
    try {
      const response = await execute();
      setConnectors(response.data.data);
    } catch (error) {
      logger.log(error);
      //TODO setToast
    }
  };

  const refreshHandler = async () => {
    setDrawerState(null);
    await getConnectors();
  };

  useEffect(() => {
    getConnectors();
  }, []);
  return (
    <Tabs isLazy variant="soft-rounded" colorScheme="orange">
      <Flex
        flexDirection="row"
        alignItems="start"
        justifyContent="space-between"
      >
        <TabList>
          <Tab>Connectors</Tab>
        </TabList>
        {connectors.length > 0 && (
          <RoleBasedRender roles={onlyAdmins}>
            <Button
              leftIcon={<FaPlus />}
              colorScheme="whatsapp"
              variant="solid"
              onClick={() => setDrawerState({isOpen: true})}
              disabled={!canEdit}
            >
              Add Connector
            </Button>
          </RoleBasedRender>
        )}
      </Flex>
      {drawerState?.isOpen && (
        <CreateConnectorDrawer
          chargePointCode={chargePointCode}
          chargePointId={chargePointId}
          refreshHandler={getConnectors}
          update={refreshHandler}
        />
      )}

      <TabPanels>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <TabPanel>
            {connectors.length > 0 ? (
              <Connectors
                data={connectors}
                refreshHandler={() => getConnectors()}
              />
            ) : (
              <Flex
                padding="18"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <RoleBasedRender roles={onlyAdmins}>
                  <Button
                    leftIcon={<FaPlus />}
                    colorScheme="whatsapp"
                    variant="solid"
                    onClick={() => setDrawerState({isOpen: true})}
                    disabled={!canEdit}
                  >
                    Add Connector
                  </Button>
                </RoleBasedRender>
              </Flex>
            )}
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};

export default ConnectorTabs;
