import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

import React, {useEffect, useMemo, useState} from "react";
import {useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import useAxios from "axios-hooks";
import {useNavigate} from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";

// Custom components
import {deleteModalState} from "atoms/deleteModalState";
import DeleteModal from "components/modals/DeleteModal";
import {formattedIstDate} from "utils/utils";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import Card from "components/card/Card";
import logger from "infra/logger";
import { accessLevels } from "utils/accessLevels";
import { loggedInUserState } from "atoms/loggedInUserState";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import { onlyAdmins } from "utils/accessLevels";

export default function ChargePointsTable(props) {
  const {columnsData} = props;
  const [chargePoints, setChargePoints] = useState([]);
  const [showDeleteModal, setShowDeleteModal] =
    useRecoilState(deleteModalState);
  const [selectedStation, setSelectedStation] = useState(null);
  const loggerInUser = useRecoilValue(loggedInUserState)
  const navigate = useNavigate();
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => chargePoints, [chargePoints]);

  const [{chargePointsData, loading, error}, getPoints] = useAxios(
    {method: "GET", url: `/api/chargePoint`},
    {manual: true}
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0, // Start at page 0
        pageSize: 10, // Set the initial page size
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: {pageIndex},
  } = tableInstance;

  const {canPreviousPage, canNextPage, pageCount, nextPage, previousPage} =
    tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const getChargePoints = async () => {
    try {
      const response = await getPoints();
      setChargePoints(response.data.data);
    } catch (error) {
      logger.log(error);
    }
  };

  const handleDeleteClick = (station) => {
    setSelectedStation(station);
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setSelectedStation(null);
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(false);
    getChargePoints();
  };

  useEffect(() => {
    getChargePoints();
  }, []);

  return loading ? (
    <LoadingSpinner />
  ) : chargePoints.length > 0 ? (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{sm: "scroll", lg: "hidden"}}
    >
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="center"
                    align="center"
                    fontSize={{sm: "10px", lg: "12px"}}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "S.No") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {Number(cell.row.id) + 1}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "ChargePoint") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text
                          me="10px"
                          color={textColor}
                          fontSize="sm"
                          fontWeight="700"
                        >
                          {cell.row.original.Code}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Description") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text
                          me="10px"
                          color={textColor}
                          fontSize="sm"
                          fontWeight="700"
                        >
                          {cell.row.original.Description}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Visibility") {
                    data = (
                      <Flex
                        style={{
                          textAlign: "center",
                          backgroundColor:
                            cell.row.original.Visibility === "Public"
                              ? "blue"
                              : "red",
                          borderColor:
                            cell.row.original.Visibility === "Public"
                              ? "blue"
                              : "red",
                          borderRadius: "5px",
                        }}
                        align="center"
                        justify="center"
                      >
                        <Text
                          style={{
                            fontSize: "sm",
                            fontWeight: "700",
                            color: "white",
                          }}
                          p="1"
                        >
                          {cell.row.original.Visibility}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Status") {
                    const status = cell.row.original.Status;
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text
                          color={"white"}
                          bg={status === "Online" ? "whatsapp.500" : "red.600"}
                          fontSize="sm"
                          fontWeight="700"
                          border="1px solid whatsapp.500s"
                          borderRadius="md"
                          p="1"
                          textAlign={"center"}
                        >
                          {status ? status : "Offline"}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Last Connected") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {formattedIstDate(cell.row.original.LastConnectedAt)}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Actions") {
                    const role = loggerInUser?.role;
                    const accessLevel = accessLevels[role]?.accessLevel;
                    const isDisabled = accessLevel > 2;
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Button
                          colorScheme="blue"
                          variant="ghost"
                          onClick={() =>
                            navigate(
                              `/admin/chargingstations/view/${cell.row.original.ChargingStationId}/chargepoints?chargePoint=${cell.row.original.Code}`
                            )
                          }
                        >
                          View
                        </Button>
                        <RoleBasedRender roles={onlyAdmins}>
                        <Button
                          colorScheme="red"
                          variant="ghost"
                          isDisabled={isDisabled}
                          onClick={() => handleDeleteClick(cell.row.original)}
                        >
                          Delete
                        </Button>
                        </RoleBasedRender>
                      </Flex>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{sm: "14px"}}
                      minW={{sm: "150px", md: "200px", lg: "auto"}}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        data={selectedStation}
        title="chargePoint"
      />
      <Flex justify="space-between" alignItems="center">
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </Button>
        <Text>
          Page {pageIndex + 1} of {pageCount}
        </Text>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Button>
      </Flex>
    </Card>
  ) : (
    <Text w="100%" textAlign="center" mt={5}>
      {" "}
      No ChargePoints Found
    </Text>
  );
}
