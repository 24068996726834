import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Spinner,
    Input,
    Select,
  } from "@chakra-ui/react";
  import useAxios from "axios-hooks";
  import React, {useState} from "react";
  import {Controller, useForm} from "react-hook-form";
  import * as yup from "yup";
  import {yupResolver} from "@hookform/resolvers/yup";

  import LoadingSpinner from "components/feedback/LoadingSpinner";
  import Card from "components/card/Card";
  import logger from "infra/logger";
  import {toastState} from "atoms/toastState";
  import {loggedInUserState} from "atoms/loggedInUserState";
import { createSourceDrawerState } from "atoms/createSourceDrawerState";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import { sourceDrawerSelectOptions } from "../variables/sourceDrawerSelectOptions";
  
  const CreateSourceDrawer = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const setToast = useSetRecoilState(toastState);
    const [drawerState, setDrawerState] = useRecoilState(createSourceDrawerState);
    const user = useRecoilValue(loggedInUserState);
    const validationSchema = yup.object().shape({
      Name: yup.string().min(1).required("Name is required"),
      PartnerId: yup.string().min(1).required("Partner Id is required"),
    });
  
    const {
      control,
      handleSubmit,
      formState: {errors, isValid},
      register,
      setValue,
      getValues,
    } = useForm({
      mode: "onChange",
      resolver: yupResolver(validationSchema),
    });
  
    const [{data, loading: createLoading, error}, createSource] = useAxios(
      {method: "POST", url: `/api/source/create`},
      {manual: true}
    );
  
    const createHandler = async () => {
      setIsLoading(true);
      const formValues = getValues();
      let data = {};
      if (formValues.Name.trim() !== "") {
        data["Name"] = formValues.Name.trim();
      }
      if (formValues.PartnerId.trim() !== "") {
        data["PartnerId"] = formValues.PartnerId.trim();
      }
      logger.log(formValues);
      logger.log(data);
      if (Object.keys(data).length === 5) {
        try {
          const response = await createSource({data: data});
  
          setToast({
            isOpen: true,
            title: "Source Created successfully",
            status: "success",
          });
          props.update();
        } catch (error) {
          const err = error?.response?.data?.error;
          logger.log(error);
          setToast({
            isOpen: true,
            title: "Error occured when creating Source",
            description: err ? err : null,
            status: "error",
          });
        }
      } else {
        setToast({
          isOpen: true,
          title: "Enter all the fields to create a Source",
          status: "warning",
          position: "top",
        });
      }
      setIsLoading(false);
    };
  
    const closeHandler = () => {
      setDrawerState(null);
    };
  
    return (
      <Drawer
        isOpen={drawerState?.isOpen}
        placement="right"
        onClose={closeHandler}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create a new Source</DrawerHeader>
  
          <DrawerBody>
            {!createLoading ? (
              <>
                <Card>
                  {/* {drawerState?.activeId && <UserCard user={user} />} */}
                  <form>
                    {sourceDrawerSelectOptions.map((item, index) => (
                      <Flex key={index} mb="20px" alignItems="center">
                        <Controller
                          name={item.name}
                          control={control}
                          defaultValue=""
                          render={({field}) => (
                            <FormControl
                              isRequired
                              flexDirection="row"
                              isInvalid={errors[item.name]}
                            >
                              <FormLabel fontSize={20}>{item.label}</FormLabel>
                              {item.type === "select" ? (
                                <Select
                                  {...field}
                                  focusBorderColor="#f57d36"
                                  fontSize="sm"
                                  width="400px"
                                  height="50px"
                                  isRequired
                                >
                                  <option value="">Select</option>
                                  {item.options.map((option, ind) => {
                                    return (
                                      <option key={ind} value={option.value}>
                                        {option.name}
                                      </option>
                                    );
                                  })}
                                </Select>
                              ) : (
                                <Input
                                  {...field}
                                  focusBorderColor="#f57d36"
                                  fontSize="sm"
                                  placeholder={item.placeholder}
                                  width="400px"
                                  height="50px"
                                  type={item.type}
                                  isRequired
                                />
                              )}
                              <FormHelperText color={"#f57d36"}>
                                {item.helper}
                              </FormHelperText>
                              <FormErrorMessage mb={"20px"}>
                                {errors[item.name] && errors[item.name].message}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        />
                      </Flex>
                    ))}
                  </form>
                </Card>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </DrawerBody>
  
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={closeHandler}>
              Cancel
            </Button>
            <Button
              bg="#f57d36"
              color="white"
              colorScheme="orange"
              onClick={handleSubmit(createHandler)}
              isLoading={isLoading}
              loadingText="Creating"
              spinner={<Spinner size="md" />}
              disabled={isLoading}
            >
              Create
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  };
  
  export default CreateSourceDrawer;
  