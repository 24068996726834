const subDomain = window.location.hostname.split(".")[0];
export const accessLevels = {
  uadmin: {
    accessLevel: 0,
  },
  admin: {
    accessLevel: 1,
  },
  manager: {
    accessLevel: 2,
  },
  property_owner: {
    accessLevel: 3,
  },
};

export const onlyUAdmins = ["uadmin"];
export const onlyAdmins = ["admin", "uadmin"];
export const onlyOtherAdmins = ["admin"];
export const adminsAndManagers = ["uadmin", "admin", "manager"];
export const allRoles = ["uadmin", "admin", "manager", "property_owner"];

export const onlyAdminsAndPropertyOwners = [
  "uadmin",
  "admin",
  "property_owner",
];

export const onlyOtherAdminsAndPropertyOwners = ["admin", "property_owner"];
