import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { payOutsDrawerState } from "atoms/payOutsDrawerState";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Button,
  FormControl,
  FormLabel,
  Input,
  Card,
  Flex,
  FormHelperText,
  FormErrorMessage,
} from "@chakra-ui/react";
import { formattedDate } from "utils/utils";
import { PayRunDrawerState } from "atoms/payRunDrawerState";
import useAxios from "axios-hooks";
import logger from "infra/logger";
import { toastState } from "atoms/toastState";
import { Controller, useForm } from "react-hook-form";
import { updatePayoutTableHeaders } from "../variables/tableHeaders";
import LoadingSpinner from "components/feedback/LoadingSpinner";

const PayOutsDrawer = ({ isOpen, onClose, data }) => {
  const setToast = useSetRecoilState(toastState);
  const [viewPayRunState, setViewPayRunState] =
    useRecoilState(PayRunDrawerState);
  const [payout, SetPayout] = useState({});

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const [{ loading: payoutDataLoading }, getPayoutData, cancelRequest] =
    useAxios(
      { method: "GET", url: `/api/payouts/${viewPayRunState?.payoutId}` },
      { manual: true }
    );

  const closeHandler = () => {
    setViewPayRunState(null);
  };

  const getPayout = async () => {
    try {
      const response = await getPayoutData();
      SetPayout(response?.data?.data);
    } catch (error) {
      //check if error is cancelled error
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "Error occured while getting rfid details",
          status: "error",
        });
        closeHandler();
      }
    }
  };

  useEffect(() => {
    if (viewPayRunState?.payoutId) {
      getPayout();
      return () => cancelRequest();
    }
  }, []);

  useEffect(() => {
    if (payout) {
      setValue("PayeeName", payout.payee_name);
      setValue("PayeeRefNo", payout.payout_ref_no);
      setValue("GrossAmount", payout.gross_amount);
      setValue("TaxAmount", payout.tax_amount);
      setValue("NetAmount", payout.net_amount);
      setValue("TotalUnitsConsumed", payout.total_units_consumed);
      setValue("SettledToBank", payout.settled_to_bank);
      setValue("BenificiaryName", payout.beneficiary_name);
      setValue("BenificiaryAccountNo", payout.beneficiary_account_number);
      setValue("IfscCode", payout.ifsc);
      setValue("UtrNo", payout.utr_number);
      setValue("Arrears", payout.arrears);
      setValue("Status", payout.status);
      setValue("Type", payout.type);
    }
  }, [payout]);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>View Payout Details</DrawerHeader>

        <DrawerBody>
          {!payoutDataLoading ? (
            <>
              <form>
                {updatePayoutTableHeaders.map((item, index) => (
                  <Flex key={index} mb="20px" alignItems="center">
                    <Controller
                      name={item.name}
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          flexDirection="row"
                          isInvalid={errors[item.name]}
                        >
                          <FormLabel fontSize={20}>{item.label}</FormLabel>
                          <Input
                            {...field}
                            focusBorderColor="#f57d36"
                            fontSize="sm"
                            placeholder={item.placeholder}
                            width="400px"
                            height="50px"
                            type={item.type}
                            name={item.name}
                          />
                          <FormHelperText color={"#f57d36"}>
                            {item.helper}
                          </FormHelperText>
                          <FormErrorMessage mb={"20px"}>
                            {errors[item.name] && errors[item.name].message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  </Flex>
                ))}
              </form>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default PayOutsDrawer;
