const logger = {
  assert: (condition, message) => {
    if (process.env.NODE_ENV !== "production") {
      console.assert(condition, message);
    }
  },

  clear: () => {
    if (process.env.NODE_ENV !== "production") {
      console.clear();
    }
  },

  count: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.count(message);
    }
  },

  countReset: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.countReset(message);
    }
  },

  debug: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.debug(message);
    }
  },

  error: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.error(message);
    }
  },

  group: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.group(message);
    }
  },

  groupCollapsed: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.groupCollapsed(message);
    }
  },

  groupEnd: () => {
    if (process.env.NODE_ENV !== "production") {
      console.groupEnd();
    }
  },

  info: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.info(message);
    }
  },

  log: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.log(message);
    }
  },

  profile: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.profile(message);
    }
  },

  profileEnd: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.profileEnd(message);
    }
  },

  table: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.table(message);
    }
  },

  time: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.time(message);
    }
  },

  timeEnd: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.timeEnd(message);
    }
  },

  timeStamp: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.timeStamp(message);
    }
  },

  trace: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.trace(message);
    }
  },

  warn: (message) => {
    if (process.env.NODE_ENV !== "production") {
      console.warn(message);
    }
  },
};

export default logger;
