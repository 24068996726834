import { Box } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";

const RfidCards = () => {

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Outlet />
    </Box>
  );
}

export default RfidCards
