import {
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React from "react";

import UnsettledTransactions from "../tabpanels/UnsettledTransactions";
import Payouts from "../tabpanels/Payouts";
import PayoutRun from "../tabpanels/PayoutRun";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import {onlyUAdmins} from "utils/accessLevels";
import Settlement from "../components/Settlement";
import Card from "components/card/Card";
import {getPreviousMonthName} from "utils/utils";
// import { useRecoilValue } from "recoil";

const PayoutTabs = () => {
  // const loggedInUser = useRecoilValue(loggedInUserState);
  // const role = loggedInUser?.role;

  return (
    <Flex direction="column" w="100%">
      <Tabs variant="soft-rounded" colorScheme="orange" isLazy={true}>
        <TabList gap={7} outline="none">
          <Tab _focus={{boxShadow: "none"}}>Unsettled Transactions</Tab>
          <Tab _focus={{boxShadow: "none"}}>Payouts</Tab>
          <RoleBasedRender roles={onlyUAdmins}>
            <Tab _focus={{boxShadow: "none"}}>Run Payouts</Tab>
          </RoleBasedRender>
        </TabList>

        <TabPanels>
          <TabPanel
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Settlement type="amount" />
            <UnsettledTransactions />
          </TabPanel>
          <TabPanel>
            <Payouts />
          </TabPanel>
          <RoleBasedRender roles={onlyUAdmins}>
            <TabPanel
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <PayoutRun />
            </TabPanel>
          </RoleBasedRender>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default PayoutTabs;
