import {toastState} from "atoms/toastState";
import useAxios from "axios-hooks";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

import TransactionsTable from "../components/TransactionsTable";
import {transactionsTableHeaders} from "../variables/tableHeaders";
import logger from "infra/logger";

const UnsettledTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const setToast = useSetRecoilState(toastState);
  const [{data, loading, error}, getTransactions, cancelRequest] = useAxios(
    {method: "GET", url: "/api/transaction/unsettled"},
    {manual: true}
  );

  const fetchUnsettledransactions = async () => {
    try {
      const response = await getTransactions();
      setTransactions(response?.data?.data || []);
      console.log("data", response?.data?.data);
    } catch (error) {
      logger.log(error);
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "Error Occured",
          status: "error",
          description: error?.response?.data?.error,
        });
      }
    }
  };

  useEffect(() => {
    fetchUnsettledransactions();
    return () => cancelRequest();
  }, []);
  return (
    <TransactionsTable
      columnsData={transactionsTableHeaders}
      tableData={transactions}
    />
  );
};

export default UnsettledTransactions;
