import React, {useState} from "react";
import useAxios from "axios-hooks";
import {Box, Button, Card, Flex, Text} from "@chakra-ui/react";
import {useRecoilState, useSetRecoilState} from "recoil";
import {toastState} from "atoms/toastState";
import {MdDelete} from "react-icons/md";
import {FaFileCircleCheck, FaFileCircleMinus} from "react-icons/fa6";
import {GiCash} from "react-icons/gi";

import logger from "infra/logger";
import DeleteModal from "components/modals/DeleteModal";
import {deleteModalState} from "atoms/deleteModalState";
const PayrunBox = ({payrun, fetchPayrun}) => {
  const setToast = useSetRecoilState(toastState);
  const [showDeleteModal, setShowDeleteModal] =
    useRecoilState(deleteModalState);

  const dateObj = new Date(payrun?.Date);
  // Get the month in the first three letters
  const month = dateObj.toLocaleString("en-US", {month: "short"});
  // Get the year
  const year = dateObj.getFullYear();

  const [
    {data: approveData, loading: approveLoading, error: approveError},
    execute,
  ] = useAxios({}, {manual: true});

  const statusRefData = {};

  const statusRefractor = (status) => {
    switch (status) {
      case "yet_to_be_approved":
        statusRefData["status"] = "Yet to be approved";
        statusRefData["bg"] = "#fdeade";
        statusRefData["color"] = "#f57d36";
        return;
      case "approved":
        statusRefData["status"] = "Approved";
        statusRefData["bg"] = "#c8f3ca";
        statusRefData["color"] = "green";
        return;
      case "payment_initiated":
        statusRefData["status"] = "Payment Initiated";
        statusRefData["bg"] = "#fff586";
        statusRefData["color"] = "#ebd000";
        return;

      case "payment_completed":
        statusRefData["status"] = "Payment Completed";
        statusRefData["bg"] = "#e5bdff";
        statusRefData["color"] = "#9600ff";
        return;

      default:
        statusRefData["status"] = "Fetching...";
        statusRefData["bg"] = "#fdeade";
        statusRefData["color"] = "#f57d36";
        return;
    }
  };

  statusRefractor(payrun?.PaymentStatus);

  const handleDeleteClick = (data) => {
    logger.log("deleting data");
    logger.log(data);
    setShowDeleteModal(true);
    // setSelectedPayrunData(data);
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(false);
    // setSelectedPayrunData(null);
    fetchPayrun();
  };
  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    // setSelectedPayrunData(null);
  };

  const approveHandler = async (status) => {
    try {
      const response = await execute({
        method: "PATCH",
        url: `api/payrun/update/${payrun?.Id}?status=${status}`,
      });
      setToast({
        isOpen: true,
        title: "Success",
        status: "success",
        description: response?.data?.message,
      });
      fetchPayrun();
    } catch (error) {
      logger.log(error);
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "Error Occured",
          status: "error",
          description: error?.response?.data?.error,
        });
      }
    }
  };

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        backgroundColor={"white"}
        borderRadius={10}
        w={"90%"}
        maxW={"700px"}
        aspectRatio={3 / 1.2}
        direction={"column"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        border={"2px solid #ffffff"}
        // bg={"#f7f7f7"}
      >
        <Flex w={"100%"} h={"60%"}>
          <Flex
            h={"100%"}
            w={"30%"}
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            p={2}
            bg={"#e8edf7"}
            border={"2px solid #ffffff"}
          >
            <Text fontSize={25} fontWeight={900} color={"000000"}>
              {month.toUpperCase()}
            </Text>
            <Text fontSize={45} fontWeight={900} color={"purple.700"}>
              {year}
            </Text>
          </Flex>
          <Flex
            h={"100%"}
            w={"70%"}
            direction={"column"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            p={2}
            bg={"#e8edf7"}
            border={"2px solid #ffffff"}
          >
            <Text fontSize={"20px"} fontWeight={900} color={"gray.400"}>
              Total Amount
            </Text>
            <Flex
              flex={1}
              w={"100%"}
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text fontSize={"60px"} fontWeight={900} color={"#f57d36"}>
                ₹ {payrun?.TotalAmount}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex w={"100%"} h={"40%"}>
          <Flex
            h={"100%"}
            w={"40%"}
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            p={2}
            bg={"#e8edf7"}
            border={"2px solid #ffffff"}
          >
            <Flex
              h={"100%"}
              w={"100%"}
              direction={"column"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              p={2}
            >
              <Text fontSize={"15px"} fontWeight={900} color={"gray.400"}>
                Status
              </Text>
              <Flex
                flex={1}
                w={"100%"}
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text
                  fontSize={"20px"}
                  fontWeight={900}
                  color={statusRefData?.color}
                  bg={statusRefData?.bg}
                  p={2}
                  borderRadius={5}
                >
                  {statusRefData?.status}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            h={"100%"}
            w={"60%"}
            direction={"column"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            p={2}
            bg={"#e8edf7"}
            border={"2px solid #ffffff"}
          >
            <Flex
              flex={1}
              w={"100%"}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              gap={2}
            >
              {(payrun?.PaymentStatus === "yet_to_be_approved" ||
                payrun?.PaymentStatus === "approved") && (
                <Button
                  leftIcon={
                    payrun?.PaymentStatus === "approved" ? (
                      <GiCash size={20} />
                    ) : (
                      <FaFileCircleCheck size={20} />
                    )
                  }
                  bg={"#c1f7cf"}
                  color={"green"}
                  textAlign={"center"}
                  borderRadius={5}
                  _hover={{bg: "green", color: "white"}}
                  isLoading={approveLoading}
                  onClick={() =>
                    approveHandler(
                      payrun?.PaymentStatus === "approved"
                        ? "initiate_payment"
                        : "approve"
                    )
                  }
                >
                  {payrun?.PaymentStatus === "approved"
                    ? "Initiate Payment"
                    : "Approve"}
                </Button>
              )}
              {payrun?.PaymentStatus === "approved" && (
                <Button
                  leftIcon={<FaFileCircleMinus size={20} />}
                  bg={"#fef2f1"}
                  color={"red"}
                  textAlign={"center"}
                  borderRadius={5}
                  isLoading={approveLoading}
                  _hover={{bg: "red", color: "white"}}
                  onClick={() => approveHandler("unapprove")}
                >
                  Unapprove
                </Button>
              )}
              {payrun?.PaymentStatus === "yet_to_be_approved" && (
                <Button
                  leftIcon={<MdDelete size={20} />}
                  bg={"#fef2f1"}
                  color={"red"}
                  textAlign={"center"}
                  borderRadius={5}
                  isLoading={approveLoading}
                  _hover={{bg: "red", color: "white"}}
                  onClick={() => handleDeleteClick(payrun?.Id)}
                >
                  Delete
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        data={payrun}
        title="payrun"
      />
    </Box>
  );
};

export default PayrunBox;
