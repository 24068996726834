import {AbsoluteCenter, Box, Divider} from "@chakra-ui/react";
import React from "react";

const SeparatorWithTitle = ({title}) => {
  return (
    <Box position="relative" padding="10">
      <Divider />
      <AbsoluteCenter w="500px" px="4" fontWeight="900" fontSize={28} textAlign={"center"}>
        {title}
      </AbsoluteCenter>
    </Box>
  );
};

export default SeparatorWithTitle;
