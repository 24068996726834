import {Flex} from "@chakra-ui/react";
import React from "react";

import Settlement from "./Settlement";
import PayoutTabs from "../tabs/PayoutTabs";

const PayoutsGroup = () => {
  return (
    <Flex
      direction="column"
      w="100%"
      alignItems="center"
      justifyContent="flex-start"
      gap={5}
    >
      {/* <Settlement type="amount"/> */}
      <PayoutTabs />
    </Flex>
  );
};

export default PayoutsGroup;
