export const  partnerDrawerSelectOptions = [
    {
      label: "Name",
      placeholder: "Name",
      name: "Name",
      helper: "",
    },
    {
      label: "Phone Number",
      placeholder: "Phone Number",
      type: "tel", // Changed to "tel" type for phone numbers
      name: "MobileNumber",
      helper: "*Note: type without country code",
    },
    {
      label: "Email",
      placeholder: "Email",
      type: "email",
      name: "Email",
      helper: "",
    },
    {
      label: "Status",
      placeholder: "Select Status",
      type: "select",
      name: "Status",
      options: [
        {
          name: "Active",
          value: true,
        },

        {name: "Inactive", value: false},
      ],
    },
  ]