import React, { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { useSetRecoilState } from "recoil";
import { Flex } from "@chakra-ui/react";

import StationImageUpload from "../components/StationImageUpload";
import StationImageRender from "../components/StationImageRender";
import { toastState } from "atoms/toastState";
import Card from "components/card/Card";

const Photos = ({ stationId }) => {
  const setToast = useSetRecoilState(toastState);
  const [images, setImages] = useState([]);
  const [{ data, loading, error }, execute, cancelRequest] = useAxios(
    { method: "GET", url: `/api/chargingStationImage/forStation/${stationId}` },
    { manual: true }
  );

  const getImages = async () => {
    try {
      const response = await execute();
      setImages(response.data.data);
    } catch (error) {
      const err = error?.response?.data?.error;
      if(error?.code!=="ERR_CANCELED"){
      setToast({
        isOpen: true,
        title: "Error occured while getting Imagess",
        description: err ? err : null,
        status: "error",
      });
    }
    }
  };
  useEffect(() => {
    getImages();
    return () => cancelRequest();
  }, []);
  return (
    <Card width="100%">
      <Flex alignItems={"center"} width="100%" direction="column-reverse">
        <StationImageRender images={images} getImages={getImages} />
        <StationImageUpload stationId={stationId} getImages={getImages} />
      </Flex>
    </Card>
  );
};

export default Photos;
