export const LOCAL_STORAGE_KEY = "authUser"
 
export const MAP_BOX_ACCESS_TOKEN = process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN
 
export const ADMIN = "admin"

export const MANAGER = "manager"
 
export const ANY_ADMIN = "any_admin"

export const ANY_ROLE = "any_role"
