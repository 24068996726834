export const partnerTableHeaders = [
  {
    Header: "S.No",
    // accessor: "data.name", // Accessing name from the JSON data
  },

  {
    Header: "Name",
    // accessor: "data.name", // Accessing name from the JSON data
  },
   {
    Header: "User Name",
    // accessor: "data.name", // Accessing name from the JSON data
  },
  {
    Header: "Email",
    // accessor: "data.phoneNumber", // Accessing phoneNumber from the JSON data
  },
  {
    Header: "Mobile Number",
    // accessor: "data.phoneNumber", // Accessing phoneNumber from the JSON data
  },
  {
    Header: "Status",
    // accessor: "data.phoneNumber", // Accessing phoneNumber from the JSON data
  },
  {
    Header: "Actions",
    // accessor: "data.chargingPoints[0].isActive", // Accessing isActive from the chargingPoints array in JSON data
  },
];
