import useAxios from "axios-hooks";
import React, {useEffect, useState} from "react";

import RfidColumnsTable from "./components/RfidColumnsTable";
import { columnsDataColumns } from "./variables/columnsData";
import logger from "infra/logger";

const RfidCardsList = () => {
  const [rfids, setRfids] = useState([]);
  const [{data, loading, error}, execute] = useAxios(
    {method: "GET", url: "/api/rfid"},
    {manual: true}
  );

  const getRfids = async () => {
    try {
      const response = await execute();
      const result = response.data.data;
      setRfids(result);
    } catch (error) {
      logger.log(error);
    }
  };

  useEffect(() => {
    getRfids();
  }, []);
  return (
    <RfidColumnsTable columnsData={columnsDataColumns} tableData={rfids} getRfids={getRfids} />
  );
};

export default RfidCardsList;
