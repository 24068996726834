import React, { useEffect, useState } from "react";

// Chakra imports
import { Box, Flex, Select, Text, useColorModeValue } from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";

// Assets

import ReactECharts from "echarts-for-react";
import { monthlyTrendRevenueBar } from "variables/charts";
import useAxios from "axios-hooks";
import logger from "infra/logger";

export default function MonthlyTrendRevenue(props) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [yearList, setYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [updatedOption, setUpdatedOption] = useState(monthlyTrendRevenueBar);

  const [{ data, loading, error }, execute, cancelRequest] = useAxios(
    {
      method: "GET",
      url: `/api/dashboard/analytics?key=year_revenue_monthly_trend&cp=${
        props?.chargePointCode == "all" ? "" : props?.chargePointCode
      }&year=${selectedYear}`,
    },
    { manual: true }
  );

  const getMonthlyRevenue = async () => {
    try {
      const response = await execute();
      const monthlyRevenueData = response.data.data;

      const revenueValues = monthlyRevenueData.map(
        (item) => item.total_revenue
      );

      const updatedOption = {
        ...monthlyTrendRevenueBar,

        series: [
          {
            ...monthlyTrendRevenueBar.series[0],
            data: revenueValues,
          },
        ],
      };

      setUpdatedOption(updatedOption);
    } catch (error) {
      logger.log("Error in getMonthlyRevenue:", error);
    }
  };

  const generateYearList = () => {
    const currentYear = new Date().getFullYear();
    const yearList = Array.from(
      { length: 5 },
      (_, index) => currentYear - index
    );
    setYearList(yearList);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  useEffect(() => {
    generateYearList();
  }, []);

  useEffect(() => {
    if (yearList.length > 0) {
      setSelectedYear(yearList[0]);
    }
  }, [yearList]);

  useEffect(() => {
    if (selectedYear) {
      getMonthlyRevenue();
      return () => cancelRequest();
    }
  }, [selectedYear]);

  useEffect(() => {
    if (props?.chargePointCode) {
      const timeout = setTimeout(() => {
        if (selectedYear) {
          getMonthlyRevenue();
        }
      }, 700);
      return () => {
        clearTimeout(timeout);
        cancelRequest();
      };
    }
  }, [props?.chargePointCode, props?.refresh]);

  return (
    <Card align="center" direction="column" w="100%" {...rest}>
      <Flex justify="space-between" align="center" px="15px" py="10px" w="100%">
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Monthly Revenue
        </Text>
        <Select
          value={selectedYear}
          onChange={handleYearChange}
          size="md"
          width="100px"
        >
          {yearList.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </Flex>

      <Box minH="260px" minW="90%" mt="auto" pt={10} pb={20}>
        <ReactECharts option={updatedOption} />
      </Box>
    </Card>
  );
}
