import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {ChakraProvider} from "@chakra-ui/react";
import {ThemeEditorProvider} from "@hypertheme-editor/chakra-ui";
import {RecoilRoot} from "recoil";

import "assets/css/App.css";
import theme from "theme/theme";
import AppRoutes from "routes/AppRoutes";

const rootElement = document.getElementById("root");
ReactDOM.createRoot(rootElement).render(
  <ChakraProvider
    theme={theme}
    toastOptions={{defaultOptions: {position: "bottom"}}}
  >
    <StrictMode>
      <RecoilRoot>
        {/* <ThemeEditorProvider> */}
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
        {/* </ThemeEditorProvider> */}
      </RecoilRoot>
    </StrictMode>
  </ChakraProvider>
);
