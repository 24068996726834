import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Box,
  Checkbox,
  IconButton,
  Collapse,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import Card from "components/card/Card";
import useAxios from "axios-hooks";
import { useSetRecoilState } from "recoil";
import { toastState } from "atoms/toastState";
import logger from "infra/logger";

const AccessManagementModal = ({ isOpen, onClose, data, onOpen }) => {
  const [isAllowed, setIsAllowed] = useState([]);
  const [isBlocked, setIsBlocked] = useState([]);
  const setToast = useSetRecoilState(toastState);
  const [stationAccessData, setStationAccessData] = useState([]);
  const [expandedStations, setExpandedStations] = useState({});
  const [{ data: accessData, isLoading: accessLoading }, executeAccess] =
    useAxios(
      {
        method: "GET",
        url: `/api/userStationAccess/propertyOwner/${data?.Id}`,
      },
      { manual: true }
    );

  const [{ data: updateData, loading, error }, update] = useAxios(
    {
      method: "POST",
      url: `/api/userStationAccess/create`,
    },
    { manual: true }
  );
  const chargingStations = [];

  const handleSave = async () => {
    try {
      const res = await update({
        data: {
          userId: data?.Id,
          allowedList: isAllowed,
          blockedList: isBlocked,
        },
      });
      setToast({
        isOpen: true,
        title: "Access updated successfully",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      //TODO show toast notification
      setToast({
        isOpen: true,
        title: "Error occured when updating access",
        description: error?.response?.data?.message,
        status: "error",
        duration: 3000,
      });
    } finally {
      onClose();
    }
  };

  const fetchUserStationAccessData = async () => {
    //get user station access data
    try {
      const res = await executeAccess();
      const array = res?.data?.data;
      setStationAccessData(res?.data?.data);
      array?.forEach((item) => {
        const stations = item?.name || [];
        if (stations.length > 0) {
          chargingStations.push(stations);
        }
        const cps = item?.chargePoints || [];
        if (cps.length > 0) {
          cps.forEach((cp) => {
            if (cp?.isAllowed) {
              //prevent duplicates
              setIsAllowed((prev) => [...prev, cp?.id]);
            } else {
              //prevent duplicates
              setIsBlocked((prev) => [...prev, cp?.id]);
            }
          });
        }
      });

      const initialExpandedState = chargingStations.reduce((acc, _, index) => {
        acc[index] = true;
        return acc;
      }, {});
      setExpandedStations(initialExpandedState);
    } catch (error) {
      logger.log(error);
    }
  };

  const toggleExpand = (index) => {
    setExpandedStations((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const stations = stationAccessData?.map((station, index) => {
    const chargePoints = station?.chargePoints.map((chargepoint, cpIndex) => {
      const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
          //check in fetched data
          if (isAllowed.includes(chargepoint.id)) {
            //if same do nothing
            return;
          } else {
            //if not add to allowed list
            //remove from blocked list
            setIsBlocked((prev) => prev.filter((id) => id !== chargepoint.id));
            setIsAllowed((prev) => [...prev, chargepoint.id]);
          }
        } else {
          //check in fetched data
          if (isBlocked.includes(chargepoint.id)) {
            //if same do nothing
            return;
          } else {
            //remove from allowed list
            setIsAllowed((prev) => prev.filter((id) => id !== chargepoint.id));
            //if not add to blocked list
            setIsBlocked((prev) => [...prev, chargepoint.id]);
          }
        }
      };
      return (
        <Box key={cpIndex} display="flex" alignItems="center" px={10} py={2}>
          {chargepoint.code && chargepoint.code.length > 0 && (
            <Checkbox
              mr={2}
              isChecked={isAllowed.includes(chargepoint.id)}
              onChange={(e) => handleCheckboxChange(e)}
            />
          )}
          <Text fontSize={15} fontWeight="bold">
            {chargepoint.code}
          </Text>
        </Box>
      );
    });
    return (
      <Card
        key={index}
        variant="elevated"
        p={1}
        borderRadius={10}
        w="80%"
        mt={5}
        ml={5}
        mb={3}
        borderWidth="1px"
        borderColor="gray.200"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text fontWeight="bold" fontSize={17} ml={2}>
            {station.name}
          </Text>
          <IconButton
            icon={
              expandedStations[index] ? <ChevronUpIcon /> : <ChevronDownIcon />
            }
            onClick={() => toggleExpand(index)}
            variant="ghost"
            aria-label="Toggle Chargepoints"
          />
        </Box>
        <Collapse in={expandedStations[index]} animateOpacity>
          <Box
            mt={4}
            maxHeight="150px"
            overflowY="auto"
            display="flex"
            flexWrap="wrap"
            gap={6}
          >
            {chargePoints}
          </Box>
        </Collapse>
      </Card>
    );
  });
  const closeHandler = () => {
    setIsAllowed([]);
    setIsBlocked([]);
    onClose();
  };

  useEffect(() => {
    if (isOpen && data?.Id) {
      fetchUserStationAccessData();
    }
  }, [isOpen, data]);

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={closeHandler}
      onOpen={fetchUserStationAccessData}
    >
      <ModalOverlay />
      <ModalContent
        maxWidth={{ base: "90%", md: "80%", lg: "60%" }}
        minH={"30vh"}
      >
        <ModalHeader textAlign={"center"} as="header">
          Access Management
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box p={2} pt={0}>
            <Text mt={3} fontSize="xl" fontWeight="bold">
              Charging Stations
            </Text>
            <Box mt={4} maxHeight="60vh" overflowY="auto">
              {stations}
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSave}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AccessManagementModal;
