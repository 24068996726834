import {Icon} from "@chakra-ui/react";
import {useColorModeValue} from "@chakra-ui/system";
import React from "react";
import { BsLightningChargeFill } from "react-icons/bs";

import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";

const TotalUnits = ({stats}) => {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");



  return (
    <MiniStatistics
      startContent={
        <IconBox
          w="86px"
          h="86px"
          bg={boxBg}
          icon={
            <Icon
              w="32px"
              h="32px"
              as={BsLightningChargeFill}
              color={brandColor}
            />
          }
        />
      }
      name="Total Units"
      value={stats?.totalUnitsConsumed}
    />
  );
};

export default TotalUnits;
