import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Spinner,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

import LoadingSpinner from "components/feedback/LoadingSpinner";
import {toastState} from "atoms/toastState";
import {createUserDrawerState} from "atoms/createUserDrawerState";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import Card from "components/card/Card";
import logger from "infra/logger";
import {loggedInUserState} from "atoms/loggedInUserState";
import {userDrawerSelectOptions} from "../variables/userDrawerSelectOptions";

const CreateUserDrawer = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const setToast = useSetRecoilState(toastState);
  const [drawerState, setDrawerState] = useRecoilState(createUserDrawerState);
  const loggedInUser = useRecoilValue(loggedInUserState);

  const validationSchema = yup.object().shape({
    FirstName: yup.string().min(1).required("First Name is required"),
    LastName: yup.string().min(1).required("Last Name is required"),
    MobileNumber: yup
      .string()
      .matches(/^[0-9]*$/, "Mobile Number must contain only digits")
      .max(10)
      .min(10)
      .required("Phone Number is required "),
    Email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address format"
      )
      .required("Email is required"),
    Role: yup.string().required("Role is required"),
    Type: yup.string(),
  });

  const {
    control,
    handleSubmit,
    formState: {errors},
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [{data, loading: createLoading, error}, createUser] = useAxios(
    {method: "POST", url: `/api/user/create`},
    {manual: true}
  );

  const handleRoleChange = (event) => {
    const newRole = event.target.value;
    setSelectedRole(newRole);
    setValue("Role", newRole);
  };

  const createHandler = async () => {
    setIsLoading(true);
    const formValues = getValues();
    let data = {};

    if (formValues.Email.trim() !== "") {
      data["Email"] = formValues.Email.trim();
    }
    if (formValues.FirstName.trim() !== "") {
      data["FirstName"] = formValues.FirstName.trim();
    }
    if (formValues.LastName.trim() !== "") {
      data["LastName"] = formValues.LastName.trim();
    }
    if (formValues.MobileNumber.trim() !== "") {
      data["MobileNumber"] = formValues.MobileNumber.trim();
    }
    if (formValues.Role !== "") {
      data["Role"] = formValues.Role;
    }
    if (formValues.Type !== "") {
      data["Type"] = formValues.Type;
    }
    else {
      data["Type"] = "";
    }

    logger.log(formValues);
    logger.log(data);

    if (Object.keys(data).length === 6) {
      // Adjust this condition if necessary
      try {
        const response = await createUser({data: data});

        setToast({
          isOpen: true,
          title: "User Created successfully",
          status: "success",
        });
        props.update();
      } catch (error) {
        const err = error?.response?.data?.error;
        logger.log(error);
        setToast({
          isOpen: true,
          title: "Error occurred when creating User",
          description: err ? err : null,
          status: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        title: "Enter all the fields to create User",
        status: "warning",
        position: "top",
      });
    }
    setIsLoading(false);
  };

  const closeHandler = () => {
    setDrawerState(null);
  };

  return (
    <Drawer
      isOpen={drawerState?.isOpen}
      placement="right"
      onClose={closeHandler}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Create a new User</DrawerHeader>

        <DrawerBody>
          {!createLoading ? (
            <>
              <Card>
                <form>
                  {userDrawerSelectOptions.map((item, index) => (
                    <Flex key={index} mb="20px" alignItems="center">
                      <Controller
                        name={item.name}
                        control={control}
                        defaultValue=""
                        render={({field}) => (
                          <FormControl
                            isRequired
                            flexDirection="row"
                            isInvalid={errors[item.name]}
                          >
                            <FormLabel fontSize={20}>{item.label}</FormLabel>
                            {item.type === "select" ? (
                              <Select
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                width="400px"
                                height="50px"
                                isRequired
                                onChange={
                                  item.name === "Role"
                                    ? handleRoleChange
                                    : field.onChange
                                } // Handle Role change
                              >
                                <option value="">Select</option>
                                {item.options.map((option, ind) => (
                                  <option key={ind} value={option.value}>
                                    {option.name}
                                  </option>
                                ))}
                              </Select>
                            ) : (
                              <Input
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                placeholder={item.placeholder}
                                width="400px"
                                height="50px"
                                type={item.type}
                                isRequired
                              />
                            )}
                            <FormHelperText color={"#f57d36"}>
                              {item.helper}
                            </FormHelperText>
                            <FormErrorMessage mb={"20px"}>
                              {errors[item.name] && errors[item.name].message}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    </Flex>
                  ))}

                  {selectedRole === "admin" && loggedInUser?.tenantType === "bco" && (
                    <Flex mb="20px" alignItems="center">
                      <Controller
                        name="Type"
                        control={control}
                        defaultValue=""
                        render={({field}) => (
                          <FormControl
                            flexDirection="row"
                            isInvalid={errors.Type}
                          >
                            <FormLabel fontSize={20}>Type</FormLabel>
                            <Select
                              {...field}
                              focusBorderColor="#f57d36"
                              fontSize="sm"
                              width="400px"
                              height="50px"
                            >
                              <option value="">Select</option>
                              <option value="investor">Investor</option>
                            </Select>
                            {/* <FormHelperText color={"#f57d36"}>
                              Please select a type
                            </FormHelperText> */}
                            <FormErrorMessage mb={"20px"}>
                              {errors.Type && errors.Type.message}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    </Flex>
                  )}
                </form>
              </Card>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            bg="#f57d36"
            color="white"
            colorScheme="orange"
            onClick={handleSubmit(createHandler)}
            isLoading={isLoading}
            loadingText="Creating"
            spinner={<Spinner size="md" />}
            disabled={isLoading}
          >
            Create
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CreateUserDrawer;
