import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";

import ColumnsTable from "views/admin/users/components/ColumnsTable";
import { tableHeaders } from "./variables/tableHeaders";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import { useSetRecoilState } from "recoil";
import { deleteModalState } from "atoms/deleteModalState";
import logger from "infra/logger";

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const setDeleteModal = useSetRecoilState(deleteModalState)
  const [{ data, loading, error }, execute] = useAxios({ method: "GET", url: "/api/user" }, { manual: true });
  const [{data:status, loading:statusLoading, error:statusError}, executeStatus] = useAxios({ method: "GET", url: "" }, { manual: true })

  const getUsers = async () => {
    try {
      const response = await execute();
      setUsers(response.data.data)
      console.log(response.data.data)
    } catch (error) {
      logger.log(error);
    }
  };
  const changeStatus = async (id, status) => {
    try {
      const response = await executeStatus({ method: "PUT", url: `/api/user/${id}`, data: { status: !status } });
      getUsers()
    } catch (error) {
      logger.log(error);
    }
  }
  useEffect(() => {
    setDeleteModal(false)
    getUsers();
  }, []);

  return !loading ? (
    <ColumnsTable columnsData={tableHeaders} tableData={users} getUsers={getUsers} />
  ) : (
    <LoadingSpinner />
  );
};

export default UsersList;
