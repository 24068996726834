import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Switch,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaRupeeSign } from "react-icons/fa";
import { useRecoilValue } from "recoil";

import { chargePointState } from "atoms/chargePointState";
import { onlyUAdmins } from "utils/accessLevels";
import { loggedInUserState } from "atoms/loggedInUserState";

const BillingRow = ({ row, index, addToUpdate }) => {
  const chargePoints = useRecoilValue(chargePointState);
  const [check, setCheck] = useState(null);
  const [tariff, setTariff] = useState(null);
  const [updateData, setUpdateData] = useState({
    Id: row?.original?.Id,
    payload: {},
  });

  const loggedInUser = useRecoilValue(loggedInUserState);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const changeHandler = (e) => {
    if (e.target.name === "TaxEnabled") {
      setCheck(e.target.checked);
      setUpdateData((prev) => {
        return {
          ...prev,
          payload: {
            ...prev.payload,
            TaxEnabled: e.target.checked,
            ShouldToggleTax: true,
          },
        };
      });
    }
    if (e.target.name === "Tariff") {
      setTariff(e.target.value);
      setUpdateData((prev) => {
        return {
          ...prev,
          payload: {
            ...prev.payload,
            Tariff: parseFloat(e.target.value),
          },
        };
      });
    }
  };

  const cells = row?.cells?.map((cell, inde) => {
    let data = "";
    if (cell.column.Header === "S.No") {
      data = (
        <Flex align="center" justify={"center"}>
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {inde + 1}
          </Text>
        </Flex>
      );
    } else if (cell.column.Header === "Charge Points") {
      data = (
        <Flex align="center" justify={"center"}>
          <Text me="10px" color={textColor} fontSize="sm" fontWeight="700">
            {row?.original?.Code}
          </Text>
        </Flex>
      );
    } else if (cell.column.Header === "Price Per Kwh") {
      data = (
        <Flex align="center" justify={"center"}>
          <InputGroup w={"50%"}>
            <InputLeftAddon>₹</InputLeftAddon>
            <Input
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              type="number"
              value={
                updateData?.payload?.Tariff !== undefined &&
                updateData?.payload?.Tariff !== null
                  ? updateData?.payload?.Tariff
                  : row?.original?.Tariff
              }
              name="Tariff"
              onChange={(e) => changeHandler(e)}
              readOnly={!onlyUAdmins.includes(loggedInUser?.role)}
            />
          </InputGroup>

          <Text
            me="10px"
            color={textColor}
            fontSize="sm"
            fontWeight="700"
          ></Text>
        </Flex>
      );
    } else if (cell.column.Header === "Tax Enabled") {
      data = (
        <Flex align="center" justify="center">
          <Switch
            name="TaxEnabled"
            isChecked={check}
            defaultChecked={row?.original?.TaxEnabled}
            onChange={(e) => changeHandler(e)}
            readOnly={!onlyUAdmins.includes(loggedInUser?.role)}
          />
        </Flex>
      );
    } else if (cell.column.Header === "Total Price Per Unit") {
      const activePrice =
        updateData?.payload?.Tariff !== undefined &&
        updateData?.payload?.Tariff !== null
          ? updateData?.payload?.Tariff
          : row?.original.Tariff;

      // Ensure that activePrice is 0 if it's undefined or NaN
      const validActivePrice =
        isNaN(activePrice) || activePrice === undefined ? 0 : activePrice;
      const finalPrice = (
        check ? validActivePrice * (1 + row?.original?.Gst) : validActivePrice
      ).toFixed(2);
      data = (
        <Flex
          align="center"
          justify={"center"}
          color={textColor}
          fontSize="md"
          fontWeight="700"
        >
          <Icon as={FaRupeeSign} w={3} h={4} mr={2} />
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {finalPrice}
          </Text>
        </Flex>
      );
    }
    return (
      <Td
        {...cell.getCellProps()}
        key={inde}
        fontSize={{ sm: "14px" }}
        minW={{ sm: "150px", md: "200px", lg: "auto" }}
        borderColor="transparent"
      >
        {data}
      </Td>
    );
  });
  useEffect(() => {
    setCheck(row?.original?.TaxEnabled);
    setTariff(row?.original?.Tariff);
    setUpdateData({
      Id: row?.original?.Id,
      payload: {},
    });
  }, [chargePoints]);
  useEffect(() => {
    if (
      updateData?.payload?.Tariff != undefined ||
      updateData?.payload?.TaxEnabled != undefined
    ) {
      addToUpdate(updateData);
    }
  }, [updateData]);
  return (
    <Tr {...row.getRowProps()} key={index}>
      {cells}
    </Tr>
  );
};

export default BillingRow;