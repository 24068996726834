// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";
import AdminNavbarLinks from "components/navbar/NavbarLinksAdmin";
import {FaPlus} from "react-icons/fa";
import {useLocation, useNavigate} from "react-router-dom";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {createUserDrawerState} from "atoms/createUserDrawerState";
import {createRfidDrawerState} from "atoms/createRfidDrawerState";
import {createPropertyOwnerDrawerState} from "atoms/createPropertyOwnerState";
import logger from "infra/logger";
import {loggedInUserState} from "atoms/loggedInUserState";
import {accessLevels} from "utils/accessLevels";
import {createPartnerDrawerState} from "atoms/createPartnerDrawerState";
import {createTenantDrawerState} from "atoms/createTenantDrawerState";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import {onlyAdmins} from "utils/accessLevels";
import {onlyUAdmins} from "utils/accessLevels";

export default function AdminNavbar(props) {
  const loggedInUser = useRecoilValue(loggedInUserState);
  const role = loggedInUser?.role;
  const canEdit = accessLevels[role]?.accessLevel <= 2;
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const setUserDrawerState = useSetRecoilState(createUserDrawerState);
  const setRfidDrawerState = useSetRecoilState(createRfidDrawerState);
  const setPropertyOwnerDrawerState = useSetRecoilState(
    createPropertyOwnerDrawerState
  );
  const setPartnerDrawerState = useSetRecoilState(createPartnerDrawerState);
  const setTenantDrawerState = useSetRecoilState(createTenantDrawerState);
  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);
    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const {secondary, message, brandText} = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  let navbarPosition = "fixed";
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue(
    "rgba(244, 247, 254, 0.2)",
    "rgba(11,20,55,0.5)"
  );
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  let gap = "0px";
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const location = useLocation();

  const isChargingStationsRoute =
    location.pathname === "/admin/chargingstations/list";
  const isUserRoute = location.pathname === "/admin/users/list";
  const isRfidRoute = location.pathname === "/admin/rfid_cards/list";
  const isPropertyOwnerRoute =
    location.pathname === "/admin/propertyowners/list";
  const isPartnersRoute = location.pathname === "/admin/partners/list";
  const isTenantsRoute = location.pathname === "/admin/tenants/list";

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{xl: "center"}}
      display={secondary ? "block" : "flex"}
      minH="75px"
      justifyContent={{xl: "center"}}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={{base: "12px", md: "30px", lg: "30px", xl: "30px"}}
      px={{
        sm: paddingX,
        md: "10px",
      }}
      ps={{
        xl: "12px",
      }}
      pt="8px"
      // top={{ base: "12px", md: "16px", lg: "20px", xl: "20px" }}
      w={{
        base: "calc(100vw - 6%)",
        md: "calc(100vw - 8%)",
        lg: "calc(100vw - 6%)",
        xl: "calc(100vw - 350px)",
        "2xl": "calc(100vw - 365px)",
      }}
    >
      <Flex
        w="100%"
        display={"flex"}
        flexDirection={{
          sm: "column",
          md: "row",
        }}
        alignItems={{xl: "center"}}
        justifyContent={"space-between"}
        mb={gap}
      >
        <Box mb={{sm: "8px", md: "0px"}}>
          <Breadcrumb>
            <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
              <BreadcrumbLink href="#" color={secondaryText}>
                Admin
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem color={secondaryText} fontSize="sm">
              <BreadcrumbLink href="#" color={secondaryText}>
                {brandText}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          {/* Here we create navbar brand, based on route name */}
          <Link
            color={mainText}
            href="#"
            bg="inherit"
            borderRadius="inherit"
            fontWeight="bold"
            fontSize="34px"
            _hover={{color: {mainText}}}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
          >
            {brandText}
          </Link>
        </Box>

        {isChargingStationsRoute && (
          <RoleBasedRender roles={onlyAdmins}>
            <Button
              leftIcon={<FaPlus />}
              ms={"auto"}
              me={10}
              colorScheme="whatsapp"
              variant="solid"
              onClick={() => navigate("/admin/chargingstations/new")}
              disabled={!canEdit}
            >
              ADD STATION
            </Button>
          </RoleBasedRender>
        )}
        {isUserRoute && (
          <RoleBasedRender roles={onlyAdmins}>
            <Button
              leftIcon={<FaPlus />}
              ms={"auto"}
              me={10}
              colorScheme="whatsapp"
              variant="solid"
              onClick={() => setUserDrawerState({isOpen: true})}
              disabled={!canEdit}
            >
              ADD USERS
            </Button>
          </RoleBasedRender>
        )}
        {isRfidRoute && (
          <RoleBasedRender roles={onlyAdmins}>
            <Button
              leftIcon={<FaPlus />}
              ms={"auto"}
              me={10}
              colorScheme="whatsapp"
              variant="solid"
              onClick={() => setRfidDrawerState({isOpen: true})}
              disabled={!canEdit}
            >
              GENERATE RFID CARD
            </Button>
          </RoleBasedRender>
        )}
        {isPropertyOwnerRoute && (
          <RoleBasedRender roles={onlyAdmins}>
            <Button
              leftIcon={<FaPlus />}
              ms={"auto"}
              me={10}
              colorScheme="whatsapp"
              variant="solid"
              onClick={() => setPropertyOwnerDrawerState({isOpen: true})}
              disabled={!canEdit}
            >
              ADD PROPERTY OWNER
            </Button>
          </RoleBasedRender>
        )}
        {isPartnersRoute && (
          <RoleBasedRender roles={onlyUAdmins}>
            <Button
              leftIcon={<FaPlus />}
              ms={"auto"}
              me={10}
              colorScheme="whatsapp"
              variant="solid"
              onClick={() => setPartnerDrawerState({isOpen: true})}
              disabled={!canEdit}
            >
              ADD PARTNER
            </Button>
          </RoleBasedRender>
        )}
        {isTenantsRoute && (
          <RoleBasedRender roles={onlyUAdmins}>
            <Button
              leftIcon={<FaPlus />}
              ms={"auto"}
              me={10}
              colorScheme="whatsapp"
              variant="solid"
              onClick={() => setTenantDrawerState({isOpen: true})}
              disabled={!canEdit}
            >
              ADD TENANT
            </Button>
          </RoleBasedRender>
        )}

        <Box w={{sm: "100%", md: "unset"}}>
          <AdminNavbarLinks
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            fixed={props.fixed}
            scrolled={scrolled}
          />
        </Box>
      </Flex>
      {secondary ? <Text color="white">{message}</Text> : null}
    </Box>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
