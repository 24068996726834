import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import { partnerTableHeaders } from "./variables/partnerTableHeaders";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import { deleteModalState } from "atoms/deleteModalState";
import logger from "infra/logger";
import PartnersColumnsTable from "./components/PartnersColumnsTable";

const PartnersList = () => {
  const [users, setUsers] = useState([]);
  const setDeleteModal = useSetRecoilState(deleteModalState)
  const [{ data, loading, error }, execute] = useAxios({ method: "GET", url: "/api/user" }, { manual: true });
  const [{data:status, loading:statusLoading, error:statusError}, executeStatus] = useAxios({ method: "GET", url: "" }, { manual: true })

  const getPartners= async () => {
    try {
      const response = await execute();
      setUsers(response.data.data)
    } catch (error) {
      logger.log(error);
    }
  };
  const changeStatus = async (id, status) => {
    try {
      const response = await executeStatus({ method: "PUT", url: `/api/user/${id}`, data: { status: !status } });
      getPartners()
    } catch (error) {
      logger.log(error);
    }
  }
  useEffect(() => {
    setDeleteModal(false)
    getPartners();
  }, []);

  return !loading ? (
    <PartnersColumnsTable columnsData={partnerTableHeaders} tableData={users} getUsers={getPartners} />
  ) : (
    <LoadingSpinner />
  );
};

export default PartnersList;
