import { Icon } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import React, { useEffect, useState } from "react";
import { MdOutlineTimer } from "react-icons/md";

import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { getTimerString } from "utils/utils";

const TotalDuration = ({ data }) => {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (
    <MiniStatistics
      startContent={
        <IconBox
          w="56px"
          h="56px"
          bg={boxBg}
          icon={
            <Icon w="32px" h="32px" as={MdOutlineTimer} color={brandColor} />
          }
        />
      }
      name="Total Duration"
      value={
        getTimerString(data) == "undefineds" ? "00:00:00" : getTimerString(data)
      }
    />
  );
};

export default TotalDuration;
