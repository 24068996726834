import React, {useMemo} from "react";
import {useTable, useGlobalFilter, useSortBy, usePagination} from "react-table";
import {useRecoilState} from "recoil";
import {payOutsDrawerState} from "atoms/payOutsDrawerState";
import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import {formattedDate} from "utils/utils";
import PayOutsDrawer from "./PayOutsDrawer";

export default function PayoutsTable(props) {
  const {columnsData, tableData} = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [viewPayOutState, setViewPayOutState] =
    useRecoilState(payOutsDrawerState);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0, pageSize: 10},
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: {pageIndex},
  } = tableInstance;
  const {canPreviousPage, canNextPage, pageCount, nextPage, previousPage} =
    tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const editHandler = (row) => {
    setViewPayOutState({isOpen: true, data: row.original});
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{sm: "scroll", lg: "hidden"}}
    >
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{sm: "10px", lg: "12px", xl: "12px"}}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "S.No") {
                    data = (
                      <Flex>
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {i + 1}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Payout Ref No") {
                    data = (
                      <Flex align="center">
                        <Text
                          me="10px"
                          color={textColor}
                          fontSize="sm"
                          fontWeight="700"
                        >
                          {cell?.row?.original?.payout_ref_no}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Payout Date") {
                    data = (
                      <Flex align="center">
                        <Text
                          me="10px"
                          color={textColor}
                          fontSize="sm"
                          fontWeight="700"
                        >
                          {formattedDate(cell?.row?.original?.payout_date)}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Total Amount") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell?.row?.original?.net_amount?.toFixed(2)}
                      </Text>
                    );
                  } else if (cell.column.Header === "Settled to Bank") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell?.row?.original?.settled_to_bank || "N/A"}
                      </Text>
                    );
                  } else if (cell.column.Header === "Account No") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell?.row?.original?.beneficiary_account_number}
                      </Text>
                    );
                  } else if (cell.column.Header === "UTR No") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell?.row?.original?.utr_number}
                      </Text>
                    );
                  } else if (cell.column.Header === "Actions") {
                    data = (
                      <Button
                        colorScheme="blue"
                        variant="ghost"
                        onClick={() => editHandler(row)}
                      >
                        View
                      </Button>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{sm: "14px"}}
                      minW={{sm: "150px", md: "200px", lg: "auto"}}
                      borderColor="transparent"
                      alignItems="center"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {viewPayOutState.isOpen && (
        <PayOutsDrawer
          isOpen={viewPayOutState.isOpen}
          onClose={() =>
            setViewPayOutState({...viewPayOutState, isOpen: false})
          }
          data={viewPayOutState.data}
        />
      )}
      {tableData.length > 0 ? (
        <Flex justify="space-between" alignItems="center">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </Button>
          <Text>
            Page {pageIndex + 1} of {pageCount}
          </Text>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </Button>
        </Flex>
      ) : (
        <Flex justifyContent="center" alignItems="center">
          No payouts
        </Flex>
      )}
    </Card>
  );
}
