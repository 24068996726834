// PayRunTable.jsx
import {
  Button,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useRecoilState } from "recoil";

import Card from "components/card/Card";
import { PayRunDrawerState } from "atoms/payRunDrawerState";
import PayRunDrawer from "./PayRunDrawer";
import { FaRupeeSign } from "react-icons/fa";
import PayOutsDrawer from "./PayOutsDrawer";

const PayoutTable = (props) => {
  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [viewPayRunState, setViewPayRunState] =
    useRecoilState(PayRunDrawerState);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0, // Start at page 0
        pageSize: 10, // Set the initial page size
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },
  } = tableInstance;

  const { canPreviousPage, canNextPage, pageCount, nextPage, previousPage } =
    tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const editHandler = (id) => {
    setViewPayRunState({ isOpen: true, payoutId: id });
  };

  const payoutTypeRefractor = (type) => {
    switch (type) {
      case "bco":
        return "BCO";
      case "cpo_operated_by_urzza":
        return "CPO Operated By Urzza";
      case "property_owner":
        return "Property Owner";

      default:
        return "Urzza";
    }
  }

  return (
    <>
      {data.length > 0 ? (
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px", xl: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={i}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "S.No") {
                        data = (
                          <Flex>
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {i + 1}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header === "Payee Name") {
                        data = (
                          <Flex align="center">
                            <Text
                              me="10px"
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell?.row?.original?.payee_name}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header === "Type") {
                        data = (
                          <Flex align="center">
                            <Text
                              me="10px"
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {payoutTypeRefractor(cell?.row?.original?.type)}
                            </Text>
                          </Flex>
                        );
                      } else if (
                        cell.column.Header === "Total Units Consumed"
                      ) {
                        data = (
                          <Flex align="center">
                            <Text
                              me="10px"
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell?.row?.original?.total_units_consumed?.toFixed(
                                2
                              )} Kwh
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header === "Gross Amount") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            <Icon as={FaRupeeSign} w={3} h={4} mr={2} />
                            {cell?.row?.original?.gross_amount?.toFixed(2)}
                          </Text>
                        );
                      } else if (cell.column.Header === "Tax Amount") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            <Icon as={FaRupeeSign} w={3} h={4} mr={2} />
                            {cell?.row?.original?.tax_amount}
                          </Text>
                        );
                      } else if (cell.column.Header === "Net Amount") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            <Icon as={FaRupeeSign} w={3} h={4} mr={2} />
                            {cell?.row?.original?.net_amount?.toFixed(2)}
                          </Text>
                        );
                      } else if (cell.column.Header === "Arrears") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell?.row?.original?.arrears}
                          </Text>
                        );
                      } else if (cell.column.Header === "Actions") {
                        data = (
                          <Button
                            colorScheme="blue"
                            variant="ghost"
                            onClick={() =>
                              editHandler(cell?.row?.original?.id)
                            }
                          >
                            View
                          </Button>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                          alignItems="center"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Flex justify="space-between" alignItems="center" mt={4}>
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous
            </Button>
            <Text>
              Page {pageIndex + 1} of {pageCount}
            </Text>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </Button>
          </Flex>

          {viewPayRunState?.isOpen && (
            <PayOutsDrawer
              isOpen={viewPayRunState.isOpen}
              onClose={() => setViewPayRunState({ isOpen: false, data: null })}
              data={viewPayRunState?.payoutId}
            />
          )}
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

export default PayoutTable;
