/* eslint-disable */
import React from "react";
import {NavLink, useLocation} from "react-router-dom";
// chakra imports
import {Box, Flex, HStack, Text, useColorModeValue} from "@chakra-ui/react";
import {loggedInUserState} from "atoms/loggedInUserState";
import {useRecoilValue} from "recoil";
import {accessLevels} from "utils/accessLevels";
import RoleBasedRender from "components/wrappers/roleBasedRender";

const Links = (props) => {
  const loggedInUser = useRecoilValue(loggedInUserState);
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const {routes} = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (route) => {
    if (location.pathname.length > route.length) {
      return location.pathname.includes(route + "/");
    } else {
      return location.pathname === route;
    }
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const links = routes.map((route, index) => {
    const locationPath = route.layout + route.path;
    const isActive = activeRoute(locationPath);
    const role = loggedInUser?.role;
    const isAllowed = accessLevels[role]?.accessLevel <= route.accessLevel;
    if (route.layout === "/admin") {
      return (
        <RoleBasedRender roles={route?.roles} key={index}>
          <NavLink key={index} to={route.layout + route.path} width="100%">
            <Flex
              bg={isActive ? "#fef4e8" : "transparent"}
              borderRightRadius={4}
              px={2}
              maxH={40}
              py={2}
              justifyContent={"space-between"}
              dir="row"
              align="center"
              w={"100%"}
              borderRight={isActive ? "0px solid #f57d36" : "none"}
              borderLeft={isActive ? "0px solid #f57d36" : "none"}
              _hover={{bg: "gray.100"}}
            >
              <HStack
                spacing={isActive ? "22px" : "26px"}
                py="5px"
                ps="10px"
                w={"100%"}
                h={"100%"}
              >
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <Flex
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    height={"40px"}
                    color={isActive ? "#f57d36" : textColor}
                    me="18px"
                  >
                    {route.icon}
                  </Flex>
                  <Text
                    me="auto"
                    color={isActive ? activeColor : textColor}
                    fontWeight={isActive ? "bold" : "normal"}
                  >
                    {route.name}
                  </Text>
                </Flex>
              </HStack>
              <Flex
                h="52px"
                w="4px"
                bg={isActive ? "#f57d36" : "none"}
                right={0}
                borderRadius={5}
              />
            </Flex>
          </NavLink>
        </RoleBasedRender>
      );
    }
  });
  //  BRAND
  return [links];
};

export default Links;
