import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Progress,
  Text,
} from "@chakra-ui/react";
import React from "react";
import {AiFillThunderbolt} from "react-icons/ai";
const ChargingSessionCard = ({session}) => {
  return (
    <Box mb={5} w={"100%"} minW={200}>
      <Flex direction="column" alignItems={"center"}>
        <Text mb={2} fontWeight={600}>
          CONSUMPTION
        </Text>
        <Progress
          isIndeterminate
          height="32px"
          w={"90%"}
          borderRadius={5}
          borderWidth={2}
          boxShadow="dark-lg"
          mb={8}
          variant={"vertical"}
          colorScheme="orange"
        >
          <CircularProgressLabel
            fontSize={"15px"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"row"}
            display={"flex"}
            fontWeight={600}
          >
            <AiFillThunderbolt size={18} color="#f57d36" style={{marginRight:"5px", position:"absolute", left: 5}}/>
            {session?.totalUnitsConsumed.toFixed(2) || 0} KwH
          </CircularProgressLabel>
        </Progress>
        <Text mb={2} fontWeight={600}>
          SOC
        </Text>
        <CircularProgress
          value={Math.floor(session?.chargePercentage) || 0}
          color="green.400"
          thickness="15px"
          size="70px"
          isIndeterminate={session?.chargePercentage ? false : true}
          hasStripe={true}
          isAnimated={true}
        >
          <CircularProgressLabel fontSize="15px">
            {Math.floor(session?.chargePercentage) || 0}%
          </CircularProgressLabel>
        </CircularProgress>
      </Flex>
    </Box>
  );
};

export default ChargingSessionCard;
