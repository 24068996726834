

export const columnsDataColumns = [
  {
    Header: "S.No",
    accessor: "data.Sno", // Accessing name from the JSON data
  },
  {
    Header: "IdTag",
    accessor: "data.idTag", // Accessing name from the JSON data
  },
  {
    Header: "Email",
    accessor: "data.email", // Accessing phoneNumber from the JSON data
  },
  {
    Header: "PhoneNumber",
    accessor: "data.phoneNumber", // Accessing city from the JSON data
  },
  {
    Header: "Balance",
    accessor: "data.balance", // Accessing isActive from the chargingPoints array in JSON data
  },
  {
    Header: "Status",
    accessor: "data.status", // Accessing isActive from the chargingPoints array in JSON data
  },
  {
    Header: "Actions",
    accessor: "data.action", // Accessing isActive from the chargingPoints array in JSON data
  },
];
