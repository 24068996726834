import { Tag } from "@chakra-ui/react";
import {activeConnectorState} from "atoms/activeConnectorState";
import React from "react";
import {useRecoilValue} from "recoil";

const ConnectorStatusCard = () => {
  const activeConnector = useRecoilValue(activeConnectorState);
  const status = activeConnector?.Status;
  const colorScheme =
    status === "Charging" || status === "Preparing"
      ? "orange"
      : status === "Available"
      ? "green"
      : "black";
  return (
    <Tag
      size="lg"
      variant="solid"
      position={"absolute"}
      left={0}
      top={1}
      px={2}
      borderWidth={1}
      backgroundColor={colorScheme}
    >
      {status || "Unavailable"}
    </Tag>
  );
};

export default ConnectorStatusCard;
