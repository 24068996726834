import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    Select,
  } from "@chakra-ui/react";
  import useAxios from "axios-hooks";
  import React, { useEffect, useState } from "react";
  import { Controller, useForm } from "react-hook-form";
  import * as yup from "yup";
  import { yupResolver } from "@hookform/resolvers/yup";

  import LoadingSpinner from "components/feedback/LoadingSpinner";
  import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
  import { toastState } from "atoms/toastState";
  import Card from "components/card/Card";
  import logger from "infra/logger";
  import { loggedInUserState } from "atoms/loggedInUserState";
import { updateSourceDrawerState } from "atoms/updateSourceDrawerState";
import { sourceDrawerSelectOptions } from "../variables/sourceDrawerSelectOptions";
  
  const UpdateSourceDrawer = (props) => {
    const setToast = useSetRecoilState(toastState);
    const [drawerState, setDrawerState] = useRecoilState(updateSourceDrawerState);
    const [source, setSource] = useState({
      Name: "",
      PartnerId: "",
    });
    const loggedInUser = useRecoilValue(loggedInUserState);
    const validationSchema = yup.object().shape({
      Name: yup.string(),
      PartnerId: yup.string(),
    });
  
    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
      register,
      setValue,
      getValues,
    } = useForm({
      mode: "onChange",
      resolver: yupResolver(validationSchema),
    });
  
    const [{ loading: userLoading }, getSource,cancelRequest] = useAxios(
      { method: "GET", url: `/api/source/id/${drawerState?.activeId}` },
      { manual: true }
    );
  
    const [{ data: updatedUser, loading: updateUserLoading }, updateSource] =
      useAxios(
        { method: "PATCH", url: `/api/source/update/${drawerState?.activeId}` },
        { manual: true }
      );
  
    const getSourceDetails = async () => {
      try {
        const response = await getSource();
        const data = response.data.data;
        setValue("Name", data.Name);
        setValue("PartnerId", data.PartnerId);
        setSource((prev) => {
          return {
            ...prev,
            Name: data.Name,
            PartnerId: data.PartnerId,
          };
        });
        logger.log("source",data);
      } catch (error) {
        logger.log(error);
        if(error?.code !== "ERR_CANCELED"){
        setToast({
          isOpen: true,
          title: "Error occured while getting Source details",
          status: "error",
        });
        closeHandler();
      }
    }
    };
  
    const closeHandler = () => {
      setDrawerState(null);
    };
  
    const saveHandler = async () => {
      const formValues = getValues();
      let sourceToUpdate = {};
      if (
        formValues.Name !== "" &&
        formValues.Name !== source?.Name
      ) {
        sourceToUpdate["Name"] = formValues.Name;
      }
      if (
        formValues.PartnerId !== "" &&
        formValues.PartnerId !== source?.PartnerId
      ) {
        sourceToUpdate["PartnerId"] = parseFloat(formValues.PartnerId);
      }
      logger.log(formValues);
      logger.log(sourceToUpdate);
      if (Object.keys(sourceToUpdate)?.length > 0) {
        try {
          const response = await updateSource({ data: sourceToUpdate });
          setToast({
            isOpen: true,
            title: "Source details Updated",
            status: "success",
          });
          props.update();
        } catch (error) {
          const err = error?.response?.data?.error;
          logger.log(error);
          setToast({
            isOpen: true,
            title: "Error occured when updating Source",
            description: err ? err : null,
            status: "error",
          });
        }
      } else {
        setToast({
          isOpen: true,
          title: "nothing new to update",
          status: "warning",
        });
      }
    };
  
    useEffect( () => {
      if (drawerState?.activeId) {
         getSourceDetails();
         return () => cancelRequest();
      }
    }, []);
  
    return (
      <Drawer
        isOpen={drawerState?.isOpen}
        placement="right"
        onClose={closeHandler}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            Update Your Details 
          </DrawerHeader>
  
          <DrawerBody>
            {!userLoading && !updateUserLoading ? (
              <>
                <Card>
                  {/* {drawerState?.activeId && <UserCard user={user} />} */}
                  <form>
                    {sourceDrawerSelectOptions.map((item, index) => (
                      <Flex key={index} mb="20px" alignItems="center">
                        <Controller
                          name={item.name}
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl
                              flexDirection="row"
                              isInvalid={errors[item.name]}
                            >
                              <FormLabel fontSize={20}>{item.label}</FormLabel>
                              {item.type === "select" ? (
                                <Select
                                  {...field}
                                  focusBorderColor="#f57d36"
                                  fontSize="sm"
                                  width="400px"
                                  height="50px"
                                  isRequired
                                >
                                  <option value="">Select</option>
                                  {item.options.map((option, ind) => {
                                    return (
                                      <option key={ind} value={option.value}>
                                        {option.name}
                                      </option>
                                    );
                                  })}
                                </Select>
                              ) : (
                                <Input
                                  {...field}
                                  focusBorderColor="#f57d36"
                                  fontSize="sm"
                                  placeholder={item.placeholder}
                                  width="400px"
                                  height="50px"
                                  type={item.type}
                                  isRequired
                                />
                              )}
                              <FormHelperText color={"#f57d36"}>
                                {item.helper}
                              </FormHelperText>
                              <FormErrorMessage mb={"20px"}>
                                {errors[item.name] && errors[item.name].message}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        />
                      </Flex>
                    ))}
                  </form>
                </Card>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </DrawerBody>
  
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={closeHandler}>
              Cancel
            </Button>
            <Button
              bg="#f57d36"
              color="white"
              colorScheme="orange"
              onClick={handleSubmit(saveHandler)}
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  };
  
  export default UpdateSourceDrawer;
  