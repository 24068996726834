import {Flex, Image, Tag} from "@chakra-ui/react";
import React from "react";

import ccstype2 from "../../assets/img/chargePointsTab/ccstype2.png";
import AC1 from "../../assets/img/chargePointsTab/actype1.png";
import AC2 from "../../assets/img/chargePointsTab/ac2.png";
import CDMO from "../../assets/img/chargePointsTab/chademo.png";

import {useRecoilValue} from "recoil";
import {activeConnectorState} from "atoms/activeConnectorState";
import Pulsing from "components/feedback/Pulsing";

const ConnectorCard = () => {
  const activeConnector = useRecoilValue(activeConnectorState);
  const status = activeConnector?.Status;
  const typeOfConnector = activeConnector?.TypeOfConnector;
  let logo;
  switch (typeOfConnector) {
    case "AC Type 1":
      logo = AC1;
      break;
    case "AC Type 2":
      logo = AC2;
      break;
    case "CCS Type 2":
      logo = ccstype2;
      break;
    case "CHAdeMO":
      logo = CDMO;
      break;
    default:
      logo = ccstype2;
      break;
  }
  return (
    <Flex flexDirection="column" alignItems="center" mb={5} w={100} position="relative">
      <Pulsing width="10px" left="0px" position="absolute"/>
      <Image maxW="120px" minW="80px" m={4} src={logo} alt="type of connector"></Image>
      <Tag fontWeight="extrabold" fontSize={15}  fontFamily={"Roboto"} mb={5}>
        {activeConnector?.TypeOfConnector}
      </Tag>
      {activeConnector?.Status && (
        <Tag
          size="lg"
          colorScheme={
            status === "Preparing" || status === "Charging"
              ? "orange"
              : status === "Available"
              ? "green"
              : "blackAlpha"
          }
          borderRadius="full"
        >
          {activeConnector?.Status}
        </Tag>
      )}
    </Flex>
  );
};

export default ConnectorCard;
