import React, {useEffect} from "react";
import {Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import {useLocation, useNavigate} from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";

import StationOverView from "../tab_panels/StationOverView";
import Photos from "../tab_panels/Photos";
import Location from "../tab_panels/Location";
import ChargePointTabs from "./ChargePointTabs";
import OcppLogs from "../tab_panels/OcppLogs";
import BillingPlan from "../tab_panels/BillingPlan";
import ChargingSessions from "../tab_panels/ChargingSessions";
import {loggedInUserState} from "atoms/loggedInUserState";
import {accessLevels} from "utils/accessLevels";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import {onlyAdmins} from "utils/accessLevels";
import {chargePointCodeState} from "atoms/chargePointCodeState";
import {chargePointConnectorIdState} from "atoms/chargePointConnectorState";

const ChargingStationTabs = (props) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const [chargePointCode, setChargePointCode] =
    useRecoilState(chargePointCodeState);
  const [chargePointConnectorId, setChargePointConnectorId] = useRecoilState(
    chargePointConnectorIdState
  );
  const location = useLocation();
  const navigate = useNavigate();
  const isNewChargingStation =
    location.pathname === "/admin/chargingstations/new";

  const loggedInUser = useRecoilValue(loggedInUserState);
  const role = loggedInUser?.role;
  const accessLevel = accessLevels[role]?.accessLevel;

  const clickHandler = (path, queryParams = "") => {
    navigate(`/admin/chargingstations/view/${props?.id}/${path}`);
  };

  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case `/admin/chargingstations/view/${props?.id}/overview`: {
        setActiveTabIndex(0);
        break;
      }
      case `/admin/chargingstations/view/${props?.id}/photos`: {
        setActiveTabIndex(1);
        break;
      }
      case `/admin/chargingstations/view/${props?.id}/location`: {
        setActiveTabIndex(2);
        break;
      }
      case `/admin/chargingstations/view/${props?.id}/chargepoints`: {
        setActiveTabIndex(3);
        setChargePointCode("");
        setChargePointConnectorId("");
        break;
      }
      case `/admin/chargingstations/view/${props?.id}/ocpplogs`: {
        setActiveTabIndex(4);
        break;
      }
      case `/admin/chargingstations/view/${props?.id}/billingplan`: {
        if (onlyAdmins.includes(role)) {
          setActiveTabIndex(5);
        } else {
          navigate("/404");
          }

        break;
      }
      case `/admin/chargingstations/view/${props?.id}/sessions`: {
        setActiveTabIndex(6);
        break;
      }
      case "/admin/chargingstations/new": {
        setActiveTabIndex(0);
        break;
      }
      default:
        navigate("/404");
        break;
    }
  }, [location.pathname]);

  const getChargePointQueryParams = () => {
    return `?chargepoint=${chargePointCode}&connector=${chargePointConnectorId}`;
  };

  return (
    <Tabs
      variant="soft-rounded"
      colorScheme="orange"
      isLazy
      index={activeTabIndex}
    >
      <TabList color="white" gap={5}>
        <Tab
          _focus={{boxShadow: "none"}}
          onClick={() => clickHandler("overview")}
        >
          Overview
        </Tab>
        {!isNewChargingStation && (
          <>
            <Tab
              _focus={{boxShadow: "none"}}
              onClick={() => clickHandler("photos")}
            >
              Photos
            </Tab>
            <Tab
              _focus={{boxShadow: "none"}}
              onClick={() => clickHandler("location")}
            >
              Location
            </Tab>
            <Tab
              _focus={{boxShadow: "none"}}
              onClick={() =>
                clickHandler("chargepoints", getChargePointQueryParams())
              }
            >
              Charge Points
            </Tab>
            <Tab
              _focus={{boxShadow: "none"}}
              onClick={() => clickHandler("ocpplogs")}
            >
              OCPP Logs
            </Tab>
            <RoleBasedRender roles={onlyAdmins}>
              <Tab
                _focus={{boxShadow: "none"}}
                onClick={
                  accessLevels[loggedInUser?.role]?.accessLevel <= 2
                    ? () => clickHandler("billingplan")
                    : null
                }
              >
                Billing Plan
              </Tab>
            </RoleBasedRender>

            <Tab
              _focus={{boxShadow: "none"}}
              onClick={() => clickHandler("sessions")}
            >
              Charging Sessions
            </Tab>
          </>
        )}
      </TabList>

      <TabPanels>
        <TabPanel>
          <StationOverView
            getStation={props?.getStation}
            data={props?.data ? props.data : null}
            id={props?.id ? props.id : null}
            buttonTitle={props?.id ? "Save" : "Create"}
          />
        </TabPanel>
        <TabPanel>
          <Photos stationId={props?.id} />
        </TabPanel>
        <TabPanel>
          <Location
            getStation={props?.getStation}
            id={props?.id}
            data={props?.data}
          />
        </TabPanel>
        <TabPanel>
          <ChargePointTabs id={props?.id} />
        </TabPanel>
        <TabPanel>
          <OcppLogs id={props?.id} />
        </TabPanel>
        {/* <RoleBasedRender roles={onlyAdmins}> */}
          <TabPanel>
            <BillingPlan id={props?.id ? props.id : null} />
          </TabPanel>
        {/* </RoleBasedRender> */}
        <TabPanel>
          <ChargingSessions id={props?.id ? props.id : null} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ChargingStationTabs;
