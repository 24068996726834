import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  SimpleGrid,
  Textarea,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import {useSetRecoilState} from "recoil";
import {useRecoilValue} from "recoil";
import {useNavigate} from "react-router-dom";
import axios from "axios";

import Card from "components/card/Card";
import {toastState} from "atoms/toastState";
import {Validation_MobileNumber} from "utils/validations";
import {Validation_Name} from "utils/validations";
import {Validation_Name_Req} from "utils/validations";
import {Validation_PinCode_Req} from "utils/validations";
import {Validation_MobileNumber_Req} from "utils/validations";
import {Validation_Address_Req} from "utils/validations";
import {Validation_Descrption_Req} from "utils/validations";
import {Validation_State_Req} from "utils/validations";
import {Validation_City} from "utils/validations";
import {Validation_City_Req} from "utils/validations";
import {Validation_Country_Req} from "utils/validations";
import {Validation_PinCode} from "utils/validations";
import {Validation_Address} from "utils/validations";
import {Validation_Descrption} from "utils/validations";
import {Validation_State} from "utils/validations";
import {Validation_Country} from "utils/validations";
import {StationInputFields} from "../variables/InputFields";
import {getGridRulesFromIndex} from "utils/utils";
import logger from "infra/logger";
import {loggedInUserState} from "atoms/loggedInUserState";
import {accessLevels} from "utils/accessLevels";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import {onlyAdmins} from "utils/accessLevels";

const inputFields = [
  "Name",
  "Description",
  "Address",
  "PhoneNumber",
  "PinCode",
  "City",
  "State",
  "Country",
];

const StationOverView = (props) => {
  const loggedInUser = useRecoilValue(loggedInUserState);
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [pinCode, setPinCode] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cities, setCities] = useState([]);

  // axios requests
  const [{d, loading, error}, createStation] = useAxios(
    {method: "POST", url: `/api/chargingStation/create`},
    {manual: true}
  );
  const [
    {d: updateData, loading: updateLoading, error: updateError},
    updateStaton,
  ] = useAxios(
    {method: "PATCH", url: `/api/chargingStation/update/${props?.id}`},
    {manual: true}
  );

  // validation schemas
  const updateSchema = yup.object().shape({
    Name: Validation_Name,
    PinCode: Validation_PinCode,
    PhoneNumber: Validation_MobileNumber,
    Address: Validation_Address,
    Description: Validation_Descrption,
    State: Validation_State,
    City: Validation_City,
    Country: Validation_Country,
  });
  const createSchema = yup.object().shape({
    Name: Validation_Name_Req,
    PinCode: Validation_PinCode_Req,
    PhoneNumber: Validation_MobileNumber_Req,
    Address: Validation_Address_Req,
    Description: Validation_Descrption_Req,
    State: Validation_State_Req,
    City: Validation_City_Req,
    Country: Validation_Country_Req,
  });

  const validationSchema = props?.id ? updateSchema : createSchema;

  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  // function to handle update or create

  const createHandler = async () => {
    const formValues = getValues();
    let data = {};
    if (formValues.Name.trim() !== "") {
      data["Name"] = formValues.Name.trim();
    }
    if (
      formValues.PinCode.trim() !== "" &&
      formValues.PinCode.trim().length === 6
    ) {
      data["PinCode"] = formValues.PinCode.trim();
    }
    if (
      formValues.PhoneNumber.trim() !== "" &&
      formValues.PhoneNumber.trim().length === 10
    ) {
      data["PhoneNumber"] = formValues.PhoneNumber.trim();
    }
    if (formValues.Address.trim() !== "") {
      data["Address"] = formValues.Address.trim();
    }
    if (formValues.Description.trim() !== "") {
      data["Description"] = formValues.Description.trim();
    }
    if (formValues.State.trim() !== "") {
      data["State"] = formValues.State.trim();
    }
    if (formValues.City.trim() !== "") {
      data["City"] = formValues.City.trim();
    }
    if (formValues.Country.trim() !== "") {
      data["Country"] = formValues.Country.trim();
    }
    if (!props?.id && Object.keys(data).length > 7) {
      try {
        const response = await createStation({data: data});
        setToast({
          isOpen: true,
          title: "ChargingStation Created successfully",
          status: "success",
        });
        //navigate to viiew cs
        navigate("/admin/chargingstations/list");
      } catch (error) {
        const err = error?.response?.data?.error;

        logger.log(error);
        setToast({
          isOpen: true,
          title: "Error occured when creating ChargingStation",
          description: err ? err : null,
          status: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        title: "Enter all the fields to create ChargingStation",
        status: "warning",
        position: "top",
      });
    }
  };

  const updateHandler = async () => {
    const formValues = getValues();
    let udata = {};
    if (
      formValues.Name.trim() !== "" &&
      formValues.Name.trim() !== props.data.Name
    ) {
      udata["Name"] = formValues.Name.trim();
    }
    if (
      formValues.PinCode.trim() !== "" &&
      formValues.PinCode.trim().length === 6 &&
      formValues.PinCode.trim() !== props.data.PinCode
    ) {
      udata["PinCode"] = formValues.PinCode.trim();
    }
    if (
      formValues.PhoneNumber.trim() !== "" &&
      formValues.PhoneNumber.trim().length === 10 &&
      "+91" + formValues.PhoneNumber.trim() !== props.data.PhoneNumber
    ) {
      udata["PhoneNumber"] = formValues.PhoneNumber.trim();
    }
    if (
      formValues.Address.trim() !== "" &&
      formValues.Address.trim() !== props.data.Address
    ) {
      udata["Address"] = formValues.Address.trim();
    }
    if (
      formValues.Description.trim() !== "" &&
      formValues.Description.trim() !== props.data.Description
    ) {
      udata["Description"] = formValues.Description.trim();
    }
    if (
      formValues.State.trim() !== "" &&
      formValues.State.trim() !== props.data.State
    ) {
      udata["State"] = formValues.State.trim();
    }
    if (
      formValues.City.trim() !== "" &&
      formValues.City.trim() !== props.data.City
    ) {
      udata["City"] = formValues.City.trim();
    }
    if (
      formValues.Country.trim() !== "" &&
      formValues.Country.trim() !== props.data.Country
    ) {
      udata["Country"] = formValues.Country.trim();
    }
    if (props?.id && Object.keys(udata).length > 0) {
      try {
        const response = await updateStaton({data: udata});
        setToast({
          isOpen: true,
          title: "ChargingStation updated successfully",
          status: "success",
        });
        await props.getStation();
      } catch (error) {
        const err = error?.response?.data?.error;
        setToast({
          isOpen: true,
          title: "Error occured when updating ChargingStation",
          description: err ? err : null,
          status: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        title: "Nothing new to update",
        status: "warning",
        position: "top",
      });
    }
  };

  // set city handler
  const cityHandler = (e) => {
    setSelectedCity(e.target.value);
    setValue("City", e.target.value);
  };

  const pinCodeHandler = (e) => {
    if (e.target.value.length === 6) {
      setPinCode(e.target.value);
    } else if (e.target.value.length > 6) {
      setPinCode("");
    }
  };

  const fetchPincodeData = async () => {
    const formValues = getValues();
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${formValues.PinCode}`
      );
      if (response.status === 200) {
        const data = response.data;
        if (data && data[0] && data[0]?.PostOffice && data[0]?.PostOffice[0]) {
          const postOffice = data[0].PostOffice[0];
          const cityNames = response.data[0].PostOffice.map(
            (postOffice) => postOffice.Name
          );
          setCities(cityNames);
          setValue("State", postOffice.State);
          setValue("Country", postOffice.Country);
        }
      } else {
        setCities([]);
      }
    } catch (error) {
      logger.log(error);
      setCities([]);
    }
  };

  useEffect(() => {
    if (pinCode.length === 6) {
      fetchPincodeData();
    }
    if (pinCode.length > 6) {
      setCities([]);
    }
  }, [pinCode]);

  useEffect(() => {
    if (props?.id && props?.data) {
      inputFields.forEach((fieldName) => {
        if (props?.data && props?.data[fieldName]) {
          if (fieldName === "PhoneNumber") {
            const str = props.data[fieldName];
            let phoneNumber = str.slice(-10); //use only last 10 characters
            setValue(fieldName, phoneNumber);
          } else {
            setValue(fieldName, props.data[fieldName]);
            if (fieldName === "PinCode") {
              fetchPincodeData();
            }
            if (fieldName === "City") {
              setSelectedCity(props.data[fieldName]);
            }
          }
        }
      });
    }
  }, [props?.data]);
  return (
    <Card w="100%" px="20px">
      <Box display="flex" justifyContent="center">
        <form>
          {/* <Grid mr="20px" columns={[1, 1, 1, 2, 3]} spacing={"40px"}> */}
          <Grid
            templateAreas={`"one one one one one one"
                            "two two two three three three"
                            "four four five five six six"
                            "seven seven eight eight - -"`}
            mr="20px"
            gridTemplateRows={"repeat(4)"}
            gridTemplateColumns={"repeat(6)"}
            gap={4}
          >
            {StationInputFields.map((item, index) => {
              const rules = getGridRulesFromIndex(index);
              return (
                <GridItem area={rules.area} key={index}>
                  <Flex
                    key={index}
                    mb="20px"
                    alignItems="center"
                    w={"100%"}
                    justifyContent={"center"}
                  >
                    <Controller
                      name={item.name}
                      control={control}
                      defaultValue=""
                      render={({field}) => (
                        <FormControl isRequired isInvalid={errors[item.name]}>
                          <FormLabel fontSize={20}>{item.label}</FormLabel>
                          {item.type == "city" ? (
                            <Select
                              value={selectedCity}
                              onChange={(e) => cityHandler(e)}
                              focusBorderColor="#f57d36"
                              fontSize="sm"
                              width="250px"
                              height="50px"
                            >
                              <option value="">Select a city</option>
                              {cities.map((city, index) => (
                                <option key={index} value={city}>
                                  {city}
                                </option>
                              ))}
                            </Select>
                          ) : item.label !== "Address" &&
                            item.label !== "Description" ? (
                            <Input
                              disabled={
                                item.label == "State" || item.label == "Country"
                              }
                              {...field}
                              focusBorderColor="#f57d36"
                              fontSize={rules?.fontSize || "sm"}
                              fontWeight={rules?.fontWeight || "unset"}
                              textAlign={rules?.textAlign || "unset"}
                              onInput={
                                item.label === "Pin code"
                                  ? (e) => pinCodeHandler(e)
                                  : null
                              }
                              placeholder={item.placeholder}
                              width="100%"
                              minW="250px"
                              height="50px"
                              type={item.type}
                              required
                              {...(item.type === "number"
                                ? {required: true}
                                : null)}
                            />
                          ) : (
                            <Textarea
                              {...field}
                              focusBorderColor="#f57d36"
                              placeholder={item.placeholder}
                              minHeight={40}
                              required
                            />
                          )}

                          <FormHelperText color={"#f57d36"}>
                            {item.helper}
                          </FormHelperText>
                          <FormErrorMessage mb={"20px"}>
                            {errors[item.name] && errors[item.name].message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  </Flex>
                </GridItem>
              );
            })}
          </Grid>
          {/* </SimpleGrid> */}
        </form>
      </Box>

      <Flex justifyContent="center">
        <RoleBasedRender roles={onlyAdmins}>
          <Button
            bgColor={"#f57d36"}
            _hover={{bgColor: "#d44e00"}}
            color="white"
            size="md"
            width="20%"
            loading={loading || updateLoading}
            onClick={
              props?.id
                ? handleSubmit(updateHandler)
                : handleSubmit(createHandler)
            }
          >
            {props.buttonTitle}
          </Button>
        </RoleBasedRender>
      </Flex>
    </Card>
  );
};

export default StationOverView;
