import {toastState} from "atoms/toastState";
import useAxios from "axios-hooks";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {TbCreditCardPay} from "react-icons/tb";

import logger from "infra/logger";
import {
  payoutRunTableHeaders,
  payRunTableHeaders,
} from "../variables/tableHeaders";
import {Box, Button, Card, Flex, Text} from "@chakra-ui/react";
import PayrunTable from "../components/PayRunTable";
import PayoutTable from "../components/PayoutTable";
import {getPreviousMonthNameAndYear} from "utils/utils";
import PayrunBox from "../components/PayrunBox";
// import PayRunDataTable fro../components/PayRunTableble";

const PayoutRun = () => {
  const [payoutsData, setPayoutsData] = useState({payrun: [], payouts: []});
  const [monthYear, setmonthYear] = useState(null);
  const setToast = useSetRecoilState(toastState);
  const [{data, loading, error}, getPayrun, cancelRequest] = useAxios(
    {
      method: "GET",
      url: `api/payrun?month=${monthYear?.monthNumber}&year=${monthYear?.year}`,
    },
    {manual: true}
  );
  const [
    {
      generatePayrunData,
      loading: generatePayrunLoading,
      error: generatePayrunError,
    },
    generatePayrun,
    cancelReq,
  ] = useAxios({method: "POST", url: "api/payrun/create"}, {manual: true});
  // const id = payrunData?.id
  // console.log(id)
  const [
    {data: approveData, loading: approveLoading, error: approveError},
    execute,
  ] = useAxios({}, {manual: true});
  const fetchPayrun = async () => {
    try {
      const response = await getPayrun();
      logger.log("response data is : ");
      logger.log(response?.data?.data);
      const payoutData = response?.data?.data;
      setPayoutsData((prev) => {
        return {
          ...prev,
          payrun: payoutData?.payrunData,
          payouts: [...payoutData?.payoutsList],
        };
      });
    } catch (error) {
      logger.log(error);
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "Error Occured",
          status: "error",
          description: error?.response?.data?.error,
        });
      }
    }
  };

  const generatePayRun = async () => {
    try {
      const response = await generatePayrun();
      fetchPayrun();
    } catch (error) {
      logger.log(error);
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "Error Occured",
          status: "error",
          description: error?.response?.data?.error,
        });
      }
    }
  };

  const approveHandler = async (status) => {
    try {
      const response = await execute({
        method: "PATCH",
        url: `api/payrun/update/${payoutsData?.payrun[0]?.Id}?status=${status}`,
      });
      setToast({
        isOpen: true,
        title: "Success",
        status: "success",
        description: response?.data?.message,
      });
      fetchPayrun();
    } catch (error) {
      logger.log(error);
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "Error Occured",
          status: "error",
          description: error?.response?.data?.error,
        });
      }
    }
  };
  const getLastMonthYear = () => {
    const prevMonthData = getPreviousMonthNameAndYear();
    setmonthYear((prev) => {
      return {
        ...prev,
        month: prevMonthData?.month,
        year: prevMonthData?.year,
        monthNumber: prevMonthData?.monthNumber,
      };
    });
  };
  useEffect(() => {
    getLastMonthYear();
  }, []);
  useEffect(() => {
    if (monthYear) {
      fetchPayrun();
      return () => {
        cancelRequest();
        cancelReq();
      };
    }
  }, [monthYear]);
  return (
    <Flex direction={"column"} gap={8} width={"100%"}>
      {payoutsData?.payrun?.length > 0 ? (
        <>
          <PayrunBox
            payrun={payoutsData?.payrun[0]}
            fetchPayrun={fetchPayrun}
          />
          {/* <PayrunTable
            columnsData={payRunTableHeaders}
            tableData={payoutsData?.payrun}
            approveHandler={approveHandler}
            fetchPayrun={fetchPayrun}
            generatePayRun={generatePayRun}
          /> */}
          <PayoutTable
            columnsData={payoutRunTableHeaders}
            tableData={payoutsData?.payouts}
          />
        </>
      ) : (
        <Card
          p={5}
          borderRadius={8}
          backgroundColor="white"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={"100vh"}
          maxHeight={"600px"}
          gap={10}
        >
          <Text fontSize="30px" fontWeight="900" textAlign="center">
            {`Payouts for ${monthYear?.month} ${monthYear?.year}`}
          </Text>
          <Button
            borderRadius={8}
            colorScheme="blue"
            width={"40%"}
            maxW={"300px"}
            alignItems="center"
            onClick={generatePayRun}
            fontSize={"18px"}
            isLoading={generatePayrunLoading}
            leftIcon={<TbCreditCardPay size={30} />}
            bg={"#f57d36"}
            py={8}
          >
            Generate
          </Button>
        </Card>
      )}
    </Flex>
  );
};

export default PayoutRun;
