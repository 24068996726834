import {
    Button,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
  } from "@chakra-ui/react";
  import React, {useMemo, useState} from "react";
  import {useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
  
  // Custom components
  import Card from "components/card/Card";
  
  import {useRecoilState, useRecoilValue} from "recoil";
  import DeleteModal from "components/modals/DeleteModal";
  import {deleteModalState} from "atoms/deleteModalState";
  import {loggedInUserState} from "atoms/loggedInUserState";
  import {accessLevels} from "utils/accessLevels";
import { updateSourceDrawerState } from "atoms/updateSourceDrawerState";
import { createSourceDrawerState } from "atoms/createSourceDrawerState";
import CreateSourceDrawer from "./CreateSourceDrawer";
import UpdateSourceDrawer from "./UpdateSourceDrawer";
  // import Menu from "components/menu/MainMenu";
  
  export default function SourceColumnsTable(props) {
    const {columnsData, tableData, getSources,responseData} = props;
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);
    const loggedInUser = useRecoilValue(loggedInUserState);
    const role = loggedInUser?.role;
    const canEdit =
      accessLevels[role]?.accessLevel <= 2 || role === "urzza_cms_admin";
    const [updateDrawerState, setUpdateDrawerState] = useRecoilState(
      updateSourceDrawerState
    );
    const [createDrawerState, setCreateDrawerState] = useRecoilState(
      createSourceDrawerState
    );
  
    const tableInstance = useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
    } = tableInstance;
    initialState.pageSize = 8;
  
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  
    const [showDeleteModal, setShowDeleteModal] =
      useRecoilState(deleteModalState);
    const [selectedSource, setSelectedSource] = useState(null);
  
  
    const handleDeleteClick = (source) => {
      setSelectedSource(source);
      setShowDeleteModal(true);
    };
    const updateHandler = () => {
      setUpdateDrawerState(null);
      setCreateDrawerState(null);
      getSources();
    };
  
    const handleConfirmDelete = () => {
      setShowDeleteModal(false);
      // getSources();
    };
  
    const handleCancelDelete = () => {
      setSelectedSource(null);
      setShowDeleteModal(false);
    };
  
    const editHandler = (id) => {
      setUpdateDrawerState({isOpen: true, activeId: id});
    };
  
  
    return (
     <Card direction="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }} overflowY={"hidden"}>
    <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
      <Thead>
        {headerGroups.map((headerGroup, index) => (
          <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, index) => (
              <Th {...column.getHeaderProps(column.getSortByToggleProps())} key={index} borderColor={borderColor}>
                <Flex justify="center" align="center" fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
                  {column.render("Header")}
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()} key={index}>
              {row.cells.map((cell, index) => {
                let data = "";
                if (cell.column.Header === "S.No") {
                  data = (
                    <Flex align="center" justify={"center"}>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {Number(cell.row.id) + 1}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "Name") {
                  data = (
                    <Flex align="center" justify={"center"}>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.row.original.Name}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "Partner Id") {
                  data = (
                    <Flex align="center" justify={"center"}>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.row.original.PartnerId}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "Actions") {
                  data = (
                    <>
                     <Flex align="center" justify={"center"}>
                      <Button colorScheme="blue" variant="ghost" onClick={() => editHandler(cell.row.original.Id)}>
                        Edit
                      </Button>
                      <Button colorScheme="red" variant="ghost" onClick={() => handleDeleteClick(cell.row.original)}>
                        Delete
                      </Button>
                    </Flex>
                    </>
                  );
                }
                return (
                  <Td
                    {...cell.getCellProps()}
                    key={index}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor="transparent"
                  >
                    {data}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>

    <DeleteModal
      isOpen={showDeleteModal}
      onClose={handleCancelDelete}
      onConfirm={handleConfirmDelete}
      data={selectedSource}
      title="source"
    />
    {createDrawerState?.isOpen && <CreateSourceDrawer update={updateHandler} />}
    {updateDrawerState?.isOpen && <UpdateSourceDrawer update={updateHandler} />}
  </Card>
);
};