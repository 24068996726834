const ChargePointsVisibility = [
  { name: "Public", value: "Public" },
  { name: "Private", value: "Private" },
];
const ChargePointsTypeOfCharger = [
  { name: "AC Charger", value: "AC" },
  { name: "DC Charger", value: "DC" },
];

export const chargePointDrawerSelectOptions = [
  {
    label: "Name",
    placeholder: "Name",
    name: "Name",
    type: "input",
  },
  {
    label: "Code",
    placeholder: "Code",
    name: "Code",
    type: "input",
  },
  {
    label: "Description",
    placeholder: "Description",
    name: "Description",
    type: "input",
  },
  {
    label: "Visibility",
    placeholder: "Visibility",
    name: "Visibility",
    type: "select",
    options: ChargePointsVisibility,
  },
  {
    label: "Type of Charger",
    placeholder: "Type of Charger",
    name: "TypeOfCharger",
    type: "select",
    options: ChargePointsTypeOfCharger,
  },
  {
    label: "Power",
    placeholder: "Power",
    name: "Power",
    type: "input",
  },
];

export const createChargePointDrawerSelectOptions = [
  {
    label: "Name",
    placeholder: "Name",
    name: "Name",
    type: "input",
  },
  {
    label: "Code",
    placeholder: "Code",
    name: "Code",
    type: "input",
  },
  {
    label: "Tariff",
    placeholder: "Tariff",
    name: "Tariff",
    type: "input",
  },
  {
    label: "Description",
    placeholder: "Description",
    name: "Description",
    type: "input",
  },
  {
    label: "Visibility",
    placeholder: "Visibility",
    name: "Visibility",
    type: "select",
    options: ChargePointsVisibility,
  },
  {
    label: "Type of Charger",
    placeholder: "Type of Charger",
    name: "TypeOfCharger",
    type: "select",
    options: ChargePointsTypeOfCharger,
  },
  {
    label: "Power",
    placeholder: "Power",
    name: "Power",
    type: "input",
  },
]
