import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { FaChargingStation } from "react-icons/fa";
import { BsCloudLightningFill } from "react-icons/bs";
import { BiSolidErrorCircle } from "react-icons/bi";
import { formattedIstDate } from "utils/utils";
const Log = ({ data }) => {
  const chargePointColor = "blue.400";
  const centralSystemColor = "orange.500";
  const errorColor = "red.600";
  const cs = data.Origin === "cs";
  const cp = data.Origin === "cp";
  const error = data.Level === "error";

  return (
    <Flex
      color={"white"}
      justifyContent={"space-between"}
      alignItems={"flex-start"}
      mb={5}
    >
      <Text ml={"5"} width={"30%"}>
        {formattedIstDate(data.Time)}
      </Text>

      <Icon
        w={6}
        h={6}
        as={
          error
            ? BiSolidErrorCircle
            : cs
            ? BsCloudLightningFill
            : FaChargingStation
        }
        mr={5}
        color={error ? errorColor : cp ? chargePointColor : centralSystemColor}
      />

      <Text
        color={error ? errorColor : cp ? chargePointColor : centralSystemColor}
        width={"60%"}
      >
        {data.Log}
      </Text>
    </Flex>
  );
};

export default Log;
