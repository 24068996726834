import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  SimpleGrid,
  Text,
  Wrap,
} from "@chakra-ui/react";
import {yupResolver} from "@hookform/resolvers/yup";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {MdMessage} from "react-icons/md";
import * as yup from "yup";
import useAxios from "axios-hooks";
import {useRecoilValue, useSetRecoilState} from "recoil";

import { activeConnectorState } from "atoms/activeConnectorState";
import {toastState} from "atoms/toastState";
import Card from "components/card/Card";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import logger from "infra/logger";
//import { TbMessage } from "react-icons/tb";

const RemoteStart = ({ refresh }) => {
  const activeConnector = useRecoilValue(activeConnectorState)
  const setToast = useSetRecoilState(toastState);
  const [{data, loading, error}, execute] = useAxios(
    {method: "POST", url: "/api/ocpp16/charging/start"},
    {manual: true}
  );
  const validationSchema = yup.object().shape({
    IdTag: yup.string().required("Please enter IdTag"),
  });

  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const controllers = [
    {
      name: "IdTag",
      label: "IdTag",
      type: "input",
      helperText: "Enter IdTag/MobileNumber",
    },
  ].map((ele, index) => {
    return (
      <Controller
        key={index}
        name={ele.name}
        control={control}
        render={({field}) => (
          <FormControl as="fieldset" maxW={200} isInvalid={errors[ele.name]}>
            <FormLabel as="legend" fontSize={18}>
              {ele.label}
            </FormLabel>
            {ele.type === "input" && (
              <Input
                required
                maxLength={10}
                placeholder="Enter IdTag"
                {...field}
                type="text"
              />
            )}
            <FormHelperText>{ele?.helperText}</FormHelperText>
            <FormErrorMessage mb={"20px"}>
              {errors[ele.name] && errors[ele.name].message}
            </FormErrorMessage>
          </FormControl>
        )}
      />
    );
  });

  const operation = async () => {
    // const formValues = getValues();
    let body = {};
    body["ConnectorId"] = activeConnector.Code;
    body["ChargerId"] = activeConnector.ChargePointCode;
    body["IdTag"] = '.';
    try {
      const response = await execute({data: body});
      setToast({
        isOpen: true,
        title: "Charging Started Successfully",
        description: response.data.data.Message,
        status: "success",
      });
    } catch (error) {
      const err = error?.response?.data?.error;
      logger.log(error);
      setToast({
        isOpen: true,
        title: "Error occured when starting Charger",
        description: err ? err : null,
        status: "error",
      });
    }
    refresh();
  };
  return loading ? (
    <LoadingSpinner />
  ) : (
    <Card
      alignItems="center"
      justifyContent="center"
      position="relative"
      height="100%"
      width="100%"
    >
      <Text fontWeight={700} fontSize={20} borderBottom="1px solid black" position={"absolute"} top={0} >
        Remote Start
      </Text>
      <Card alignItems="center" justifyContent="center">
          {/* <SimpleGrid columns={{sm: 2, md: 2, lg: 3}} spacing={5} mb={5}>
            {controllers}
          </SimpleGrid> */}
          <Button
            borderRadius={5}
            textColor={"white"}
            backgroundColor="#f57d36"
            _hover={{backgroundColor: "whatsapp.600"}}
            rightIcon={<MdMessage />}
            // onClick={handleSubmit(operation)}
            onClick={operation}
          >
            Send Message
          </Button>
      </Card>
    </Card>
  );
};

export default RemoteStart;
