import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useAxios from "axios-hooks";

import SettlementCard from "components/card/SettlementCard";
import logger from "infra/logger";
import { toastState } from "atoms/toastState";
import { useSetRecoilState } from "recoil";

const Settlement = () => {
  const [transactionsData, setTransactionsData] = useState([]);
  const setToast = useSetRecoilState(toastState);
  const [{ data, loading, error }, getTransactions, cancelRequest] = useAxios(
    { method: "GET", url: "/api/transaction/pending/payout_amounts" },
    { manual: true }
  );
  const fetchUnsettledTransactions = async () => {
    try {
      const response = await getTransactions();
      setTransactionsData(response?.data?.data);
      console.log("response", response?.data?.data);
    } catch (error) {
      logger.log(error);
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "Error Occured",
          status: "error",
          description: error?.response?.data?.error,
        });
      }
    }
  };
  useEffect(() => {
    fetchUnsettledTransactions();
    return () => cancelRequest();
  }, []);
  return (
    <Flex
      direction="row"
      alignItems="center"
      justifyContent="space-around"
      w="100%"
      mb={5}
      gap={10}
      wrap="wrap"
      borderRadius={8}
      px={5}
      py={2}
    >
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        boxShadow="3px 3px 10px 3px #dddddd"
        borderRadius={12}
        w="40%"
      >
        <SettlementCard
          title="Gross Amount"
          value={transactionsData?.GrossAmount || 0}
          type="amount"
          colorScheme="orange"
          width="100%"
        />
      </Flex>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        boxShadow="3px 3px 10px 3px #dddddd"
        borderRadius={12}
        position="relative"
        overflow="hidden"
        w="40%"
      >
        <SettlementCard
          title="Net Amount"
          value={transactionsData?.NetAmount || 0}
          type="amount"
          colorScheme="green"
          width="100%"
        />
      </Flex>
    </Flex>
  );
};

export default Settlement;
