import {Icon} from "@chakra-ui/react";
import {useColorModeValue} from "@chakra-ui/system";
import React from "react";
import { FaRupeeSign } from "react-icons/fa";

import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";

const TotalAmount = ({stats}) => {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

//   const [duration, setDuration] = useState("");
  

  return (
    <MiniStatistics
      startContent={
        <IconBox
          w="86px"
          h="86px"
          bg={boxBg}
          icon={
            <Icon w="32px" h="32px" as={FaRupeeSign} color={brandColor} />
          }
        />
      }
      name="Total Amount"
      value={stats?.totalAmount}
    />
  );
};

export default TotalAmount;
