import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  defaultStandaloneParam,
  useColorModeValue,
} from "@chakra-ui/react";
import React, {useMemo, useState} from "react";
import {useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";

// Custom components
import Card from "components/card/Card";
import {useRecoilState, useRecoilValue} from "recoil";
import {createRfidDrawerState} from "atoms/createRfidDrawerState";
import {updateRfidDrawerState} from "atoms/updateRfidDrawerState";
import CreateRfidDrawer from "./CreateRfidDrawer";
import {deleteModalState} from "atoms/deleteModalState";
import DeleteModal from "components/modals/DeleteModal";
import UpdateRfidDrawer from "./UpdateRfidDrawer";
import {loggedInUserState} from "atoms/loggedInUserState";
import {accessLevels} from "utils/accessLevels";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import { onlyAdmins } from "utils/accessLevels";
//import Menu from "components/menu/MainMenu";

const RfidColumnsTable = (props) => {
  const {columnsData, tableData, getRfids} = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const role = loggedInUser?.role;
  const canEdit = accessLevels[role]?.accessLevel <= 2;

  const [updateDrawerState, setUpdateDrawerState] = useRecoilState(
    updateRfidDrawerState
  );
  const [createDrawerState, setCreateDrawerState] = useRecoilState(
    createRfidDrawerState
  );
  const [selectedRfid, setSelectedRfid] = useState(null);
  const [showDeleteModal, setShowDeleteModal] =
    useRecoilState(deleteModalState);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 8;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const handleDeleteClick = (rfid) => {
    setSelectedRfid(rfid);
    setShowDeleteModal(true);
  };

  const updateHandler = () => {
    setUpdateDrawerState(null);
    setCreateDrawerState(null);
    getRfids();
  };
  const handleConfirmDelete = () => {
    setShowDeleteModal(false);
    getRfids();
  };

  const handleCancelDelete = () => {
    setSelectedRfid(null);
    setShowDeleteModal(false);
  };

  const editHandler = (id) => {
    setUpdateDrawerState({isOpen: true, activeId: id});
  };

  return (
    <Card
      // maxW="lg"
      direction="column"
      w="100%"
      px="0px"
      overflowX={{sm: "scroll", lg: "hidden"}}
    >
      {/* <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Menu />
      </Flex> */}
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="center"
                    align="center"
                    fontSize={{sm: "10px", lg: "12px"}}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  const target = cell.row.original;
                  let data = "";
                  if (cell.column.Header === "S.No") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {Number(cell.row.id) + 1}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Id") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text
                          me="10px"
                          color={textColor}
                          fontSize="sm"
                          fontWeight="700"
                        >
                          {target.Id}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "IdTag") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {target.idTag}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Email") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {target.email}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "PhoneNumber") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {target.mobileNumber}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Balance") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {target.balance}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Status") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Text
                          color={"white"}
                          fontSize="sm"
                          fontWeight="700"
                          bgColor={target.isActive ? "whatsapp.500" : "red"}
                          textAlign={"center"}
                          px={1}
                          py={1}
                          borderRadius={5}
                        >
                          {target.isActive ? "Active" : "InActive"}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Actions") {
                    data = (
                      <Flex align="center" justify={"center"}>
                        <Button
                          colorScheme="blue"
                          variant="ghost"
                          onClick={() => editHandler(target.Id)}
                          isDisabled={!canEdit}
                        >
                          View
                        </Button>
                        <RoleBasedRender roles={onlyAdmins}>
                          <Button
                            colorScheme="red"
                            variant="ghost"
                            onClick={() => handleDeleteClick(target)}
                            isDisabled={!canEdit}
                          >
                            Delete
                          </Button>
                        </RoleBasedRender>
                      </Flex>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{sm: "14px"}}
                      minW={{sm: "150px", md: "200px", lg: "auto"}}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        data={selectedRfid}
        title="rfid"
      />
      {updateDrawerState && <UpdateRfidDrawer update={updateHandler} />}
      {createDrawerState && <CreateRfidDrawer update={updateHandler} />}
    </Card>
  );
};

export default RfidColumnsTable;
