import React, {useEffect, useState} from "react";
import {
  Radio,
  RadioGroup,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  FormHelperText,
  Tag,
  VStack,
  Input,
  FormErrorMessage,
  Button,
} from "@chakra-ui/react";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAxios from "axios-hooks";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {FaChargingStation} from "react-icons/fa";

import {toastState} from "atoms/toastState";
import {activeConnectorState} from "atoms/activeConnectorState";
import logger from "infra/logger";
import { loggedInUserState } from "atoms/loggedInUserState";
import { accessLevels } from "utils/accessLevels";

const ConnectorsData = [
  {
    name: "mode",
    type: "radio",
    label: "Select Mode",
    helperText: "Select Mode of your Charging",
    options: [
      {name: "Money", Value: "Money"},
      {name: "Units", Value: "Units"},
      {name: "Time", Value: "Time"},
    ],
  },
  {
    name: "value",
    type: "input",
    label: "Value",
    helperText: "Enter Value in Rupees or Minutes or Units",
  },
  // {
  //   name: "mobileNumber",
  //   type: "input",
  //   label: "Enter Mobile Number",
  //   helperText: "please enter your mobile number",
  // },
];
const StartCharging = ({refresh}) => {
  const activeConnector = useRecoilValue(activeConnectorState);
  const [mode, setMode] = useState("Money");
  const loggedInUser = useRecoilValue(loggedInUserState);
const role = loggedInUser?.role;
const canEdit = accessLevels[role]?.accessLevel <= 2;
  const setToast = useSetRecoilState(toastState);
  const [{data, loading, error}, execute] = useAxios(
    {method: "POST", url: `/api/ocpp16/charging/start`},
    {manual: true}
  );

  const validationSchema = yup.object().shape({
    mode: yup.string().required("Mode is Required"),
    value: yup.number().moreThan(0).required(""),
    // mobileNumber: Validation_MobileNumber_Req,
  });

  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const controllers = ConnectorsData.map((ele, i) => {
    return (
      <Controller
        key={i}
        name={ele.name}
        control={control}
        render={({field}) => (
          <FormControl
            as="fieldset"
            ml={10}
            maxW={300}
            isInvalid={errors[ele.name]? true : false}
          >
            <FormLabel as="legend" fontSize={18}>
              {ele.label}
            </FormLabel>
            {ele.type === "radio" && (
              <RadioGroup {...field} defaultValue="Money" required isDisabled={!canEdit}> 
                <HStack spacing="24px">
                  <Radio value="money" disabled={!canEdit}>Money</Radio>
                  <Radio value="units" disabled={!canEdit}>Units</Radio>
                  {/* <Radio value="time">Time</Radio> */}
                </HStack>
              </RadioGroup>
            )}
            {ele.type === "input" && (
              <Input
                required
                disabled={!canEdit}
                maxLength={10}
                placeholder={
                  // ele.name === "mobileNumber" ? "Mobile Number" : "Value"
                  "Value"
                }
                {...field}
                type="number"
              />
            )}
            <FormHelperText>{ele?.helperText}</FormHelperText>
            <FormErrorMessage mb={"20px"}>
              {errors[ele.name] && errors[ele.name].message}
            </FormErrorMessage>
          </FormControl>
        )}
      />
    );
  });
  const startCharging = async () => {
    const formValues = getValues();
    let body = {};
    body["ConnectorId"] = activeConnector.Code;
    body["ChargerId"] = activeConnector.ChargePointCode;
    body["IdTag"] = `${formValues.mobileNumber}`;
    body["Mode"] = formValues.mode;
    body["Value"] = parseFloat(formValues.value);
    body["RequestSource"] = "CMSUI";
    try {
      const response = await execute({data: body});
      setToast({
        isOpen: true,
        title: "Charging Started Successfully",
        description: response.data.data.Message,
        status: "success",
      });
    } catch (error) {
      const err = error?.response?.data?.error;
      logger.log(error);
      setToast({
        isOpen: true,
        title: "Error occured when starting Charger",
        description: err ? err : null,
        status: "error",
      });
    }
    refresh();
  };
  useEffect(() => {
    setValue("mode", "Money");
    setValue("value", 0);
  }, []);
  return (
    <Flex
      direction={"row"}
      ml={5}
      flexWrap={"wrap"}
      gap={5}
      alignItems={"center"}
    >
      {controllers}
      <Button
        disabled={activeConnector?.Status !== "Preparing" ? true : false}
        variant="solid"
        colorScheme="green"
        ml={8}
        onClick={handleSubmit(startCharging)}
        borderRadius={8}
        leftIcon={<FaChargingStation size={20} />}
        isDisabled={!canEdit}
      >
        Start Charging
      </Button>
    </Flex>
  );
};

export default StartCharging;
