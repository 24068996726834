import {useToast} from "@chakra-ui/react";
import {toastState} from "atoms/toastState";
import {useEffect} from "react";
import {useRecoilState} from "recoil";

const ToastNotification = () => {
  const toast = useToast();
  const [toastData, setToastData] = useRecoilState(toastState);
  const closeToast = () => {
    setToastData({});
  };

  useEffect(() => {
    if (toastData?.isOpen) {
      toast({
        title: toastData.title,
        status: toastData.status,
        description: toastData?.description,
        duration: 2000,
        isClosable: true,
        position: toastData?.position || "bottom",
        onCloseComplete: () => closeToast(),
      });
      return () => closeToast();
    }
  }, [toastData]);

  return null;
};

export default ToastNotification;
