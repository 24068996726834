import { Box, Flex, Select, Text, useColorModeValue } from "@chakra-ui/react";
import { format, subMonths } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import useAxios from "axios-hooks";

import Card from "components/card/Card.js";
import logger from "infra/logger";
import { weeklyDowntimeBar } from "variables/charts";
import { dailyDowntimeBar } from "variables/charts";

const WeeklyDowntimeReport = (props) => {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const { ...rest } = props;
  const chartRef = useRef(null);

  const [monthLists, setMonthLists] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [updatedOption, setUpdatedOption] = useState(dailyDowntimeBar);

  // const [revenueMonth, setRevenueMonth] = useState(0);
  const [{ data, loading, error }, execute, cancelRequest] = useAxios(
    {
      method: "GET",
      url: `/api/dashboard/analytics?key=weekly_downtime_report_trend&cp=${
        props?.chargePointCode == "all" ? "" : props?.chargePointCode
      }&year=${selectedDate?.year}&month=${selectedDate?.monthNum + 1}`,
    },
    { manual: true }
  );

  const getWeeklyDowntime = async () => {
    try {
      const response = await execute();
      const weeklyData = response?.data?.data;
      console.log("weeklyData", weeklyData);
      const legendData = weeklyData?.map((ele) => {
        return ele.station_name;
      });
      const seriesData = weeklyData?.map((item) => {
        const itemDowntime = item.total_downtime.map((ele) => {
          return (ele.total_downtime / 60).toFixed(3); // in minutes
        });
        return {
          name: item.station_name,
          data: itemDowntime,
          type: "bar",
          smooth: true,
          barWidth: 20,
          stack: "stacked",
        };
      });
      const xAxisData = weeklyData[0]?.total_downtime.map((ele) => {
        return "Week " + ele.week_number;
      });
      const adjustedBottomPadding = Math.max(
        80,
        Math.ceil(legendData.length / 5) * 30
      );
      setUpdatedOption((prev) => {
        const newSeries =
          weeklyData?.length === 1
            ? prev.series.map((element) => {
                let iSessions = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                if (element?.name === seriesData[0]?.name) {
                  iSessions = seriesData[0]?.data;
                }
                return {
                  ...element,
                  data: iSessions,
                };
              })
            : seriesData;
        return {
          ...prev,
          xAxis: {
            ...prev.xAxis,
            data: xAxisData,
          },
          grid: {
            ...prev.grid,
            bottom: adjustedBottomPadding,
          },
          legend: {
            ...prev.legend,
            data: legendData,
          },
          series: newSeries,
        };
      });
    } catch (error) {
      logger.log("Error in getWeeklyDowntimeReport:", error);
    }
  };

  const generateMonthList = () => {
    const currentDate = new Date();
    const currentMonthYear = {
      month: format(currentDate, "MMM"),
      year: format(currentDate, "yyyy"),
      monthNum: currentDate.getMonth(),
    };
    const monthList = [currentMonthYear];
    for (let i = 1; i < 12; i++) {
      const previousMonth = subMonths(currentDate, i);

      monthList.push({
        month: format(previousMonth, "MMM"),
        year: format(previousMonth, "yyyy"),
        monthNum: previousMonth.getMonth(),
      });
    }

    setMonthLists(monthList.reverse());
  };

  const monthChangeHandler = (event) => {
    const data = JSON.parse(event.target.value);
    const index = monthLists.findIndex(
      (ele) => ele.month === data.month && ele.year === data.year
    );
    setSelectedDate(monthLists[index]);
  };

  useEffect(() => {
    generateMonthList();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      getWeeklyDowntime();
      return () => cancelRequest();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (props?.chargePointCode) {
      const timeout = setTimeout(() => {
        if (selectedDate) {
          getWeeklyDowntime();
        }
      }, 400);
      return () => {
        clearTimeout(timeout);
        cancelRequest();
      };
    }
  }, [props?.chargePointCode, props?.refresh]);

  useEffect(() => {
    if (monthLists.length > 0) {
      const currentDate = new Date();
      const currentMonth = format(currentDate, "MMM");
      const currentYear = format(currentDate, "yyyy");
      const index = monthLists.findIndex(
        (ele) => ele.month === currentMonth && ele.year === currentYear
      );
      setSelectedDate(monthLists[index]);
      return () => cancelRequest();
    }
  }, [monthLists]);

  return (
    <Card
      justifyContent="space-between"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      {...rest}
    >
      <Flex
        align="center"
        justifyContent="space-between"
        w="100% "
        ps="0px"
        pe="20px"
        pt="5px"
      >
        <Text
          color={textColor}
          fontSize="xl"
          textAlign="start"
          fontWeight="700"
          lineHeight="100%"
        >
          Weekly Downtime Report
        </Text>
        <Select
          value={JSON.stringify(selectedDate)}
          onChange={(e) => monthChangeHandler(e)}
          size="md"
          width="150px"
        >
          {monthLists.map((dailySessions, index) => {
            return (
              <option key={index} value={JSON.stringify(dailySessions)}>
                {dailySessions.month}, {dailySessions.year}
              </option>
            );
          })}
        </Select>
      </Flex>
      <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
        <Box minH="260px" minW="95%" mt="auto" ml="auto" mr="auto">
          <ReactECharts ref={chartRef} option={updatedOption} />
        </Box>
      </Flex>
    </Card>
  );
};

export default WeeklyDowntimeReport;
