import React, { useState } from "react";
import { DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";


import { useRecoilState } from "recoil";
import { viewTenantsDrawerState } from "atoms/viewTenantsDrawer";
import Card from "components/card/Card";
import ViewTenants from "./ViewTenants";
import CreateTenants from "./CreateTenants";
import { createTenantDrawerState } from "atoms/createTenantDrawerState";
import { updateTenantDrawerState } from "atoms/updateTenantDrawerState";
import { tenantTableHeaders } from "../variables/tenantTableHeaders";

const TenantsList = (props) => {
  const [viewTenantsState, setViewTenantsState] = useRecoilState(viewTenantsDrawerState);
  const [createDrawerState, setCreateDrawerState] = useRecoilState(createTenantDrawerState);
  const [updateDrawerState, setUpdateDrawerState] = useRecoilState(updateTenantDrawerState);

  const {
    tenants = [],
    verificationStatus,
    setVerificationStatus,
    // actionButtonsHandler,
    getTenants,
    setTenants,
  } = props;
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const tenantHeaderStyle = {
    color: "gray.400",
    textAlign: "center",
  };

  const handleDownload = (url) => {
    window.open(url, "_blank");
  };

  const viewHandler = (tenant) => {
    setViewTenantsState({
      isOpen: true,
      data: tenant,
    });
  };

  const updateHandler = () => {
    setUpdateDrawerState(null);
    setCreateDrawerState(null);
    getTenants();
  };


  return (
    <>
      <Card
        direction="column"
        w="100%"
        px="0px"
        marginBottom="20px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex justify="flex-end" align="center" mb={3} gap={5} px={10}>
          <Box>
            <FormControl>
              <Select
                variant="outline"
                value={verificationStatus}
                marginRight={5}
                color={"gray.500"}
                onChange={(e) => setVerificationStatus(e.target.value)}
              >
                <option value="all">All</option>
                <option value="not_initiated">Not Initiated</option>
                <option value="pending">Pending</option>
                <option value="verified">Success</option>
                <option value="rejected">Rejected</option>
              </Select>
            </FormControl>
          </Box>
          <Button borderRadius={5} colorScheme="blue" onClick={getTenants}>
            Search
          </Button>
        </Flex>
      </Card>
      <Card direction="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <TableContainer>
          <Table variant="simple" color="secondaryGray.900" fontWeight={"700"} mb="24px">
            <Thead>
              <Tr>
                {tenantTableHeaders.map((item, index) => {
                  return (
                    <Th {...tenantHeaderStyle} key={index}>
                      {item.Header}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody style={{ marginTop: "8px" }} textAlign="center" justifyContent={"center"}>
              {tenants.map((tenant, index) => {
                let status = tenant?.VerificationStatus;
                let color = "black.500";
                let statusText = "";
                switch (status) {
                  case "verified":
                    statusText = "Verified";
                    color = "green.300";
                    break;
                  case "rejected":
                    statusText = "Rejected";
                    color = "red.300";
                    break;
                  case "pending":
                    statusText = "Pending";
                    color = "orange.300";
                    break;
                  case "not_initiated":
                    statusText = "Not Initiated";
                    color = "blue.300";
                    break;
                  default:
                    statusText = "N/A";
                    color = "gray.300";
                    break;
                }
                return (
                  <Tr key={tenant.Id}>
                    <Td textAlign={"center"}>{index + 1}</Td>
                    <Td textAlign={"center"}>{tenant?.FirstName + " " + tenant?.LastName}</Td>
                    <Td textAlign={"center"}>{tenant?.NameSpace}</Td>
                    <Td textAlign={"center"}>{tenant?.PhoneNumber}</Td>
                    <Td textAlign={"center"}>{tenant?.PrimaryEmail}</Td>
                    <Td textAlign={"center"}>{tenant?.Type === "cpo" ? "CPO" : tenant?.Type === "bco" ? "BCO" : tenant?.Type === "cpo_operated_by_urzza" ? "CPO Operated By Urzza" : "NA"}</Td>
                    <Td textAlign={"center"}>
                      <Text
                        style={{ color: "white" }}
                        borderRadius={4}
                        fontSize="xs"
                        fontWeight={"medium"}
                        textAlign={"center"}
                        p={1}
                        backgroundColor={color}
                      >
                        {statusText}
                      </Text>
                    </Td>
                    <Td textAlign={"center"}>
                      <Button
                        size="sm"
                        leftIcon={<DownloadIcon />}
                        bg="blue.700"
                        color={"white"}
                        _hover={{ bg: "blue.600" }}
                        borderRadius={5}
                        onClick={() => handleDownload(tenant?.UnderTakingDocumentUrl)}
                      >
                        Download
                      </Button>
                    </Td>
                    <Td textAlign={"center"}>
                      <Flex align="center" justify={"center"}>
                        {/* {tenant?.VerificationStatus !== "verified" && (
                          <>
                            <Button
                              colorScheme="green"
                              variant="ghost"
                              onClick={() => actionButtonsHandler("verified", tenant?.Id)}
                            >
                              Verify
                            </Button>
                            <Button
                              colorScheme="red"
                              variant="ghost"
                              onClick={() => actionButtonsHandler("rejected", tenant?.Id)}
                            >
                              Reject
                            </Button>
                          </>
                        )} */}
                        <Button
                          colorScheme="blue"
                          variant="ghost"
                          onClick={() => viewHandler(tenant)}
                          isDisabled={tenant?.Role === "urzza_cms_admin"}
                        >
                          Edit
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        {createDrawerState?.isOpen && <CreateTenants update={updateHandler} />}
        {viewTenantsState?.isOpen && (
          <ViewTenants
           getTenants={getTenants}
            tenantsData={viewTenantsState?.data}
            onClose={() =>
              setViewTenantsState({
                isOpen: false,
                data: null,
              })
            }
          />
        )}
        {tenants.length === 0 && (
          <Card p={4} mb={4}>
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
              bg={"white"}
              // boxShadow={"base"}
              // padding={60}
            >
              No Tenants
            </Flex>
          </Card>
        )}
      </Card>
    </>
  );
};

export default TenantsList;
