// Chakra imports
import { Box, Flex, Text, Image, color } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Slider from "react-slick";

import Analytics1 from "../../assets/img/Analytics1.svg";
import Analytics2 from "../../assets/img/Analytics2.svg";
import Analytics3 from "../../assets/img/Analytics3.svg";
import Analytics4 from "../../assets/img/Analytics4.svg";
import './../../assets/css/Authillustration.css';


function AuthIllustration(props) {
  const { children } = props;

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    pauseOnHover: true,
  };

  const slides = [
    {
      image: Analytics1,
      title: "Centralized Command Center",
      description:
        "Power at Your FingerTips - Total Control Over Your Charging Stations ",
      height: "80%",
      width: "65%",
    },
    {
      image: Analytics2,
      title: "Advanced Technological Integration",
      description:
        "Future-Ready Charging: Advanced Tech for Tomorrow’s Vehicles (OCPP 2.1 and OCPI Compatible)",
      height: "170%",
      width: "120%",
    },
    {
      image: Analytics3,
      title: " Performance Analytics",
      description: "Insightful Analytics: Performance Tracking Made Easy",
      height: "80%",
      width: "90%",
    },
    {
      image: Analytics4,
      title: "Real-Time Notifications",
      description: "Stay Connected,Stay Informed with Real-Time Alerts",
      height: "80%",
      width: "90%",
    },
  ];

  return (
    <Flex position="relative" bg={"#2A3F54"} h={"100vh"}>
      <Flex h={"100%"} w="100%" justifyContent="start" direction="row">
        {children}
        <Box
          display={{ base: "none", lg: "block" }}
          h="100%"
          minH="100vh"
          w={"50%"}
          bg={"white"}
        >
          <Flex
            direction="column"
            w="100%"
            h="100%"
            position="relative"
            alignItems={"center"}
            justifyContent={"center"}
            gap={5}
          >
            <Box w="100%" maxW="600px" mx="auto">
              <Slider {...sliderSettings}>
                {slides.map((slide, index) => (
                  <Box key={index} textAlign="center">
                    <Image
                      src={slide.image}
                      alt="URZZA CMS"
                      w={slide.width}
                      h={slide.height}
                      mx="auto"
                    />
                    <Text
                      color={"black"}
                      fontSize={38}
                      fontWeight={500}
                      marginTop={25}
                    >
                      {slide.title}
                    </Text>
                    <Text
                      color={"black"}
                      fontSize={20}
                      fontWeight={400}
                      maxW={"462px"}
                      mx="auto"
                      marginBottom={20}
                    >
                      {slide.description}
                    </Text>
                  </Box>
                ))}
              </Slider>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
