import React from "react";

// Chakra imports
import { Box } from "@chakra-ui/react";
import PayoutsGroup from "./components/PayoutsGroup";

export default function Payouts() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} overflow="hidden" px={5}>
      <PayoutsGroup />
    </Box>
  );
}
