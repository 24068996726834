import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "axios-hooks";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import Card from "components/card/Card";
import { toastState } from "atoms/toastState";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import { updateUserFinancialDetailsDrawerState } from "atoms/updateUserFinancialDetailsDrawerState";
import { loggedInUserState } from "atoms/loggedInUserState";
import { UpdateUserFinancialDrawerSelectOptions } from "../variables/options";
import logger from "infra/logger";

//   import { updateTenantDetailsDrawerState } from "atoms/updateTenantDetailsDrawerState";

const UpdateUserFinancialDetailsDrawer = (props) => {
  const setToast = useSetRecoilState(toastState);
  const [drawerState, setDrawerState] = useRecoilState(
    updateUserFinancialDetailsDrawerState
  );

  const loggedInUser = useRecoilValue(loggedInUserState);

  // get reciolVAlue form loggedInUserState from atoms
  // you can get the tenant id from recoilvalue

  const [userFinancialDetails, setUserFinancialDetails] = useState({
    BankAccountNo: "",
    BankName: "",
    IfscCode: "",
    BeneficiaryName: "",
    PanCard: "",
    GstNo: "",
  });

  const validationSchema = yup.object().shape({
    BankAccountNo: yup.string(),
    BankName: yup.string(),
    IfscCode: yup.string(),
    BeneficiaryName: yup.string(),
    PanCard: yup
      .string()
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN card number"),
    GstNo: yup.string(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [
    { loading: UserFinancialDetailsLoading },
    getUserFinancialDetails,
    cancelRequest,
  ] = useAxios(
    { method: "GET", url: `/api/user/profile/${loggedInUser?.userId}` },
    { manual: true }
  );

  const [
    {
      data: UpdateUserAccountDetails,
      loading: updateUserAccountDetailsLoading,
    },
    updateTenantAccountDetails,
  ] = useAxios(
    {
      method: "PATCH",
      url: `/api/user/update/financials/${loggedInUser?.userId}`,
    },
    { manual: true }
  );
  const closeAccountHandler = () => {
    setDrawerState(null);
  };
  const getUserAccountDetails = async () => {
    try {
      const response = await getUserFinancialDetails();
      const data = response.data.data;
      setValue("BankName", data?.BankName);
      setValue("BankAccountNo", data?.BankAccountNo);
      setValue("IfscCode", data?.IfscCode);
      setValue("BeneficiaryName", data?.BeneficiaryName);
      setValue("PanCard", data?.PanCard);
      setValue("GstNo", data?.GstNo);

      setUserFinancialDetails((prev) => {
        return {
          ...prev,
          BankName: data?.BankName,
          BankAccountNo: data?.BankAccountNo,
          IfscCode: data?.IfscCode,
          BeneficiaryName: data?.BeneficiaryName,
          PanCard: data?.PanCard,
          GstNo: data?.GstNo,
        };
      });
    } catch (error) {
      logger.log(error);
      if (error?.code != "ERR_CANCELED") {
      setToast({
        isOpen: true,
        title: "Error occured while getting user financial details",
        status: "error",
      });
      closeAccountHandler();
    }
    }
  };

  const UpdateAccountHandler = async () => {
    const formValues = getValues();
    let userFinancialDetailsUpdate = {};

    if (
      formValues.BankName.trim() !== "" &&
      formValues.BankName.trim() !== userFinancialDetails?.BankName
    ) {
      userFinancialDetailsUpdate["BankName"] = formValues?.BankName.trim();
    }
    if (
      formValues.BankAccountNo.trim() !== "" &&
      formValues.BankAccountNo.trim() !== userFinancialDetails?.BankAccountNo
    ) {
      userFinancialDetailsUpdate["BankAccountNo"] =
        formValues?.BankAccountNo.trim();
    }
    if (
      formValues.BeneficiaryName.trim() !== "" &&
      formValues.BeneficiaryName.trim() !==
        userFinancialDetails?.BeneficiaryName
    ) {
      userFinancialDetailsUpdate["BeneficiaryName"] =
        formValues?.BeneficiaryName.trim();
    }
    if (
      formValues.IfscCode.trim() !== "" &&
      formValues.IfscCode.trim() !== userFinancialDetails?.IfscCode
    ) {
      userFinancialDetailsUpdate["IfscCode"] = formValues?.IfscCode.trim();
    }
    if (
      formValues.PanCard &&
      formValues.PanCard.trim() !== "" &&
      formValues.PanCard.trim() !== userFinancialDetails?.PanCard
    ) {
      userFinancialDetailsUpdate["PanCard"] = formValues.PanCard.trim();
    }

    if (
      formValues.GstNo &&
      formValues.GstNo.trim() !== "" &&
      formValues.GstNo.trim() !== userFinancialDetails?.GstNo
    ) {
      userFinancialDetailsUpdate["GstNo"] = formValues.GstNo.trim();
    }

    if (Object.keys(userFinancialDetailsUpdate).length > 0) {
      try {
        const response = await updateTenantAccountDetails({
          data: userFinancialDetailsUpdate,
        });
        setToast({
          isOpen: true,
          title: "User Financial details Updated",
          status: "success",
        });
        props.update();
        closeAccountHandler();
        getUserAccountDetails();
      } catch (error) {
        const err = error?.response?.data?.error;
        logger.log(error);
        setToast({
          isOpen: true,
          title: "Error occured when updating Details",
          description: err ? err : null,
          status: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        title: "nothing new to update",
        status: "warning",
      });
    }
  };

  useEffect(() => {
    getUserAccountDetails();
    return () => cancelRequest();
  }, []);

  return (
    <Drawer
      isOpen={drawerState?.isOpen}
      placement="right"
      onClose={closeAccountHandler}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Update User Financial Details</DrawerHeader>
        <DrawerBody>
          {!UserFinancialDetailsLoading && !updateUserAccountDetailsLoading ? (
            <>
              <Card>
                <form>
                  {UpdateUserFinancialDrawerSelectOptions.map((item, index) => (
                    <Flex key={index} mb="20px" alignItems="center">
                      <Controller
                        name={item.name}
                        control={control}
                        render={({ field }) => (
                          <FormControl
                            flexDirection="row"
                            isInvalid={errors[item.name]}
                          >
                            <FormLabel fontSize={16}>{item.label}</FormLabel>
                            <Input
                              {...field}
                              focusBorderColor="#f57d36"
                              fontSize="sm"
                              placeholder={item.placeholder}
                              width="400px"
                              height="50px"
                              type={item.type}
                            />
                            <FormHelperText color={"#f57d36"}>
                              {item.helper}
                            </FormHelperText>
                            <FormErrorMessage mb={"20px"}>
                              {errors[item.name] && errors[item.name].message}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    </Flex>
                  ))}
                </form>
              </Card>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={closeAccountHandler}>
            Cancel
          </Button>
          <Button
            bg="#f57d36"
            color="white"
            colorScheme="orange"
            onClick={handleSubmit(UpdateAccountHandler)}
          >
            Update
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default UpdateUserFinancialDetailsDrawer;
