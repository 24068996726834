export const formattedDate = (utc) => {
  const utcDate = new Date(utc);
  utcDate.setHours(utcDate.getHours() + 5);
  utcDate.setMinutes(utcDate.getMinutes() + 30);

  // Format the IST date and time
  const formattedIST = utcDate.toLocaleString("en-IN", {
    timeZone: "Asia/Kolkata", // Set the timezone to IST
  });
  return formattedIST; // Output: "01/31/2023, 10:51:37 AM"
};

export const formattedIstDate = (utc) => {
  const isDate = new Date(utc);

  // Format the IST date and time
  const formattedIST = isDate.toLocaleString("en-IN", {
    timeZone: "Asia/Kolkata", // Set the timezone to IST
  });
  return formattedIST; // Output: "01/31/2023, 10:51:37 AM"
};

export const getGridRulesFromIndex = (index) => {
  let rules = {};
  switch (index) {
    case 0:
      rules["area"] = "one";
      rules["fontSize"] = "25";
      rules["fontWeight"] = "700";
      rules["textAlign"] = "center";
      break;
    case 1:
      rules["area"] = "two";
      break;
    case 2:
      rules["area"] = "three";
      break;
    case 3:
      rules["area"] = "four";
      break;
    case 4:
      rules["area"] = "five";
      break;
    case 5:
      rules["area"] = "six";
      break;
    case 6:
      rules["area"] = "seven";
      break;
    case 7:
      rules["area"] = "eight";
      break;
    default:
      break;
  }

  return rules;
};
export const getFirstDateOfMonth = () => {
  const today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  const firstDayOfMonth = new Date(year, month - 1, 1);
  const formattedDate = formatDate(firstDayOfMonth);
  return formattedDate;
};
// Function to get the current date
export const getCurrentDate = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = String(today.getMonth() + 1).padStart(2, "0"); // Zero-padding month
  const currentDay = String(today.getDate()).padStart(2, "0"); // Zero-padding day
  const currentHour = String(today.getHours()).padStart(2, "0"); // Zero-padding hour
  const currentMinute = String(today.getMinutes()).padStart(2, "0"); // Zero-padding minute

  const currentDateAndTime = `${currentYear}-${currentMonth}-${currentDay}T${currentHour}:${currentMinute}`;
  return currentDateAndTime;
};
export function formatDate(date, hours = 0, minutes = 0) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Zero-padding month
  const day = String(date.getDate()).padStart(2, "0"); // Zero-padding day
  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}`;
  return `${formattedDate}T${formattedTime}`;
}
export const getPreviousMonthNameAndYear = () => {
  const today = new Date();
  //get date in IST
  today.setHours(today.getHours() + 5);
  today.setMinutes(today.getMinutes() + 30);
  let month = today.getMonth();
  let year = today.getFullYear();
  //get previous months name and year
  if (month === 0) {
    month = 11;
    year = today.getFullYear() - 1;
  } else {
    month -= 1;
    year = today.getFullYear();
  }
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return { month: monthNames[month], year: year, monthNumber: month + 1 };
};

export const getTimerString = (seconds) => {
  let timerString = "";

  // Calculate days
  const days = Math.floor(seconds / 86400);
  if (days > 0) {
    timerString += `${String(days).padStart(2, "0")}d:`;
    seconds = seconds % 86400;
  }

  // Calculate hours
  const hours = Math.floor(seconds / 3600);
  if (hours > 0) {
    timerString += `${String(hours).padStart(2, "0")}h:`;
    seconds = seconds % 3600;
  }

  // Calculate minutes
  const minutes = Math.floor(seconds / 60);
  if (minutes > 0) {
    timerString += `${String(minutes).padStart(2, "0")}m:`;
    seconds = seconds % 60;
  }

  // Calculate seconds
  timerString += `${String(seconds).padStart(2, "0")}s`;

  return timerString;
};
