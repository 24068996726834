import {Button, Flex, FormControl, FormLabel, Select} from "@chakra-ui/react";
import React from "react";
import {MdModeEdit} from "react-icons/md";
import {useRecoilValue} from "recoil";

import {activeConnectorState} from "atoms/activeConnectorState";
import {loggedInUserState} from "atoms/loggedInUserState";
import {accessLevels} from "utils/accessLevels";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import {onlyAdmins} from "utils/accessLevels";

const SelectEditConnector = ({connectorHandler, options, editHandler}) => {
  const loggedInUser = useRecoilValue(loggedInUserState);
  const role = loggedInUser?.role;
  const canEdit = accessLevels[role]?.accessLevel <= 2;
  const activeConnector = useRecoilValue(activeConnectorState);
  return (
    <Flex
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="flex-end"
      gap={10}
      mb={10}
    >
      <FormControl width={300}>
        <FormLabel ml={2} fontWeight={"extrabold"}>
          Select Connector
        </FormLabel>
        <Select
          onChange={(e) => connectorHandler(e)}
          value={activeConnector?.Code}
          name="Connector"
          disabled={!canEdit}
        >
          {options}
        </Select>
      </FormControl>
      <RoleBasedRender roles={onlyAdmins}>
        <Button
          w={40}
          leftIcon={<MdModeEdit />}
          colorScheme="twitter"
          variant="solid"
          borderRadius={8}
          onClick={() => editHandler(activeConnector.Id)}
          disabled={!canEdit}
        >
          Edit
        </Button>
      </RoleBasedRender>
    </Flex>
  );
};

export default SelectEditConnector;
