import {Button, Text} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {MdMessage} from "react-icons/md";
import useAxios from "axios-hooks";
import {useRecoilValue, useSetRecoilState} from "recoil";

import { activeConnectorState } from "atoms/activeConnectorState";
import {toastState} from "atoms/toastState";
import Card from "components/card/Card";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import logger from "infra/logger";
//import { TbMessage } from "react-icons/tb";

const RemoteStop = ({ refresh }) => {
  
    const activeConnector = useRecoilValue(activeConnectorState)

  const setToast = useSetRecoilState(toastState);
  const [session, setSession] = useState(null);

  const [{data, loading, error}, execute] = useAxios(
    {
      method: "GET",
      url: `/api/chargingSession/ongoing?chargePointCode=${activeConnector?.ChargePointCode}&connectorId=${activeConnector?.Code}`,
    },
    {manual: true}
  );
  const [
    {data: stopData, loading: stopLaoding, error: stopError},
    executeStop,
  ] = useAxios(
    {
      method: "POST",
      url: `/api/ocpp16/charging/stop`,
    },
    {manual: true}
  );

  const getOngoingSesson = async () => {
    try {
      const response = await execute();
      setSession(response.data.data);
    } catch (error) {
      const err = error?.response?.data?.error;
      logger.log(error);
      setToast({
        isOpen: true,
        title: "Error occured when fetching Session",
        description: err ? err : null,
        status: "error",
      });
    }
  };

  const operation = async () => {
    let body = {};
    body["ClientId"] = session.chargePointCode;
    body["TransactionId"] = session.id;
    try {
      const response = await executeStop({data: body});
      setToast({
        isOpen: true,
        title: "Charging Stopped Successfully",
        description: response.data.data.Message,
        status: "success",
      });
    } catch (error) {
      const err = error?.response?.data?.error;
      logger.log(error);
      setToast({
        isOpen: true,
        title: "Error occured when stopping Charger",
        description: err ? err : null,
        status: "error",
      });
    }
    refresh();
  };
  useEffect(() => {
    if (activeConnector?.Status === "Charging") {
      getOngoingSesson();
    }
  }, []);
  return loading ? (
    <LoadingSpinner />
  ) : (
    <Card alignItems="center" pt={0}>
      <Text
        fontWeight={700}
        fontSize={20}
        borderBottom="1px solid black"
        mb={5}
      >
        Remote Stop
      </Text>
      <Button
        isDisabled={session ? false : true}
        borderRadius={5}
        textColor={"white"}
        backgroundColor="#f57d36"
        _hover={{backgroundColor: "whatsapp.600"}}
        rightIcon={<MdMessage />}
        onClick={operation}
      >
        Send Message
      </Button>
    </Card>
  );
};

export default RemoteStop;
