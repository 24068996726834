import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import useAxios from "axios-hooks";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Card from "components/card/Card";
import { toastState } from "atoms/toastState";
import logger from "infra/logger";
import {
  BillingPlanFixedInputFields,
  BillingPlanUnitInputFields,
} from "../variables/InputFields";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import { onlyUAdmins } from "utils/accessLevels";
import { loggedInUserState } from "atoms/loggedInUserState";

const Configurations = (props) => {
  const id = props.id;
  const [isDedicatedLine, setIsDedicatedLine] = useState(false);
  const [configurations, setConfigurations] = useState({});
  const setToast = useSetRecoilState(toastState);

  const loggedInUser = useRecoilValue(loggedInUserState);

  const validationSchema = yup.object().shape({
    FixedElectricityCharges: yup
      .number()
      .min(0, "Must be a positive number or zero")
      .typeError("Must be a number"),
    FixedRentalCharges: yup
      .number()
      .min(0, "Must be a positive number or zero")
      .typeError("Must be a number"),
    FixedDataCharges: yup
      .number()
      .min(0, "Must be a positive number or zero")
      .typeError("Must be a number"),
    ElectricityCost: yup
      .number()
      .min(0, "Must be a positive number or zero")
      .typeError("Must be a number"),
    RentalCost: yup
      .number()
      .min(0, "Must be a positive number or zero")
      .typeError("Must be a number"),
    ChargerOperationCost: yup
      .number()
      .min(0, "Must be a positive number or zero")
      .typeError("Must be a number"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [
    { data: configureData, loading: configureLoading, error: configureError },
    getStation,
  ] = useAxios(
    { method: "GET", url: `/api/chargingStation/${id}` },
    { manual: true }
  );

  const [
    { data: updateConfigureData, loading: updateLoading, error: updateError },
    updateConfiguration,
  ] = useAxios(
    { method: "PATCH", url: `/api/chargingStation/update/${id}` },
    { manual: true }
  );

  const fetchConfiguration = async () => {
    try {
      const response = await getStation();
      const result = response.data.data;
      const contractConfig = result.ContractConfig;
      console.log("abc", result);

      setValue(
        "FixedElectricityCharges",
        contractConfig?.fixed_charges?.electricity_charges
      );
      setValue(
        "FixedRentalCharges",
        contractConfig?.fixed_charges?.rental_cost
      );
      setValue("FixedDataCharges", contractConfig?.fixed_charges?.data_charges);
      setValue(
        "ElectricityCost",
        contractConfig?.per_unit_charges?.electricity_charges
      );
      setValue("RentalCost", contractConfig?.per_unit_charges?.rental_cost);
      setValue(
        "ChargerOperationCost",
        contractConfig?.per_unit_charges?.charger_operation_charges
      );
      setConfigurations(contractConfig);
      setIsDedicatedLine(contractConfig?.is_dedicated_line);
    } catch (error) {
      logger.error("Error fetching configurations:", error);
      //TODO set toast notification
    }
  };

  const onSubmit = async () => {
    const formValues = getValues();
    let udata = {};
    if (formValues.FixedElectricityCharges !== "") {
      udata["FixedElectricityCharges"] = parseFloat(
        formValues.FixedElectricityCharges
      );
    }
    if (formValues.FixedRentalCharges !== "") {
      udata["FixedRentalCost"] = parseFloat(formValues.FixedRentalCharges);
    }
    if (formValues.FixedDataCharges !== "") {
      udata["FixedDataCharges"] = parseFloat(formValues.FixedDataCharges);
    }
    if (formValues.ElectricityCost !== "") {
      udata["PerUnitElectricityCharges"] = parseFloat(
        formValues.ElectricityCost
      );
    }
    if (formValues.RentalCost !== "") {
      udata["PerUnitRentalCost"] = parseFloat(formValues.RentalCost);
    }
    if (formValues.ChargerOperationCost !== "") {
      udata["PerUnitChargerOperationCharges"] = parseFloat(
        formValues.ChargerOperationCost
      );
    }
    udata["IsDedicatedLine"] = isDedicatedLine;

    if (Object.keys(udata).length > 0) {
      try {
        const response = await updateConfiguration({ data: udata });
        logger.log("updated data", response.data);
        setToast({
          isOpen: true,
          title: "Configurations updated successfully",
          status: "success",
          duration: 5000,
        });
        await fetchConfiguration();
      } catch (error) {
        logger.error("Error updating configurations:", error);
        setToast({
          isOpen: true,
          title: "Error updating configurations",
          status: "error",
          duration: 5000,
        });
      }
    }
  };

  useEffect(() => {
    fetchConfiguration();
  }, []);

  const fixedInputs = BillingPlanFixedInputFields.map((item) => {
    return (
      <Controller
        name={item.name}
        control={control}
        render={({ field }) => (
          <FormControl isInvalid={errors[item.name]}>
            <FormLabel color="black" fontSize={18}>
              {item.label}
            </FormLabel>
            <InputGroup>
              <InputLeftAddon>{item?.caluculatedAgainst}</InputLeftAddon>
              <Input
                style={{ width: "100%", maxWidth: "300px" }}
                {...field}
                placeholder={item.placeholder}
                type="number"
                onChange={(e) => {
                  // Remove leading zeros
                  const value = e.target.value.replace(/^0+(?=\d)/, "");
                  field.onChange(value);
                }}
                readOnly={!onlyUAdmins.includes(loggedInUser?.role)}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors[item.name] && errors[item.name].message}
            </FormErrorMessage>
          </FormControl>
        )}
      />
    );
  });

  const unitInputs = BillingPlanUnitInputFields.map((item) => {
    return (
      <Controller
        name={item.name}
        control={control}
        render={({ field }) => (
          <FormControl isInvalid={errors[item.name]}>
            <FormLabel color="black" fontSize={18}>
              {item.label}
            </FormLabel>
            <InputGroup>
              <InputLeftAddon>{item?.caluculatedAgainst}</InputLeftAddon>
              <Input
                style={{ width: "100%", maxWidth: "300px" }}
                {...field}
                placeholder={item.placeholder}
                type="number"
                onChange={(e) => {
                  // Remove leading zeros
                  const value = e.target.value.replace(/^0+(?=\d)/, "");
                  field.onChange(value);
                }}
                readOnly={!onlyUAdmins.includes(loggedInUser?.role)}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors[item.name] && errors[item.name].message}
            </FormErrorMessage>
          </FormControl>
        )}
      />
    );
  });

  return (
    <Card
      direction="column"
      w="100%"
      overflowX={{ sm: "scroll", lg: "hidden" }}
      display="flex"
      flexDir="column"
      padding="30px"
    >
      <Flex w="100%" mb="20px" flexDir={"column"} alignItems={"center"} mt={10}>
        <Box
          w="100%"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          // mt={5}
        >
          <Text
            fontSize={20}
            mr={5}
            position={"absolute"}
            top={0}
            fontWeight={"bold"}
          >
            CONFIGURATION
          </Text>
          <RoleBasedRender roles={onlyUAdmins}>
            <Button
              type="submit"
              bg={"#f57d36"}
              color={"white"}
              borderRadius={8}
              _hover={{ backgroundColor: "orange.600" }}
              isLoading={updateLoading}
              position={"absolute"}
              right={4}
              top={0}
              p={5}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </RoleBasedRender>
        </Box>
        <Text
          w="100%"
          fontSize={20}
          casing="capitalize"
          fontWeight={"bold"}
          color={"gray.400"}
          pb={1}
          borderBottom={"1px solid"}
          borderColor={"gray.200"}
        >
          Fixed Charges
        </Text>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <SimpleGrid
            columns={2}
            spacing={2}
            // borderWidth={1}
            // borderColor={"gray.200"}
            p={5}
            w={"100%"}
            // maxW={"1000px"}
            // alignItems={"center"}
            minChildWidth={"226px"}
            overflow={"hidden"}
          >
            {fixedInputs}
          </SimpleGrid>
        </form>
        <Text
          w="100%"
          fontSize={20}
          casing="capitalize"
          fontWeight={"bold"}
          color={"gray.400"}
          pb={1}
          borderBottom={"1px solid"}
          borderColor={"gray.200"}
        >
          Unit Charges
        </Text>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <SimpleGrid
            columns={2}
            spacing={2}
            // borderWidth={1}
            // borderColor={"gray.200"}
            p={5}
            w={"100%"}
            // maxW={"1000px"}
            alignItems={"center"}
            minChildWidth={"226px"}
            overflow={"hidden"}
          >
            {unitInputs}
          </SimpleGrid>
        </form>
        <Checkbox
          mt={6}
          onChange={() => {
            setIsDedicatedLine(!isDedicatedLine);
          }}
          isChecked={isDedicatedLine}
          size={"lg"}
        >
          <Text>Make it Dedicated Line</Text>
        </Checkbox>
      </Flex>
    </Card>
  );
};

export default Configurations;
