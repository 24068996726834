import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Spinner,
  Input,
  Select,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import LoadingSpinner from "components/feedback/LoadingSpinner";
import Card from "components/card/Card";
import logger from "infra/logger";
import { createTenantDrawerState } from "atoms/createTenantDrawerState";
import { toastState } from "atoms/toastState";
import { useRecoilState, useSetRecoilState } from "recoil";

const CreateTenants = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const setToast = useSetRecoilState(toastState);
  const [drawerState, setDrawerState] = useRecoilState(createTenantDrawerState);
  const validationSchema = yup.object().shape({
    FirstName: yup.string().min(1).required("Name is required"),
    LastName: yup.string().min(1).required("Name is required"),
    PhoneNumber: yup
      .string()
      .matches(/^[0-9]*$/, "Mobile Number must contain only digits")
      .max(10)
      .min(10)
      .required("Phone Number is required "),
    PrimaryEmail: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address format"
      )
      .required("Email is required"),
    NameSpace: yup.string().required("NameSpace is required"),
    Type: yup.string().required("Type is required"),
    BankAccountNo: yup.string().required("BankAccountNo is required"),
    BankName: yup.string().required("BankName is required"),
    IfscCode: yup.string().required("IfscCode is required"),
    BeneficiaryName: yup.string().required("BeneficiaryName is required"),
    GstNo: yup.string().required("GstNo is required"),
    Code: yup.string().required("Code is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [{ data, loading: createLoading, error }, createTenant] = useAxios(
    { method: "POST", url: `/api/tenant/create` },
    { manual: true }
  );

  const createHandler = async () => {
    setIsLoading(true);
    const formValues = getValues();
    let data = {};
    if (formValues.FirstName?.trim() !== "") {
      data["FirstName"] = formValues.FirstName?.trim();
    }
    if (formValues.LastName?.trim() !== "") {
      data["LastName"] = formValues.LastName?.trim();
    }
    if (formValues.PrimaryEmail.trim() !== "") {
      data["PrimaryEmail"] = formValues.PrimaryEmail.trim();
    }
    if (formValues.PhoneNumber.trim() !== "") {
      data["PhoneNumber"] = formValues.PhoneNumber.trim();
    }
    if (formValues.NameSpace.trim() !== "") {
      data["NameSpace"] = formValues.NameSpace.trim();
    }
     data["Role"] = "admin";
    if (formValues.Type.trim() !== "") {
      data["Type"] = formValues.Type.trim();
    }
    if (formValues.BankAccountNo.trim() !== "") {
      data["BankAccountNo"] = formValues.BankAccountNo.trim();
    }
    if (formValues.BankName.trim() !== "") {
      data["BankName"] = formValues.BankName.trim();
    }
    if (formValues.IfscCode.trim() !== "") {
      data["IfscCode"] = formValues.IfscCode.trim();
    }
    if (formValues.BeneficiaryName.trim() !== "") {
      data["BeneficiaryName"] = formValues.BeneficiaryName.trim();
    }
    if (formValues.GstNo.trim() !== "") {
      data["GstNo"] = formValues.GstNo.trim();
    }
    if (formValues.Code.trim() !== "") {
      data["Code"] = formValues.Code.trim();
    }
    logger.log(formValues);
    logger.log("data", data);
    if (Object.keys(data).length === 13) {
      try {
        const response = await createTenant({ data: data });
        setToast({
          isOpen: true,
          title: "Tenant Created successfully",
          status: "success",
        });
        props.update();
      } catch (error) {
        const err = error?.response?.data?.error;
        logger.log(error);
        setToast({
          isOpen: true,
          title: "Error occurred when creating Tenant",
          description: err ? err : null,
          status: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        title: "Enter all the fields to create Tenant",
        status: "warning",
        position: "top",
      });
    }
    setIsLoading(false);
  };

  const closeHandler = () => {
    setDrawerState(null);
  };
  return (
    <Drawer
      isOpen={drawerState?.isOpen}
      placement="right"
      onClose={closeHandler}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Create a new Tenant</DrawerHeader>

        <DrawerBody>
          {!createLoading ? (
            <>
              <Card>
                <form>
                  {[
                    {
                      name: "FirstName",
                      label: "FirstName",
                      type: "text",
                      placeholder: "Enter FirstName",
                    },
                    {
                      name: "LastName",
                      label: "LastName",
                      type: "text",
                      placeholder: "Enter LastName",
                    },
                    {
                      name: "PrimaryEmail",
                      label: "Email",
                      type: "text",
                      placeholder: "Enter Email",
                    },
                    {
                      name: "PhoneNumber",
                      label: "Phone Number",
                      type: "text",
                      placeholder: "Enter Phone Number",
                    },
                    {
                      name: "NameSpace",
                      label: "NameSpace",
                      type: "text",
                      placeholder: "Enter NameSpace",
                    },
                    {
                      name: "Type",
                      label: "Type",
                      type: "select",
                      placeholder: "Select Type",
                      options: [
                        { name: "CPO", value: "cpo" },
                        { name: "BCO", value: "bco" },
                        { name: "CPO Operated by URZZA", value: "cpo_operated_by_urzza" },
                      ],
                    },
                    {
                      name: "Code",
                      label: "Code",
                      type: "text",
                      placeholder: "Enter Code",
                    },
                    {
                      name: "BankAccountNo",
                      label: "BankAccountNo",
                      type: "text",
                      placeholder: "Enter BankAccountNo",
                    },
                    {
                      name: "BankName",
                      label: "BankName",
                      type: "text",
                      placeholder: "Enter BankName",
                    },
                    {
                      name: "IfscCode",
                      label: "IfscCode",
                      type: "text",
                      placeholder: "Enter IfscCode",
                    },
                    {
                      name: "BeneficiaryName",    
                      label: "BeneficiaryName",
                      type: "text",
                      placeholder: "Enter BeneficiaryName",
                    },
                    {
                      name: "GstNo",
                      label: "GstNo",
                      type: "text",
                      placeholder: "Enter GstNo",
                    },
                  ].map((item, index) => (
                    <Flex key={index} mb="20px" alignItems="center">
                      <Controller
                        name={item.name}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl
                            isRequired
                            flexDirection="row"
                            isInvalid={errors[item.name]}
                          >
                            <FormLabel fontSize={20}>{item.label}</FormLabel>
                            {item.type === "select" ? (
                              <Select
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                width="400px"
                                height="50px"
                                isRequired
                              >
                                <option value="">Select</option>
                                {item.options.map((option, ind) => {
                                  return (
                                    <option key={ind} value={option.value}>
                                      {option.name}
                                    </option>
                                  );
                                })}
                              </Select>
                            ) : (
                              <Input
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                placeholder={item.placeholder}
                                width="400px"
                                height="50px"
                                type={item.type}
                                isRequired
                              />
                            )}
                            <FormHelperText color={"#f57d36"}>
                              {item.helper}
                            </FormHelperText>
                            <FormErrorMessage mb={"20px"}>
                              {errors[item.name] && errors[item.name].message}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    </Flex>
                  ))}
                </form>
              </Card>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            bg="#f57d36"
            color="white"
            colorScheme="orange"
            onClick={handleSubmit(createHandler)}
            isLoading={isLoading}
            loadingText="Creating"
            spinner={<Spinner size="md" />}
            disabled={isLoading}
          >
            Create
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CreateTenants;
