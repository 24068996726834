import {
  Button,
  Text,
} from "@chakra-ui/react";
import React from "react";
import {MdMessage} from "react-icons/md";
import useAxios from "axios-hooks";
import {useRecoilValue, useSetRecoilState} from "recoil";

import { activeConnectorState } from "atoms/activeConnectorState";
import {toastState} from "atoms/toastState";
import Card from "components/card/Card";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import logger from "infra/logger";

const UnlockConnector = ({ refresh }) => {
    const activeConnector = useRecoilValue(activeConnectorState)

  const setToast = useSetRecoilState(toastState);
  const [{data, loading, error}, execute] = useAxios(
    {method: "POST", url: "/api/ocpp16/unlockConnector"},
    {manual: true}
  );


  const operation = async () => {
    let body = {};
    body["ConnectorId"] = activeConnector.Code;
    body["ChargerId"] = activeConnector.ChargePointCode;
    try {
      const response = await execute({data: body});
      setToast({
        isOpen: true,
        title: "Connector Unlocked Successfully",
        description: response.data.data.Message,
        status: "success",
      });
    } catch (error) {
      const err = error?.response?.data?.error;
      logger.log(error);
      setToast({
        isOpen: true,
        title: "Error occured when Unlocking",
        description: err ? err : null,
        status: "error",
      });
    }
    refresh();
  };
  return loading ? (
    <LoadingSpinner />
  ) : (
    <Card alignItems="center" pt={0}>
      <Text fontWeight={700} fontSize={20} borderBottom="1px solid black" mb={5}>
        Unlock Connector
      </Text>
      <Button
        borderRadius={5}
        textColor={"white"}
        backgroundColor="#f57d36"
        _hover={{backgroundColor: "whatsapp.600"}}
        rightIcon={<MdMessage />}
        onClick={operation}
      >
        Send Message
      </Button>
    </Card>
  );
};

export default UnlockConnector;
