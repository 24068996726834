import React, {useEffect, useState} from "react";
import {
  Container,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Box,
  Flex,
  Heading,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import {FaPencilAlt, FaCloudUploadAlt, FaArrowDown} from "react-icons/fa";
import useAxios from "axios-hooks";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";

import {loggedInUserState} from "atoms/loggedInUserState";
import {toastState} from "atoms/toastState";
import logger from "infra/logger";
import {updateUserFinancialDetailsDrawerState} from "atoms/updateUserFinancialDetailsDrawerState";
import UpdateUserFinancialDetailsDrawer from "./components/UpdateUserFinancialDetailsDrawer";

const AccountSettings = () => {
  const [userFinancialDetailsDrawerState, setUserFinancialDetailsDrawerState] =
    useRecoilState(updateUserFinancialDetailsDrawerState);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const setToast = useSetRecoilState(toastState);
  const [selectedFile, setSelectedFile] = useState([]);
  const formControlStyle = {
    width: "98%",
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    marginBottom: "10px",
    padding: "15px",
    Height: "150px",
  };
  const formLabelsStyle = {
    width: "170px",
    margin: "0 5px 0 0",
    paddingRight: "5px",
    fontWeight: "noraml",
  };

  const [userDetails, setUserDetails] = useState({
    BeneficiaryName: "",
    Name: "",
    Email: "",
    MobileNumber: "",
    PanCard: "",
    BankAccountNo: "",
    BankName: "",
    IfscCode: "",
    GstNo: "",
    VerificationStatus: "",
  });

  const [{data, loading, error}, execute,cancelRequest] = useAxios(
    {
      url: `/api/user/profile/${loggedInUser?.userId}`,
      method: "GET",
    },
    {manual: true}
  );

  const [{uploadData, Uploadloading, Uploaderror}, UploadDocument] = useAxios(
    {
      url: `/api/tenant/upload_undertaking_document/${loggedInUser?.tenantId}`,
      method: "POST",
      headers: {"Content-Type": "multipart/form-data"},
    },
    {manual: true}
  );

  const fetchData = async () => {
    try {
      const response = await execute();
      const responseData = response.data.data;
      logger.log(responseData);
      setUserDetails({
        BeneficiaryName: responseData?.BeneficiaryName,
        Name: responseData?.FirstName + " " + responseData?.LastName,
        Email: responseData?.Email,
        MobileNumber: responseData.MobileNumber,
        PanCard: responseData?.PanCard,
        BankAccountNo: responseData?.BankAccountNo,
        BankName: responseData?.BankName,
        IfscCode: responseData?.IfscCode,
        GstNo: responseData?.GstNo,
        VerificationStatus: responseData?.VerificationStatus,
      });
    } catch (error) {
      logger.error("Error fetching tenant details:", error);
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "Error Occured",
          status: "error",
          description: error?.response?.data?.error,
        });
      }
    }
  };

  const status = userDetails?.VerificationStatus;
  let color = "black.500";
  let statusText = "";
  switch (status) {
    case "verified":
      statusText = "Verified";
      color = "green.300";
      break;
    case "rejected":
      statusText = "Rejected";
      color = "red.300";
      break;
    case "pending":
      statusText = "Pending";
      color = "orange.300";
      break;
    case "not_initiated":
      statusText = "Not Initiated";
      color = "blue.300";
      break;
    default:
      statusText = "N/A";
      color = "gray.300";
      break;
  }

  const handleInput = (e) => {
    const acceptedFileTypes = [
      "application/pdf",
      "application/doc",
      "application/docx",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (!acceptedFileTypes.includes(e.target.files[0].type)) {
      setToast({
        isOpen: true,
        title: "Invalid file type",
        description: "Please upload a PDF or DOC Files",
        status: "error",
      });
      return;
    }
    setSelectedFile([e.target.files[0]]);
  };
  const handleDrop = (e) => {
    e.preventDefault();

    const droppedFiles = e.dataTransfer.files;
    const acceptedFiles = Array.from(droppedFiles).filter((file) =>
      [
        "application/pdf",
        "application/doc",
        "application/docx",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(file.type)
    );

    if (acceptedFiles.length > 0) {
      const selectedFileName = acceptedFiles[0].name;
      setSelectedFile([acceptedFiles[0]]);

      // Display the selected file name
      setToast({
        isOpen: true,
        title: "File Selected",
        description: selectedFileName,
        status: "success",
      });
    } else {
      setToast({
        isOpen: true,
        title: "Invalid file type",
        description: "Please upload a PDF or DOC Files",
        status: "error",
      });
    }
  };

  const handleUpload = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".pdf,.doc,.docx";
    fileInput.style.display = "none";

    fileInput.addEventListener("change", (event) => {
      const file = event.target.files[0];
      if (
        file.type === "application/pdf" ||
        file.type === "application/.doc" ||
        file.type === "application/.docx" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        setSelectedFile(file);
        logger.log("Selected file:", file);
      } else {
        // alert('Please select a PDF or DOC file.');
      }
    });

    fileInput.click();
  };
  const uploadHandler = async () => {
    logger.log("Files:", selectedFile);
    if (selectedFile?.size > 0) {
      // Check if the file size exceeds the limit (in bytes)
      const maxSizeInBytes = 150 * 1024; // 150 KB in bytes

      if (selectedFile?.size > maxSizeInBytes) {
        setToast({
          isOpen: true,
          title: "File size exceeds the limit",
          description: `Please select an File with a size less than 150 KB.`,
          status: "warning",
        });
        return;
      }

      // Continue with the upload logic
      const formData = new FormData();
      formData.append("file", selectedFile);

      try {
        const response = await UploadDocument({ data: formData });
        setToast({
          isOpen: true,
          title: "File uploaded successfully",
          status: "success",
        });
        logger.log("response", response);
        setSelectedFile([]);
      } catch (error) {
        const err = error?.response?.data?.error;
        setToast({
          isOpen: true,
          title: "Error occurred when uploading file",
          description: err ? err : null,
          status: "error",
        });
        logger.log(error);
      }
    } else {
      setToast({
        isOpen: true,
        title: "No file provided to upload",
        status: "warning",
        position: "top",
      });
    }
  };

  const update = () => {
    setUserFinancialDetailsDrawerState({isOpen: false});
    fetchData(); // fetch data
  };

  const updateAcc = () => {
    setUserFinancialDetailsDrawerState({isOpen: false});
    fetchData();
  };
  const handleDownload = () => {
    try {
      const localFileUrl =
        process.env.PUBLIC_URL + "/Urzza_CPO undertaking-Letter.docx";

      fetch(localFileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Urzza_CPO undertaking-Letter.docx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          logger.error("Error downloading document:", error);
        });
    } catch (error) {
      logger.error("Error downloading document:", error);
    }
  };

  document.body.addEventListener("drop", (event) => {
    event.preventDefault();
  });

  document.body.addEventListener("dragover", (event) => {
    event.preventDefault();
  });

  // const editHandler = () => {
  //   setTenantDetailsDrawerState({isOpen: true});
  // };

  const editAccountHandler = () => {
    setUserFinancialDetailsDrawerState({isOpen: true});
  };

  const handleSubmit = async () => {
    try {
    } catch (error) {
      logger.error("Error submitting tenant details:", error);
    }
  };

  useEffect(() => {
    fetchData();
    return () => cancelRequest();
  }, []);

  return (
    <Flex
      direction={{base: "column", md: "column"}}
      alignItems="center"
      justifyContent={"flex-start"}
      flex="1"
    >
      <Box
        // flex="1" ml="250px"
        borderStyle="solid"
        borderColor="black"
        borderRadius="10px"
        backgroundColor="white"
        position="relative"
        display="flex"
        flexDirection="column"
        margin="0"
        padding={"10px"}
        width={{base: "80%", md: "100%"}}
      >
        <Container
          maxW="5xl"
          paddingBottom="20px"
          paddingTop="20px"
          mt="20"
          borderColor="grey.400"
          borderWidth="1px"
          borderRadius={"10px"}
          style={{
            paddingTop: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Heading
              as="h1"
              size="lg"
              style={{marginBottom: "34px"}}
              border="#323131"
            >
              User Details
            </Heading>
          </Flex>
          <Flex direction={{base: "column", md: "row"}}>
            <form onSubmit={handleSubmit} style={{width: "48%"}}>
              <FormControl style={{...formControlStyle}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <FormLabel
                    htmlFor="UserName"
                    style={{
                      ...formLabelsStyle,
                      marginBottom: "5px",
                    }}
                  >
                    User Name
                  </FormLabel>
                  <span style={{marginLeft: "2px", fontWeight: "bold"}}>
                    {userDetails?.Name}
                  </span>
                </div>
              </FormControl>
              <FormControl style={{...formControlStyle}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <FormLabel
                    htmlFor="PrimaryEmail"
                    style={{
                      ...formLabelsStyle,
                      marginBottom: "5px",
                    }}
                  >
                    Primary Email
                  </FormLabel>
                  <span style={{marginLeft: "2px", fontWeight: "bold"}}>
                    {userDetails?.Email}
                  </span>
                </div>
              </FormControl>
            </form>
            <form onSubmit={handleSubmit} style={{width: "48%"}}>
              <FormControl style={{...formControlStyle}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <FormLabel
                    htmlFor="PrimaryPhoneNumber"
                    style={{
                      ...formLabelsStyle,
                      marginBottom: "5px",
                    }}
                  >
                    Primary Phone Number
                  </FormLabel>
                  <span style={{marginLeft: "2px", fontWeight: "bold"}}>
                    {userDetails?.MobileNumber}
                  </span>
                </div>
              </FormControl>
            </form>
          </Flex>
        </Container>
        {userFinancialDetailsDrawerState?.isOpen && (
          <UpdateUserFinancialDetailsDrawer update={update} />
        )}
      </Box>
      <Box
        borderStyle="solid"
        borderColor="black"
        borderRadius="10px"
        backgroundColor="white"
        position="relative"
        display="flex"
        flexDirection="column"
        margin="0"
        padding="10px"
        width={{base: "80%", md: "100%"}}
      >
        <Container
          maxW="5xl"
          paddingTop={"20px"}
          paddingBottom="20px"
          borderColor="grey.400"
          borderWidth="1px"
          borderStyle="solid"
          marginTop={"10"}
          borderRadius="10px"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Heading
              as="h1"
              size="lg"
              fontWeight="bold"
              style={{marginBottom: "34px"}}
            >
              User Bank Account Details
            </Heading>
            <Button
              style={{
                marginRight: "55px",
                marginBottom: "15px",
                color: "black",
                border: "1px solid grey",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                borderRadius: "5px",
              }}
              onClick={editAccountHandler}
            >
              <span style={{marginRight: "5px"}}>Edit</span>
              <FaPencilAlt style={{color: "black", fontSize: "15px"}} />
            </Button>
          </Flex>
          <Flex direction={{base: "column", md: "row"}}>
            <form onSubmit={handleSubmit} style={{width: "48%"}}>
              <FormControl style={{...formControlStyle}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <FormLabel
                    htmlFor="accountHolderName"
                    style={{
                      ...formLabelsStyle,
                      marginBottom: "5px",
                    }}
                  >
                    Account Holder Name
                  </FormLabel>

                  <span style={{marginLeft: "2px", fontWeight: "bold"}}>
                    {userDetails?.BeneficiaryName}
                  </span>
                </div>
              </FormControl>
              <FormControl style={{...formControlStyle}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <FormLabel
                    htmlFor="bankName"
                    style={{
                      ...formLabelsStyle,
                      marginBottom: "5px",
                    }}
                  >
                    Bank Name
                  </FormLabel>

                  <span style={{marginLeft: "2px", fontWeight: "bold"}}>
                    {userDetails?.BankName}
                  </span>
                </div>
              </FormControl>
              <FormControl style={{...formControlStyle}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <FormLabel
                    htmlFor="pancard"
                    style={{
                      ...formLabelsStyle,
                      marginBottom: "5px",
                    }}
                  >
                    Pan Card
                  </FormLabel>

                  <span style={{marginLeft: "2px", fontWeight: "bold"}}>
                    {userDetails?.PanCard}
                  </span>
                </div>
              </FormControl>
            </form>
            <form onSubmit={handleSubmit} style={{width: "48%"}}>
              <FormControl style={{...formControlStyle}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <FormLabel
                    htmlFor="accountNumber"
                    style={{
                      ...formLabelsStyle,
                      marginBottom: "5px",
                    }}
                  >
                    Account Number
                  </FormLabel>

                  <span style={{marginLeft: "2px", fontWeight: "bold"}}>
                    {userDetails?.BankAccountNo}
                  </span>
                </div>
              </FormControl>
              <FormControl style={{...formControlStyle}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <FormLabel
                    htmlFor="ifscCode"
                    style={{
                      ...formLabelsStyle,
                      marginBottom: "5px",
                    }}
                  >
                    Ifsc Code
                  </FormLabel>

                  <span style={{marginLeft: "2px", fontWeight: "bold"}}>
                    {userDetails?.IfscCode}
                  </span>
                </div>
              </FormControl>
              <FormControl style={{...formControlStyle}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <FormLabel
                    htmlFor="GstNo"
                    style={{
                      ...formLabelsStyle,
                      marginBottom: "5px",
                    }}
                  >
                    GST Number
                  </FormLabel>

                  <span style={{marginLeft: "2px", fontWeight: "bold"}}>
                    {userDetails?.GstNo}
                  </span>
                </div>
              </FormControl>
            </form>
          </Flex>
        </Container>
      </Box>
      <Box
        borderRadius="10px"
        backgroundColor="white"
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems={"center"}
        margin="0"
        width={{base: "80%", md: "100%"}}
        padding={"10px"}
      >
        <Flex
          maxW={"5xl"}
          padding="20px"
          borderColor="grey.400"
          borderRadius="10px"
          borderWidth="1px"
          borderStyle="solid"
          marginTop="10"
          overflow={"hidden"}
          direction={"column"}
          w={"100%"}
        >
          <Heading
            as="h1"
            size="lg"
            fontWeight="bold"
            style={{marginBottom: "34px"}}
          >
            Payment Settlement Details
          </Heading>
          <Flex
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              marginBottom: "10px",
              border: "1px solid #e3e0e0",
              padding: "20px",
              height: "80px",
            }}
          >
            <Text
              htmlFor="statusofdocumentverification"
              style={{
                width: "270px",
                margin: "0 15px 0 0",
                marginLeft: "85px",
                paddingLeft: "15px",
                fontWeight: "normal",
              }}
            >
              Status Of Document Verification
            </Text>
            <span style={{marginRight: "15px"}}>:</span>

            <Text
              style={{color: "white"}}
              borderRadius="md"
              fontSize="xs"
              fontWeight={"medium"}
              textAlign={"center"}
              p={1}
              w={"24"}
              backgroundColor={color}
            >
              <strong>{statusText}</strong>
            </Text>
          </Flex>
          <Flex
            padding="10px"
            borderWidth="1px"
            borderStyle="solid"
            border="1px solid #e3e0e0"
            marginTop={"10"}
            direction="column"
          >
            <Heading
              marginRight={{base: "0", md: "260px"}}
              marginBottom={{base: "20px", md: "80px"}}
              marginLeft={{base: "0", md: "80px"}}
              as="h3"
              size="md"
            >
              Why We Need To Download UnderTaking Document
            </Heading>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Heading
                marginRight={{base: "0", md: "260px"}}
                marginBottom="20px"
                as="h3"
                size="md"
              >
                Steps To Be Followed
              </Heading>

              <UnorderedList>
                <ListItem>Download Undertaking Document</ListItem>
                <ListItem>
                  Print This Undertaking Document On Karnataka Stamp Paper
                </ListItem>
                <ListItem>Fill The Undertaking Document</ListItem>
                <ListItem>Upload the Filled Document As PDF</ListItem>
                <ListItem>Wait For The Verification To Complete</ListItem>
                <ListItem>
                  Click Here to Complete The Download{" "}
                  <FaArrowDown
                    style={{
                      marginLeft: "150px",
                      marginTop: "3px",
                      fontSize: "25px",
                      marginBottom: "4px",
                    }}
                  />
                </ListItem>
              </UnorderedList>

              <Button
                bg="rgba(232, 116, 25, 0.8)"
                color="white"
                width={{base: "80%", md: "50%"}}
                padding="10px 20px"
                marginBottom="20px"
                marginRight={"70px"}
                _hover={{bg: "rgba(232, 116, 25, 2)"}}
                onClick={handleDownload}
              >
                Download Undertaking Document
              </Button>
            </div>
          </Flex>
          <Container marginTop={"10"}>
            <FormControl
              display="flex"
              flexDirection="column"
              alignItems="center"
              background="#e7e0e0"
              borderRadius="5px"
              padding="10px"
              marginBottom={{base: "30px", md: "0"}}
              borderStyle="dashed"
              borderColor="black"
              borderWidth="2px"
              cursor="pointer"
              minHeight={{base: "220px", md: "170px"}}
              onClick={handleUpload}
              onDrop={handleDrop}
            >
              <FormLabel
                htmlFor="downloadundertaking"
                marginBottom="10px"
                fontWeight="bold"
                textAlign="center"
                pointerEvents="none"
              >
                Upload Document
              </FormLabel>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div style={{marginRight: "10px", width: "30%"}}>
                  <Input
                    cursor={"pointer"}
                    type="text"
                    onChange={(e) => handleInput(e)}
                    onDrop={handleDrop}
                    value={selectedFile.name}
                    isReadOnly
                    placeholder="Upload file"
                    textAlign="center"
                    marginBottom={{base: "10px", md: "0"}}
                    marginRight={"40px"}
                    width="150%"
                    height={{base: "40px", md: "50px"}}
                  />

                  <Text fontSize="sm" color="black.500" marginLeft="45px">
                    or
                  </Text>
                  <Text fontSize="sm" color="gray.500" marginTop="15px">
                    Drag and drop files here
                  </Text>
                  <Text fontSize="sm">Only .pdf formats allowed</Text>
                </div>
                
              </div>
            </FormControl>
            <Button
              style={{
                position: "absolute",
                bottom: "30px",
                left: "74%",
                backgroundColor: "green",
                color: "white",
                border: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              leftIcon={
                <FaCloudUploadAlt
                  style={{
                    color: "white",
                    fontSize: "20px",
                    marginRight: "6px",
                  }}
                />
              }
              onClick={uploadHandler}
            >
              Upload
            </Button>
          </Container>
        </Flex>
      </Box>
      {userFinancialDetailsDrawerState?.isOpen && (
        <UpdateUserFinancialDetailsDrawer update={updateAcc} />
      )}
    </Flex>
  );
};

export default AccountSettings;
