export const rfidDrawerSelectOptions = [
    {
      label: "Id Tag",
      placeholder: "Id Tag",
      type: "input",
      name: "IdTag",
      helper: "",
    },
    {
      label: "Mobile Number",
      placeholder: "Enter Mobile Number",
      type: "tel", // Changed to "tel" type for phone numbers
      name: "MobileNumber",
      helper: "*Note: type without country code",
    },
    {
      label: "Email",
      placeholder: "Email",
      type: "email",
      name: "Email",
      helper: "",
    },
    {
      label: "Balance",
      placeholder: "Enter Amount",
      type: "number",
      name: "Balance",
      helper: "",
    },
    {
      label: "Status",
      placeholder: "Status",
      type: "select",
      name: "Status",
      helper: "",
      options: [
        {name: "Active", value: true},
        {name: "InActive", value: false},
      ],
    },
    {
      label: "Rf-id Type",
      placeholder: "rf-id type",
      type: "select",
      name: "RfidType",
      options: [
        {name: "Authorized", value: "rfid_authorized"},
        {name: "Wallet", value: "rfid_wallet"},
      ],
    },
  ]
   