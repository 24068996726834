import React, {useEffect, useState} from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import {viewTenantsDrawerState} from "atoms/viewTenantsDrawer";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import {toastState} from "atoms/toastState";
import useAxios from "axios-hooks";
import {updateTenantDrawerState} from "atoms/updateTenantDrawerState";
import logger from "infra/logger";
import Card from "components/card/Card";
import LoadingSpinner from "components/feedback/LoadingSpinner";

const ViewTenants = (props) => {
  const {tenantsData, getTenants} = props;
  const tenantsDrawer = useSetRecoilState(viewTenantsDrawerState);
  const [viewTenantsState, setViewTenantsState] = useRecoilState(
    viewTenantsDrawerState
  );
  const [drawerState, setDrawerState] = useRecoilState(updateTenantDrawerState);
  const setToast = useSetRecoilState(toastState);
  const [tenantsDetails, setTenantsDetails] = useState({
    FirstName: "",
    LastName: "",
    PhoneNumber: "",
    PrimaryEmail: "",
    NameSpace: "",
    Type: "",
    Code: "",
  });

  const inputStyle = {
    focusBorderColor: "#f57d36",
    fontSize: "sm",
    width: "400px",
    height: "50px",
  };
  const validationSchema = yup.object().shape({
    PhoneNumber: yup
      .string()
      .matches(/^[0-9]*$/, "Phone Number must contain only digits")
      .max(10)
      .min(10)
      .required("Phone Number is required "),
    PrimaryEmail: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address format"
      )
      .required("Email is required"),
    NameSpace: yup.string().required("NameSpace is required"),
    Code: yup.string().required("Code is required"),
  });
  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      FirstName: tenantsData?.FirstName || "",
      LastName: tenantsData?.LastName || "",
      PhoneNumber: tenantsData?.PhoneNumber || "",
      PrimaryEmail: tenantsData?.PrimaryEmail || "",
      NameSpace: tenantsData?.NameSpace || "",
      // Role: tenantsData?.Role || "",
      Type: tenantsData?.Type || "",
      Code: tenantsData?.Code || "",
      BankAccountNo: tenantsData?.BankAccountNo || "",
      BankName: tenantsData?.BankName || "",
      IfscCode: tenantsData?.IfscCode || "",
      BeneficiaryName: tenantsData?.BeneficiaryName || "",
      GstNo: tenantsData?.GstNo || "",
    },
  });
  const getTenantsDetails = async () => {
    try {
      const data = tenantsData;

      setValue("FirstName", data.FirstName);
      setValue("LastName", data.LastName);
      setValue("PrimaryEmail", data.PrimaryEmail);
      setValue("PhoneNumber", data.PhoneNumber);
      setValue("NameSpace", data.NameSpace);
      // setValue("Role", data.Role);
      setValue("Type", data.Type);
      setValue("Code", data.Code);
      setTenantsDetails(data);
    } catch (error) {
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "Error occured while getting user details",
          status: "error",
        });
        // closeHandler();
      }
    }
  };
  const [
    {data: tenantsDetailsData, loading: tenantsDetailsLoading},
    updateTenants,
  ] = useAxios(
    {method: "PATCH", url: `/api/tenant/update/${tenantsData?.Id}`},
    {manual: true}
  );

  const saveHandler = async () => {
    const formValues = getValues();
    let data = {};
    if (formValues.FirstName.trim() !== "") {
      data["FirstName"] = formValues.FirstName.trim();
    }
    if (formValues.LastName.trim() !== "") {
      data["LastName"] = formValues.LastName.trim();
    }
    if (formValues.PrimaryEmail.trim() !== "") {
      data["PrimaryEmail"] = formValues.PrimaryEmail.trim();
    }
    if (formValues.PhoneNumber.trim() !== "") {
      data["PhoneNumber"] = formValues.PhoneNumber.trim();
    }
    if (formValues.NameSpace.trim() !== "") {
      data["NameSpace"] = formValues.NameSpace.trim();
    }
    if (formValues.Type.trim() !== "") {
      data["Type"] = formValues.Type.trim();
    }
    if (formValues.Code.trim() !== "") {
      data["Code"] = formValues.Code.trim();
    }
    if (formValues.BankAccountNo.trim() !== "") {
      data["BankAccountNo"] = formValues.BankAccountNo.trim();
    }
    if (formValues.BankName.trim() !== "") {
      data["BankName"] = formValues.BankName.trim();
    }
    if (formValues.IfscCode.trim() !== "") {
      data["IfscCode"] = formValues.IfscCode.trim();
    }
    if (formValues.BeneficiaryName.trim() !== "") {
      data["BeneficiaryName"] = formValues.BeneficiaryName.trim();
    }
    if (formValues.GstNo.trim() !== "") {
      data["GstNo"] = formValues.GstNo.trim();
    }
    if (Object.keys(data).length > 0) {
      try {
        const response = await updateTenants({data: data});
        setToast({
          isOpen: true,
          title: "Tenant updated successfully",
          status: "success",
        });
        setTenantsDetails(data);
        tenantsDrawer(null);
        getTenants();
      } catch (error) {
        setToast({
          isOpen: true,
          title: "Error occured while updating Tenant",
          status: "error",
        });
      }
    }
    logger.log("data", data);
  };
  const closeHandler = () => {
    tenantsDrawer(null);
  };
  useEffect(() => {
    if (drawerState?.ActiveId) {
      getTenantsDetails();
    }
  }, []);

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      marginBottom="20px"
      overflowX={{sm: "scroll", lg: "hidden"}}
    >
      <Drawer
        isOpen={viewTenantsState.isOpen}
        placement="right"
        onClose={() => setViewTenantsState({isOpen: false})}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Edit Tenant Details</DrawerHeader>
          <DrawerBody>
            { !tenantsDetailsLoading ? (
            <Card>
              <form onSubmit={handleSubmit(saveHandler)}>
                <Flex direction="column">
                  <FormControl mb="20px" isInvalid={errors.FirstName}>
                    <FormLabel fontSize={16}>FirstName</FormLabel>
                    <Input
                      placeholder="FirstName"
                      type="text"
                      {...register("FirstName")}
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                    />
                    <FormErrorMessage>
                      {errors.FirstName?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mb="20px" isInvalid={errors.LastName}>
                    <FormLabel fontSize={16}>LastName</FormLabel>
                    <Input
                      placeholder="LastName"
                      {...register("LastName")}
                      type="text"
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                    />
                    <FormErrorMessage>
                      {errors.LastName?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mb="20px" isInvalid={errors.PrimaryEmail}>
                    <FormLabel fontSize={16}>Primary Email</FormLabel>
                    <Input
                      placeholder="Primary Email"
                      {...register("PrimaryEmail")}
                      type="email"
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                    />
                    <FormErrorMessage>
                      {errors.PrimaryEmail?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mb="20px" isInvalid={errors.PhoneNumber}>
                    <FormLabel fontSize={16}>Phone Number</FormLabel>
                    <Input
                      placeholder="Phone Number"
                      {...register("PhoneNumber")}
                      type="tel"
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                    />
                    <FormErrorMessage>
                      {errors.PhoneNumber?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mb="20px" isInvalid={errors.NameSpace}>
                    <FormLabel fontSize={16}>NameSpace</FormLabel>
                    <Input
                      placeholder="NameSpace"
                      {...register("NameSpace")}
                      type="text"
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                    />
                    <FormErrorMessage>
                      {errors.NameSpace?.message}
                    </FormErrorMessage>
                  </FormControl>
                  {/* <FormControl mb="20px" isInvalid={errors.Role}>
                    <FormLabel fontSize={16}>Role</FormLabel>
                    <Select
                      placeholder="Role"
                      {...register("Role")}
                      type="text"
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                      isDisabled={true}
                    >
                      <option value="admin">Admin</option>
                    </Select>
                    <FormErrorMessage>{errors.Role?.message}</FormErrorMessage>
                  </FormControl> */}
                  <FormControl mb="20px" isInvalid={errors.Type}>
                    <FormLabel fontSize={16}>Type</FormLabel>
                    <Select
                      placeholder="Select Type"
                      {...register("Type")}
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                      isDisabled={true}
                    >
                      <option value="cpo">CPO</option>
                      <option value="bco">BCO</option>
                      <option value="cpo_operated_by_urzza">
                        CPO Operated by URZZA
                      </option>
                    </Select>
                    <FormErrorMessage>{errors.Type?.message}</FormErrorMessage>
                  </FormControl>

                  <FormControl mb="20px" isInvalid={errors.Code}>
                    <FormLabel fontSize={16}>Code</FormLabel>
                    <Input
                      placeholder="Code"
                      {...register("Code")}
                      type="text"
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                    />
                    <FormErrorMessage>{errors.Code?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl mb="20px" isInvalid={errors.Status}>
                    <FormLabel fontSize={16}>BankAccountNo</FormLabel>
                    <Input
                      placeholder="BankAccountNo"
                      {...register("BankAccountNo")}
                      type="text"
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                    />
                    <FormErrorMessage>
                      {errors.BankAccountNo?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mb="20px" isInvalid={errors.Status}>
                    <FormLabel fontSize={16}>BankName</FormLabel>
                    <Input
                      placeholder="BankName"
                      {...register("BankName")}
                      type="text"
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                    />
                    <FormErrorMessage>
                      {errors.BankName?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mb="20px" isInvalid={errors.Status}>
                    <FormLabel fontSize={16}>IfscCode</FormLabel>
                    <Input
                      placeholder="IfscCode"
                      {...register("IfscCode")}
                      type="text"
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                    />
                    <FormErrorMessage>
                      {errors.IfscCode?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mb="20px" isInvalid={errors.Status}>
                    <FormLabel fontSize={16}>BeneficiaryName</FormLabel>
                    <Input
                      placeholder="BeneficiaryName"
                      {...register("BeneficiaryName")}
                      type="text"
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                    />
                    <FormErrorMessage>
                      {errors.BeneficiaryName?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl mb="20px" isInvalid={errors.Status}>
                    <FormLabel fontSize={16}>GstNo</FormLabel>
                    <Input
                      placeholder="GstNo"
                      {...register("GstNo")}
                      type="text"
                      focusBorderColor="#f57d36"
                      fontSize="sm"
                      width="400px"
                      height="50px"
                    />
                    <FormErrorMessage>{errors.GstNo?.message}</FormErrorMessage>
                  </FormControl>
                </Flex>
              </form>
            </Card>
          ) : (
            <LoadingSpinner />
          )}
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={closeHandler}>
              Cancel
            </Button>
            <Button
              bg="#f57d36"
              color="white"
              colorScheme="orange"
              onClick={handleSubmit(saveHandler)}
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Card>
  );
};

export default ViewTenants;
