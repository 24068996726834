import { getCurrentDate } from "utils/utils";
import { getFirstDateOfMonth } from "utils/utils";

export const dynamicStatsSelectOptions = [
  {
    label: "From",
    type: "datetime-local",
    name: "From",
    defaultValue: getFirstDateOfMonth(),
  },
  {
    label: "To",
    type: "datetime-local",
    name: "To",
    defaultValue: getCurrentDate(),
  },
  {
    label: "ChargePoint",
    type: "select",
    name: "ChargePoint",
    defaultValue: "all",
  },
];

export const dynamicAnalyticsSelectOptions = [
  {
    label: "ChargePoint",
    type: "select",
    name: "ChargePoint",
    defaultValue: "all",
  },
];
