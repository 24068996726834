import React, { useEffect, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { auth } from "../../../firebase";
import useAuthentication from "hooks/UseAuthentication";
import Footer from "components/footer/FooterAuth";
import LOGO from "../../../assets/img/cmsLogo.svg";
import { toastState } from "atoms/toastState";
import { useSetRecoilState } from "recoil";
import useAxios from "axios-hooks";
import { configureAxios } from "utils/api";
const ResetPasswordPage = () => {
  // Chakra color mode
  //const textColor = useColorModeValue("navy.700", "white");
  const textColor = "white";
  //const textColorSecondary = "gray.400";
  const textColorSecondary = "white";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const [isLoading, setIsLoading] = useState(false);
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const [show, setShow] = useState(false);
  const [resetPasswordToken, setResetPasswordToken] = useState("");
  const setToast = useSetRecoilState(toastState);
  const navigate = useNavigate();
  const location = useLocation();

  const schema = yup.object().shape({
    NewPassword: yup.string().required("password is required"),
    ConfirmNewPassword: yup.string().required("password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [{ data, loading: resetPasswordLoading, error }, resetPassword] =
    useAxios(
      { method: "POST", url: `/api/auth/resetpassword` },
      { manual: true }
    );

  const [
    {
      data: encodedStringValid,
      loading: encodedStringValidLoading,
      error: encodedStringValidError,
    },
    verifyResetPasswordString,
  ] = useAxios(
    { method: "POST", url: `/api/auth/verifyresetpasswordstring` },
    { manual: true }
  );

  const handleClick = () => setShow(!show);

  const verifyEncodedString = async (resetToken) => {
    try {
      let params = {};
      params["encodedString"] =
        location?.state?.encodedString !== undefined
          ? location?.state?.encodedString
          : resetToken;
      const response = await verifyResetPasswordString({ params });
      if (response?.data?.redirect) {
        navigate(response?.data?.redirect);
      }
    } catch (error) {
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "UnknownError",
          status: "error",
        });
        navigate("/auth/signIn");
      }
    }
  };
  const submitHandler = async () => {
    setIsLoading(true);
    const formValues = getValues();
    const NewPassword = formValues.NewPassword;
    const ConfirmNewPassword = formValues.ConfirmNewPassword;
    if (NewPassword === ConfirmNewPassword) {
      try {
        let reqData = {};
        // reqData["email"] = location?.state?.email;
        reqData["newPassword"] = NewPassword;
        reqData["encodedString"] =
          location?.state?.encodedString !== undefined
            ? location?.state?.encodedString
            : resetPasswordToken;
        const response = await resetPassword({ data: reqData });
        navigate("/auth/signIn");
        setToast({
          isOpen: true,
          title: "Password reset successfully",
          status: "success",
        });
        setIsLoading(false);
      } catch (error) {
        if (error?.code !== "ERR_CANCELED") {
          setToast({
            isOpen: true,
            title: "UnknownError",
            status: "error",
          });
          navigate("/auth/signIn");
          setIsLoading(false);
        }
      }
    } else {
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "Passwords don't match",
          status: "error",
        });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    configureAxios(null);
    const urlParams = new URLSearchParams(window.location.search);
    const resetToken = urlParams.get("resetPasswordToken");
    setResetPasswordToken(resetToken);
    verifyEncodedString(resetToken); // Pass resetToken directly to the function
  }, [location.search]);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        w={{ base: "100%", md: "100%", lg: "50%" }}
        h="100%"
        zIndex={100}
        direction="column"
        alignItems={{ base: "center", lg: "flex-start" }}
        justifyContent={"flex-start"}
        gap={40}
        position={"relative"}
        pl={{ base: "unset", lg: "100px", md: "unset" }}
        pt={{ base: 10, md: 20, lg: 25 }}
      >
        <Image src={LOGO} alt="URZZA CMS" w="auto" h="80px" />
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex direction="column" alignItems={"flex-start"} w={"100%"}>
            <Heading color={textColor} fontSize="36px" mb="10px">
              Reset Password
            </Heading>

            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Enter password to reset your password
            </Text>
          </Flex>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <form onSubmit={handleSubmit(submitHandler)}>
              <Controller
                name="NewPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl isInvalid={errors.NewPassword} isRequired>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      New Password
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        {...field}
                        fontSize="sm"
                        isRequired
                        placeholder="New Password"
                        mb="5px"
                        size="lg"
                        type={show ? "text" : "password"}
                        variant="auth"
                        borderRadius={0}
                        bg={"white"}
                      />
                      <InputRightElement
                        display="flex"
                        alignItems="center"
                        mt="4px"
                        color={"blackAlpha.200"}
                      >
                        <Icon
                          color={"blackAlpha.400"}
                          _hover={{ cursor: "pointer" }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={handleClick}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage mb={"20px"}>
                      {errors.NewPassword && errors.NewPassword.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                name="ConfirmNewPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl isInvalid={errors.ConfirmNewPassword} isRequired>
                    <FormLabel
                      mt="25px"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Confirm New Password
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        {...field}
                        fontSize="sm"
                        isRequired
                        placeholder="Confirm New Password"
                        mb="5px"
                        size="lg"
                        type={show ? "text" : "password"}
                        variant="auth"
                        borderRadius={0}
                        bg={"white"}
                      />
                      <InputRightElement
                        display="flex"
                        alignItems="center"
                        mt="4px"
                        color={"blackAlpha.200"}
                      >
                        <Icon
                          color={"blackAlpha.400"}
                          _hover={{ cursor: "pointer" }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={handleClick}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage mb={"20px"}>
                      {errors.ConfirmNewPassword &&
                        errors.ConfirmNewPassword.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Flex
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row"
              >
                <Button
                  type="submit"
                  fontSize="md"
                  variant="brand"
                  _hover={{ backgroundColor: "#ff5c00" }}
                  _focus={{ bg: "#ff5c00 !important" }}
                  fontWeight="700"
                  h="50"
                  mb="24px"
                  px={20}
                  bg="#f57d36"
                  color="white"
                  mt="20px"
                  borderRadius={0}
                  colorScheme="blue"
                  isLoading={isLoading}
                  loadingText="Signing In"
                  spinner={<Spinner color="white" />}
                >
                  Submit
                </Button>
                <Text
                  style={{
                    cursor: "pointer",
                    color: "#f57d36",
                    fontWeight: "500",
                    textDecoration: "underline",
                    textDecorationColor: "#f57d36",
                  }}
                  onClick={() => navigate("/auth/signIn")}
                >
                  Back to SignIn?
                </Text>
              </Flex>
            </form>
          </Flex>
        </Flex>

        <Footer />
      </Flex>
    </DefaultAuth>
  );
};

export default ResetPasswordPage;
