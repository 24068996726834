import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useRecoilState } from "recoil";

import { deleteModalState } from "atoms/deleteModalState";
import Card from "components/card/Card";
import DeleteModal from "components/modals/DeleteModal";

const StationImageRender = ({ images, getImages }) => {
  const [showDeleteModal, setShowDeleteModal] =
    useRecoilState(deleteModalState);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedDeleteImage, setSelectedDeleteImage] = useState(null);

  const handleDeleteClick = (image) => {
    setSelectedDeleteImage(images.indexOf(image));
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setSelectedDeleteImage(null);
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(false);
    getImages();
  };

  const handleImageClick = (image) => {
    setSelectedImageIndex(images.indexOf(image));
  };

  const handlePrevClick = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const renderImage = (index) => {
    const image = images[index];

    return (
      <Flex direction="column" align="center" justifyContent={"center"} key={index} position={"relative"} width={"100%"} height={"100%"}>
        <Image 
          src={image.ImageUrl}
          alt={`Image ${index + 1}`}
          fallbackSrc="https://via.placeholder.com/600"
          maxWidth={"75%"}
          maxHeight={"80%"}
        />
        <Text mt={2} bottom={1} position={"absolute"}>Image {index + 1}</Text>
      </Flex>
    );
  };

  return (
    <Card>
      {images.length > 0 ? (
        <Grid gap={2.5} templateColumns="repeat(auto-fit, minmax(150px,0fr))">
          {images.map((image, index) => (
            <GridItem key={index}>
              <Flex
                position="relative"
                borderRadius={3}
                borderColor="black.500"
                borderWidth={0.5}
                overflow={"hidden"}
              >
                <Image
                  width="150px"
                  height="150px"
                  src={image.ImageUrl}
                  alt="no image"
                  fallbackSrc="https://via.placeholder.com/150"
                  onClick={() => handleImageClick(image)}
                  cursor="pointer"
                />
                <IconButton
                  w="20px"
                  h="30px"
                  position="absolute"
                  bottom="0"
                  right="0"
                  zIndex={2}
                  backgroundColor="red"
                  borderRadius={0}
                  borderTopLeftRadius={5}
                  borderLeftColor={"white"}
                  borderTopColor={"white"}
                  borderWidth={2}
                  _hover={{ bgColor: "blue" }}
                  onClick={() => handleDeleteClick(image)}
                >
                  <DeleteIcon color={"white"} />
                </IconButton>
              </Flex>
            </GridItem>
          ))}
        </Grid>
      ) : (
        <Text> No Images</Text>
      )}
      {selectedImageIndex !== null && (
        <Modal
          isOpen={true}
          onClose={() => setSelectedImageIndex(null)}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Image Preview</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex justify="space-between" align="center" width={"100%"} height={"400px"}>
                <IconButton
                  aria-label="Previous"
                  icon={<ChevronLeftIcon />}
                  onClick={handlePrevClick}
                  ml={2}
                />
                {renderImage(selectedImageIndex)}
                <IconButton
                  aria-label="Next"
                  icon={<ChevronRightIcon />}
                  onClick={handleNextClick}
                  mr={2}
                />
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        data={images[selectedDeleteImage]}
        title="chargingStationImage"
      />
    </Card>
  );
};

export default StationImageRender;
