import { Box, Flex, Text } from "@chakra-ui/react";
import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { downtimePieChartOptions } from "variables/charts";
import Card from "components/card/Card";

const TotalDowntimeReport = ({ ChargePoint, From, To, refresh }) => {
  const [totalMinsInSelectedTimeFrame, settotalMinsInSelectedTimeFrame] =
    useState(0);
  const [chartOptions, setChartOptions] = useState(downtimePieChartOptions);

  const [{ data, loading, error }, execute, cancelRequest] = useAxios(
    {
      method: "GET",
      url: `/api/dashboard/analytics?key=downtime_report&cp=${
        ChargePoint === "all" ? "" : ChargePoint
      }&startTime=${From}&endTime=${To}`,
    },
    { manual: true }
  );

  const calculateTotalMins = () => {
    const fromDate = new Date(From);
    const toDate = new Date(To);
    const diffInMins = (toDate.getTime() - fromDate.getTime()) / 1000 / 60; // in minutes

    settotalMinsInSelectedTimeFrame(diffInMins);
  };

  const getDowntimeReportData = async () => {
    try {
      const response = await execute();
      const downtimeData = response.data.data;

      // convert to minutes
      const downtimeDataInMins = downtimeData / 60;

      setChartOptions((prev) => ({
        ...prev,
        legend: {
          ...prev.legend,
          data: ["Downtime", "Uptime"], // Static legend data
        },
        series: [
          {
            ...prev.series[0],
            data: [
              {
                value: downtimeDataInMins.toFixed(3),
                name: "Downtime",
                itemStyle: { color: "tomato" },
              },
              {
                value:
                  totalMinsInSelectedTimeFrame - downtimeDataInMins.toFixed(3),
                name: "Uptime",
                itemStyle: { color: "#3b7bc8" },
              },
            ],
          },
        ],
      }));
    } catch (error) {
      console.error("Error fetching downtime data:", error);
    }
  };

  useEffect(() => {
    calculateTotalMins();
    if (totalMinsInSelectedTimeFrame > 0) {
      setTimeout(() => getDowntimeReportData(), 300);
      return () => cancelRequest();
    }
  }, [From, To, ChargePoint, refresh]);

  return (
    <Box w="full" h="full" p="4" bg="white" borderRadius="md">
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-evenly"
      >
        {/* Chart Section */}
        <Card
          p="20px"
          align="center"
          direction="column"
          w="100%"
          flex="1"
          borderWidth="1px"
          borderColor="gray.200"
          borderRadius="md"
        >
          <Flex
            px={{ base: "0px", "2xl": "10px" }}
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            mb="8px"
          >
            <Text color="gray.500" fontSize="md" fontWeight="600" mt="4px">
              Downtime Vs Uptime
            </Text>
          </Flex>
          <ReactECharts option={chartOptions} />
        </Card>
      </Flex>
    </Box>
  );
};

export default TotalDowntimeReport;
