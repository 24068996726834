import {loggedInUserState} from "atoms/loggedInUserState";
import React from "react";
import {Navigate} from "react-router-dom";
import {useRecoilValue} from "recoil";

const PrivateRoute = ({element: Component, roles, ...rest}) => {
  const loggedInUser = useRecoilValue(loggedInUserState);
  if (loggedInUser) {
    if (roles && !roles.includes(loggedInUser?.role)) {
      return <Navigate to="/404" />;
    }
  }
  return <Component {...rest} />;
};

export default PrivateRoute;
