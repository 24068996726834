export const tableHeaders = [
  {
    Header: "S.No",
    // accessor: "data.name", 
  },
  {
    Header: "ChargePoint",
    // accessor: "data.phoneNumber", // Accessing phoneNumber from the JSON data
  },
  {
    Header: "Description",
    // accessor: "data.city", // Accessing city from the JSON data
  },
  {
    Header: "Visibility",
    // accessor: "data.chargingPoints[0].isActive", // Accessing isActive from the chargingPoints array in JSON data
  },
  {
    Header: "Status",
  },
  {
    Header: "Last Connected",
  },
  {
    Header: "Actions",
  },
];

export const stationTableHeaders = [
  {
    Header: "S.No",
    // accessor: "data.name",  
  },
  {
    Header: "Name",
    // accessor: "data.phoneNumber", // Accessing phoneNumber from the JSON data
  },
  {
    Header: "PinCode",
    // accessor: "data.city", // Accessing city from the JSON data
  },
  {
    Header: "Status",
    // accessor: "data.chargingPoints[0].isActive", // Accessing isActive from the chargingPoints array in JSON data
  },
  {
    Header: "No.of CP",
  },

  {
    Header: "Actions",
  },
];

export const chargingSessionTableHeaders = [
  {
    Header: "S.No",
    // accessor: "data.name", 
  },
  {
    Header: "Source",
    // accessor: "data.requestSource", // Accessing Source from the JSON data
  },
  {
    Header: "Session Id",
    // accessor: "data.sessionId", // Accessing sessionId from the JSON data
  },
  {
    Header: "Transaction Id",
    // accessor: "data.transactionId", // Accessing transactionId from the JSON data
  },
  {
    Header: "Start Time",
    // accessor: "data.city", // Accessing city from the JSON data
  },
  {
    Header: "End Time",
    // accessor: "data.chargingPoints[0].isActive", // Accessing isActive from the chargingPoints array in JSON data
  },
  {
    Header: "No of Units Dispatched",
  },
  {
    Header: "Total Amount",
  },
];

export const billingPlanTableHeaders = [
  {
    Header: "S.No",
  },
  {
    Header: "Charge Points",
  },
  {
    Header: "Price Per Kwh",
  },
  {
    Header: "Tax Enabled",
  },
  {
    Header: "Total Price Per Unit",
  },
];
