import {
  Box,

} from "@chakra-ui/react";
import React, { useState } from "react";

import ChargingStationTabs from "./tabs/ChargingStationTabs";

const AddChargingStation = () => {
  const [input, setInput] = useState(null);
  const isError = input?.stationName === "";
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "20px" }}>
    <ChargingStationTabs />
    </Box>
  );
};

export default AddChargingStation;
