import React from "react";

// Chakra imports
import {Flex, useColorModeValue} from "@chakra-ui/react";

// Custom components
import {MainLogo} from "components/icons/Icons";
import {HSeparator} from "components/separator/Separator";
import { useNavigate } from "react-router-dom";

export function SidebarBrand() {
  const navigate = useNavigate();
  return (
    <Flex
      align="center"
      direction="column"
      justifyContent={"space-between"}
      position={"relative"}
      onClick={() => navigate("/admin/dashboard")}
    >
      <MainLogo width="80%" />
      <HSeparator mt="12px" />
    </Flex>
  );
}

export default SidebarBrand;
