import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Spinner,
  Select,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useRecoilState, useSetRecoilState } from "recoil";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { toastState } from "atoms/toastState";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import Card from "components/card/Card";
import { createRfidDrawerState } from "atoms/createRfidDrawerState";
import logger from "infra/logger";
import { rfidDrawerSelectOptions } from "../variables/rfidDrawerSelectOptions";

const CreateRfidDrawer = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const setToast = useSetRecoilState(toastState);
  const [drawerState, setDrawerState] = useRecoilState(createRfidDrawerState);

  const validationSchema = yup.object().shape({
    IdTag: yup.string().min(1).required("Id Tag is required"),
    MobileNumber: yup
      .string()
      .matches(/^[0-9]*$/, "Mobile Number must contain only digits")
      .max(10)
      .min(10)
      .required("Phone Number is required "),
    Email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address format"
      )
      .required("Email is required"),
    Balance: yup
      .number()
      .default(0)
      .min(0)
      .required("please specify topup Amount"),
    Status: yup
      .boolean()
      .oneOf([true, false], "Select a valid option")
      .required("Activation is required"),
    RfidType: yup.string().required("Rfid Type is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [{ data, loading: createLoading, error }, createRfid] = useAxios(
    { method: "POST", url: `/api/rfid/create` },
    { manual: true }
  );

  const getStatus = (status) => {
    if (status === "false") {
      return false;
    } else if (status === "true") {
      return true;
    }
  };

  const createHandler = async () => {
    setIsLoading(true);
    const formValues = getValues();
    logger.log(formValues);
    let data = {};
    data["idTag"] = formValues.IdTag;
    if (formValues.Email.trim() !== "") {
      data["email"] = formValues.Email.trim();
    }
    if (formValues.MobileNumber.trim() !== "") {
      data["mobileNumber"] = formValues.MobileNumber.trim();
    }
    data["balance"] = parseFloat(formValues.Balance);
    data["status"] = getStatus(formValues.Status);
    data["type"] = formValues.RfidType;
    if (Object.keys(data).length === 6) {
      try {
        const response = await createRfid({ data: data });
        setToast({
          isOpen: true,
          title: "Rfid Created successfully",
          status: "success",
        });
        props.update();
      } catch (error) {
        const err = error?.response?.data?.error;
        logger.log(error);
        setToast({
          isOpen: true,
          title: "Error occured when creating Rfid",
          description: err ? err : null,
          status: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        title: "Enter all the fields to create Rfid",
        status: "warning",
        position: "top",
      });
    }
    setIsLoading(false);
  };

  const closeHandler = () => {
    setDrawerState(null);
  };

  return (
    <Drawer
      isOpen={drawerState?.isOpen}
      placement="right"
      onClose={closeHandler}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Generate a new Rf-id Card</DrawerHeader>

        <DrawerBody>
          {!createLoading ? (
            <>
              <Card>
                <form>
                  {rfidDrawerSelectOptions.map((item, index) => (
                    <Flex key={index} mb="20px" alignItems="center">
                      <Controller
                        name={item.name}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl
                            isRequired
                            flexDirection="row"
                            isInvalid={errors[item.name]}
                          >
                            <FormLabel fontSize={20}>{item.label}</FormLabel>
                            {item.type === "select" ? (
                              <Select
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                width="400px"
                                height="50px"
                                isRequired
                              >
                                <option value="">Select</option>
                                {item.options.map((option, ind) => {
                                  return (
                                    <option
                                      key={ind}
                                      value={option.value}
                                      disabled={option.name === "Wallet"}
                                    >
                                      {option.name}
                                    </option>
                                  );
                                })}
                              </Select>
                            ) : (
                              <Input
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                placeholder={item.placeholder}
                                width="400px"
                                height="50px"
                                type={item.type}
                                isRequired
                              />
                            )}
                            <FormHelperText color={"#f57d36"}>
                              {item.helper}
                            </FormHelperText>
                            <FormErrorMessage mb={"20px"}>
                              {errors[item.name] && errors[item.name].message}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    </Flex>
                  ))}
                </form>
              </Card>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            bg="#f57d36"
            color="white"
            colorScheme="orange"
            onClick={handleSubmit(createHandler)}
            isLoading={isLoading}
            loadingText="Creating"
            spinner={<Spinner size="md" />}
            disabled={isLoading}
          >
            Create
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CreateRfidDrawer;
