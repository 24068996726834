import {Box, Button, Flex, Text} from "@chakra-ui/react";
import React, {Suspense, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useAxios from "axios-hooks";

import ChargingStationTabs from "./tabs/ChargingStationTabs";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import logger from "infra/logger";

const ViewChargingStation = () => {
  const {id} = useParams(); //shoulf fetch station details with this id
  const navigate = useNavigate();
  const initialTabIndex = 5;

  const [station, SetStation] = useState(null);
  const [{data, loading, error}, getStation] = useAxios(
    {method: "GET", url: `/api/chargingStation/${id}`},
    {manual: true}
  );
  const getChargingStation = async () => {
    try {
      const response = await getStation();
      SetStation(response.data.data);
    } catch (error) {
      logger.log(error);
    }
  };

  useEffect(() => {
    getChargingStation();
  }, []);

  return (
    <Box pt={{base: "130px", md: "80px", xl: "20px"}}>
      <Suspense fallback={<LoadingSpinner />}>
        <Flex
          mb={5}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text
            ml={5}
            fontWeight={700}
            fontSize={30}
            color="#f57d36"
            textAlign={"center"}
          >
            {station?.Name}
          </Text>
          <Button
            bgColor={"#f57d36"}
            _hover={{bgColor: "#d44e00"}}
            onClick={() => navigate(-1)}
            marginLeft={"auto"}
            color={"white"}
            p={5}
            borderRadius={8}
          >
            Back
          </Button>
        </Flex>

        <ChargingStationTabs
          id={id}
          data={station}
          getStation={getChargingStation}
          initialTabIndex={initialTabIndex}
        />
      </Suspense>
    </Box>
  );
};

export default ViewChargingStation;
