import { Box, Text } from "@chakra-ui/react";
import React from "react";

const Billing = () => {
    return (
            <Text>Billing</Text>
        
    );
}

export default Billing