import {toastState} from "atoms/toastState";
import useAxios from "axios-hooks";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import PayoutsTable from "../components/PayoutsTable";
import {payoutsTableHeaders} from "../variables/tableHeaders";
import logger from "infra/logger";
import {Box} from "@chakra-ui/react";

const Payouts = () => {
  const [payouts, setPayouts] = useState([]);
  const setToast = useSetRecoilState(toastState);
  const [{data, loading, error}, getPayouts, cancelRequest] = useAxios(
    {method: "GET", url: "api/payouts/all"},
    {manual: true}
  );

  const fetchPayouts = async () => {
    try {
      const response = await getPayouts();
      logger.log("response data is : ");
      logger.log(response?.data?.data);
      setPayouts(response?.data?.data);
      // setPayoutsResponse(response?.data?.data);
    } catch (error) {
      logger.log(error);
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "Error Occured",
          status: "error",
          description: error?.response?.data?.error,
        });
      }
    }
  };

  useEffect(() => {
    fetchPayouts();
    return () => cancelRequest();
  }, []);
  return payouts?.length > 0 ? (
    <PayoutsTable columnsData={payoutsTableHeaders} tableData={payouts} />
  ) : (
    <Box w="100%" padding={5}>
      No Previous Payouts
    </Box>
  );
};

export default Payouts;
