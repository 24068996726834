import { useRecoilState, useSetRecoilState } from "recoil";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "axios-hooks";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";

import { createChargingPointDrawerState } from "atoms/createChargingPointDrawerState";
import { toastState } from "atoms/toastState";
import Card from "components/card/Card";
import logger from "infra/logger";
import { createChargePointDrawerSelectOptions } from "../variables/chargePointDrawerSelectOption";

const CreateChargePointDrawer = (props) => {
  const setToast = useSetRecoilState(toastState);
  const [drawerState, setDrawerState] = useRecoilState(
    createChargingPointDrawerState
  );

  const validationSchema = yup.object().shape({
    Name: yup.string().min(1).required("Name is required"),
    Code: yup.string().min(1).required("Code is required"),
    Description: yup.string().min(1).required("Description is required"),
    Tariff: yup.number().min(0).required("tariff is a must"),
    Visibility: yup
      .string()
      .oneOf(["Public", "Private"], "Select a valid option")
      .required("Visibility is required"),
    TypeOfCharger: yup
      .string()
      .oneOf(["AC", "DC"], "Select a valid option")
      .required("Type of Charger is required"),

    Power: yup.string().min(1).required("Power is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [{ data, loading: createLoading, error }, CreateChargingPoint] =
    useAxios(
      { method: "POST", url: `/api/chargePoint/create` },
      { manual: true }
    );

  const createHandler = async () => {
    const formValues = getValues();
    let data = {};
    if (formValues.Name.trim() !== "") {
      data["Name"] = formValues.Name.trim();
    }
    if (formValues.Code.trim() !== "") {
      data["Code"] = formValues.Code.trim();
    }
    if (formValues.Description.trim() !== "") {
      data["Description"] = formValues.Description.trim();
    }
    if (formValues.Visibility.trim() !== "") {
      data["Visibility"] = formValues.Visibility.trim();
    }
    if (formValues.TypeOfCharger.trim() !== "") {
      data["TypeOfCharger"] = formValues.TypeOfCharger.trim();
    }
    if (formValues.Power.trim() !== "") {
      data["Power"] = parseInt(formValues.Power.trim());
    }
    if (formValues.Tariff) {
      data["Tariff"] = parseFloat(formValues.Tariff);
    }
    data["Gst"] = 0.18;
    data["TaxEnabled"] = false;
    if (Object.keys(data).length === 9) {
      data["ChargingStationId"] = parseInt(props?.id);
      try {
        const response = await CreateChargingPoint({ data: data });
        setToast({
          isOpen: true,
          title: "Charge Point Created Sucessfully",
          status: "success",
        });
        props.update();
      } catch (error) {
        const err = error?.response?.data?.metadata;
        logger.log(error.response);
        setToast({
          isOpen: true,
          title: err ? err?.error_code : "Error occured",
          description: err ? err?.error_message : null,
          status: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        title: "Enter all the fields to create Charging Station",
        status: "warning",
        position: "top",
      });
    }
  };
  const closeHandler = () => {
    setDrawerState({ isOpen: false });
  };

  return (
    <Drawer
      isOpen={drawerState?.isOpen}
      placement="right"
      onClose={closeHandler}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Create a new Charge Point</DrawerHeader>
        <DrawerBody>
          <>
            <Card>
              <form>
                {createChargePointDrawerSelectOptions.map((item, index) => (
                  <Flex key={index} mb="20px" alignItems="center">
                    <Controller
                      name={item.name}
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          isRequired
                          flexDirection="row"
                          isInvalid={errors[item.name]}
                        >
                          <FormLabel fontSize={16}>{item.label}</FormLabel>
                          {item.type === "select" ? (
                            <Select
                              {...field}
                              focusBorderColor="#f57d36"
                              fontSize="sm"
                              width="400px"
                              height="50px"
                            >
                              <option value="">Select</option>
                              {item.options.map((option, ind) => {
                                return (
                                  <option key={ind} value={option.value}>
                                    {option.name}
                                  </option>
                                );
                              })}
                            </Select>
                          ) : (
                            <Input
                              {...field}
                              focusBorderColor="#f57d36"
                              fontSize="sm"
                              placeholder={item.placeholder}
                              width="400px"
                              height="50px"
                              type={item.type}
                              isRequired
                            />
                          )}
                          <FormHelperText color={"#f57d36"}>
                            {item.helper}
                          </FormHelperText>
                          <FormErrorMessage mb={"20px"}>
                            {errors[item.name] && errors[item.name].message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  </Flex>
                ))}
              </form>
            </Card>
          </>
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            bg="#f57d36"
            color="white"
            colorScheme="orange"
            onClick={handleSubmit(createHandler)}
          >
            Create
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CreateChargePointDrawer;
