import {
  Box,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import React, {useState} from "react";
import { useRecoilValue } from "recoil";

import RemoteStart from "./operatiions/RemoteStart";
import RemoteStop from "./operatiions/RemoteStop";
import ChangeAvailability from "./operatiions/ChangeAvailability";
import TriggerMessage from "./operatiions/TriggerMessage";
import UnlockConnector from "./operatiions/UnlockConnector";
import ConnectorStatus from "./ConnectorStatus";
import { activeConnectorState } from "atoms/activeConnectorState";
import { loggedInUserState } from "atoms/loggedInUserState";
import { accessLevels } from "utils/accessLevels";
import Card from "components/card/Card";

const remoteOperations = [
  {displayName: "Remote Start", value: "RemoteStart"},
  {displayName: "Remote Stop", value: "RemoteStop"},
  {displayName: "Change Availability", value: "ChangeAvailability"},
  {displayName: "Trigger Message", value: "TriggerMessage"},
  {displayName: "Unlock Connector", value: "UnlockConnector"},
];
const RemoteOperations = ({ refresh }) => {
    const activeConnector = useRecoilValue(activeConnectorState)
    const loggedInUser = useRecoilValue(loggedInUserState);
  const role = loggedInUser?.role;
  const canEdit = accessLevels[role]?.accessLevel <= 2;
  const [selectedOperation, setSelectedOperation] = useState("");

  const status = activeConnector?.Status
    ? activeConnector.Status
    : "Unavailable";

  const options = remoteOperations.map((ele, index) => {
    let isDisabled;
    if (status === "Unavailable" && ele.value !== "ChangeAvailability" && ele.value !== "TriggerMessage") {
      isDisabled = true;
    } else if (
      ele.value === "RemoteStart" &&
      (status === "Charging" || status !== "Preparing")
    ) {
      isDisabled = true;
    } else if (ele.value === "RemoteStop" && status !== "Charging") {
      isDisabled = true;
    } 
     else {
      isDisabled = !canEdit && (ele.value === "ChangeAvailability" || ele.value === "TriggerMessage");
    }
    return (
      <Radio
        isDisabled={isDisabled|| !canEdit}
        key={index}
        value={ele.value}
        colorScheme="pink"
        borderColor={"blackAlpha.400"}
      >
        {ele.displayName}
      </Radio>
    );
  });

  const changeHandler = (e) => {
    setSelectedOperation(e);
  };


  return (
    <Box
      w="auto"
      maxW="fit-content"
      minW={500}
      mt={8}
      borderRadius={8}
      borderWidth={0.5}
      backgroundColor="orange.100"
      pt={2}
      px={5}
      pb={5}
    >
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        position={"relative"}
      >
        <ConnectorStatus />
 
        <Tag
          size="lg"
          variant="solid"
          backgroundColor="pink.500"
          fontSize={18}
          fontWeight={600}
          mt={2}
        >
          Remote Operations
        </Tag>
      </Flex>
      <Flex mt={8} borderRadius={0} gap={5}>
        <RadioGroup
          defaultValue={status === "Unavailable" ? "" : selectedOperation}
          onChange={(e) => changeHandler(e)}
          value={selectedOperation}
          variant="checkbox"
          bg="white"
          borderRadius={8}
          h={"auto"}
          overflow="hidden"
          borderWidth={1}
          isDisabled={!canEdit}
        >
          <Tag
            fontSize={17}
            fontWeight={700}
            bg={"pink.500"}
            borderRadius={0}
            textAlign="center"
            color="white"
            w="100%"
            justifyContent={"center"}
            py={1.5}
            mb={1}
          >
            Select Operation
          </Tag>
          <Stack spacing={5} direction="column" p={5}>
            <Radio display={"hidden"} isDisabled={true} value="">
              Not Selected
            </Radio>
            {options}
          </Stack>
        </RadioGroup>
        <Card
          width="auto"
          borderRadius={8}
          alignItems="center"
          justifyContent={status === "Unavailable" ? "center" : "start"}
          borderWidth={1}
          flex={1}
        >
          {selectedOperation === "" && (
            <Text
              justifySelf={"center"}
              fontWeight={700}
              fontSize={20}
              color={"gray.300"}
            >
              {status === "Unavailable"
                ? "Charger Offline"
                : "Please select an Operation"}
            </Text>
          )}
          {selectedOperation === "RemoteStart" && (
            <RemoteStart refresh={refresh} />
          )}
          {selectedOperation === "RemoteStop" && (
            <RemoteStop refresh={refresh}/>
          )}
          {selectedOperation === "ChangeAvailability" && (
            <ChangeAvailability refresh={refresh}/>
          )}
          {selectedOperation === "TriggerMessage" && (
            <TriggerMessage refresh={refresh}/>
          )}
          {selectedOperation === "UnlockConnector" && (
            <UnlockConnector refresh={refresh}/>
          )}
        </Card>
      </Flex>
    </Box>
  );
};

export default RemoteOperations;
