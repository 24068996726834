import React, { useState } from "react";
// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import Footer from "components/footer/FooterAuth";
import LOGO from "../../../assets/img/cmsLogo.svg";
import useAxios from "axios-hooks";
import { useSetRecoilState } from "recoil";
import { toastState } from "atoms/toastState";
import logger from "infra/logger";
const OtpVerificationPage = () => {
  // Chakra color mode
  //const textColor = useColorModeValue("navy.700", "white");
  const textColor = "white";
  //const textColorSecondary = "gray.400";
  const textColorSecondary = "white";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const [isLoading, setIsLoading] = useState(false);
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const setToast = useSetRecoilState(toastState);
  const navigate = useNavigate();
  const location = useLocation();

  const schema = yup.object().shape({
    Otp: yup.string().min(6).max(6).required("Otp is Required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [{ data, loading, error }, execute] = useAxios(
    { method: "POST", url: `/api/auth/verifyotp` },
    { manual: true }
  );

  const submitHandler = async () => {
    setIsLoading(true);
    const formValues = getValues();
    try {
      let reqData = {};
      reqData["otp"] = parseInt(formValues?.Otp);
      reqData["email"] = location?.state?.email;
      const response = await execute({ data: reqData });
      logger.log(response);
      navigate("/auth/resetPassword", {
        state: {
          email: location?.state?.email,
          encodedString: response?.data?.data,
        },
      });
      setToast({
        isOpen: true,
        title: `Otp Verification Success`,
        status: "success",
      });
    } catch (error) {
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "Error in verfiying OTP",
          status: "error",
        });
      }
    }

    setIsLoading(false);
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        w={{ base: "100%", md: "100%", lg: "50%" }}
        h="100%"
        zIndex={100}
        direction="column"
        alignItems={{ base: "center", lg: "flex-start" }}
        justifyContent={"flex-start"}
        gap={40}
        position={"relative"}
        pl={{ base: "unset", lg: "100px", md: "unset" }}
        pt={{ base: 10, md: 20, lg: 25 }}
      >
        <Image src={LOGO} alt="URZZA CMS" w="auto" h="80px" />
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex direction="column" alignItems={"flex-start"} w={"100%"}>
            <Heading color={textColor} fontSize="36px" mb="10px">
              Verify OTP
            </Heading>

            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Enter the 6 digit OTP sent to your email
            </Text>
          </Flex>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <form onSubmit={handleSubmit(submitHandler)}>
              <Controller
                name="Otp"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl isInvalid={errors.Otp} isRequired>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Enter OTP
                    </FormLabel>
                    <Input
                      {...field}
                      isRequired
                      variant="auth"
                      fontSize="sm"
                      type="number"
                      placeholder="Enter six digits OTP"
                      mb="5px"
                      fontWeight="500"
                      size="lg"
                      borderRadius={0}
                      bg={"white"}
                      maxLength={6}
                    />
                    <FormErrorMessage mb={"20px"}>
                      {errors.Otp && errors.Otp.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Flex
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row"
              >
                <Button
                  type="submit"
                  fontSize="md"
                  variant="brand"
                  _hover={{ backgroundColor: "#ff5c00" }}
                  _focus={{ bg: "#ff5c00 !important" }}
                  fontWeight="700"
                  h="50"
                  mb="24px"
                  px={20}
                  bg="#f57d36"
                  color="white"
                  mt="20px"
                  borderRadius={0}
                  colorScheme="blue"
                  isLoading={isLoading}
                  spinner={<Spinner color="white" />}
                >
                  Submit
                </Button>
                <Text
                  style={{
                    cursor: "pointer",
                    color: "#f57d36",
                    fontWeight: "500",
                    textDecoration: "underline",
                    textDecorationColor: "#f57d36",
                  }}
                  onClick={() => navigate("/auth/forgotpassword")}
                >
                  Back to ForgotPassword?
                </Text>
              </Flex>
            </form>
          </Flex>
        </Flex>

        <Footer />
      </Flex>
    </DefaultAuth>
  );
};

export default OtpVerificationPage;
