import { Icon } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import React, { useEffect, useState } from "react";
import { FaRupeeSign } from "react-icons/fa";

import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";

const TotalRevenue = ({data}) => {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [revenue, setRevenue] = useState(0);


  useEffect(() => {
    setRevenue(data?.toFixed(2));
  }, [data]);

  return (
    <MiniStatistics
      startContent={
        <IconBox
          w="56px"
          h="56px"
          bg={boxBg}
          icon={<Icon w="32px" h="32px" as={FaRupeeSign} color={brandColor} />}
        />
      }
      name="Total Revenue"
      value={revenue}
    />
  );
};

export default TotalRevenue;
