import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, {useMemo, useState} from "react";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";

import BillingRow from "./BillingRow";
import Card from "components/card/Card";


const Billing = ({columnsData, tableData, addToUpdate}) => {
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0, // Start at page 0
        pageSize: 10, // Set the initial page size
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: {pageIndex},
  } = tableInstance;

  // const { canPreviousPage, canNextPage, pageCount, nextPage, previousPage } =
  //   tableInstance;
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{sm: "scroll", lg: "hidden"}}
    >
      
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="center"
                    align="center"
                    fontSize={{sm: "10px", lg: "12px"}}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return <BillingRow key={index} row={row} index={index} addToUpdate={(d) => addToUpdate(d)} />;
          })}
        </Tbody>
      </Table>
      {/* <Flex justify="space-between" alignItems="center">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </Button>
          <Text>
            Page {pageIndex + 1} of {pageCount}
          </Text>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </Button>
        </Flex> */}
    </Card>
  );
};

export default Billing;
