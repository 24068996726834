// this is a custom hook to handle all the authentication logic

import {useSetRecoilState} from "recoil";
import {auth} from "../firebase";
import {signInWithEmailAndPassword, signOut} from "firebase/auth";
import {toastState} from "atoms/toastState";
import logger from "infra/logger";

const useAuthentication = () => {
  const setToast = useSetRecoilState(toastState);

  const login = async (email, password) => {
    logger.log("running login")
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setToast({
        isOpen: true,
        title: `Welcome ${userCredential.user.email}`,
        status: "success",
      });
    } catch (error) {
      setToast({
        isOpen: true,
        title: "Invalid credentials, please check your Email/Passwords",
        status: "error",
      });
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setToast({
        isOpen: true,
        title: "user successfully logged out",
        status: "success",
      });
    } catch (error) {
      setToast({
        isOpen: true,
        title: "error occured please reopen the tab",
        status: "error",
      });
    }
  };

  return {login, logout};
};

export default useAuthentication;
