import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useRecoilState, useSetRecoilState } from "recoil";
import useAxios from "axios-hooks";
import * as yup from "yup";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";

import { toastState } from "atoms/toastState";
import Card from "components/card/Card";
import { createConnectorDrawerState } from "atoms/createConnectorDrawerState";
import logger from "infra/logger";
import { createChargePointConnectorSelectOptions } from "../variables/chargePointConnectorSelectOptions";

const CreateConnectorDrawer = (props) => {
  const setToast = useSetRecoilState(toastState);
  const [drawerState, setDrawerState] = useRecoilState(
    createConnectorDrawerState
  );
  const [{ data, loading: createLoading, error }, CreateConnector] = useAxios(
    { method: "POST", url: `/api/chargePointConnector/create` },
    { manual: true }
  );

  const validationSchema = yup.object().shape({
    Code: yup.string().min(1).required("Code is required"),
    TypeOfConnector: yup
      .string()
      .oneOf(
        ["AC Type 1", "AC Type 2", "CCS Type 2", "CHAdeMO"],
        "Select a valid option"
      )
      .required("Type of Connector is required"),
    QrCode: yup.string().min(1).required("Qr Code is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const connectorTypeOptions = [
    { name: "AC Type 1", value: "AC Type 1" },
    { name: "AC Type 2", value: "AC Type 2" },
    { name: "CCS Type 2", value: "CCS Type 2" },
    { name: "CHAdeMO", value: "CHAdeMO" },
  ];

  const closeHandler = () => {
    setDrawerState(null);
  };

  const createHandler = async () => {
    const formValues = getValues();
    let data = {};

    if (formValues.Code.trim() !== "") {
      data["Code"] = parseInt(formValues.Code.trim());
    }

    if (formValues.TypeOfConnector.trim() !== "") {
      data["TypeOfConnector"] = formValues.TypeOfConnector.trim();
    }
    if (formValues.QrCode.trim() !== "") {
      data["QrCode"] = formValues.QrCode.trim();
    }
    if (props?.chargePointId) {
      data["ChargePointId"] = parseInt(props?.chargePointId);
    }
    data["ChargePointCode"] = props.chargePointCode
    if (Object.keys(data).length === 5) {
      try {
        const response = await CreateConnector({ data: data });
        setToast({
          isOpen: true,
          title: "Connector Created Sucessfully",
          status: "success",
        });
        props.update();
      } catch (error) {
        const err = error?.response?.data?.error;
        logger.log(error);
        setToast({
          isOpen: true,
          title: "Error occured when creating Connector",
          description: err ? err : null,
          status: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        title: "Enter all the fields to create Connector",
        status: "warning",
        position: "top",
      });
    }
  };

  return (
    <Drawer
      isOpen={drawerState?.isOpen}
      placement="right"
      onClose={closeHandler}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Create a new Connector</DrawerHeader>
        <DrawerBody>
          <Card>
            <form>
              {createChargePointConnectorSelectOptions.map((item, index) => (
                <Flex key={index} mb="20px" alignItems="center">
                  <Controller
                    name={item.name}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl
                        isRequired
                        flexDirection="row"
                        isInvalid={errors[item.name]}
                      >
                        <FormLabel fontSize={16}>{item.label}</FormLabel>
                        {item.type === "select" ? (
                          <Select
                            {...field}
                            focusBorderColor="#f57d36"
                            fontSize="sm"
                            width="400px"
                            height="50px"
                          >
                            <option value="">Select</option>
                            {item.options.map((option, ind) => {
                              return (
                                <option key={ind} value={option.value}>
                                  {option.name}
                                </option>
                              );
                            })}
                          </Select>
                        ) : (
                          <Input
                            {...field}
                            focusBorderColor="#f57d36"
                            fontSize="sm"
                            placeholder={item.placeholder}
                            width="400px"
                            height="50px"
                            type={item.type}
                            isRequired
                          />
                        )}
                        <FormHelperText color={"#f57d36"}>
                          {item.helper}
                        </FormHelperText>
                        <FormErrorMessage mb={"20px"}>
                          {errors[item.name] && errors[item.name].message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </Flex>
              ))}
            </form>
          </Card>
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            bg="#f57d36"
            color="white"
            colorScheme="orange"
            onClick={handleSubmit(createHandler)}
          >
            Create
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CreateConnectorDrawer;
