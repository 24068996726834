import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";

import DashboardTabs from "./DashboardTabs";
import { Outlet } from "react-router-dom";

export default function Dashboard() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "10px" }}>
      <Outlet />
    </Box>
  );
}
