import {
  Button,
  Flex,
  Icon,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useNavigate } from "react-router-dom";
import { FaRupeeSign } from "react-icons/fa";

// Custom components
import Card from "components/card/Card";
import { sampleData } from "../variables/sampleData";
import { formattedIstDate } from "utils/utils";


const ChargingSessionsColumnsTable = (props) => {
  const navigate = useNavigate();
  const { columnsData, tableData } = props;
  const samData = sampleData.data;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0, // Start at page 0
        pageSize: 10, // Set the initial page size
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },
  } = tableInstance;

  const { canPreviousPage, canNextPage, pageCount, nextPage, previousPage } =
    tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "S.No") {
                    data = (
                      <Flex align="center">
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {Number(cell.row.id) + 1}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Source") {
                    data = (
                      <Flex align="center">
                        <Text
                          me="10px"
                          color={textColor}
                          fontSize="sm"
                          fontWeight="700"
                        >
                          {cell.row.original.requestSource}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Session Id") {
                    data = (
                      <Flex align="center">
                        <Text
                          me="10px"
                          color={textColor}
                          fontSize="sm"
                          fontWeight="700"
                        >
                          {cell.row.original.id}
                        </Text>
                      </Flex>
                    );
                  } else if(cell.column.Header === "Transaction Id") {
                    data = (
                      <Flex align="center">
                        <Text
                          me="10px"
                          color={textColor}
                          fontSize="sm"
                          fontWeight="700"
                        >
                          {cell.row.original.transactionId}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Start Time") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {formattedIstDate(cell.row.original.startTime)}
                      </Text>
                    );
                  } else if (cell.column.Header === "End Time") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {formattedIstDate(cell.row.original.endTime)}
                      </Text>
                    );
                  } else if (cell.column.Header === "No of Units Dispatched") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.row.original.totalUnitsConsumed.toFixed(3)} Kwh
                      </Text>
                    );
                  } else if (cell.column.Header === "Total Amount") {
                    data = (
                      <Flex
                        color={textColor}
                        fontSize="md"
                        fontWeight="700"
                        alignItems={"center"}
                      >
                        <Icon as={FaRupeeSign} w={3} h={4} mr={2} />
                        <Text>
                          {cell.row.original.netAmount.toFixed(2)}
                        </Text>
                      </Flex>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                      alignItems={"center"}
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex justify="space-between" alignItems="center">
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </Button>
        <Text>
          Page {pageIndex + 1} of {pageCount}
        </Text>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Button>
      </Flex>
    </Card>
  );
};
export default ChargingSessionsColumnsTable;
