// Chakra imports
import {
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";

import { deleteModalState } from "atoms/deleteModalState";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import DashboardOverviewTab from "./components/DashboardOverviewTab";
import DashboardAnalyticsTab from "./components/DashboardAnalyticsTab";
import PerformanceReportTab from "./components/PerformanceReportTab";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import { onlyUAdmins } from "utils/accessLevels";

const loadingData = [
  {
    Name: <Skeleton />,
    PinCode: <LoadingSpinner />,
    PhoneNumber: <LoadingSpinner />,
  },
];
const DashboardTabs = () => {
  const setDeleteModal = useSetRecoilState(deleteModalState);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const location = useLocation();
  const navigation = useNavigate();
  useEffect(() => {
    setDeleteModal(false);
  }, []);

  const clickHandler = (x) => {
    if (x === 0) {
      navigation(`/admin/dashboard/overview`);
    } else if (x === 1) {
      navigation(`/admin/dashboard/analytics`);
    } else if (x === 2) {
      navigation(`/admin/dashboard/performancereport`);
    }
  };

  useEffect(() => {
    if (location.pathname === "/admin/dashboard/overview") {
      setActiveTabIndex(0);
    } else if (location.pathname === "/admin/dashboard/analytics") {
      setActiveTabIndex(1);
    } else if (location.pathname === "/admin/dashboard/performancereport") {
      setActiveTabIndex(2);
    }
  }, [location]);

  return (
    <Tabs
      variant="soft-rounded"
      colorScheme="orange"
      isLazy={true}
      index={activeTabIndex}
      style={{ marginTop: "70px" }}
    >
      <TabList color="whiter" gap={5}>
        <Tab _focus={{ boxShadow: "none" }} onClick={() => clickHandler(0)}>
          Overview
        </Tab>
        <Tab _focus={{ boxShadow: "none" }} onClick={() => clickHandler(1)}>
          Analytics
        </Tab>
        <RoleBasedRender roles={onlyUAdmins}>
          <Tab _focus={{ boxShadow: "none" }} onClick={() => clickHandler(2)}>
            Performance Report
          </Tab>
        </RoleBasedRender>
      </TabList>
      <TabPanels>
        <TabPanel>
          <DashboardOverviewTab />
        </TabPanel>
        <TabPanel>
          <DashboardAnalyticsTab />
        </TabPanel>
        <RoleBasedRender roles={onlyUAdmins}>
          <TabPanel>
            <PerformanceReportTab />
          </TabPanel>
        </RoleBasedRender>
      </TabPanels>
    </Tabs>
  );
};

export default DashboardTabs;
