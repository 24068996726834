import {Box, Button, Flex, FormControl, Input, Text} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import React, {useState} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";

import {loggedInUserState} from "atoms/loggedInUserState";
import {accessLevels} from "utils/accessLevels";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import logger from "infra/logger";
import {toastState} from "atoms/toastState";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import {onlyAdmins} from "utils/accessLevels";

const StationImageUpload = ({stationId, getImages}) => {
  const [images, setImages] = useState([]);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const role = loggedInUser?.role;
  const setToast = useSetRecoilState(toastState);
  const [{data, loading, error}, execute] = useAxios(
    {
      method: "POST",
      url: `/api/chargingStationImage/create/${stationId}`,
      headers: {"Content-Type": "multipart/form-data"},
    },
    {manual: true}
  );

  const handleInput = (e) => {
    const acceptedFileTypes = ["image/jpeg", "image/png"];
    if (!acceptedFileTypes.includes(e.target.files[0].type)) {
      setToast({
        isOpen: true,
        title: "Invalid file type",
        description: "Please upload a JPG or PNG image",
        status: "error",
      });
      return;
    }
    setImages([e.target.files[0]]);
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const droppedFiles = e.dataTransfer.files;
    const acceptedFiles = Array.from(droppedFiles).filter((file) =>
      ["image/jpeg", "image/png"].includes(file.type)
    );

    if (acceptedFiles.length > 0) {
      setImages([acceptedFiles[0]]);
    } else {
      setToast({
        isOpen: true,
        title: "Invalid file type",
        description: "Please upload a JPG or PNG image",
        status: "error",
      });
    }
  };

  const handleUpload = async () => {
    logger.log("handleUpload", images);
    if (images.length > 0) {
      const selectedImage = images[0];
      logger.log("selectedImage", selectedImage);

      // Check if the image size exceeds the limit (in bytes)
      const maxSizeInBytes = 15 * 1024 * 1024; //1 MB as an example, change it according to your requirements

      if (selectedImage.size > maxSizeInBytes) {
        setToast({
          isOpen: true,
          title: "Image size exceeds the limit",
          description: `Please select an image with a size less than ${
            maxSizeInBytes / (1024 * 1024)
          } MB.`,
          status: "warning",
        });
        return;
      }

      // Continue with the upload logic
      const formData = new FormData();
      formData.append("file", selectedImage);

      try {
        const response = await execute({data: formData});
        setToast({
          isOpen: true,
          title: "Image Uploaded successfully",
          status: "success",
        });

        setImages([]);
        getImages();
      } catch (error) {
        const err = error?.response?.data?.error;
        setToast({
          isOpen: true,
          title: "Error occurred when Uploading Image",
          description: err ? err : null,
          status: "error",
        });
        logger.log(error);
      }
    } else {
      setToast({
        isOpen: true,
        title: "No file provided to Upload",
        status: "warning",
        position: "top",
      });
    }
  };

  return !loading ? (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      width="100%"
      alignItems="center"
      onDrop={handleDrop}
    >
      <form style={{width: "100%"}}>
        <Flex
          alignItems={"center"}
          direction={"column"}
          borderWidth={1}
          borderColor={"black.500"}
          borderRadius={5}
          justify={"center"}
        >
          <FormControl position="relative">
            <Input
              focusBorderColor="#f57d36"
              type="file"
              width={"100%"}
              height={80}
              onChange={(e) => handleInput(e)}
              onDrop={handleDrop}
              _placeholder={{alignSelf: "center"}}
              style={{position: "relative", zIndex: 1, opacity: 0}}
              accept="image/jpeg, image/png"
            />
            <Flex
              direction={"column"}
              align="center"
              justify={"center"}
              position="absolute"
              style={{zIndex: 0}}
              w={"100%"}
              h={"100%"}
              top={0}
            >
              <Text fontSize={32} fontWeight={"900"} color={"blackAlpha.500"}>
                +
              </Text>
              <Text color={"blackAlpha.500"}>DRAG & DROP</Text>
              <Text
                mt="2"
                color={images.length > 0 ? "#666" : "blackAlpha.500"}
              >
                {images.length > 0 ? images[0].name : "No file chosen"}
              </Text>
            </Flex>
          </FormControl>
        </Flex>
      </form>
      <RoleBasedRender roles={onlyAdmins}>
        <Button
          mt={10}
          bgColor={"#f57d36"}
          color={"white"}
          _hover={{bgColor: "blue"}}
          transition={"400"}
          onClick={handleUpload}
          width={"250px"}
        >
          Upload
        </Button>
      </RoleBasedRender>
    </Box>
  ) : (
    <LoadingSpinner />
  );
};

export default StationImageUpload;
