export const userDrawerSelectOptions = [
    {
      label: "First Name",
      placeholder: "First Name",
      name: "FirstName",
      helper: "",
    },
    {
      label: "Last Name",
      placeholder: "Last Name",
      name: "LastName",
      helper: "",
    },
    {
      label: "Phone Number",
      placeholder: "Phone Number",
      type: "tel", // Changed to "tel" type for phone numbers
      name: "MobileNumber",
      helper: "*Note: type without country code",
    },
    {
      label: "Email",
      placeholder: "Email",
      type: "email",
      name: "Email",
      helper: "",
    },
    {
      label: "Role",
      placeholder: "Select Role",
      type: "select",
      name: "Role",
      options: [
        {
          name: "Admin",
          value: "admin",
        },
        {
          name: "Manager",
          value:"manager",
        },
        {name: "Property Owner", value: "property_owner"},
      ],
    }
  ]