import {Box, Button, Flex, Text} from "@chakra-ui/react";
import React from "react";
import {useNavigate} from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      w={"100%"}
      h={"100vh"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      bg={"black"}
    >
      <Text fontSize={"6xl"} fontWeight={"bold"} color={"white"}>
        404 -  PageNotFound
      </Text>
      <Flex flexDirection="row" alignItems="center">
        <Button
          onClick={() => navigate(-2)}
          color="#f57d36"
          bg={"transparent"}
          _hover={{bg: "transparent", cursor: "pointer"}}
          fontSize={"3xl"}
        >
          click here
        </Button>
        <Text fontSize={"3xl"} fontWeight={"bold"} color={"white"}>
          to go back
        </Text>
      </Flex>
    </Box>
  );
};

export default PageNotFound;
