import {
  Button,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import ViewSessionDrawer from "./ViewSessionDrawer";
import { formattedDate } from "utils/utils";
import { FaRupeeSign } from "react-icons/fa";
import { formattedIstDate } from "utils/utils";

export default function TransactionsTable(props) {
  // const navigate = useNavigate();
  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0, // Start at page 0
        pageSize: 10, // Set the initial page size
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },
  } = tableInstance;

  const { canPreviousPage, canNextPage, pageCount, nextPage, previousPage } =
    tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const editHandler = (_id) => {
    //TODO setupo transaction drawer state
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index + "x"}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px", xl: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {useMemo(
            () =>
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={i}>
                    {row.cells.map((cell, ind) => {
                      let data = "";
                      if (cell.column.Header === "S.No") {
                        data = (
                          <Flex>
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {i + 1}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header === "Transaction Date") {
                        data = (
                          <Flex align="center">
                            <Text
                              me="10px"
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {formattedIstDate(cell?.row?.original?.Date)}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header === "Charger") {
                        data = (
                          <Flex align="center">
                            <Text
                              me="10px"
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {cell?.row?.original?.ChargePointCode}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header === "Connector") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell?.row?.original?.Connector}
                          </Text>
                        );
                      } else if (cell.column.Header === "Gross Amount") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            <Icon as={FaRupeeSign} w={3} h={4} mr={2} />
                            {cell?.row?.original?.GrossAmount?.toFixed(2)}
                          </Text>
                        );
                      } else if (cell.column.Header === "Tax Amount") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            <Icon as={FaRupeeSign} w={3} h={4} mr={2} />
                            {cell?.row?.original?.TaxAmount?.toFixed(2)}
                          </Text>
                        );
                      } else if (cell.column.Header === "Net Amount") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            <Icon as={FaRupeeSign} w={3} h={4} mr={2} />
                            {cell?.row?.original?.NetAmount?.toFixed(2)}
                          </Text>
                        );
                      } 
                      // else if (cell.column.Header === "Actions") {
                      //   data = (
                      //     <Button
                      //       colorScheme="blue"
                      //       variant="gh  ost"
                      //       onClick={() =>
                      //         editHandler(cell?.row?.original?._id)
                      //       }
                      //     >
                      //       View
                      //     </Button>
                      //   );
                      // }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={ind}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              }),
            [page]
          )}
        </Tbody>
      </Table>
      {false && <ViewSessionDrawer />}
      {/*TODO implement functionality*/}

      {tableData.length > 0 ? (
        <Flex justify="space-between" alignItems="center">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </Button>
          <Text>
            Page {pageIndex + 1} of {pageCount}
          </Text>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </Button>
        </Flex>
      ) : (
        <Flex justify="center" alignItems="center">
          No Unsettled Transactions
        </Flex>
      )}
    </Card>
  );
}
