import React, {useEffect, useState} from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {RiEyeCloseLine} from "react-icons/ri";
import {onAuthStateChanged, signInWithEmailAndPassword} from "firebase/auth";
import {MdOutlineRemoveRedEye} from "react-icons/md";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";

import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import {auth} from "../../../firebase";
import useAuthentication from "hooks/UseAuthentication";
import Footer from "components/footer/FooterAuth";
import LOGO from "../../../assets/img/Urzza_Auth_Logo.png";
const SignIn = () => {
  // Chakra color mode
  //const textColor = useColorModeValue("navy.700", "white");
  const textColor = "white";
  //const textColorSecondary = "gray.400";
  const textColorSecondary = "white";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const [isLoading, setIsLoading] = useState(false);
  const googleHover = useColorModeValue(
    {bg: "gray.200"},
    {bg: "whiteAlpha.300"}
  );
  const googleActive = useColorModeValue(
    {bg: "secondaryGray.300"},
    {bg: "whiteAlpha.200"}
  );

  const {login} = useAuthentication();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const schema = yup.object().shape({
    Email: yup
      .string()
      .email("Invalid Email Format")
      .required("Email is Required"),
    Password: yup.string().required("password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleClick = () => setShow(!show);
  const signInHandler = async () => {
    setIsLoading(true);
    const formValues = getValues();
    const subDomain = window.location.hostname.split(".")[0];
    const split = formValues.Email.split("@");
    const actual_email = split[0] + "-" + subDomain + "@" + split[1];
    await login(actual_email, formValues.Password);
    setIsLoading(false)
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        navigate("/admin/dashboard");
      }
    });
    return () => unsubscribe(); // Cleanup the listener when the component unmounts
  }, []);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        w={{base: "100%", md: "100%", lg: "50%"}}
        h="100%"
        zIndex={100}
        direction="column"
        alignItems={{base: "center", lg: "flex-start"}}
        justifyContent={"flex-start"}
        gap={40}
        position={"relative"}
        pl={{base: "unset", lg: "100px", md: "unset"}}
        pt={{base: 10, md: 20, lg: 25}}
      >
        <Image src={LOGO} alt="URZZA CMS" h="105px" w="250px" marginTop={5} />
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex direction="column" alignItems={"flex-start"} w={"100%"}>
            <Heading color={textColor} fontSize="36px" mb="10px">
              Welcome to Urzza CMS
            </Heading>

            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Welcome, please login to your account
            </Text>
          </Flex>
          <Flex
            zIndex="2"
            direction="column"
            w={{base: "100%", md: "420px"}}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{base: "auto", lg: "unset"}}
            me="auto"
            mb={{base: "20px", md: "auto"}}
          >
            <form onSubmit={handleSubmit(signInHandler)}>
              <Controller
                name="Email"
                control={control}
                defaultValue=""
                render={({field}) => (
                  <FormControl isInvalid={errors.Email} isRequired>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Email
                    </FormLabel>
                    <Input
                      {...field}
                      isRequired
                      variant="auth"
                      fontSize="sm"
                      type="email"
                      placeholder="Email"
                      mb="5px"
                      fontWeight="500"
                      size="lg"
                      borderRadius={0}
                      bg={"white"}
                    />
                    <FormErrorMessage mb={"20px"}>
                      {errors.Email && errors.Email.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                name="Password"
                control={control}
                defaultValue=""
                render={({field}) => (
                  <FormControl isInvalid={errors.Password} isRequired>
                    <FormLabel
                      mt="25px"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Password
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        {...field}
                        fontSize="sm"
                        isRequired
                        placeholder="Password"
                        mb="5px"
                        size="lg"
                        type={show ? "text" : "password"}
                        variant="auth"
                        borderRadius={0}
                        bg={"white"}
                      />
                      <InputRightElement
                        display="flex"
                        alignItems="center"
                        mt="4px"
                        color={"blackAlpha.200"}
                      >
                        <Icon
                          color={"blackAlpha.400"}
                          _hover={{cursor: "pointer"}}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={handleClick}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage mb={"20px"}>
                      {errors.Password && errors.Password.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
              <Flex
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row"
              >
                <Button
                  type="submit"
                  fontSize="md"
                  variant="brand"
                  _hover={{backgroundColor: "#ff5c00"}}
                  _focus={{bg:"#ff5c00 !important"}}
                  fontWeight="700"
                  h="50"
                  mb="24px"
                  px={20}
                  bg="#f57d36"
                  color="white"
                  mt="20px"
                  borderRadius={0}
                  colorScheme="blue"
                  isLoading={isLoading}
                  loadingText="Signing In"
                  spinner={<Spinner color="white" />}
                >
                  Sign In
                </Button>
                <Text style={{cursor: "pointer", color: "#f57d36", fontWeight: "500", textDecoration: "underline", textDecorationColor: "#f57d36"}}
                    onClick={() => navigate("/auth/forgotpassword")}>
                    Forgot Password?
                </Text>
              </Flex>
            </form>
          </Flex>
        </Flex>

        <Footer />
      </Flex>
    </DefaultAuth>
  );
};

export default SignIn;
