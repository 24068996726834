import { Icon } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import React, { useEffect, useState } from "react";
import { BsLightningChargeFill } from "react-icons/bs";

import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";

const AvgUnitsPerSession = ({data}) => {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [avgUnitsPerSession, setAvgUnitsPerSession] = useState(data);

  useEffect(() => {
    setAvgUnitsPerSession(data?.toFixed(2));
  }, [data]);

  return (
    <MiniStatistics
      startContent={
        <IconBox
          w="56px"
          h="56px"
          bg={boxBg}
          icon={
            <Icon
              w="32px"
              h="32px"
              as={BsLightningChargeFill}
              color={brandColor}
            />
          }
        />
      }
      name="Avg. Units/Session"
      value={avgUnitsPerSession}
    />
  );
};

export default AvgUnitsPerSession;
