import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { useSetRecoilState } from "recoil";

import OngoingSessionsColumnsTable from "./OngoingSessionsColumnsTable";
import { chargingSessionTableHeaders } from "../variables/tableHeaders";
import { toastState } from "atoms/toastState";

const OngoingSessionsList = () => {
  const [isInitial, setIsInitial] = useState(true);
  const [sessions, setSessions] = useState([]);
  const setToast = useSetRecoilState(toastState);
  const [{ data, loading, error }, execute, cancelRequest] = useAxios(
    { method: "GET", url: "/api/chargingSession/ongoing/all" },
    { manual: true }
  );
  const stopCharging = () => {};
  const getChargingSessions = async () => {
    try {
      const response = await execute();
      setSessions(response.data.data);
    } catch (error) {
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: `Error occured while getting onGoing Sessions`,
          status: "error",
        });
      }
    }
  };
  useEffect(() => {
    if (isInitial) {
      const timeout = setTimeout(() => {
        getChargingSessions();
      }, 1200);
      return () => {
        clearTimeout(timeout);
        cancelRequest();
      };
    } else {
      getChargingSessions();
      return () => cancelRequest();
    }
  }, []);
  return (
    <Box
      width="100%"
      padding={5}
      backgroundColor="#e8edf7"
      display="flex"
      flexDirection="column"
      alignItems="center"
      borderRadius={8}
      mb={5}
    >
      {/* <Settlement /> */}
      <OngoingSessionsColumnsTable
        columnsData={chargingSessionTableHeaders}
        tableData={sessions}
      />
    </Box>
  );
};

export default OngoingSessionsList;
