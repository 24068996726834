import { Icon } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import React, { useEffect, useState } from "react";
import { BsLightningChargeFill } from "react-icons/bs";

import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";

const TotalUnits = ({data}) => {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [units, setUnits] = useState(data);

  useEffect(() => {
    setUnits(data?.toFixed(2));
  }, [data]);

  return (
    <MiniStatistics
      startContent={
        <IconBox
          w="56px"
          h="56px"
          bg={boxBg}
          icon={
            <Icon
              w="32px"
              h="32px"
              as={BsLightningChargeFill}
              color={brandColor}
            />
          }
        />
      }
      name="Total No. of Units"
      value={units}
    />
  );
};

export default TotalUnits;
