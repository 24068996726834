import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useRecoilState, useSetRecoilState} from "recoil";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

import LoadingSpinner from "components/feedback/LoadingSpinner";
import Card from "components/card/Card";
import {updateRfidDrawerState} from "atoms/updateRfidDrawerState";
import {toastState} from "atoms/toastState";
import logger from "infra/logger";
import RoleBasedRender from "components/wrappers/roleBasedRender";
import {onlyAdmins} from "utils/accessLevels";
import {rfidDrawerSelectOptions} from "../variables/rfidDrawerSelectOptions";

const UpdateRfidDrawer = (props) => {
  const setToast = useSetRecoilState(toastState);
  const [drawerState, setDrawerState] = useRecoilState(updateRfidDrawerState);
  const [rfid, setRfid] = useState(null);

  const validationSchema = yup.object().shape({
    IdTag: yup
      .string()
      .min(1)
      .matches(
        /^[a-zA-Z0-9]*$/,
        "Id Tag must be Valid, doesn't contains special characters"
      ),
    MobileNumber: yup
      .string()
      .max(10)
      .matches(/^[0-9]*$/, "Mobile Number must contain only digits"),
    Email: yup.string().email("Invalid email address"),
    Balance: yup.number().default(0).min(0),
    Status: yup.boolean().oneOf([true, false], "Select a valid option"),
    RfidType: yup.string(),
  });

  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [{loading: rfidLoading}, getRfid, cancelRequest] = useAxios(
    {method: "GET", url: `/api/rfid/${drawerState?.activeId}`},
    {manual: true}
  );

  const [{data: updatedRfid, loading: updateRfidLoading}, updateRfid] =
    useAxios(
      {method: "PATCH", url: `/api/rfid/update/${drawerState?.activeId}`},
      {manual: true}
    );
  const getStatus = (status) => {
    if (status === "false") {
      return false;
    } else if (status === "true") {
      return true;
    }
  };

  const getRfidDetails = async () => {
    try {
      const response = await getRfid();
      const result = response.data.data;
      setRfid(result);
    } catch (error) {
      logger.log(error);
      //check if error is cancelled error
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "Error occured while getting rfid details",
          status: "error",
        });
        closeHandler();
      }
    }
  };

  const closeHandler = () => {
    setDrawerState(null);
  };

  const saveHandler = async () => {
    const formValues = getValues();
    let rfidToUpdate = {};
    if (formValues.IdTag.trim() !== rfid.IdTag) {
      rfidToUpdate["idTag"] = formValues.IdTag;
    }
    if (
      formValues.Email.trim() !== "" &&
      formValues.Email.trim() !== rfid.Email
    ) {
      rfidToUpdate["email"] = formValues.Email.trim();
    }
    if (
      formValues.MobileNumber.trim() !== "" &&
      formValues.MobileNumber.trim() !== rfid.MobileNumber
    ) {
      rfidToUpdate["mobileNumber"] = formValues.MobileNumber.trim();
    }
    const Status = getStatus(formValues.Status);
    if (formValues.Balance !== 0 && formValues.Balance !== rfid.balance) {
      rfidToUpdate["balance"] = parseFloat(formValues.Balance);
    }
    if (Status !== rfid.isActive) {
      rfidToUpdate["status"] = Status;
    }
    if (formValues.RfidType !== rfid.type) {
      rfidToUpdate["type"] = formValues.RfidType;
    }
    if (Object.keys(rfidToUpdate).length > 0) {
      try {
        const response = await updateRfid({data: rfidToUpdate});
        setToast({
          isOpen: true,
          title: "Rfid details Updated",
          status: "success",
        });
        props.update();
      } catch (error) {
        const err = error?.response?.data?.metadata?.error_message;
        logger.log(error);
        setToast({
          isOpen: true,
          title: error?.response?.data?.metadata?.error_code,
          description: err ? err : null,
          status: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        title: "nothing new to update",
        status: "warning",
      });
    }
  };

  useEffect(() => {
    if (drawerState?.activeId) {
      getRfidDetails();
      return () => cancelRequest();
    }
  }, []);

  useEffect(() => {
    if (rfid) {
      if (rfid.isActive) {
      }
      setValue("IdTag", rfid.idTag);
      setValue("Balance", rfid.balance);
      setValue("Email", rfid.email);
      setValue("Status", rfid.isActive);
      setValue("MobileNumber", rfid.mobileNumber.slice(-10));
      setValue("RfidType", rfid.type);
    }
  }, [rfid]);

  return (
    <Drawer
      isOpen={drawerState?.isOpen}
      placement="right"
      onClose={closeHandler}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Update Rfid Details {drawerState?.activeId}</DrawerHeader>

        <DrawerBody>
          {!rfidLoading && !updateRfidLoading ? (
            <>
              <Card>
                <form>
                  {rfidDrawerSelectOptions.map((item, index) => (
                    <Flex key={index} mb="20px" alignItems="center">
                      <Controller
                        name={item.name}
                        control={control}
                        defaultValue=""
                        render={({field}) => (
                          <FormControl
                            flexDirection="row"
                            isInvalid={errors[item.name]}
                          >
                            <FormLabel fontSize={20}>{item.label}</FormLabel>
                            {item.type === "select" ? (
                              <Select
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                width="400px"
                                height="50px"
                                name={item.name}
                              >
                                <option value="">Select</option>
                                {item.options.map((option, ind) => {
                                  return (
                                    <option
                                      key={ind}
                                      value={option.value}
                                      disabled={option.name === "Wallet"}
                                    >
                                      {option.name}
                                    </option>
                                  );
                                })}
                              </Select>
                            ) : (
                              <Input
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                placeholder={item.placeholder}
                                width="400px"
                                height="50px"
                                type={item.type}
                                name={item.name}
                              />
                            )}
                            <FormHelperText color={"#f57d36"}>
                              {item.helper}
                            </FormHelperText>
                            <FormErrorMessage mb={"20px"}>
                              {errors[item.name] && errors[item.name].message}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    </Flex>
                  ))}
                </form>
              </Card>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </DrawerBody>

        <DrawerFooter>
          <RoleBasedRender roles={onlyAdmins}>
            <Button variant="outline" mr={3} onClick={closeHandler}>
              Cancel
            </Button>
            <Button
              bg="#f57d36"
              color="white"
              colorScheme="orange"
              onClick={handleSubmit(saveHandler)}
            >
              Save
            </Button>
          </RoleBasedRender>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default UpdateRfidDrawer;
