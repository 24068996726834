import {
  Box,
  Flex,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tag,
  Text,
} from "@chakra-ui/react";
import React from "react";

const SettlementCard = ({ title, value, type, width, range, colorScheme }) => {
  const formattedValue = type === "amount" ? `₹ ${parseFloat(value).toFixed(2)}` : parseFloat(value).toFixed(2);
  return (
    <Box px={5} py={2} minW="250px" width={width}>
      <Stat>
        <StatLabel>
          <Tag colorScheme={colorScheme} fontSize={20} fontWeight={500} variant="solid">{title}</Tag>
        </StatLabel>
        <StatNumber fontSize={40}>{formattedValue}</StatNumber>
        <StatHelpText>{range}</StatHelpText>
      </Stat>
    </Box>
  );
};

export default SettlementCard;
