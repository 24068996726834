export const transactionsTableHeaders = [
  {
    Header: "S.No",
  },
  {
    Header: "Transaction Date",
  },
  {
    Header: "Charger",
  },
  {
    Header: "Connector",
  },
  {
    Header: "Gross Amount",
  },
  {
    Header: "Tax Amount",
  },
  {
    Header: "Net Amount",
  },
  // {
  //   Header: "Actions",
  // },
];
export const payoutsTableHeaders = [
  {
    Header: "S.No",
  },
  {
    Header: "Payout Ref No",
  },
  {
    Header: "Payout Date",
  },
  {
    Header: "Total Amount",
  },
  {
    Header: "Settled to Bank",
  },
  {
    Header: "Account No",
  },
  {
    Header: "UTR No",
  },
  {
    Header: "Actions",
  },
];
export const updatePayoutTableHeaders = [
  {
    label: "Payee Name",
    placeholder: "Enter Payee Name",
    type: "input",
    name: "PayeeName",
    helper: "",
  },
  {
    label: "Payout Reference No.",
    placeholder: "Enter Payout Reference No.",
    type: "input", 
    name: "PayoutRefNo",
    helper: "",
  },
  {
    label: "Gross Amount",
    placeholder: "Enter Gross Amount", 
    type: "input",
    name: "GrossAmount",
    helper: "",
  },
  {
    label: "Tax Amount",
    placeholder: "Enter Tax Amount",
    type: "input",
    name: "TaxAmount",
    helper: "",
  },
  {
    label: "Net Amount",
    placeholder: "Enter Net Amount",
    type: "input",
    name: "NetAmount",
    helper: "",
  },
  // Total Units Consumed
  {
    label: "Total Units Consumed",
    placeholder: "Enter Total Units Consumed",
    type: "input",
    name: "TotalUnitsConsumed",
    helper: "",
  },
  //Settled to Bank
  {
    label: "Settled to Bank",
    placeholder: "Enter Settled to Bank",
    type: "input",
    name: "SettledToBank",
    helper: "",
  },
  // Benificiary Name
  {
    label: "Benificiary Name",
    placeholder: "Enter Benificiary Name",
    type: "input",
    name: "BenificiaryName",
    helper: "",
  },
  // Benificiary Account No.
  {
    label: "Benificiary Account No.",
    placeholder: "Enter Benificiary Account No.",
    type: "input",
    name: "BenificiaryAccountNo",
    helper: "",
  },
  // IFSC Code
  {
    label: "IFSC Code",
    placeholder: "Enter IFSC Code",
    type: "input",
    name: "IfscCode",
    helper: "", 
  },
  //UTR No.
  {
    label: "UTR No.",
    placeholder: "Enter UTR No.",
    type: "input",
    name: "UtrNo",
    helper: "", 
  },
  // Arrears
  {
    label: "Arrears",
    placeholder: "Enter Arrears",
    type: "number",
    name: "Arrears",
    helper: "", 
  },
  //Status
  {
    label: "Status",
    placeholder: "Enter Status",
    type: "input",
    name: "Status",
    helper: "", 
  },
  // Type 
  {
    label: "Type",
    placeholder: "Enter Type",
    type: "input",
    name: "Type",
    helper: "", 
  },
]
 
export const payoutRunTableHeaders = [
  {
    Header: "S.No",
  },
  {
    Header: "Payee Name",
  },
  {
    Header: "Type",
  },
  {
    Header: "Total Units Consumed",
  },
  {
    Header: "Gross Amount",
  },
  {
    Header: "Tax Amount",
  },
  {
    Header: "Net Amount",
  },
  {
    Header: "Arrears",
  },
  {
    Header: "Actions",
  },
];
export const payRunTableHeaders = [
  {
    Header: "S.No",
  },
  {
    Header: "Id",
  },
  {
    Header: "Total Amount",
  },
  {
    Header: "Payment Status",
  },
  {
    Header: "Actions",
  },
];
