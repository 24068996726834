import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, {useMemo, useState} from "react";
import {useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";

// Custom components
import Card from "components/card/Card";

import {useRecoilState, useRecoilValue} from "recoil";
import CreateUserDrawer from "./CreateUserDrawer";
import UpdateUserDrawer from "./UpdateUserDrawer";
import {updateUserDrawerState} from "atoms/updateUserDrawerState";
import {createUserDrawerState} from "atoms/createUserDrawerState";
import DeleteModal from "components/modals/DeleteModal";
import {deleteModalState} from "atoms/deleteModalState";
import AccessManagementModal from "components/modals/AccessManagementModal";
import {loggedInUserState} from "atoms/loggedInUserState";
import {accessLevels} from "utils/accessLevels";
// import Menu from "components/menu/MainMenu";

export default function ColumnsTable(props) {
  const {columnsData, tableData, getUsers} = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const role = loggedInUser?.role;
  const canEdit =
    accessLevels[role]?.accessLevel <= 2 || role === "urzza_cms_admin";
  const [updateDrawerState, setUpdateDrawerState] = useRecoilState(
    updateUserDrawerState
  );
  const [createDrawerState, setCreateDrawerState] = useRecoilState(
    createUserDrawerState
  );

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 8;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const [showDeleteModal, setShowDeleteModal] =
    useRecoilState(deleteModalState);
  const [selectedUser, setSelectedUser] = useState(null);

  const {isOpen, onOpen, onClose} = useDisclosure();
  const [accessManagementData, setAccessManagementData] = useState(null);

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };
  const updateHandler = () => {
    setUpdateDrawerState(null);
    setCreateDrawerState(null);
    getUsers();
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(false);
    getUsers();
  };

  const handleCancelDelete = () => {
    setSelectedUser(null);
    setShowDeleteModal(false);
  };

  const editHandler = (id) => {
    setUpdateDrawerState({isOpen: true, activeId: id});
  };

  const accessManagementHandler = (user) => {
    setAccessManagementData(user);
    onOpen();
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{sm: "scroll", lg: "hidden"}}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        {/* <Menu /> */}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{sm: "10px", lg: "12px"}}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "S.No") {
                    data = (
                      <Flex align="center">
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {Number(cell?.row?.id) + 1}
                        </Text>
                      </Flex>
                    );
                  } else if (cell?.column?.Header === "First Name") {
                    data = (
                      <Flex align="center">
                        <Text
                          me="10px"
                          color={textColor}
                          fontSize="sm"
                          fontWeight="700"
                        >
                          {cell?.row?.original?.FirstName}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Last Name") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell?.row?.original?.LastName}
                      </Text>
                    );
                  } else if (cell.column.Header === "Phone Number") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell?.row?.original?.MobileNumber}
                      </Text>
                    );
                  } else if (cell.column.Header === "Email") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell?.row?.original?.Email}
                      </Text>
                    );
                  } else if (cell.column.Header === "Role") {
                    const role = cell?.row?.original?.Role;
                    let displayRole = "Manager";
                    switch (role) {
                      case "admin": {
                        displayRole = "Admin";
                        break;
                      }
                      case "manager": {
                        displayRole = "Manager";
                        break;
                      }
                      case "property_owner": {
                        displayRole = "Property Owner";
                        break;
                      }
                      default: {
                        displayRole = "N/A";
                      }
                    }
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {displayRole}
                      </Text>
                    );
                  } else if (cell?.column?.Header === "Type") {
                    const type = cell?.row?.original?.Type;
                    const displayText = type ? type : "-";
                  
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {displayText}
                      </Text>
                    );                  
                  } else if (cell.column.Header === "Actions") {
                    const loggedInUserRole = loggedInUser?.role;
                    const entryRole = cell?.row?.original?.Role;
                    const entryLevel = accessLevels[entryRole]?.accessLevel;
                    const loggedInLevel =
                      accessLevels[loggedInUserRole]?.accessLevel;
                    const isDisabled =
                      entryLevel <= 0
                        ? loggedInLevel > 0
                          ? true
                          : false
                        : false;
                    const isSelfAccount = loggedInUser?.userId === cell?.row?.original?.Id;
                    data = (
                      <>
                        <Button
                          colorScheme="blue"
                          variant="ghost"
                          onClick={() => editHandler(cell?.row?.original?.Id)}
                          isDisabled={!canEdit || isDisabled }
                          hidden = {!canEdit || isDisabled }
                        >
                          Edit
                        </Button>
                        {loggedInLevel <= 1 && entryLevel > 1 && (
                          <Button
                            colorScheme="green"
                            variant="ghost"
                            onClick={() =>
                              accessManagementHandler(cell?.row?.original)
                            }
                          >
                            Access
                          </Button>
                        )}
                        {(loggedInLevel <= 1) && <Button
                          colorScheme="red"
                          variant="ghost"
                          onClick={() => handleDeleteClick(cell?.row?.original)}
                          isDisabled={!canEdit || isDisabled || isSelfAccount|| cell?.row?.original?.Role === "urzza_cms_admin"|| cell?.row?.original?.Role === "tenant_cms_admin"}
                          hidden = {!canEdit || isDisabled || isSelfAccount|| cell?.row?.original?.Role === "urzza_cms_admin"|| cell?.row?.original?.Role === "tenant_cms_admin"}
                        >
                          Delete
                        </Button>}
                      </>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{sm: "14px"}}
                      minW={{sm: "150px", md: "200px", lg: "auto"}}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        data={selectedUser}
        title="user"
      />
      {createDrawerState?.isOpen && <CreateUserDrawer update={updateHandler} />}
      {updateDrawerState?.isOpen && <UpdateUserDrawer update={updateHandler} />}
      <AccessManagementModal
        isOpen={isOpen}
        onClose={onClose}
        data={accessManagementData}
        onOpen={onOpen}
      />
    </Card>
  );
}
