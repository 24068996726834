import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useRecoilState, useSetRecoilState } from "recoil";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { toastState } from "atoms/toastState";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import Card from "components/card/Card";
import logger from "infra/logger";
import { updatePartnerDrawerState } from "atoms/updatePartnerDrawerState";
import { partnerDrawerSelectOptions } from "../variables/partnerDrawerSelectOptions";

const UpdatePartnerDrawer = (props) => {
  const setToast = useSetRecoilState(toastState);
  const [drawerState, setDrawerState] = useRecoilState(
    updatePartnerDrawerState
  );
  const [partner, setPartner] = useState({
    Name: "",
    Email: "",
    MobileNumber: "",
    Status: false,
  });
  const validationSchema = yup.object().shape({
    Name: yup.string(),
    MobileNumber: yup
      .string()
      .max(10)
      .matches(/^[0-9]*$/, "Mobile Number must contain only digits"),
    Email: yup.string().email("Invalid email address"),
    Status: yup.boolean(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [{ loading: partnerLoading }, getPartner, cancelRequest] = useAxios(
    { method: "GET", url: `/api/partner/${drawerState?.activeId}` },
    { manual: true }
  );

  const [
    { data: updatedPartner, loading: updatePartnerLoading },
    updatePartner,
  ] = useAxios(
    { method: "PATCH", url: `/api/partner/update/${drawerState?.activeId}` },
    { manual: true }
  );

  const getPartnerDetails = async () => {
    try {
      const response = await getPartner();
      const data = response.data.data;
      setValue("Name", data.Name);
      setValue("Email", data.Email);
      setValue("MobileNumber", data.MobileNumber);
      setValue("Status", data.IsActive);
      setPartner((prev) => {
        return {
          ...prev,
          Name: data.Name,
          Email: data.Email,
          MobileNumber: data.MobileNumber,
          Status: data.IsActive,
        };
      });
      logger.log(data);
    } catch (error) {
      logger.log(error);
      if (error?.code !== "ERR_CANCELED") {
        setToast({
          isOpen: true,
          title: "Error occured while getting partner details",
          status: "error",
        });
        closeHandler();
      }
    }
  };

  const closeHandler = () => {
    setDrawerState(null);
  };

  const saveHandler = async () => {
    const formValues = getValues();
    logger.log(formValues);
    let partnerToUpdate = {};
    if (
      formValues.Email.trim() !== "" &&
      formValues.Email.trim() !== partner.Email
    ) {
      partnerToUpdate["email"] = formValues.Email.trim();
    }
    if (
      formValues.Name.trim() !== "" &&
      formValues.Name.trim() !== partner.Name
    ) {
      partnerToUpdate["name"] = formValues.Name.trim();
    }
    if (
      formValues.MobileNumber.trim() !== "" &&
      formValues.MobileNumber.trim() !== partner.MobileNumber
    ) {
      partnerToUpdate["mobile"] = formValues.MobileNumber.trim();
    }
    if (formValues.Status !== "" && formValues.Status !== partner.IsActive) {
      partnerToUpdate["is_active"] = JSON.parse(formValues.Status);
    }
    logger.log(partnerToUpdate);
    if (Object.keys(partnerToUpdate).length > 0) {
      try {
        const response = await updatePartner({ data: partnerToUpdate });
        setToast({
          isOpen: true,
          title: "partner details Updated",
          status: "success",
        });
        props.update();
      } catch (error) {
        const err = error?.response?.data?.error;
        logger.log(error);
        if (error?.code != "ERR_CANCELED") {
          setToast({
            isOpen: true,
            title: "Error occured when updating partner",
            description: err ? err : null,
            status: "error",
          });
        }
      }
    } else {
      setToast({
        isOpen: true,
        title: "nothing new to update",
        status: "warning",
      });
    }
  };

  useEffect(() => {
    if (drawerState?.activeId) {
      getPartnerDetails();
      return () => cancelRequest();
    }
  }, []);

  return (
    <Drawer
      isOpen={drawerState?.isOpen}
      placement="right"
      onClose={closeHandler}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          Update Your Details {drawerState?.activeId && partner["Email"]}
        </DrawerHeader>

        <DrawerBody>
          {!partnerLoading && !updatePartnerLoading ? (
            <>
              <Card>
                <form>
                  {partnerDrawerSelectOptions.map((item, index) => (
                    <Flex key={index} mb="20px" alignItems="center">
                      <Controller
                        name={item.name}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl
                            flexDirection="row"
                            isInvalid={errors[item.name]}
                          >
                            <FormLabel fontSize={20}>{item.label}</FormLabel>
                            {item.type === "select" ? (
                              <Select
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                width="400px"
                                height="50px"
                                isRequired
                              >
                                <option value="">Select</option>
                                {item.options.map((option, ind) => {
                                  return (
                                    <option key={ind} value={option.value}>
                                      {option.name}
                                    </option>
                                  );
                                })}
                              </Select>
                            ) : (
                              <Input
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                placeholder={item.placeholder}
                                width="400px"
                                height="50px"
                                type={item.type}
                                isRequired
                              />
                            )}
                            <FormHelperText color={"#f57d36"}>
                              {item.helper}
                            </FormHelperText>
                            <FormErrorMessage mb={"20px"}>
                              {errors[item.name] && errors[item.name].message}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    </Flex>
                  ))}
                </form>
              </Card>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            bg="#f57d36"
            color="white"
            colorScheme="orange"
            onClick={handleSubmit(saveHandler)}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default UpdatePartnerDrawer;
