import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useRecoilState, useSetRecoilState } from "recoil";
import * as yup from "yup";
import useAxios from "axios-hooks";

import { toastState } from "atoms/toastState";
import { updateChargingPointDrawerState } from "atoms/updateChargingPointDrawerState";
import Card from "components/card/Card";
import LoadingSpinner from "components/feedback/LoadingSpinner";
import logger from "infra/logger";
import { chargePointDrawerSelectOptions } from "../variables/chargePointDrawerSelectOption";

const UpdateChargePointDrawer = (props) => {
  const setToast = useSetRecoilState(toastState);
  const [drawerState, setDrawerState] = useRecoilState(
    updateChargingPointDrawerState
  );
  const [chargePoint, setChargePoint] = useState({
    Name: "",
    Code: "",
    Description: "",
    Visibility: "",
    TypeOfCharger: "",
    Power: "",
  });

  const validationSchema = yup.object().shape({
    Name: yup.string(),
    Code: yup.string(),
    Description: yup.string(),
    Visibility: yup.string(),
    TypeOfCharger: yup.string(),
    Power: yup.string(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [{ loading: chargePointLoading }, getChargePoint, cancelRequest] = useAxios(
    { method: "GET", url: `/api/chargePoint/${props?.chargePointId}` },
    { manual: true }
  );

  const [
    { data: UpdateChargePoint, loading: updateChargingPointLoading },
    updateChargePoint,
  ] = useAxios(
    {
      method: "PATCH",
      url: `/api/chargePoint/update/${props?.chargePointId}`,
    },
    { manual: true }
  );

  const getChargePointDetails = async () => {
    try {
      const response = await getChargePoint();
      const data = response.data.data;
      setValue("Name", data.Name);
      setValue("Code", data.Code);
      setValue("Visibility", data.Visibility);
      setValue("TypeOfCharger", data.TypeOfCharger);
      setValue("Power", data.Power);
      setValue("Description", data.Description);

      setChargePoint((prev) => {
        return {
          ...prev,
          Name: data.Name,
          Code: data.Code,
          Description: data.Description,
          Visibility: data.Visibility,
          TypeOfCharger: data.TypeOfCharger,
          Power: data.Power,
        };
      });
    } catch (error) {
      logger.log(error?.code);
      if (error?.code !== "ERR_CANCELED") {
      setToast({
        isOpen: true,
        title: "Error occured while getting ChargePoint details",
        status: "error",
      });
      closeHandler();
    }
    }
  };

  const closeHandler = () => {
    setDrawerState(null);
  };

  const UpdateHandler = async () => {
    const formValues = getValues();
    let chargePointUpdate = {};
    if (
      formValues.Name.trim() !== "" &&
      formValues.Name.trim() !== chargePoint.Name
    ) {
      chargePointUpdate["Name"] = formValues.Name.trim();
    }
    if (
      formValues.Code.trim() !== "" &&
      formValues.Code.trim() !== chargePoint.Code
    ) {
      chargePointUpdate["Code"] = formValues.Code.trim();
    }
    if (
      formValues.Description.trim() !== "" &&
      formValues.Description.trim() !== chargePoint.Description
    ) {
      chargePointUpdate["Description"] = formValues.Description.trim();
    }
    if (
      formValues.Visibility.trim() !== "" &&
      formValues.Visibility.trim() !== chargePoint.Visibility
    ) {
      chargePointUpdate["Visibility"] = formValues.Visibility.trim();
    }
    if (
      formValues.TypeOfCharger.trim() !== "" &&
      formValues.TypeOfCharger.trim() !== chargePoint.TypeOfCharger
    ) {
      chargePointUpdate["TypeOfCharger"] = formValues.TypeOfCharger.trim();
    }
    if (
      typeof formValues.Power === "string" &&
      formValues.Power.trim() !== ""
    ) {
      chargePointUpdate["Power"] = parseInt(formValues.Power.trim());
    }

    if (Object.keys(chargePointUpdate).length > 0) {
      try {
        const response = await updateChargePoint({ data: chargePointUpdate });
        setToast({
          isOpen: true,
          title: "ChargePoint details Updated",
          status: "success",
        });
        props.update();
      } catch (error) {
        const err = error?.response?.data?.error;
        logger.log(error);
        setToast({
          isOpen: true,
          title: "Error occured when updating Charge Point",
          description: err ? err : null,
          status: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        title: "nothing new to update",
        status: "warning",
      });
    }
  };

  useEffect( () => {
    if (props?.chargePointId) {
       getChargePointDetails();
       return () => cancelRequest();
    }
  }, []);

  return (
    <Drawer
      isOpen={drawerState?.isOpen}
      placement="right"
      onClose={closeHandler}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Update a Charge Point</DrawerHeader>
        <DrawerBody>
          {!chargePointLoading && !updateChargingPointLoading ? (
            <>
              <Card>
                <form>
                  {chargePointDrawerSelectOptions.map((item, index) => (
                    <Flex key={index} mb="20px" alignItems="center">
                      <Controller
                        name={item.name}
                        control={control}
                        render={({ field }) => (
                          <FormControl
                            isRequired
                            flexDirection="row"
                            isInvalid={errors[item.name]}
                          >
                            <FormLabel fontSize={16}>{item.label}</FormLabel>
                            {item.type === "select" ? (
                              <Select
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                width="400px"
                                height="50px"
                              >
                                <option value="">Select</option>
                                {item.options.map((option, ind) => (
                                  <option key={ind} value={option.value}>
                                    {option.name}
                                  </option>
                                ))}
                              </Select>
                            ) : (
                              <Input
                                {...field}
                                focusBorderColor="#f57d36"
                                fontSize="sm"
                                placeholder={item.placeholder}
                                width="400px"
                                height="50px"
                                type={item.type}
                                isRequired
                              />
                            )}
                            <FormHelperText color={"#f57d36"}>
                              {item.helper}
                            </FormHelperText>
                            <FormErrorMessage mb={"20px"}>
                              {errors[item.name] && errors[item.name].message}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      />
                    </Flex>
                  ))}
                </form>
              </Card>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={closeHandler}>
            Cancel
          </Button>
          <Button
            bg="#f57d36"
            color="white"
            colorScheme="orange"
            onClick={handleSubmit(UpdateHandler)}
          >
            Update
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default UpdateChargePointDrawer;
