export const UpdateUserFinancialDrawerSelectOptions =
[
  {
    label: "Account Number",
    placeholder: "Account Number",
    name: "BankAccountNo",
    type: "input",
  },
  {
    label: "Account Holder Name",
    placeholder: "Account Holder Name",
    name: "BeneficiaryName",
    type: "input",
  },
  {
    label: "Bank Name",
    placeholder: "Bank Name",
    name: "BankName",
    type: "input",
  },
  {
    label: "IFSC Code",
    placeholder: "IFSC Code",
    name: "IfscCode",
    type: "input",
  },
  {
    label: "PAN Card",
    placeholder: "PAN Card",
    name: "PanCard",
    type: "input",
  },
  {
    label: "GST Number",
    placeholder: "GST Number",
    name: "GstNo",
    type: "input",
  },
]
  