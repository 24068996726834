import logger from "infra/logger";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const generateData = (day, data) => {
  let idx = -1;
  switch (day) {
    case "Sunday":
      idx = 0;
      break;
    case "Monday":
      idx = 1;
      break;
    case "Tuesday":
      idx = 2;
      break;
    case "Wednesday":
      idx = 3;
      break;
    case "Thursday":
      idx = 4;
      break;
    case "Friday":
      idx = 5;
      break;
    case "Saturday":
      idx = 6;
      break;
    default:
      return [];
  }
  const series = [];
  for (let i = 0; i < 24; i++) {
    const x = `${i + 1}Hr`;
    const y = data?.[idx]?.[i] ?? 0; // Default to 0 if data is undefined
    series.push({ x, y });
  }
  return series;
};

const HeatMap = ({ data }) => {
  const [heatmapData, setHeatMapData] = useState({
    series: [],
    options: {
      chart: { height: 350, type: "heatmap"},

      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        heatmap: {
        enableShades: true,
        distributed: false,
        useFillColorAsStroke: false,
        },
      },
      colors: ["#F57D36"],
      title: {
        text: "Heatmap",
        align: "center",
        style: {
          fontSize: "30px",
          fontFamily: "DM Sans",
        },
      },
    },
  });

  useEffect(() => {
    setHeatMapData({
      series: [
        { name: "Saturday", data: generateData("Saturday", data) },
        { name: "Friday", data: generateData("Friday", data) },
        { name: "Thursday", data: generateData("Thursday", data) },
        { name: "Wednesday", data: generateData("Wednesday", data) },
        { name: "Tuesday", data: generateData("Tuesday", data) },
        { name: "Monday", data: generateData("Monday", data) },
        { name: "Sunday", data: generateData("Sunday", data) },
      ],
      options: heatmapData.options,
    });
  }, [data]);

  return (
    <div id="chart">
      <ReactApexChart
        options={heatmapData.options}
        series={heatmapData.series}
        type="heatmap"
        height={400}
      />
    </div>
  );
};

export default HeatMap;
