export const tenantTableHeaders = [
  {
    Header: "S.No",
  },
  {
    Header: "Name",
  },
  {
    Header: "NameSpace",
  },
  {
    Header: "Mobile Number",
  },
  {
    Header: "Email",
  },
  {
    Header: "Type",
  },
  {
    Header: "Status",
  },
  {
    Header: "Download",
  },
  {
    Header: "Actions",
  },
];
